@use '/src/styles/variables' as styles;

.counterparty {
	padding: 0.7vw 1.05vw;
	width: 100%;
	height: 100%;
	border-radius: styles.$roundedLg;
	transition: all ease 0.3s;
	box-shadow: 0.2vw 0.13vw 0.69vw 0vw rgba(0, 0, 0, 0.3);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--backgroundContainer);

	&:hover{
		border: 1px solid var(--accentPrimary);
		transform: scale(1.01);
	}
	
	.counterparty-info {
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 10.42vw;
		flex-grow: 1;
		gap: 1.05vw;

		.counterparty-info-content {
			display: flex;
			gap: 1vw;

			.counterparty-avatar {
				svg,
				img {
				width: 2.78vw;
				height: 2.78vw;
				}
	    	}
			
			.counterparty-details {
				min-width: 0;
				padding-right: 1vw;
				display: flex;
				justify-content: center;
				flex-direction: column;
				.counterparty-company-name {
					font-size: styles.$textLg;
					font-weight: styles.$fontMedium;
					color: var(--text1);
					word-wrap: break-word;
				}
				.counterparty-company-description {
					font-size: styles.$textMd;
					font-weight: styles.$fontLight;
					color: var(--text1);
				}
			}
	   }
	   
	   .counterparty-info-tags {
		display: flex;
		flex-wrap: wrap;
		max-height: 6vw;
		overflow-x: hidden;
		gap: 0.7vw;

		.counterparty-tag {
			padding: 0.14vw;
			border-radius: styles.$roundedMd;
			border: 0.035vw solid var(--text4);
			color: var(--text4);
			font-family: styles.$fontMain;
			font-size: styles.$textXs;
			font-weight: styles.$fontMedium;
		}
	   }
	}

	.label {
		max-width: 18vw;
		p {
			font-size: 0.7vw;
		}
	}

  }

@media screen and (max-width: 768px) {
	.counterparty {
		padding: 1.05vw 1.5vw;
		border-radius: 1.5vw;
		box-shadow: 0.3vw 0.19vw 1vw 0vw rgba(0, 0, 0, 0.3);

		.counterparty-info {
			width: 15.63vw;
			gap: 1.5vw;
	
			.counterparty-info-content {
				gap: 1.5vw;
	
				.counterparty-avatar {
					svg,
					img {
						width: 4.17vw;
						height: 4.17vw;
					}
				}
				
				.counterparty-details {
					padding-right: 1.5vw;
					
					.counterparty-company-name {
						font-size: 1.875vw;
					}
					.counterparty-company-description {
						font-size: 1.66vw;
					}
				}
		   }
		   
		   .counterparty-info-tags {
				gap: 1.05vw;
	
				.counterparty-tag {
					padding: 0.21vw;
					border-radius: 0.75vw;
					border: 0.05vw solid var(--text4);
					font-size: 1.05vw;
				}
		   }
		}

		.label {
			max-width: 22vw;
			p {
				font-size: 1.05vw;
			}
		}
	
	}
}


@media screen and (max-width: 768px) {
	.counterparty {
		padding: 1.05vw 1.5vw;
		border-radius: 1.5vw;
		box-shadow: 0.3vw 0.19vw 1vw 0vw rgba(0, 0, 0, 0.3);

		.counterparty-info {
			width: 15.63vw;
			gap: 1.5vw;
	
			.counterparty-info-content {
				gap: 1.5vw;
	
				.counterparty-avatar {
					svg,
					img {
						width: 4.17vw;
						height: 4.17vw;
					}
				}
				
				.counterparty-details {
					padding-right: 1.5vw;
					
					.counterparty-company-name {
						font-size: 1.875vw;
					}
					.counterparty-company-description {
						font-size: 1.66vw;
					}
				}
		   }
		   
		   .counterparty-info-tags {
				gap: 1.05vw;
	
				.counterparty-tag {
					padding: 0.21vw;
					border-radius: 0.75vw;
					border: 0.05vw solid var(--text4);
					font-size: 1.05vw;
				}
		   }
		}
	
	}
}



@media screen and (max-width: 425px) {
	.counterparty {
		padding: 2.1vw 3vw;
		border-radius: 3vw;
		box-shadow: 0.6vw 0.4vw 2vw 0vw rgba(0, 0, 0, 0.3);

		.counterparty-info {
			width: 31.2vw;
			gap: 3vw;
	
			.counterparty-info-content {
				gap: 3vw;
	
				.counterparty-avatar {
					svg,
					img {
						width: 8.34vw;
						height: 8.34vw;
					}
				}
				
				.counterparty-details {
					padding-right: 3vw;
					
					.counterparty-company-name {
						font-size: 3.75vw;
					}
					.counterparty-company-description {
						font-size: 3.32vw;
					}
				}
		   }
		   
		   .counterparty-info-tags {
				gap: 2.1vw;
	
				.counterparty-tag {
					padding: 0.42vw;
					border-radius: 1.5vw;
					border: 0.1vw solid var(--text4);
					font-size: 2.1vw;
				}
		   }
		}
		.label {
			max-width: 13vw;
			p {
				font-size: 2.1vw;
			}
		}
	}
}