.bill-date-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .expired,.soon-expired{
    opacity: 0.6;
    font-weight: 500;
    font-size: .8vw;
    padding: 0.3vw 1vw;
    text-wrap: wrap;
  }
  .expired{
    color: var(--colorAlertText);
  }
  .soon-expired{
    color: #8A8A14;
  }
}

@media screen and (max-width: 768px) {
  .bill-date-container{
    .soon-expired,.expired{
      font-size: 1.2vw;
      padding: 0.45vw 1.5vw;
    }
  }
}

@media screen and (max-width: 425px) {
  .bill-date-container{
    .soon-expired.soon-expired,.expired{
      font-size: 2.4vw;
      padding: 0.9vw 3vw;
    }
  }
}