.create-provider-container {
	padding:2vw;
    .form-elements-wrapper{
        width: 56vw;
		height: 100%;
		display: grid;
		grid-template-columns: 22vw 22vw;
		justify-content: center;
		gap: 1.3vw 4.6vw;
		
        
    } 
	.text-area {
		justify-content: flex-end;
		.text-area-container {
			background-color: var(--accentTertiary);
			height: initial;
			border-radius: 0.7vw;
			textarea {
				background-color: transparent;
			}
		}
	}
        
}