@use '/src/styles/variables' as styles;

.file-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: calc(100% - 1vw);
    position: relative;
    cursor: pointer;

    &.document {
        gap: 0.7vw;
        max-width: 10vw;

        .file-list-modal-files-name {
            color: #fff;
            font-size: styles.$textXs;
            text-align: center;
            width: 100%;
        }
    }

    img {
        height: auto;
        border-radius: styles.$roundedMd;
        max-width: 100%;
        overflow: auto;
    }

    svg {
        height: 2.43vw;

        path {
            fill: #fff;
        }
    }

    .video-player-wrapper {
        height: 100%;

        .video-player-container {
            height: 100%;

            video {
                border-radius: styles.$roundedMd;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {


    .file-item-container {
        height: calc(100% - 1.5vw);

        &.document {
            gap: 1.05vw;
            max-width: 15vw;

            .file-list-modal-files-name {
                font-size: 1.05vw;
            }
        }

        img {
            border-radius: 0.75vw;
        }

        svg {
            height: 2.43vw;

        }

        .video-player-wrapper {
            .video-player-container {

                video {
                    border-radius: 0.75vw;
                }
            }
        }
    }

}


@media screen and (max-width: 485px) {


    .file-item-container {
        height: calc(100% - 3vw);

        &.document {
            gap: 2.1vw;
            max-width: 30vw;

            .file-list-modal-files-name {
                font-size: 2.1vw;
            }
        }

        img {
            border-radius: 1.5vw;
        }

        svg {
            height: 4.86vw;

        }

        .video-player-wrapper {
            .video-player-container {

                video {
                    border-radius: 1.5vw;
                }
            }
        }
    }

}