.login {
  background-color: var(--accentPrimary);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .login-container {
    width: 40%;
    margin: 0 auto;
    border-radius: 1.4vw;
    background-color: var(--accentTertiary);
    padding: 2.08vw 3.68vw;
    display: flex;
    flex-direction: column;
    gap: 0.90vw;
    
    &-langueage {
      position: absolute;
      top: 1vw;
      right: 1vw;
      background-color: var(--accentTertiary);
      padding: 1vw 1vw;
      border-radius: 1vw;
    }
    
    .error-container{
      height: 1.3vw;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1vw 0;

      p{
        color: var(--colorAlertText);
        margin-left: 0.83vw;
        font-size: 1.112vw;
      }
    }

    .login-form {
      display: flex;
      flex-direction: column;
      gap: 1.80vw;
      font-size: 0.97vw;

      .form-controls {
        display: flex;
        flex-direction: column;
        gap: 2.08vw;
      }
      .login-options {
        display: flex;
        justify-content: space-between;

        .checkbox-label{
          font-size: 0.97vw;
        }

        a {
          font-size: 0.97vw;
          color: var(--text1);
        }
        
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .login {
    .login-container {
      border-radius: 2.1vw;
      padding: 3.12vw 5.52vw;
      gap: 1.8vw;
      width: 60%;

      &-langueage {
        top: 1.5vw;
        right: 1.5vw;
        padding: 1.5vw 1.5vw;
        border-radius: 1.5vw;
      }

      
      .error-container{
        height: 2.1vw;
        margin: 1.5vw 0;
  
        p{
          margin-left: 1.2vw;
          font-size: 1.66vw;
        }
      }
  
      .login-form {
        gap: 2.7vw;
        font-size: 1.45vw;
  
        .form-controls {
          gap: 3.12vw;
        }

        .login-options {

          .checkbox-label{
            font-size: 1.45vw;
          }
          
          a {
            font-size: 1.45vw;
          }
        }
        
        
      }
    }
  }
}

@media screen and (max-width: 425px) {

  .login {
    .login-container {
      border-radius: 4.2vw;
      padding: 6.24vw 11.04vw;
      gap: 3.6vw;
      width: 100%;

      &-langueage {
        top: 3vw;
        right: 3vw;
        padding: 3vw 3vw;
        border-radius: 3vw;
      }

      .error-container{
        height: 4.2vw;
        margin: 3vw 0;
  
        p{
          margin-left: 2.4vw;
          font-size: 3.32vw;
        }
      }
  
      .login-form {
        gap: 5.4vw;
        font-size: 2.9vw;
  
        .form-controls {
          gap: 6.24vw;
        }
        .login-options {
          .checkbox-label{
            font-size: 2.9vw;
          }

          a {
            font-size: 2.9vw;
          }
        }

      }
    }
  }
}

