.chat-bar-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  border-left: 0.12vw solid var(--accentDisabled);
  min-width: 24.3vw;

  .chat-bar-chats-container {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 24.3vw;
    .chat-card-wrapper {
      cursor: pointer;
      width: 100%;
      height: auto;
      padding: 1.7vw 2vw;
      display: flex;
      align-items: center;
      transition: all ease 0.3s;
      position: relative;
      top: -1.5vw;
    }
  }
}


@media screen and (max-width: 768px) {

  .chat-bar-container {
    width: 100%;
    border-left: 0.18vw solid var(--accentDisabled);
  
    .chat-bar-chats-container {
      width: 100%;
    }
  }

}

@media screen and (max-width: 425px) {

  .chat-bar-container {
    width: 100%;
    border-left: 0.36vw solid var(--accentDisabled);
  
    .chat-bar-chats-container {
      width: 100%;
    }
  }

}