.feedback {
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

@media screen and (max-width: 768px) {

    .feedback {
        gap: 3vw;
    }

}

@media screen and (max-width: 425px) {

    .feedback {
        gap: 6vw;
    }

}