.list-card-skeleton-container{
    width: 100%;
  padding: 1.04vw 1.73vw 1.73vw 1.73vw;
  box-shadow: 0.21vw 0.14vw 0.7vw 0vw rgba(0, 0, 0, 0.1);
  border-radius: 0.7vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25vw;
}

@media screen and (max-width: 768px) {
    .list-card-skeleton-container{
      padding: 1.54vw 2.6vw 2.6vw 2.6vw;
      box-shadow: 0.31vw 0.21vw 1.05vw 0vw rgba(0, 0, 0, 0.1);
      border-radius: 1.05vw;
    }
}

@media screen and (max-width: 425px) {
    .list-card-skeleton-container{
      padding: 3.12vw 5.1vw 5.1vw 5.1vw;
      box-shadow: 0.63vw 0.42vw 2.1vw 0vw rgba(0, 0, 0, 0.1);
      border-radius: 2.1vw;
    }
}