.audio-preview-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .audio-preview-delete-container{
        cursor: pointer;
        background: var(--colorAlertText);
        box-shadow: 0px 0px 0.69vw -1px rgba(0, 0, 0, 0.25);
        width: 2.78vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
            width: 1.32vw;
            height: 1.45vw;
        }
    }

    .audio-preview-player-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 85%;
        height: 100%;

        .audio-preview-player-controls{
            display: flex;
            flex-direction: column;
            align-items: center;

            .audio-recorder-timer {
                --audioTimerColor:#1E1B39;
            }

            .audio-preview-player-controls-button{
                svg{
                    width: 1.39vw;
                }
            }
        }

        .audio-preview-waves-container{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: 93%;
            min-width: 93%;
            height: 90%;

            .audio-wave{
                --waveFillingAccent: #1E1B39;
                --waveAccent: #F0F0F0;
            }
        }
    }

    .audio-preview-send-container{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        min-width: 2.92vw;
        min-height: 2.92vw;
        transition: all ease 0.3s;
        margin-right: 0.7vw;

        &:hover{
            background: var(--accentPrimary);
            path{
                fill: var(--textHover) !important;
            }
        }

        svg {
            height: 1.73vw;
            width: 1.73vw;
        }
    }
}

@media screen and (max-width: 768px) {

    .audio-preview-container{
    
        .audio-preview-delete-container{
            box-shadow: 0px 0px 1.05vw -1px rgba(0, 0, 0, 0.25);
            width: 4.17vw;
    
            svg{
                width: 1.98vw;
                height: 2.175vw;
            }
        }
    
        .audio-preview-player-container{
    
            .audio-preview-player-controls{
    
                .audio-preview-player-controls-button{
                    svg{
                        width: 2.1vw;
                    }
                }
            }
        }
    
        .audio-preview-send-container{
            min-width: 4.38vw;
            min-height: 4.38vw;
            margin-right: 1.05vw;
    
            svg {
                height: 2.6vw;
                width: 2.6vw;
            }
        }
    }

}

@media screen and (max-width: 425px) {

    .audio-preview-container{
    
        .audio-preview-delete-container{
            box-shadow: 0px 0px 2.1vw -1px rgba(0, 0, 0, 0.25);
            width: 8.34vw;
    
            svg{
                width: 4vw;
                height: 4.35vw;
            }
        }
    
        .audio-preview-player-container{
    
            .audio-preview-player-controls{
    
                .audio-preview-player-controls-button{
                    svg{
                        width: 4.2vw;
                    }
                }
            }
        }
    
        .audio-preview-send-container{
            min-width: 8.76vw;
            min-height: 8.76vw;
            margin-right: 2.1vw;
    
            svg {
                height: 5.2vw;
                width: 5.2vw;
            }
        }
    }

}