.confirmation-modal-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.75vw;
    height: 100%;
    width: 100%;
	padding: 0.5vw ;
    flex-direction: column;

    .description-container{
        max-width: 40vw;
        .description-content{
            font-size: 1.2vw;
            text-wrap: wrap;
            text-align: center;
        }
    }

    .error_objects-container{
        max-width: 40vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1vw;    
    }

    .buttons-container{
        width: 100%;
        display: flex;
        gap: 2vw;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {

    .confirmation-modal-container{
        gap: 2.63vw;
        padding: 0.75vw;
        .description-container{
            max-width: 60vw;
            .description-content{
                font-size: 1.8vw;
            }
        }
        .buttons-container{
            gap: 3vw;
        }
    }    

}

@media screen and (max-width: 425px) {

    .confirmation-modal-container{
        gap: 5.2vw;
        padding: 1.5vw;
        .description-container{
            max-width: 120vw;
            .description-content{
                font-size: 3.6vw;
            }
        }
        .buttons-container{
            gap: 6vw;
        }
    }    

}