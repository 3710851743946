@use '../../../../styles/variables' as styles;

.info-details-table-field{
	padding: .3vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1vw;
	
	&-title{
        color: var(--text3);
        font-weight: styles.$fontLight;
		display: flex;
		align-items: center;
		font-size: styles.$textSm;
    }

	&-content{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: .5vw;
		width: 100%;

		p{
			color: var(--text1);
			font-size: styles.$textMd;
		}
	}

	.error-wrapper p,
	.dropdown-wrapper p {
		display: flex;
		gap: 0.35vw;
		align-items: center;
		color: var(--colorAlertText);
		font-size: styles.$text2sm;
	}
}

@media screen and (max-width: 768px) {
	.info-details-table-field{
		padding: .45vw;
		gap: 1.5vw;

		&-title{
			font-size: 1.45vw;
		}
	
		&-content{
			gap: .75vw;
	
			p{
				font-size: 1.66vw;
			}	
		}

		.error-wrapper p,
		.dropdown-wrapper p {
			display: flex;
			gap: 0.35vw;
			align-items: center;
			color: var(--colorAlertText);
			font-size: styles.$text2sm;
		}
	}


}

@media screen and (max-width: 425px) {
	.info-details-table-field{
		padding: .9vw;
		gap: 3vw;
		
		&-title{
			font-size: 2.9vw;
		}
	
		&-content{
			gap: 1.5vw;
	
			p{
				font-size: 3.32vw;
			}
		}

		.error-wrapper p,
		.dropdown-wrapper p {
			display: flex;
			gap: 0.35vw;
			align-items: center;
			color: var(--colorAlertText);
			font-size: 1.2vw;
		}
	}


}