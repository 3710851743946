@use '/src/styles/variables' as styles;

.dashboard-stage-container {
    min-width: 18vw;
	display: flex;
	flex-direction: column;
	.pagination-container{
		padding: 0;
	}

	.dashboard-stage{
		height: 100%;	
	}

	.dashboard-stage-header{
		box-shadow: 0.1vw 0.1vw .5vw 0vw rgba(0, 0, 0, 0.172);
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: .45vw;
		border-top: .3vw solid black;
		margin-right: 5%;
		margin-left: 2%;
		padding: .2vw .5vw;
		margin-bottom: 1vw;

		.stage-info{
			padding:.3vw;
			font-size: .9vw;
			font-weight: 500;
			display: flex;
			align-items: center;
		}
		.icon{
			height: 1.5vw;
			width: 1.5vw;
			display: flex;
			align-items: center;
			color: white;
			background: none;
			border:none;
			padding: .2vw;
			justify-content: center;
			transition: all 100ms ease;
			border-radius: 1vw;

			svg{
				stroke: var(--text1);
				path{
					stroke: var(--text1);
				}
			}

			&:hover{
				scale: 1.05;
				cursor: pointer;
				background-color: var(--accentPrimary);
				svg{
					stroke: var(--textHover);
					path{
						stroke: var(--textHover);
					}
				}
			}

			&:active{
				scale: 1;
			}
			svg{
				height: 100%;
				width: 100%;
			}
		}
	}

    .dashboard-items {
        height: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 .5vw;
		overflow-x: visible;
		gap: .5vw;
        >:first-child {
            margin-top: 0
        }
		
    }
}

@media screen and (max-width: 768px) {


	.dashboard-stage-container {
		min-width: 27vw;

		.dashboard-stage-header{
			box-shadow: 0.15vw 0.15vw .75vw 0vw rgba(0, 0, 0, 0.172);
			border-radius: .675vw;
			border-top: .45vw solid black;
			margin-right: 5%;
			margin-left: 2%;
			padding: .3vw .75vw;
			margin-bottom: 1.5vw;

			.stage-info{
				padding: .45vw;
				font-size: 1.35vw;
			
			}
			.icon{
				height: 1.5vw;
				width: 1.5vw;
				
			}
		}

		.dashboard-items {
			padding: 0 .75vw;
			gap: .75vw;
			
		}
	}

}


@media screen and (max-width: 425px) {


	.dashboard-stage-container {
		min-width: 54vw;

		.dashboard-stage-header{
			box-shadow: 0.3vw 0.3vw 1.5vw 0vw rgba(0, 0, 0, 0.172);
			border-radius: 1.25vw;
			border-top: .9vw solid black;
			margin-right: 5%;
			margin-left: 2%;
			padding: .6vw 1.5vw;
			margin-bottom: 3vw;

			.stage-info{
				padding: .9vw;
				font-size: 2.7vw;
			
			}
			.icon{
				height: 3vw;
				width: 3vw;
				
			}
		}

		.dashboard-items {
			padding: 0 1.5vw;
			gap: 1.5vw;
			
		}
	}

}