// Variables
// -------------------
// To use these, add @use 'variables' as styles;

// Example:
// ---------------
// @use '/src/styles/variables' as styles;
// font-size: styles.font2sm;


// Typography
// ----------------------------------------
$fontMain: 'Poppins', sans-serif;

// Font Weight
$fontLight: 400;
$fontMedium: 500;
$fontBold: 600;
$font2xBold: 700;


// Font Size
$textXs: 0.7vw;
$text2sm: .8vw;         
$textSm: .97vw;          // Body Smalll
$textMd: 1.11vw;        // Body Medium
$textLg: 1.25vw;        // Body Large
$textXl: 1.38vw;        // Headline Medium
$text2xl: 1.66vw;       // Headline Large
$text3xl: 1.8vw;       



// Details
// --------------------------------

// Shadow
$shadowSm: .1vw .1vw 0.5vw 0.2vw rgba(0, 0, 0, 0.061);
$shadowMd: .15vw .15vw .7vw .7vw rgba(0, 0, 0, 0.048);
$shadowLg: .15vw .15vw 1.5vw .5vw rgba(0, 0, 0, 0.199);

// Border Radius
$roundedSm: .25vw;
$roundedMd: .5vw;
$roundedLg: 1vw;
$roundedXl: 1.5vw;
$rounded2xl: 2vw;

// Animation
$transitionDuration: 400ms;
$transition:all $transitionDuration ease;