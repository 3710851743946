@use '/src/styles/variables' as styles;
.search-messages-slider{
    position: absolute;
    right: 0;
    width: 50%;
    background: var(--backgroundSubContainer);
    height: calc(100% - 6.25vw);
    display: flex;
    flex-direction: column;
    gap: 0.69vw;
    transition: all 0.4s ease;
    box-shadow: 3px 2px 0.7vw 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;


    .search-messages-slider-text{
        color: var(--text3);
        font-size: styles.$textLg;
        font-weight: styles.$fontMedium;
    }

    .search-messages-slider-selectors-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.5vw 1.25vw;
        box-shadow: 0.2vw 0.27vw 0.69vw rgba(0, 0, 0, 0.1);

        .search-messages-slider-selectors-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 5vw;
            gap: 0.69vw;
        }

        
    }

    .search-messages-slider-tag-name{
        padding: 0 1.25vw;
    }

    .search-items-container{
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: 100%;
    }
    
}

@media screen and (max-width: 768px) {

    .search-messages-slider{
        width: 75%;
        height: calc(100% - 9.375vw);
        gap: 1.05vw;
        box-shadow: 3px 2px 1.05vw 0px rgba(0, 0, 0, 0.10);
    
    
        .search-messages-slider-text{
            font-size: 1.875vw;
        }
    
        .search-messages-slider-selectors-wrapper{
            padding: 2.25vw 1.875vw;
            box-shadow: 0.3vw 0.45vw 1.05vw rgba(0, 0, 0, 0.1);
    
            .search-messages-slider-selectors-container{
                width: 7.5vw;
                gap: 1.05vw;
            }
    
            
        }
    
        .search-messages-slider-tag-name{
            padding: 0 1.875vw;
        }
    }
}

@media screen and (max-width: 425px) {

    .search-messages-slider{
        width: 100%;
        height: calc(100% - 18.75vw);
        gap: 2.1vw;
        box-shadow: 3px 2px 2.1vw 0px rgba(0, 0, 0, 0.10);
    
    
        .search-messages-slider-text{
            font-size: 3.75vw;
        }
    
        .search-messages-slider-selectors-wrapper{
            padding: 4.5vw 3.75vw;
            box-shadow: 0.45vw 0.9vw 2.1vw rgba(0, 0, 0, 0.1);
    
            .search-messages-slider-selectors-container{
                width: 15vw;
                gap: 2.1vw;
            }
        }
    
        .search-messages-slider-tag-name{
            padding: 0 3.75vw;
        }
    }
}