@use '/src/styles/variables' as styles;
.chat-info-slider-participant-card-container{
    display: flex;
    align-items: center;
    gap: 1.4vw;
    padding: 0.35vw 0;
    width: 100%;

    .chat-info-slider-particioant-avatar{
        width: 1.73vw;
        height: 1.73vw;
    }

    .chat-info-slider-particioant-name{
        font-size: styles.$textMd;
        color: var(--text1);
    }

}

@media screen and (max-width: 768px) {

    .chat-info-slider-participant-card-container{
        gap: 2.1vw;
        padding: 0.525vw 0;
    
        .chat-info-slider-particioant-avatar{
            width: 2.5vw;
            height: 2.5vw;
        }
    
        .chat-info-slider-particioant-name{
            font-size: 1.66vw;
        }
    
    }

}

@media screen and (max-width: 425px) {

    .chat-info-slider-participant-card-container{
        gap: 4.2vw;
        padding: 1.05vw 0;
    
        .chat-info-slider-particioant-avatar{
            width: 5vw;
            height: 5vw;
        }
    
        .chat-info-slider-particioant-name{
            font-size: 3.32vw;
        }
    
    }

}