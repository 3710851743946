@use '/src/styles/variables' as styles;
.video-chat-input-container{

	display: flex;
	width: 100%;
	.video-chat-input {
		width: 100%;
		max-width: 40vw;
		display: flex;
		align-items: center;
		max-height: 10vw;
		padding: .2vw .5vw;
		font-size: styles.$textMd;
		line-height: 1.1vw;
		overflow-y: scroll;
		min-height: 2vw;
		outline: none;
		vertical-align: center;
		height: 100%;

		&::before, &::after{
			content: "\00a0";
			pointer-events: none;
		}
	}
}

@media screen and (max-width: 768px) {

	.video-chat-input-container{

		.video-chat-input {
			max-width: 60vw;
			max-height: 15vw;
			padding: .3vw .75vw;
			font-size: 1.66vw;
			line-height: 1.66vw;
			min-height: 3vw;
		}
	}
}


@media screen and (max-width: 425px) {

	.video-chat-input-container{

		.video-chat-input {
			max-width: 120vw;
			max-height: 30vw;
			padding: .6vw 1.5vw;
			font-size: 3.32vw;
			line-height: 3.32vw;
			min-height: 6vw;
		}
	}
	

}