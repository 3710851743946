

.avatar-customizer-container{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;
	overflow: visible;
	.avatar-header{
		text-align: center;
		font-size: 1.5vw;
		font-weight: 400;
		margin: 1vw;
		color: #444444;
	}
	.image-cropper-container{
		$size:32vw;
		width: $size;
		height: calc($size / 2);
		border-radius: 1vw;
		z-index: 0;
		position: relative;
		background-position: center;
		overflow: hidden;
		background-color: rgb(29, 29, 29);
	
		.cropping-area{
			position: absolute;
			width: 100%;
			height: 100%;
			background: radial-gradient(circle,  rgba(9,9,121,0) 44%, rgba(0, 0, 0, 0.63) 44.4%, rgba(0, 0, 0, 0.839) 100%);
			position: absolute;
			z-index: 100;
			aspect-ratio: 1/1;
			top: 0%;
			left: 0%;
			user-select:none;
			-ms-user-selec:none;
			-moz-user-select:none;
			-webkit-user-select:none;
			pointer-events: none;
		}
		
		.avatar{
			border-radius: 0;
			position: relative;
			display: block;
			user-select:none;
			-ms-user-selec:none;
			-moz-user-select:none;
			-webkit-user-select:none;
			pointer-events: none;
			
		}
		
		
	}
	.zoom-slider-container{
		display: grid;
		grid-template-columns: 1vw 1fr 1vw;
		gap: 1vw;
		align-items: center;
		margin:2vw;
		.input-range-slider{
			width: 100%;
		}
		.side-button{
			font-size: 2vw;
			transition: all 250ms ease;
			user-select: none;

			&:hover{
				cursor: pointer;
				scale: 1.2;
			}
		}
	}
	.action-button-container{
		display: flex;
		justify-content: space-evenly;
	}
}

@media screen and (max-width: 768px) {

	.avatar-customizer-container{
		.avatar-header{
			font-size: 2.25vw;
			margin: 1.5vw;
		}

		.image-cropper-container{
			$size: 48vw;
			width: $size;
			height: calc($size / 2);
			border-radius: 1.5vw;		
		}

		.zoom-slider-container{
			grid-template-columns: 1.5vw 1fr 1.5vw;
			gap: 1.5vw;
			margin: 3vw;

			.side-button{
				font-size: 3vw;
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.avatar-customizer-container{
		.avatar-header{
			font-size: 4.5vw;
			margin: 3vw;
		}

		.image-cropper-container{
			$size: 96vw;
			width: $size;
			height: calc($size / 2);
			border-radius: 3vw;		
		}

		.zoom-slider-container{
			grid-template-columns: 3vw 1fr 3vw;
			gap: 3vw;
			margin: 6vw;

			.side-button{
				font-size: 6vw;
			}
		}
	}

}