@use '/src/styles/variables' as styles;

.add-task-modal-wrapper{
	height: 100%;
	display: flex;
	gap: 2vw;
	padding: 1vw;

    .add-task-modal-inputs-container{
		display: flex;
		flex-direction: column;
		width: 25vw;
		margin: 0 auto;
		gap: 1vw;

		.add-task-modal-description {
			width: 100%;
			display: flex;
			flex-direction: column;
			.description-label {
				font-size: 1.25vw
			}
			textarea {
				width: 100%;
				max-width: 100%;
				max-height: 20vw;
				border: none;
				outline: none;
				padding: 1vw;
				border-radius: 0.7vw;
			}
		}
			
    }
}

@media screen and (max-width: 768px) {

	.add-task-modal-wrapper{
		display: flex;
		flex-direction: column;
		gap: 3vw;
		padding: 1.5vw;
	
		.add-task-modal-inputs-container{
			width: 37.5vw;
			gap: 1.5vw;
	
			.add-task-modal-description {
				.description-label {
					font-size: 1.875vw
				}
				textarea {
					max-height: 30vw;
					padding: 1.5vw;
					border-radius: 1.05vw;
					box-shadow: 0.03vw 2px 1.05vw 0px #0000001a;
				}
			}
				
		}
	}
}

@media screen and (max-width: 425px) {

	.add-task-modal-wrapper{
		display: flex;
		flex-direction: column;
		gap: 6vw;
		padding: 3vw;
	
		.add-task-modal-inputs-container{
			width: 75vw;
			gap: 3vw;
	
			.add-task-modal-description {
				.description-label {
					font-size: 3.75vw
				}
				textarea {
					max-height: 60vw;
					padding: 3vw;
					border-radius: 2.1vw;
					box-shadow: 0.06vw 2px 2.1vw 0px #0000001a;
				}
			}
				
		}
	}
}
