@use '/src/styles/variables' as styles;

.reset-password {
  width: 100%;
  height: 100vh;
  background-color: var(--accentPrimary);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .reset-password-form {

    width: 39.5vw;
    border-radius: 1.4vw;
    background-color: var(--accentTertiary);
    padding: 2.08vw 2.5vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
    max-height: 90vh;
    
    .input-field-container{
      height: 2.7vw;

    }

    .reset-password-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .fields-container{
        display: flex;
        flex-direction: column;
        gap: 2vw;
        margin-bottom: 1.25vw;
      }

      .submit-button{
        margin-top: 3.4vw;
      }
    
    }

    .reset-password-header-container {
      display: flex;
      flex-direction: column;
	    margin-bottom: 2.8vw;

    }

  }

}

@media screen and (max-width: 768px) {

  .reset-password {

    .reset-password-form {
      width: 60vw;
      border-radius: 2.1vw;
      padding: 3.12vw 3.75vw;
      
      .input-field-container{
        height: 4.05vw;

      }

      .reset-password-info {
        .fields-container{
          gap: 3vw;
          margin-bottom: 1.875vw;
        }

        .submit-button{
          margin-top: 5.1vw;
        }
      
      }

      .reset-password-header-container {
        margin-bottom: 4.2vw;

      }

    }

  }

}

@media screen and (max-width: 425px) {

  .reset-password {

    .reset-password-form {
      width: 100vw;
      border-radius: 4.2vw;
      padding: 6.24vw 7.5vw;
      
      .input-field-container{
        height: 8.1vw;

      }

      .reset-password-info {
        .fields-container{
          gap: 6vw;
          margin-bottom: 3.75vw;
        }

        .submit-button{
          margin-top: 10.2vw;
        }
      
      }

      .reset-password-header-container {
        margin-bottom: 8.4vw;

      }

    }

  }

}