@use '/src/styles/variables' as styles;

.price-section-wrapper{
    position: relative;
    background: #f2f4f6;
    position: relative;
    overflow: hidden;

    .price-section-container{
        width: 65vw;
        margin: 0 auto;
        padding: 0 0 5vw 0;
        display: flex;
        justify-content: center;
        

        &.price-section-container::before {
            content: "";
            position: absolute;
            width: 14vw;
            height: 34vw;
            top: 15vw;
            right: 0;
            background-color: var(--colorLightBlue);
            filter: blur(8vw);
        }
        &.price-section-container::after {
            content: "";
            position: absolute;
            width: 26vw;
            height: 14vw;
            bottom: 8vw;
            left: 0;
            background-color: #c0bbf1;
            filter: blur(6vw);
            z-index: 2;
        }

        .price-section-item{
            width: 60%;
            border-radius: styles.$roundedMd;
            border: 2px solid var(--accentPrimary);

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2vw;

            padding: 2vw 1vw;
            background: #f2f4f6;
            z-index: 3;
            opacity: 1;
            box-shadow: 1.5vw 1.5vw 1vw 0px #544b9f08;

            .price-section-item-content{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1vw;
                padding: 0vw 2vw;

                .landing-text {
                    font-weight: 500;
                    text-align: left;
                    font-size: styles.$textSm;
                    line-height: 1.2;
                    color: var(--text1);

                    svg{
                        height: 0.8vw;
                        width: auto;
                        fill: var(--text1);
                        padding-right: 0.5vw;
                    }
                }
            }

            .button{
                width: 10vw;
            }

            .title {
                font-family: "Inter", sans-serif;
                font-weight: 200;
                font-size: 3vw;
                line-height: 1.2;
            }

            .subtitle{
                font-weight: 700;
                font-size: 2.5vw;
                line-height: 1.2;
                color: var(--text3);
            }
        }
    }
}
@media (max-width: 768px) {
    .price-section-wrapper{
        .price-section-container{
            padding: 0 0 7.5vw 0;
            width: 70vw;

            &.price-section-container::before {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: 15vw;
                right: 0;
                background-color: var(--colorLightBlue);
                filter: blur(12vw);
            }
            &.price-section-container::after {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                bottom: 8vw;
                left: 0;
                background-color: #c0bbf1;
                filter: blur(9vw);
                z-index: 2;
            }
            .price-section-item{
                width: 80%;
                padding: 3vw 1.5vw;
                gap: 3vw;
                border-radius: 1.5vw;
                box-shadow: 2.3vw 2.3vw 1.5vw 0px #544b9f08;

                .price-section-item-content{
                    gap: 1.5vw;
                    padding: 0 3vw;
                    .landing-text {
                        font-size: 1.6vw;
                        svg{
                            height: 1.2vw;
                        }
                    }
                }

                .title {
                    font-size: 4.95vw;
                }

                .subtitle{
                    font-size: 4.25vw;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .price-section-wrapper{
        .price-section-container{
            width: 90vw;
            padding: 0 0 15vw 0;
            &.price-section-container::before {
                content: "";
                position: absolute;
                width: 42vw;
                height: 102vw;
                top: 15vw;
                right: 0;
                background-color: var(--colorLightBlue);
                filter: blur(24vw);
            }
            &.price-section-container::after {
                content: "";
                position: absolute;
                width: 78vw;
                height: 42vw;
                bottom: 8vw;
                left: 0;
                background-color: #c0bbf1;
                filter: blur(18vw);
                z-index: 2;
            }
            .price-section-item{
                width: 100%;
                padding: 6vw 3vw;
                gap: 6vw;
                box-shadow: 4.5vw 4.5vw 3vw 0px #544b9f08;

                .price-section-item-content{
                    gap: 3vw;
                    padding: 0 6vw;
                    .landing-text {
                        font-size: 3.3vw;
                        svg{
                            height: 2.4vw;
                        }
                    }
                }
                .title {
                    font-size: 9vw;
                }
                
                .subtitle{
                    font-size: 6vw;
                }
            }
        }
    }
}