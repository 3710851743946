@use '/src/styles/variables' as styles;
@use '/src/styles/mixin' as utils;

.offering-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  background-color: #fff;
  padding: 1.73vw;
  border-radius: 1vw;
  box-shadow: 0.2vw 0.13vw 0.69vw 0vw rgba(0, 0, 0, 0.1);
  width: 100%;
  cursor: pointer;

  .offering-delete {
	position: absolute;
	right: 1vw;
	top: 1vw;
	svg {
		fill: var(--text1);
	}
  }

  .offering-card-forecast-options-container {
    @include utils.flex-between();
    width: 100%;
    gap: 4.16vw;
  }

  .offering-card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;


    .offering-card-info-container {
      display: flex;
      align-items: center;
      gap: 0.34vw;

      .offering-avatar-container{
        width: 1.7vw;
        height: 1.7vw;
      }

      .offering-name {
        font-size: styles.$textXl;
        color: var(--text1);
      }
    }

    .offering-price {
      font-size: styles.$textLg;
      font-weight: styles.$fontMedium;
      color: var(--text1);
    }

    .offering-description {
      font-size: styles.$textLg;
      font-weight: styles.$fontMedium;
      color: var(--text1);
      width: 100%;

    }
  }

  .offering-card-forecast-data-container {
    display: flex;
    justify-content: space-between;
    gap: 1.38vw;
  }

  .offering-card-content-container{
    width: 100%;
  }
}

@media screen and (max-width: 768px) {

  .offering-card-container {
    gap: 1.5vw;
    padding: 2.55vw;
    border-radius: 1.5vw;
    box-shadow: 0.3vw 0.18vw 1.05vw 0vw rgba(0, 0, 0, 0.1);
  
    .offering-card-forecast-options-container {
      gap: 6.24vw;
    }
  
    .offering-card-content {
      gap: 0.75vw;
  
  
      .offering-card-info-container {
        gap: 0.53vw;
  
        .offering-avatar-container{
          width: 2.55vw;
          height: 2.55vw;
        }
  
        .offering-name {
          font-size: 2.07vw;
        }
      }
  
      .offering-price {
        font-size: 2.07vw;
      }
  
      .offering-description {
        font-size: 2.07vw;  
      }
    }
  
    .offering-card-forecast-data-container {
      gap: 2.1vw;
    }

  }
  

}

@media screen and (max-width: 425px) {

  .offering-card-container {
    gap: 3vw;
    padding: 5.1vw;
    border-radius: 3vw;
    box-shadow: 0.6vw 0.36vw 2.1vw 0vw rgba(0, 0, 0, 0.1);
  
    .offering-card-forecast-options-container {
      gap: 12.5vw;
    }
  
    .offering-card-content {
      gap: 1.5vw;
  
  
      .offering-card-info-container {
        gap: 1.06vw;
  
        .offering-avatar-container{
          width: 5.1vw;
          height: 5.1vw;
        }
  
        .offering-name {
          font-size: 4.14vw;
        }
      }
  
      .offering-price {
        font-size: 4.14vw;
      }
  
      .offering-description {
        font-size: 4.14vw;  
      }
    }
  
    .offering-card-forecast-data-container {
      gap: 4.2vw;
    }

  }
  

}