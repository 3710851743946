@use '/src/styles/variables' as styles;

.timer{
    display: flex;
    align-items: center;
    gap: 1vw;
    
    .timer-button{
        border-radius: 50%;
        background: var(--accentPrimary);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
        padding: 0.6vw;

        svg{
            width: 1.5vw;
            height: 1.5vw;
        }
    }

    .timer-time{
        display: flex;
        align-items: center;
        gap: 1vw;

        input {
            font-weight: styles.$fontLight;
            font-size: 1.11vw;
            display: flex;
            padding: 0.7vw 1vw;
            align-items: center;
            justify-content: space-between;
            border-radius: styles.$roundedMd;
            height: 2.928vw;
            box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
            background: var(--backgroundContainer);
            border: none;
            width: 100%;
            outline: none;

            &:disabled {
                background-color: #fff;
                pointer-events: none;
                box-shadow: none;
            }
        }
    }

}

@media (max-width: 768px) {

    .timer {
        gap: 1.5vw;
    
        .timer-button {
            padding: 0.9vw;
    
            svg {
                width: 2.25vw;
                height: 2.25vw;
            }
        }
    
        .timer-time {
            gap: 1.5vw;
    
            input {
                padding: 1.05vw 1.5vw;
                font-size: 1.665vw;
                height: 4.392vw;
                box-shadow: 0 0 1.05vw 0 rgba(0, 0, 0, 0.10);
            }
        }
    }

}

@media (max-width: 425px) {

    .timer {
        gap: 3vw;
        flex-direction: column;
    
        .timer-button {
            padding: 1.8vw;
    
            svg {
                width: 4.5vw;
                height: 4.5vw;
            }
        }
    
        .timer-time {
            gap: 3vw;
    
            input {
                padding: 2.1vw 3vw;
                height: 8.784vw;
                font-size: 3.33vw;
                box-shadow: 0 0 2.1vw 0 rgba(0, 0, 0, 0.10);
            }
        }
    }
    

}