@use '../../../../../styles/variables' as styles;

.create-bill-container {
    display: grid;
    padding: 1vw 3vw 3vw 1vw;
    grid-template-columns: 22vw 22vw;
    justify-content: center;
    gap: 3vw;

    .first-column-form-elements-wrapper {
        display: grid;
        gap: 2vw;

        .textarea-label {
            padding-bottom: 2vw;
        }
    }

    .second-colums-form-elements-wrapper {
        display: grid;
        gap: 2vw;
    }
}

@media (max-width: 768px) {
    .create-bill-container {
        grid-template-columns: 1fr;

        .second-colums-form-elements-wrapper {
            gap: 0vw;
        }
    }
}