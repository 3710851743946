
.create-inventory-item-container{

	.form-elements-wrapper {
		width: 56vw;
		height: 100%;
		display: grid;
		grid-template-columns: 22vw 22vw;
		justify-content: center;
		align-items: end;
		margin-bottom: 1vw;
		gap: 1.3vw 4.6vw;
	
		.text-area-container {
			background-color: var(--accentTertiary);
	
			textarea {
				background-color: transparent;
			}
		}		
	}
}

@media screen and (max-width: 425px) {
	.create-inventory-item-container{
		.form-elements-wrapper {
			width: 90vw;
			grid-template-columns: 42vw 42vw;		
		}
	}
}