.create-order-wrapper {
	min-height: 40vh;
	overflow: auto;
	position: relative;
	padding: 0 1vw ;
	height: 100%;
	min-width: 68vw;
	display: flex;
	flex-direction: column;
	gap: 1vw;


	.create-order-options{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2vw;

		.counterparty-list{
			width: 100%;
			.dropdown{
				width: 100%;
			}
		}
	}	

	.create-order-footer {
		display: flex;
		justify-content: flex-end;
		gap: 2vw;
		align-items: center;
		padding: 0.3vw 1vw;
		.create-order-total {
			display: flex;
			align-items: center;
			gap: 3vw;

			span:first-child {
				color: var(--text2);
				font-size: 0.97vw;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: capitalize;
			}

			span:last-child {
				color: var(--text1);
				font-size: 1.11vw;
				font-weight: bold;
				text-transform: capitalize;
			}
		}
	}
}

@media screen and (max-width: 768px) {

	.create-order-wrapper {
		padding: 0 1.5vw;
		min-width: 90vw;
		gap: 1.5vw;

	
		.create-order-options{
			gap: 2.3vw;
	
			.counterparty-list{
				width: 100%;
				.dropdown{
					width: 100%;
				}
			}
			
		}	

	
		.create-order-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.45vw 1.5vw;
			.create-order-total {
				margin-left: auto;
				display: flex;
				align-items: center;
				gap: 4.5vw;
	
				span:first-child {
					font-size: 1.45vw;
				}
	
				span:last-child {
					font-size: 1.66vw;
				}
			}
		}
	}

}