.home-content{
	position: relative;
	overflow-x: clip;
	display: flex;
	flex-direction: column;
	background: #f2f4f6;
	gap: 4vw;
	padding: 12vw 0 4vw 0;


	.tripl-info-wrapper {
		.tripl-info-hero {
			padding: 0;
		}
	}

	.landing-slider-steps-wrapper {
		padding: 0;
	}
}


@media (max-width: 768px) {
	.home-content{
		gap: 6vw;
		padding: 18vw 0 18vw 0;
		.landing-text-section-component-wrapper {
			.landing-text-section-component {
				padding: 0;
			}
		}
	
		.tripl-info-wrapper {
			.tripl-info-hero {
				padding: 0;
			}
		}
	
		.landing-slider-steps-wrapper {
			padding: 0;
		}
	}
}

@media (max-width: 425px) {
	.home-content{
		gap: 12vw;
		padding: 36vw 0 12vw 0;
		.landing-text-section-component-wrapper {
			.landing-text-section-component {
				padding: 0;
			}
		}
	
		.tripl-info-wrapper {
			.tripl-info-hero {
				padding: 0;
			}
		}
	
		.landing-slider-steps-wrapper {
			padding: 0;
		}
	}
}