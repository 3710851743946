.create-project {

    width: 56vw;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;


    .create-project-section{
        display: flex;
        align-items: flex-end;
        gap: 1vw;
    }
    .dropdown {
		.dropdown-header,
		.dropdown-options-container {
			background: var(--accentTertiary)
		}
	}
	.text-area-container {
		background: var(--accentTertiary);
		textarea {
			background: transparent
		}
	}
}