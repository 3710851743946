
.chart-legend{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1vw;
}

@media screen and (max-width: 768px) {
	
	.chart-legend{
		gap: 1.5vw;
	}

}

@media screen and (max-width: 425px) {
	
	.chart-legend{
		gap: 3vw;
	}

}