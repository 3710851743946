.chat-desktop{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff; //No variable for this background yet
    position: relative;
    
    .chat-desktop-container{
        height: calc(100% - 6.25vw);
        display: flex;

        .chat-desktop-message-area-container{
            height: 100%;
            max-width: 100%;
            min-width: 100%;
            transition: all ease 0.4s;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 768px) {
    .chat-desktop{

        .chat-desktop-container{
            height: calc(100% - 9.375vw);
        }
    }
}

@media screen and (max-width: 425px) {
    .chat-desktop{

        .chat-desktop-container{
            height: calc(100% - 18.75vw);
        }
    }
}