.account-list{
    width: 100%;
    height: 100%;
    padding: 1vw;
}

@media screen and (max-width: 768px) {

    .account-list{
        padding: 1.5vw;
    }

}

@media screen and (max-width: 425px) {

    .account-list{
        padding: 3vw;
    }

}