@use 'src/styles/variables' as styles;

$accentPrimary: var(--accentPrimary);

.layout {
    display: flex;
    position: relative;
    height: 100vh;

    .notifications-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 10;
        max-height: 50vh;
        overflow: scroll;
        padding: 1vw;
        gap: 1vw;

        &.visible {
            animation: fadeIn 1s ease-in-out forwards;
        }

        &.unvisible {
            animation: fadeOut 1s ease-in-out forwards;
        }
    }

    .layout-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .layout-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .layout-title {
                color: var(--accentPrimary);
                font-size: 1.39vw;
                font-weight: styles.$fontMedium;
                overflow-wrap: break-word;
                word-wrap: break-word;
                white-space: normal;
                word-break: break-word;
                            
                position: relative;
                padding-bottom: 0.15vw;
            
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 0.15vw;
                    background-color: #1E1B39;
                    transition: width 0.4s ease-out;
                }
                
                &:hover {
                    &::before {
                        width: 100%;
                    }
                }
            }
            .header-profile-link {
                min-width: 4.17vw;
                max-width: 4.17vw;
                min-height: 4.17vw;
                max-height: 4.17vw;

                img,
                svg,
                path {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
        }

        .layout-tab-buttons-wrapper{
            width: fit-content;

            .layout-tab-buttons-container {
                margin: 1.2vw 0;
                display: flex;
                gap: 0.55vw;
                flex-wrap: wrap;
                width: fit-content;
    
                .layout-tab-button {
                    width: fit-content;
                }
            }
        }

        .layout-content {
            height: 100%;
        }
    }

    .layout-container-padding {
        padding: 1.49vw 3.47vw;
        width: 77vw;

        .layout-content {
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            align-items: start;
            justify-content: center;
        }
    }
}

.layout-title-container {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    max-width: 80vw;
    flex: 1 1 100%;
    .layout-popup-container {
        display: inline-block;
    }
}

.navigation {
    display: flex;
    align-items: center;
    
    .navigation-buttons {
        margin-right: 2vw;
        display: flex;
        gap: 0.5vw;
    
        .navigation-buttons-btn {
            width: 1.5vw;
            height: 1.5vw;
            rotate: 180deg;
            padding-left: 0.1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            cursor: pointer;
            border-radius: 50%;
            transition: background 400ms ease;
        }
    
        .navigation-buttons-btn:last-child {
            rotate: 0deg;
        }
    
        .navigation-buttons-btn svg {
            width: 1vw;
            height: 1vw;
        }
    
        .navigation-buttons-btn:hover {
            background: $accentPrimary;
        }
    
        .navigation-buttons-btn:hover svg path {
            stroke: white;
        }
    }
}


@media screen and (max-width: 768px) {
    .layout {
        .notifications-container {
            gap: 1.5vw;
            padding: 1.5vw;
        }

        .layout-container {
            .layout-header-container {
                .layout-title {
                    font-size: 2.08vw;
                    padding-bottom: 0.45vw;
                    &::before {
                        height: 0.45vw;
                    }
                }

                .header-profile-link {
                    min-width: 6.25vw;
                    max-width: 6.25vw;
                    min-height: 6.25vw;
                    max-height: 6.25vw;

                }
            }

            .layout-tab-buttons-wrapper{
                .layout-tab-buttons-container {
                    margin-top: 1.8vw;
                    margin-bottom: 1.8vw;
                    gap: 0.825vw;
                }
            }
        }

        .layout-container-padding {
            padding: 2.235vw 5.02vw;
            width: 100vw;
        }
    }

    .navigation {
        .navigation-buttons {
            .navigation-buttons-btn {
                width: 2.25vw;
                height: 2.25vw;
                padding-left: 0.15vw;
            }

            .navigation-buttons-btn svg {
                width: 1.5vw;
                height: 1.5vw;
            }
        } 
    }
}


@media screen and (max-width: 425px) {
    .layout {
        .notifications-container {
            gap: 3vw;
            padding: 3vw;
        }

        .layout-container {
            .layout-header-container {
                .layout-title {
                    font-size: 4.16vw;
                    padding-bottom: 0.6vw;
                    &::before {
                        height: 0.6vw;
                    }
                }

                .header-profile-link {
                    min-width: 12.5vw;
                    max-width: 12.5vw;
                    min-height: 12.5vw;
                    max-height: 12.5vw;

                }
            }

            .layout-tab-buttons-wrapper{
                .layout-tab-buttons-container {
                    margin-top: 3.6vw;
                    margin-bottom: 3.6vw;
                    gap: 1.65vw;
                }
            }
        }

        .layout-container-padding {
            padding: 4.48vw 10.04vw;
            width: 100vw;
        }
    }

    .navigation {
        .navigation-buttons {
            .navigation-buttons-btn {
                width: 4.5vw;
                height: 4.5vw;
                padding-left: 0.3vw;
            }

            .navigation-buttons-btn svg {
                width: 3vw;
                height: 3vw;
            }
        }  
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        transform: translateY(0%);
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
  }