@use '/src/styles/variables' as styles;

.time-selector {
  width: 100%;
  &-wrapper {
    position: relative;
    
    &-input {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      input {
        font-weight: styles.$fontLight;
        font-size: styles.$textMd;
        display: flex;
        padding: 0.7vw 1vw;
        align-items: center;
        justify-content: space-between;
        border-radius: styles.$roundedMd;
        height: 2.928vw;
        box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
        background: var(--backgroundContainer);
        border: none;
        width: 100%;
      }

      input:focus {
        outline: none;
      } 
      
      span {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        right: 1vw;

        svg {
          width: 0.9vw;
        }
      }
    }
    
    &-dropdown {
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 0;
      transition: styles.$transition;
      border-radius: styles.$roundedMd;
      display: flex;
      background: var(--backgroundContainer);
      box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
      flex-direction: column;
      overflow-x: hidden;
      padding: 0;
      
      &-item {
        font-weight: styles.$fontLight;
        font-size: styles.$textMd;
        cursor: pointer;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: start;
        padding: 0.7vw;
        transition: styles.$transition;
        border-radius: styles.$roundedSm;
        gap: 1vw;

        &:hover{
          background: var(--accentTertiary);
        }
      }
      
      &-open {
        max-height: 15vw;
        padding: 0.7vw;
        overflow-x:hidden;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .time-selector {
    &-wrapper {
      &-input {
        input {
          font-size: 1.66vw;
          padding: 1.05vw 1.5vw;
          height: 4.35vw;
          box-shadow: 0 0 1.05vw 0 rgba(0, 0, 0, 0.10);
        }
        
        span {
          right: 1.5vw;
          
          svg {
            width: 1.4vw;
          }
        }
      }

      &-dropdown {
        &-item {
          font-size: 1.66vw;
          
        }

        &-open {
          max-height: 22.5vw;
          padding: 1.05vw;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .time-selector {
    &-wrapper {
      &-input {
        input {
          font-size: 4.33vw;
          padding: 2.1vw 3vw;
          height: 8.7vw;
        }

        span {
          right: 3vw;
          
          svg {
            width: 2.8vw;
          }
        }
      }

      &-dropdown {
        &-item {
          font-size: 4.33vw;
        }

        &-open {
          max-height: 45vw;
          padding: 2.1vw;
        }
      }
    }
  }
}