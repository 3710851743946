@use '/src/styles/variables' as styles;
.chat-message-additional-data-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.35vw;

  p{
    font-weight: styles.$fontBold;
  }

  .chat-message-additional-data{
    display: flex;
    align-items: center;
    width: 100%;


    &.user{
        color: var(--textHover);
        svg, path{
            fill: var(--textHover);
        }   
    }

    &.stranger{
        color: var(--text1);
        svg, path{
            fill: var(--text1);
        }   
    }

    svg{
      margin-right: 0.486vw;
      width: 1vw;
    }

    font-size: styles.$textSm;
    font-style: italic;
    font-weight: styles.$fontLight;
    min-width: fit-content;
  }
}

@media screen and (max-width: 768px) {

  .chat-message-additional-data-container{
    margin-bottom: 0.525vw;
  
    .chat-message-additional-data{
      svg{
        margin-right: 0.73vw;
        width: 1.5vw;
      }
  
      font-size: 1.45vw;
    }
  }
}

@media screen and (max-width: 425px) {

  .chat-message-additional-data-container{
    margin-bottom: 1.05vw;
  
    .chat-message-additional-data{
      svg{
        margin-right: 1.5vw;
        width: 3vw;
      }
  
      font-size: 2.9vw;
    }
  }
}