@use '/src/styles/variables' as styles;

.file-selector-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1vw;
  .file-selector-container {
    height: 100%;
    min-width: 55.82vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    overflow-y: scroll;
    margin: auto 0;

    .dropped-img {
      width: 18.30vw;
      height: 19.04vw;
      margin: 1.83vw;
    }

    .drop-file-icon{
      width: 4vw;
      height: 6vw;
      svg{
        path:first-child{
          fill: var(--accentSecondary);
        }
        circle{
          fill: var(--accentSecondary);
        }
      }
    }

    .drop-file {
      &:active{
        cursor: pointer;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 15vw;
      aspect-ratio: 1 / 1;
      padding: 3.87vw;
      border: .2vw dashed var(--accentSecondary);

      .drop-file-text {
        color:var(--text1);
        text-align: center;
        font-size: styles.$textSm;
      }

      .drop-file-format p{
        font-size: styles.$text2sm;
        text-align: center;
        opacity: .5;
        white-space: normal;
        max-height: 7vw;
      }
    }

    .or {
      font-size: styles.$textMd;
      line-height: 1.75vw;
    }

    .button{
      outline: .15vw solid var(--accentPrimary);
    }
    .browse-file-input-container {
      width: 10.41vw;
      position: relative;

      .max-file-size-notification{
        top: 4vw;
        position: absolute;
        height: 2vw;
        font-size: styles.$textMd;
      }

      .loading-wrapper{
        top: 0;
        left: 100%;
        position: absolute;
      }

      .input-field-container {
        display: none;
      }
    }
  }
}