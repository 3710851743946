.project-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.project-tool-bar{
		display: flex;
		align-items: center;
		gap: 1vw;
		margin-bottom: 1vw;
	}
}