.notification-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 9vw;
    height: 9vw;
    max-height: 9vw;
    border-radius: 0.7vw;
    overflow: hidden;
    background: var(--backgroundMain);
    box-shadow: 0.35vw 0vw 0.7vw rgba(0, 0, 0, 0.15);

    .notification-side-line {
        height: 100%;
        min-width: 1vw;
        max-width: 1vw;
    }

    .buttons-container {
        background-color: hsla(240, 100%, 99%, 1);
        height: 100%;
        min-width: 4vw;
        max-width: 4vw;

        .counterparty-action {
            height: 100%;
            width: 100%;

            .switch-buttons-container.vertical {
                background: none;
                height: 100%;
                width: 100%;

                svg {
                    height: 2vw;
                    width: 2vw;

                    // &:not(.excludeFill){
                    //     fill: var(--text4);
                    // }
                }
            }
        }
    }

    .notification-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1vw;
        width: 100%;
        height: 100%;

        .notification-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .notification-header-title {
                color: var(--text1);
                font-family: 'Poppins';
                font-size: 1vw;
                font-weight: 500;
            }

            .notification-header-close {
                width: 2vw;
                height: 2vw;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .notification-content-container {
            display: flex;
            align-items: center;
            height: 100%;
            gap:1vw;

            .notification-content-icon {
                width: 4vw;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .notification-content-text-container {
                width: 100%;

                .notification-content-title {
                    color: var(--text1);
                    font-size: 1.4vw;
                    font-weight: 600;
                }

                .notification-content-description {
                    width: 100%;
                    color: var(--text1);
                    font-size: 1vw;
                    font-weight: 400;
                }

                a{

                    color: var(--text1);
                    font-size: 1vw;
                    font-weight: 400;
                    text-decoration: underline;
                }
            }

            .notification-content-time-container {
                height: 100%;
                font-size: 1vw;
                white-space: nowrap;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}