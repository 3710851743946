.time-tracker{
    width: 100%;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

@media (max-width: 768px) {

    .time-tracker{
        padding: 1.5vw;
        gap: 3vw;
    }
    
}


@media (max-width: 425px) {

    .time-tracker{
        padding: 3vw;
        gap: 6vw;
    }
    
}