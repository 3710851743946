// General
$accentPrimary : #1E1B39;
$accentSecondary : #D0D5DD;
$accentTertiary : #F0F0F0;
$accentDisabled : #D9D9D9;

// Action
$colorSuccess : #BDF1CF;
$colorSuccessText : #5EA979;
$colorWarning : #ECD613;
$colorAlert : #F1BDBD;
$colorAlertText : #FF4E4E;
$colorInfo : #1573FE;
$colorLightBlue: #bbcaf1;

// Text
$text1: #444;  
$text2: #898989;
$text3: #7a7a7a;
$text4: #bbb;
$textHover: #fff;

// Background
$backgroundMain: #fff; /* Use only for background */

$backgroundContainer: #fff; /* Use for top level container/panel  1 Level Deep */
$backgroundSubContainer: #f9f9f9; /* Use for sub containers - 2 Level Deep  */
$backgroundNestedSubContainer: #FAFAFA; /* Use for nested sub containers - 3 Level Deep  */

$backgroundAlt: #E9F1FF; /* Use for bacgkround that have different or contrasting with the overall theme*/