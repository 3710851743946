.header-app-feature-wrapper {
  position: relative;
  background: #f2f4f6;
  position: relative;
  overflow-x: clip;
  margin-top: 5vw;

  .header-app-feature-images-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 4.1vw;
    height: 4.1vw;
    border-radius: 1vw;
    box-shadow: 0.5vw 0.5vw 1vw 0px #544b9f08;
    padding: 1.2vw;
    z-index: 4;

    svg {
      width: 1.6vw;
    }
  }
  .header-app-feature-hero {
    width: 65vw;
    margin: 0 auto;
    padding: 10vw 0 8vw;

    &.header-app-feature-hero::before {
      content: "";
      position: absolute;
      width: 26vw;
      height: 14vw;
      bottom: 0;
      left: 0;
      background-color: var(--colorLightBlue);
      filter: blur(6vw);
      z-index: 2;
    }
    &.header-app-feature-hero::after {
      content: "";
      position: absolute;
      width: 14vw;
      height: 34vw;
      top: 0;
      right: 0;
      background-color: #c0bbf1;
      filter: blur(8vw);
      z-index: 2;
    }

    .header-app-feature-content {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2vw;
      .title {
        font-family: "Inter", sans-serif;
        font-weight: 200;
        font-size: 3vw;
        line-height: 1.2;
      }

      .title-black {
        font-weight: 800;
      }

      .landing-text {
        max-width: 90%;
        font-size: 1.1vw;
        font-weight: 500;
        color: #6f6c8a;
        margin-top: 2vw;
        margin-bottom: 2vw;
      }

      .header-app-feature-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vw;
        margin-bottom: 4vw;

        .header-app-feature-button-1 {
          .button-active {

            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            p{
              font-size: 1.2vw;
            }
          }
        }

        .header-app-feature-button-2 {
          .button-outlined {
            border-radius: 6.94vw;
            gap: 0.56vw;
            padding: 0.35vw 0.83vw;
            background-color: white;
          }
          span {
            font-weight: 700;
          }

          button:hover{
            p{
              color: var(--accentPrimary);
            }
          }
        }
      }

      .header-app-feature-images {
        display: flex;
        justify-content: center;
        position: relative;
        gap: 2vw;
      
        img {
          height: 20vw;
          box-shadow: 1.5vw 1.5vw 2vw 0px rgba(81, 75, 135, 0.11);
        }
      
        &.first-image-transform .header-app-feature-image-0 img {
          transform: translateY(26%);
        }
      
        &.second-image-transform .header-app-feature-image-1 img {
          transform: translateY(40%);
        }
      
        .header-app-feature-image-0,
        .header-app-feature-image-1 {
          position: relative;
          display: flex;
          flex-direction: column;
        }
      }

      @keyframes content-animation {
        from {
          top: -10vw;
          opacity: 0;
        }
        to {
          top: 0;
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header-app-feature-wrapper{
    margin-top: 7vw;
    .header-app-feature-images-icon {
      margin-top: 2vw;
      width: 6.15vw;
      height: 6.15vw;
      border-radius: 1.5vw;
      svg{
        width: 2.4vw;
      }
    }
    .header-app-feature-hero{
      padding-top: 14vw;
      width: 70vw;
      &.header-app-feature-hero::before {
        content: "";
        position: absolute;
        width: 39vw;
        height: 21vw;
        bottom: 0;
        left: 0;
        background-color: var(--colorLightBlue);
        filter: blur(9vw);
        z-index: 2;
      }
      &.header-app-feature-hero::after {
        content: "";
        position: absolute;
        width: 21vw;
        height: 51vw;
        top: 0;
        right: 0;
        background-color: #c0bbf1;
        filter: blur(12vw);
        z-index: 2;
      }
      .header-app-feature-content{
        gap: 3vw;
        .title {
          font-size: 4.95vw;
        }

        .landing-text{
          font-size: 1.65vw;
        }

        .header-app-feature-buttons {
          gap: 1.5vw;
          margin-bottom: 6vw;
          .header-app-feature-button-1 {
            .button-active{
              padding: 0.73vw;
              p{
                font-size: 1.6vw;
              }
            }
          }
          .header-app-feature-button-2 {
            .button-outlined {
              padding: 0.5vw 1.2vw;
              background-color: white;
            }
          }
        }
        .header-app-feature-images {
          gap: 3vw;
          img {
            height: 23vw;
            box-shadow: 2.2vw 2.2vw 3vw 0px rgba(81, 75, 135, 0.11);
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .header-app-feature-wrapper{
    margin-top: 14vw;
    .header-app-feature-images-icon {
      margin-top: 9vw;
      width: 12.3vw;
      height: 12.3vw;
      border-radius: 3vw;
      svg{
        width: 4.8vw;
      }
    }
    .header-app-feature-hero{
      width: 80vw;
      padding-top: 18vw;
      &.header-app-feature-hero::before {
        content: "";
        position: absolute;
        width: 39vw;
        height: 21vw;
        top: 105vw;
        left: 0;
        background-color: var(--colorLightBlue);
        filter: blur(9vw);
        z-index: 2;
      }
      &.header-app-feature-hero::after {
        content: "";
        position: absolute;
        width: 21vw;
        height: 51vw;
        top: 0;
        right: 0;
        background-color: #c0bbf1;
        filter: blur(12vw);
        z-index: 2;
      }
      
      .header-app-feature-content{
        gap: 6vw;
        .title {
          font-size: 9vw;
        }

        .landing-text{
          font-size: 3.3vw;
        }

        .header-app-feature-buttons {
          gap: 3vw;
          margin-bottom: 12vw;
          .header-app-feature-button-1 {
            .button-active{
              padding: 1.65vw;
              p{
                font-size: 3.3vw;
              }
            }
            .arrow-icon-container{
              width: 5.1vw;
              height: 5.1vw;
              border-radius: 2vw;
              svg{
                  width: 3vw;
                  height: 3vw;
              }
      
            }
          }
          .header-app-feature-button-2 {
            .button-outlined {
              padding: 1.15vw 2.4vw;
              background-color: white;
            }
            .button-icon{
              svg{
                  width: 3.9vw;
                  height: 3.9vw;
              }
            }
          }
        }
        .header-app-feature-images {
          gap: 3vw;
          img {
            height: 30vw;
            box-shadow: 4.5vw 4.5vw 6vw 0px rgba(81, 75, 135, 0.11);
          }
        }
      }
    }
  }
}

