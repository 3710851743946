
.error-container{
  height: 1.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw 0;

  svg{
    width: 1.38vw;
    height: 1.38vw;
  }

  p{
    color: #F61D1D;
    margin-left: 0.83vw;
    font-size: 1.112vw;
  }
}

@media screen and (max-width: 768px) {

  .error-container{
    height: 1.95vw;
    margin: 1.5vw 0;

    svg{
      width: 2.1vw;
      height: 2.1vw;
    }

    p{
      margin-left: 1.25vw;
      font-size: 1.66vw;
    }
  }  

}

@media screen and (max-width: 425px) {

  .error-container{
    height: 4vw;
    margin: 3vw 0;

    svg{
      width: 4.2vw;
      height: 4.2vw;
    }

    p{
      margin-left: 2.5vw;
      font-size: 3.32vw;
    }
  }  

}