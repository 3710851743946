@use '/src/styles/variables' as styles;

.time-tracker-item {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding: 0.7vw 1.05vw;
    border-radius: 1vw;
    box-shadow: .2vw .13vw .69vw 0 #0000004d;
    background-color: var(--backgroundContainer);
    width: 100%;

    .time-tracker-item-header{
        display: flex;
        flex-direction: column;
        gap: 1vw;

        .time-tracker-item-title {
            color: var(--text1);
            font-size: 1.25vw;
            font-weight: 500;
        }

        .time-tracker-item-status{
            display: flex;
            flex-direction: column;
            gap: 0.5vw;
            
            .time-tracker-item-status-text-container{
                display: flex;
                align-items: center;
                gap: 1vw;

                .time-tracker-item-status-circle{
                    width: 1vw;
                    height: 1vw;
                }
    
            }

            .time-tracker-item-status-text{
                font-size: 1.11vw;
                color: var(--text1);
            }    
            
            .time-tracker-item-status-download{
                display: flex;
                gap: 2vw;
                justify-content: center;
            }

        }
    }

    .time-tracker-item-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1vw;
        width: 100%;

        .time-tracker-executor {
            display: flex;
            align-items: center;
            gap: 1vw;
            width: 40%;

            svg,
            img {
                width: 2.78vw;
                height: 2.78vw;
            }

            .time-tracker-executor-details {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .time-tracker-executor-name {
                    word-wrap: break-word;
                    color: var(--text1);
                    font-size: 1.25vw;
                    font-weight: 500;
                }

                .time-tracker-executor-position {
                    color: var(--text1);
                    font-size: 1.11vw;
                    font-weight: 400;
                }
            }
        }

        .time-tracker-details {
            display: flex;
            align-items: center;
            gap: 1vw;

            .time-tracker-separation {
                color: var(--text1);
                font-size: 1.5vw;
            }

            .time-tracker-billing {
                display: flex;
                gap: 1vw;
                align-items: center;

                p {
                    color: var(--text1);
                    font-size: 1.5vw;
                }
            }

            input {
                font-weight: styles.$fontLight;
                font-size: 1.11vw;
                display: flex;
                padding: 0.7vw 1vw;
                align-items: center;
                justify-content: space-between;
                border-radius: styles.$roundedMd;
                height: 2.928vw;
                box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
                background: #fff;
                border: none;
                width: 100%;
                outline: none;

                &:disabled {
                    background-color: #fff;
                    pointer-events: none;
                    box-shadow: none;
                }
            }
        }
    }


    .time-tracker-toggle-bar-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;



        svg {
            transform: rotate(90deg);
            width: 1.3vw;
            height: 1.3vw;
            transition: all ease 0.4s;

            path {
                stroke: var(--text1);
            }
        }

        &.open {
            svg {
                transform: rotate(-90deg);
            }

        }
    }

    .time-tracker-chart-container{
        display: flex;
        flex-direction: column;
        gap: 1vw;
        width: 100%;
        transition: max-height 0.4s ease;
        max-height: 0;
        overflow-y: scroll;

        &.open{
            max-height: 40vh;
        }
    }

}

@media (max-width: 768px) {
    .time-tracker-item {
        gap: 1.5vw;
        padding: 1.05vw 1.575vw;
        border-radius: 1.5vw;
        box-shadow: 0.3vw 0.195vw 1.035vw 0;
        width: 100%;
    
        .time-tracker-item-header {
            gap: 1.5vw;
    
            .time-tracker-item-title {
                font-size: 1.875vw;
            }
    
            .time-tracker-item-status {
                gap: 1.5vw;
    
                .time-tracker-item-status-circle {
                    width: 1.5vw;
                    height: 1.5vw;
                }
    
                .time-tracker-item-status-text {
                    font-size: 1.665vw;
                }
            }
        }
    
        .time-tracker-item-content {
            gap: 1.5vw;
            width: 100%;
    
            .time-tracker-executor {
                gap: 1.5vw;
                width: 40%;
    
                svg,
                img {
                    width: 4.17vw;
                    height: 4.17vw;
                }
    
                .time-tracker-executor-details {
                    .time-tracker-executor-name {
                        font-size: 1.875vw;
                    }
    
                    .time-tracker-executor-position {
                        font-size: 1.665vw;
                    }
                }
            }
    
            .time-tracker-details {
                gap: 1.5vw;
    
                .time-tracker-separation {
                    font-size: 2.25vw;
                }
    
                .time-tracker-billing {
                    gap: 1.5vw;
    
                    p {
                        font-size: 2.25vw;
                    }
                }

                input {
                    font-weight: styles.$fontLight;
                    font-size: 1.66vw;
                    display: flex;
                    padding: 1.05vw 1.5vw;
                    border-radius: 0.75vw;
                    height: 4.5vw;
                    box-shadow: 0 0 1.4vw 0 rgba(0, 0, 0, 0.10);
                    background: #fff;
                    border: none;
                    width: 100%;
                    outline: none;
    
                    &:disabled {
                        background-color: #fff;
                        pointer-events: none;
                        box-shadow: none;
                    }
                }
            }
        }
    
        .time-tracker-toggle-bar-button {
            svg {
                width: 1.95vw;
                height: 1.95vw;
            }
        }
    
        .time-tracker-chart-container {
            gap: 1.5vw;
            width: 100%;
        }
    }
    
}

@media (max-width: 425px) {
    .time-tracker-item {
        gap: 3vw;
        padding: 2.1vw 3.15vw;
        border-radius: 3vw;
        box-shadow: 0.6vw 0.39vw 2.07vw 0;
        width: 100%;
    
        .time-tracker-item-header {
            gap: 3vw;
    
            .time-tracker-item-title {
                font-size: 3.75vw;
            }
    
            .time-tracker-item-status {
                gap: 3vw;
    
                .time-tracker-item-status-circle {
                    width: 3vw;
                    height: 3vw;
                }
    
                .time-tracker-item-status-text {
                    font-size: 3.33vw;
                }
            }
        }
    
        .time-tracker-item-content {
            gap: 3vw;
            width: 100%;
    
            .time-tracker-executor {
                gap: 3vw;
                width: 40%;
    
                svg,
                img {
                    width: 8.34vw;
                    height: 8.34vw;
                }
    
                .time-tracker-executor-details {
                    .time-tracker-executor-name {
                        font-size: 3.75vw;
                    }
    
                    .time-tracker-executor-position {
                        font-size: 3.33vw;
                    }
                }
            }
    
            .time-tracker-details {
                gap: 3vw;
    
                .time-tracker-separation {
                    font-size: 4.5vw;
                }
    
                .time-tracker-billing {
                    gap: 3vw;
    
                    p {
                        font-size: 4.5vw;
                    }
                }
    
                input {
                    font-weight: styles.$fontLight;
                    font-size: 3.2vw;
                    display: flex;
                    padding: 2.1vw 3vw;
                    border-radius: 1.5vw;
                    height: 9vw;
                    box-shadow: 0 0 2.1vw rgba(0, 0, 0, 0.1);
                    background-color: #fff;
                    border: none;
                    width: 100%;
                    outline: none;
    
                    &:disabled {
                        background-color: #fff;
                        pointer-events: none;
                        box-shadow: none;
                    }
                }
            }
        }
    
        .time-tracker-toggle-bar-button {
            svg {
                width: 3.9vw;
                height: 3.9vw;
            }
        }
    
        .time-tracker-chart-container {
            gap: 3vw;
            width: 100%;
        }
    }
    
}