@use '/src/styles/variables' as styles;
.attachments-banner{
    position: absolute;
    transition: all ease 0.3s;
    bottom: calc(100% + 0.5vw);
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--backgroundMain);
    border-radius: 0.7vw;
    height: 6.6vw;
    overflow: hidden;
    box-shadow: 0px 0px 0.69vw -1px rgba(0, 0, 0, 0.25);
    z-index: 100;


    &.attachments-banner-closed{
        height: 0;
    }

    .attachments-banner-item{
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0.65vw 1vw; 

        .attachments-banner-icon{
            width: 1.11vw;
            height: 1.25vw;

            display: flex;
            align-items: center;

            svg{
                fill: var(--text1);
            }
        }

        .attachhments-banner-text{
            margin-left: 0.83vw;
            font-size: styles.$textMd;
            color: var(--text1);
        }
    }
}


@media screen and (max-width: 768px) {
    .attachments-banner{
        border-radius: 1.05vw;
        height: 9.9vw;
        box-shadow: 0px 0px 1.05vw -1px rgba(0, 0, 0, 0.25);
    
        .attachments-banner-item{
            padding: 0.98vw 1.5vw; 
    
            .attachments-banner-icon{
                width: 1.66vw;
                height: 1.875vw;
            }
    
            .attachhments-banner-text{
                margin-left: 1.25vw;
                font-size: 1.66vw;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .attachments-banner{
        border-radius: 2.1vw;
        height: 20vw;
        box-shadow: 0px 0px 2.1vw -1px rgba(0, 0, 0, 0.25);
    
        .attachments-banner-item{
            padding: 2vw 3vw; 
    
            .attachments-banner-icon{
                width: 3.32vw;
                height: 3.75vw;
            }
    
            .attachhments-banner-text{
                margin-left: 2.5vw;
                font-size: 3.32vw;
            }
        }
    }
}