@use '/src/styles/variables' as styles;

.registration {
  width: 100%;
  height: 100vh;
  background-color: var(--accentPrimary);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &-langueage {
    position: absolute;
    top: 1vw;
    right: 1vw;
    background-color: var(--accentTertiary);
    padding: 1vw 1vw;
    border-radius: 1vw;
  }

  .registration-form {

    width: 39.5vw;
    border-radius: 1.4vw;
    background-color: var(--accentTertiary);
    padding: 2.08vw 2.5vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
    max-height: 90vh;
    

    .registration-wrraper{
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
	    padding: 0 0.7vw 1.3vw;

      .user-name {
        display: flex;
        gap: 0.7vw
      }

      .user-name .input-wrapper {
        width: 50%;
        min-width: 0;
      }
    }

    .input-field-container{
      height: 2.7vw;

    }

    .registration-header {
      display: flex;
      justify-content: center;
	

      .registration-header-title{
        font-size: 1.66vw;
        font-weight: 600;
        color: var(--text1);
      }
    }

    .error-container {
      min-height: fit-content;
      padding: 1vw;
      display: flex;
      p{
        color: var(--colorAlertText);
        margin-left: 0.83vw;
        font-size: 1.112vw;
      }
    }

    .registration-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .fields-container{
        display: flex;
        flex-direction: column;
        gap: 2vw;
        margin-bottom: 1.25vw;
      }

      .submit-button{
        margin-top: 3.4vw;
      }
    
    }

    .registration-footer {
      display: flex;
      text-align: center;
    flex-direction: column;
    flex-direction: column;
    text-align: center;
      flex-direction: column;
    text-align: center;
      justify-content: center;
      align-items: center;
      color: var(--text1);
      text-align: center;
      font-size: 0.97vw;
      font-weight: 400;
      white-space: wrap;
      width: 100%;
  
      .registration-footer-content{
        display: flex;
        align-items: center;
        gap: 0.5vw;
  
        .registration-footer-text{
          text-align: left;
          word-break: normal;
          font-size: 0.97vw;
        }
      }
  
      .terms-and-privacy-link {
        cursor: pointer;
        text-decoration: underline;
      }
  
      .error-wrapper {
        color: var(--colorAlertText);
        font-size: styles.$text2sm;
        font-weight: 400;
        margin-top: 0.5vw;
  
        svg{
          width: 1.38vw;
          height: 1.38vw;
        }
  
        .error-text {
          display: flex;
          gap: 0.35vw;
          align-items: center;
        }
  
        .error-info-list{
          margin-left: 2.8vw;
        }
      }
    }
  }

  
}

.reminder-section {
  display: flex;
  justify-content: space-between;

  .check-container {
    display: flex;
    align-items: center;
    gap: 1.31vw;

    .check-box {
      width: 1.1vw;
      height: 1.1vw;
      border: 0.14vw solid var(--text1);
      cursor: pointer;
      border-radius: 0.35vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .checked {
      background-color: var(--text1);
      width: 0.48vw;
      height: 0.48vw;
      border-radius: 0.35vw;
    }

    .unchecked {
      background-color: var(--backgroundMain);
      width: 0.48vw;
      height: 0.48vw;
      border-radius: 0.35vw;
    }

    p {
      font-size: 0.97vw;
      color: var(--text1);
    }
  }

  a {
    text-decoration: none;
    color: var(--text1);
    font-size: 0.97vw;
    font-weight: 400;
  }


}

@media screen and (max-width: 768px) {

  .registration {

    &-langueage {
      top: 1.5vw;
      right: 1.5vw;
      padding: 1.5vw 1.5vw;
      border-radius: 1.5vw;
    }
  
    .registration-form {
  
      width: 60vw;
      border-radius: 2.1vw;
      padding: 3.12vw 3.75vw;
      max-height: 90vh;
      
  
      .registration-wrraper{
        padding: 0 1.05vw 1.95vw;
    
        .user-name {
          flex-wrap: wrap;
          gap: 1.05vw
        }

        .user-name .input-wrapper {
          width: 100%;
        }
      }
  
      .input-field-container{
        height: 4.05vw;
  
      }
  
      .registration-header {
  
        .registration-header-title{
          font-size: 3.32vw;
        }
      }
  
      .error-container {
        padding: 1.5vw;

        p{
          margin-left: 1.2vw;
          font-size: 1.66vw;
        }
      }
  
      .registration-info {
        .fields-container{
          gap: 3vw;
          margin-bottom: 1.875vw;
        }
  
        .submit-button{
          margin-top: 5.1vw;
        }
      
      }
    
      .registration-footer {
        font-size: 1.45vw;
        
        .registration-footer-content{
          display: flex;
          align-items: center;
          gap: 0.75vw;
    
          .registration-footer-text{
            font-size: 1.45vw;
            text-align: left;
            word-break: normal;
          }
        }

        .error-wrapper {
          font-size: 1.2vw;
          margin-top: 0.75vw;
    
          svg{
            width: 2.07vw;
            height: 2.07vw;
          }
    
          .error-text {
            gap: 0.525vw;
          }
    
          .error-info-list{
            margin-left: 4.2vw;
          }
        }
      }

    }
  
  }
  
  .reminder-section {
  
    .check-container {
      gap: 2.1vw;
  
      .check-box {
        width: 1.66vw;
        height: 1.66vw;
        border: 0.21vw solid var(--text1);
        border-radius: 0.53vw;
      }
  
      .checked {
        width: 0.75vw;
        height: 0.75vw;
        border-radius: 0.53vw;
      }
  
      .unchecked {
        width: 0.75vw;
        height: 0.75vw;
        border-radius: 0.53vw;
      }
  
      p {
        font-size: 1.45vw;
      }
    }
  
    a {
      font-size: 1.45vw;
    }
  }
}

@media screen and (max-width: 425px) {

  .registration {

    &-langueage {
      top: 3vw;
      right: 3vw;
      padding: 3vw 3vw;
      border-radius: 3vw;
    }
  
    .registration-form {
  
      width: 100vw;
      border-radius: 4.2vw;
      padding: 6.24vw 7.5vw;
      max-height: 90vh;
      
  
      .registration-wrraper{
        padding: 0 2.1vw 3.9vw;
    
        .user-name {
          gap: 2.1vw
        }
      }
  
      .input-field-container{
        height: 8.1vw;
  
      }
  
      .registration-header {
  
        .registration-header-title{
          font-size: 6.64vw;
        }
      }
  
      .error-container {
        padding: 3vw;

        p{
          margin-left: 2.4vw;
          font-size: 3.32vw;
        }
      }
  
      .registration-info {
        .fields-container{
          gap: 4.5vw;
          margin-bottom: 3.75vw;
        }
  
        .submit-button{
          margin-top: 10.2vw;
        }
      
      }
    
      .registration-footer {
        font-size: 2.9vw;
     
        .registration-footer-content{
          display: flex;
          align-items: center;
          gap: 0.5vw;
    
          .registration-footer-text{
            font-size: 2.9vw;
            text-align: left;
            word-break: normal;
          }
        }

        .error-wrapper {
          font-size: 2.4vw;
          margin-top: 1.5vw;
    
          svg{
            width: 4.14vw;
            height: 4.14vw;
          }
    
          .error-text {
            gap: 1.5vw;
          }
    
          .error-info-list{
            margin-left: 8.4vw;
          }
        }
      }
    }
  
  }
  
  .reminder-section {
  
    .check-container {
      gap: 4.2vw;
  
      .check-box {
        width: 3.32vw;
        height: 3.32vw;
        border: 0.42vw solid var(--text1);
        border-radius: 1.05vw;
      }
  
      .checked {
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 1.05vw;
      }
  
      .unchecked {
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 1.05vw;
      }
  
      p {
        font-size: 2.9vw;
      }
    }
  
    a {
      font-size: 2.9vw;
    }
  }
}