.label-list {
	display: flex;
	flex-direction: column;
	padding: 1vw;
	width: 100%;
	height: 100%;

	.label-heading {
		font-size: 1.25vw;
		font-weight: 600;
		color: var(--text1)
	}
}

@media screen and (max-width: 768px) {

	.label-list {
		padding: 1.5vw;
	
		.label-heading {
			font-size: 2.5vw;
		}
	}
	
}

@media screen and (max-width: 425px) {

	.label-list {
		padding: 3vw;
	
		.label-heading {
			font-size: 3.75vw;
		}

  }
  
}