@use '/src/styles/variables' as styles;

.dropdown-wrapper {
	width: 100%;
	.dropdown-label {
		font-size: styles.$textLg;
		display: inline-block;
		margin-right: 0.5vw;
	}
	
	.dropdown {
		display: flex;
		flex-direction: column;
		position: relative;
		height: 2.928vw;
		width: 100%;
		cursor: pointer;

		.avatar-container{
			min-width: 1.7vw;
			min-height: 1.7vw;
			max-width: 1.7vw;
			min-height: 1.7vw;
		}

		.error-text{
			color: var(--colorAlertText);
			font-size: styles.$text2sm;
			font-weight: 400;
			margin-top: 0.5vw;
			display: flex;
			gap: 0.35vw;
			align-items: center;

			svg{
				width: 1.38vw;
				height: 1.38vw;
			}

		}

		&-header {
			display: flex;
			padding: 0.7vw 1vw;
			align-items: center;
			justify-content: space-between;
			border-radius: styles.$roundedMd;
			height: 2.928vw;
			box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
			background: var(--backgroundContainer);

			&.disabled{
				background: transparent;
				box-shadow: none;

				.dropdown-header-icon{
					display: none;
				}
			}

			&.error{
				border: 1px solid var(--colorAlertText);
			}


			&-main-icon {
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					width: 1.0739vw;
				}
			}

			&-content{
				display: flex;
				align-items: center;
				gap: 1vw;
				width: 100%;

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					svg{
						width: 0.9vw;
					}
				}
			}

			.selected-option {
				font-weight: styles.$fontLight;
				font-size: styles.$textMd;
				line-height: 1.39vw;
				color: var(--text1);
				text-align: center;
				display: flex;
				position: relative;
				background: transparent;
				&.search{
					width: 100%;
					border: none;
					text-align: left;
					display: flex;

					&:focus{
						outline: none;
					}
				}
			}

			.dropdown-header-icon {
				justify-content: center;
				display: flex;
				align-items: center;
				transform: rotate(-90deg);
				transition: styles.$transition;

				&.open-dropdown-header-icon{
					transform: rotate(0);
				}

				svg {
					width: 0.645vw;
				}
			}
		}

		&-options-container {
			position: absolute;
			z-index: 10;
			top: 100%;
			left: 0;
			right: 0;
			max-height: 0;
			transition: styles.$transition;
			border-radius: styles.$roundedMd;
			display: flex;
			background: var(--backgroundContainer);
			box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
			flex-direction: column;
			overflow-x: hidden;
			padding: 0;

			.pagination-container{
				padding: 0;
				position: relative;
				overflow-x:hidden;
				.option-wrapper{
					position: relative;
					display: flex;
					flex-direction: column;


					.dropdown-option-text {
						display: flex;
						font-weight: styles.$fontLight;
						font-size: styles.$textLg;
						line-height: 1.39vw;
						color: var(--text1);
						overflow: visible;
					}
				}
			}
			.dropdown-option {
				width: 100%;
				display: inline-flex;
				align-items: center;
				justify-content: start;
				padding: 0.7vw;
				cursor: pointer;
				transition: styles.$transition;
				border-radius: styles.$roundedSm;
				gap: 1vw;
				
				&:hover{
					background: var(--accentTertiary);
				}
			}
			.disabledOption{
				font-size: 0.8vw;
			}

			&.open-dropdown-options-container{
				max-height: 15vw;
				padding: 0.7vw;
				overflow-x:hidden;

				.loading-container{
					position: static;
					margin: 0 auto;
				}
			}
		}
	}


	@media screen and (max-width: 768px) {
		
		.dropdown-label {
			font-size: 1.875vw;
			margin-right: 0.75vw;
		}
		
		.dropdown {
			height: 4.35vw;

			.error-text{
				font-size: 1.2vw;
				margin-top: 0.75vw;
				gap: 0.525vw;

				svg{
					width: 2.07vw;
					height: 2.07vw;
				}
			}

			.dropdown-header {
				padding: 1.05vw 1.5vw;
				gap: 1.05vw;
				border-radius: 0.75vw;
				height: 4.35vw;
				box-shadow: 0 0 1.05vw 0 rgba(0, 0, 0, 0.10);
				&-content{
					span {
						cursor: pointer;
						svg{
							width: 1.4vw;
						}
					}
				}
				.dropdown-header-main-icon {
					svg {
						width: 1.5vw;
					}
				}

				.selected-option {
					font-size: 1.66vw;
					line-height: 2.1vw;
				}

				.dropdown-header-icon {
					svg {
						width: 0.96vw;
					}
				}
			}

			.dropdown-options-container {
				border-radius: 0.75vw;
				box-shadow: 0 0 1.05vw 0 rgba(0, 0, 0, 0.10);
				.pagination-container{
					padding: 0.2vw;
					position: relative;
				}
				.dropdown-option {
					padding: 1.05vw;
					border-radius: 0.375vw;

					.dropdown-option-text {
						font-size: 1.875vw;
						line-height: 2.1vw;
					}
				}
				.disabledOption{
					font-size: 1.2vw;
				}
				&.open-dropdown-options-container{
					max-height: 22.5vw;
					padding: 1.05vw;
				}
			}
		}
	}

	@media screen and (max-width: 425px) {
		.dropdown-label {
			font-size: 3.75vw;
			margin-right: 1.5vw;
		}
		
		.dropdown {
			height: 8.7vw;

			.error-text{
				font-size: 2.4vw;
				margin-top: 1.5vw;
				gap: 1.5vw;

				svg{
					width: 4.14vw;
					height: 4.14vw;
				}
			}

			.dropdown-header {
				padding: 2.1vw 3vw;
				gap: 2.1vw;
				border-radius: 1.5vw;
				height: 8.7vw;
				box-shadow: 0 0 2.1vw 0 rgba(0, 0, 0, 0.10);
				&-content{
					span {
						cursor: pointer;
						svg{
							width: 2.8vw;
						}
					}
				}
				.dropdown-header-main-icon {
					svg {
						width: 3vw;
					}
				}

				.selected-option {
					font-size: 3.32vw;
					line-height: 4.2vw;
				}

				.dropdown-header-icon {
					svg {
						width: 2vw;
					}
				}
			}

			.dropdown-options-container {
				border-radius: 1.5vw;
				box-shadow: 0 0 2.1vw 0 rgba(0, 0, 0, 0.10);
				.pagination-container{
					padding: 0.2vw;
					position: relative;
					min-width: 30vw;
				}
				.dropdown-option {
					padding: 2.1vw;
					border-radius: 0.75vw;

					.dropdown-option-text {
						font-size: 3.75vw;
						line-height: 4.2vw;
					}
				}
				.disabledOption{
					font-size: 2.4vw;
				}
				&.open-dropdown-options-container{
					max-height: 45vw;
					padding: 2.1vw;
				}
			}
		}
	}
}

