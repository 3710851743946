.dashboard-container {
  height: 100%;
  width: 100%;
  margin-top: 0.25vw;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
}

.dashboard-container-actions {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 80%;
  padding: 2vw;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    margin-top: 0.375vw;
  }
  .dashboard-container-actions {
    padding: 3vw;
  }
}

@media screen and (max-width: 425px) {
  .dashboard-container {
    margin-top: 0.75vw;
  }
  .dashboard-container-actions {
    padding: 6vw;
  }
}
