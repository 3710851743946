.verification {
  width: 100%;
  min-height: 100vh;
  background-color: var(--accentPrimary);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .verification-form {
    width: 39.5vw;
    padding: 2vw 3.8vw 2.7vw 3.8vw;
    border-radius: 1.4vw;
    background-color: var(--backgroundMain);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875vw;

    .error-container{
      height: 1.3vw;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1vw 0;

      p{
        color: var(--colorAlertText);
        margin-left: 0.83vw;
        font-size: 1.112vw;
      }

    }
  .resend-code-container {
  color: var(--text1);
  font-size: 1.11vw;
  font-weight: 400;
  line-height: 1.67vw;
  letter-spacing: 0.035vw;
  .resend-code {
    color: var(--text1);
    cursor: pointer;
  }
  }
    .form-email-info{
      display: flex;
      flex-direction: column;
      align-items: center;

      p{
        font-size: 1vw;
        color: var(--text1);
      }
      
      .email{
        font-weight: 600;
        font-size: 1.11vw;
      }
    }
    
    .form-control{
      width: 100%;
      input{
        width: 100%;
      }
    }

  }
}
  

@media screen and (max-width: 768px) {

  .verification {
    .verification-form {
      width: 60vw;
      padding: 3vw 5.7vw 4.05vw 5.7vw;
      border-radius: 2.1vw;
      gap: 3.75vw;
  
      .error-container{
        height: 1.95vw;
        margin: 1.5vw 0;
  
        p{
          margin-left: 1.2vw;
          font-size: 1.66vw;
        }
  
      }

      .resend-code-container {
        font-size: 1.6vw;
        line-height: 2.5vw;
        letter-spacing: 0.0525vw;
      }

      .form-email-info{
        p{
          font-size: 1.5vw;
        }
        
        .email{
          font-size: 1.66vw;
        }
      }  
    }
  }
}


@media screen and (max-width: 425px) {

  .verification {
    .verification-form {
      width: 100vw;
      padding: 6vw 11.4vw 8.1vw 11.4vw;
      border-radius: 2.1vw;
      gap: 7.5vw;
  
      .error-container{
        height: 3.9vw;
        margin: 3vw 0;
  
        p{
          margin-left: 2.4vw;
          font-size: 3.32vw;
        }
  
      }

      .resend-code-container {
        font-size: 3.2vw;
        line-height: 5vw;
        letter-spacing: 0.1vw;
      }

      .form-email-info{
        p{
          font-size: 3vw;
        }
        
        .email{
          font-size: 3.32vw;
        }
      }  
    }
  }
}