.reset-password-email {
  background-color: var(--accentPrimary);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .reset-password-email-langueage{
    position: absolute;
    top: 1vw;
    right: 1vw;
    background-color: var(--accentTertiary);
    padding: 1vw 1vw;
    border-radius: 1vw;
  }

  .reset-password-email-container {
    width: 40%;
    margin: 0 auto;
    border-radius: 1.4vw;
    background-color: var(--accentTertiary);
    padding: 2.08vw 3.68vw;
    display: flex;
    flex-direction: column;
    gap: 0.90vw;
    
    .reset-password-email-form {
      display: flex;
      flex-direction: column;
      gap: 1.80vw;
      .form-controls {
        display: flex;
        flex-direction: column;
        gap: 2.08vw;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .reset-password-email {
    .reset-password-email-langueage{
      top: 1.5vw;
      right: 1.5vw;
      padding: 1.5vw 1.5vw;
      border-radius: 1.5vw;
    }
    .reset-password-email-container {
      width: 60%;
      border-radius: 2.1vw;
      padding: 3.12vw 5.55vw;
      gap: 1.35vw;
      
      .reset-password-email-form {
        gap: 3.6vw;
        .form-controls {
          gap: 3.12vw;
        }
      }
    }
  }

}

@media screen and (max-width: 425px) {

  .reset-password-email {
    .reset-password-email-langueage{
      top: 3vw;
      right: 3vw;
      padding: 3vw 3vw;
      border-radius: 3vw;
    }

    .reset-password-email-container {
      width: 100%;
      border-radius: 4.2vw;
      padding: 6.24vw 11.1vw;
      gap: 2.7vw;
      
      .reset-password-email-form {
        gap: 7.2vw;
        .form-controls {
          gap: 6.24vw;
        }
      }
    }
  }

}