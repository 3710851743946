@use '../../../styles/variables' as styles;

.profile-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: start;

	.profile-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;

		.profile-user-data {
			display: flex;
			flex-direction: column;
			align-items: start;
			margin: 3vw 0vw 3vw 0vw;
			font-size: styles.$textLg;
			color: var(--text1);
			gap: 0.6vw;
		}
	}
}


@media screen and (max-width: 768px) {


	.profile-wrapper {

		.profile-container {

			.profile-user-data {
				margin: 4.5vw 0vw 4.5vw 0vw;
				font-size: 1.5vw;
				gap: 0.9vw;
			}

			.profile-url-container {
				&-avatar {
					width: 13.5vw;
					height: 12.12vw;
					margin-right: 7.5vw;

					button {
						top: 4.5vw;
						width: 1.66vw;
						height: 1.66vw;
					}
				}


			}
		}
	}

}

@media screen and (max-width: 425px) {


	.profile-wrapper {

		.profile-container {

			.profile-user-data {
				margin: 9vw 0vw 9vw 0vw;
				font-size: 3vw;
				gap: 1.8vw;
			}

			.profile-url-container {
				&-avatar {
					width: 27vw;
					height: 24.24vw;
					margin-right: 15vw;

					button {
						top: 9vw;
						width: 3.32vw;
						height: 3.32vw;
					}
				}


			}
		}
	}

}