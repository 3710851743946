.time-trackers {
	width: 100%;
	display: flex;
	flex: content;
	flex-direction: column;
	align-items: center;
    gap: 2vw;
	padding: 5vw;
	background-color: var(--accentPrimary);
    min-height: 100vh;

    .time-tracker-details{
        flex-direction: column;
    }
}