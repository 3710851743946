.app-feature-component-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4vw;
    background: #F2F4F6;
  
    .app-feature-component {
      padding-top: 3vw;
      padding-left: 3vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      overflow-x: clip;
      gap: 5vw;
      height: 40vw;
  
      .app-feature-component-img-container {
        position: relative;
        z-index: 3;
        flex: 1;
        width: 40vw;
        img{
          width: 100%;
          z-index: 5;
        }
      }
  
      .app-feature-component-content {
        position: relative;
        width: 50vw;
  
        .app-feature-component-content-container {
          display: flex;
          flex-direction: column;
        }
  
        .app-feature-component-subtitle-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          background-color: white;
          border-radius: 6.94vw;
          padding: 0.28vw 0.83vw;
          text-align: left;
          font-size: 0.97vw;
          line-height: 1.46vw;
          max-width: fit-content;
          gap: 0.5vw;
  
          svg {
            width: 1.4vw;
            height: 1.4vw;
          }
        }
  
        .app-feature-component-first-subtitle {
          font-weight: 700;
        }
  
        .app-feature-component-rest-subtitle {
          font-weight: 400;
        }
  
        .title {
          text-align: left;
          font-weight: 800;
          margin: 0.83vw 0 1.39vw;
        }
  
        .landing-text {
          text-align: left;
          font-weight: 500;
          color: #6f6c8a;
        }
  
        .app-feature-component-buttons {
          display: flex;
          align-items: center;
          gap: 1vw;
          margin-top: 3vw;
  
          .app-feature-component-button-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5vw;
  
            .landing-text{
              display: flex;
              gap: 0.3vw;
              align-items: center;
  
              svg{
                width: 1.3vw;
                height: 1.3vw;
              }
            }
          }
  
          .app-feature-component-button-2 {
            .button-outlined {
              padding: 0.755vw 0.83vw;
              background-color: white;
            }
            span {
              font-weight: 700;
            }
  
            button:hover{
              p{
                color: var(--accentPrimary);
              }
            }
          }
        }
      }
  
      &.app-feature-component-rtl {
        padding-left: 0vw;
        padding-right: 3vw;
        flex-direction: row-reverse;
  
        .app-feature-component-img-container {
          img {
            right: 8%;
          }
        }
      }
      &.app-feature-component::before {
        content: "";
        position: absolute;
        width: 15vw; 
        height: 70vw; 
        top: -14vw; 
        left: -16vw; 
        transform: rotate(90deg); 
        z-index: 2; 
        background-color: var(--colorLightBlue); 
        filter: blur(6vw); 
      }
      &.app-feature-component::after {
        content: "";
        position: absolute;
        width: 15vw; 
        height: 70vw; 
        top: -14vw; 
        right: -16vw; 
        transform: rotate(90deg); 
        z-index: 2; 
        background-color: #c0bbf1; 
        filter: blur(7vw); 
      }
    }
  }
  
  @media (max-width: 768px) {
    .app-feature-component-wrapper {
      gap: 6vw;
      .app-feature-component {
        padding: 4.5vw 0 4.5vw 4.5vw;
        gap: 7.5vw;
        height: fit-content;

        &.app-feature-component::before {
          content: "";
          position: absolute;
          width: 21vw; 
          height: 105vw; 
          top: -6vw; 
          left: -26vw; 
          transform: rotate(90deg); 
          z-index: 2; 
          background-color: var(--colorLightBlue); 
          filter: blur(9vw); 
        }
        &.app-feature-component::after {
          content: "";
          position: absolute;
          width: 21vw; 
          height: 105vw; 
          top: -38vw; 
          right: -16vw; 
          transform: rotate(90deg); 
          z-index: 2; 
          background-color: #c0bbf1; 
          filter: blur(10vw); 
        }
  
        .app-feature-component-content {
          .app-feature-component-subtitle-container{
            padding: 0.6vw 1vw;
            font-size: 1.5vw;
            gap: 0.75vw;
            svg{
              width: 1.8vw;
              height: 1.8vw;
            }
            span{
              font-size: 1.6vw;
            }
          }
  
          .title {
            font-size: 4.95vw;
          }
    
          .landing-text {
            font-size: 1.6vw;
          }
  
          .app-feature-component-buttons{
            gap: 1.5vw;
            margin-top: 4.5vw;
            .app-feature-component-button-1{
              gap: 0.75vw;
              .button{
                padding: 0.73vw;
                p{
                  font-size: 1.6vw;
                }
              }
              .landing-text {
                font-size: 1.6vw;
                gap: 0.5vw;
                svg{
                  width: 1.95vw;
                  height: 1.95vw;
                }
              }
            }
            .app-feature-component-button-2 {
              .button-outlined {
                padding: 1vw 1.2vw;
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 425px) {
    .app-feature-component-wrapper {
      .app-feature-component {
        padding: 9vw 9vw;
        gap: 15vw;
        &.app-feature-component::before {
          content: "";
          position: absolute;
          width: 45vw; 
          height: 140vw; 
          top: 96vw; 
          left: -16vw; 
          transform: rotate(90deg); 
          z-index: 2; 
          background-color: var(--colorLightBlue); 
          filter: blur(18vw); 
        }
        &.app-feature-component::after {
          content: "";
          position: absolute;
          width: 45vw; 
          height: 140vw; 
          top: -14vw; 
          right: -16vw; 
          transform: rotate(90deg); 
          z-index: 2; 
          background-color: #c0bbf1; 
          filter: blur(21vw); 
        }

        flex-direction: column;
        .app-feature-component-content {
          width: 90vw;
          .app-feature-component-subtitle-container{
            padding: 1vw 2.4vw;
            gap: 1.5vw;
            font-size: 3vw;
            svg{
              width: 3.6vw;
              height: 3.6vw;
            }
            span{
              font-size: 3.3vw;
            }
          }
  
          .title {
            font-size: 9vw;
          }
    
          .landing-text {
            font-size: 3.3vw;
          }

          .app-feature-component-buttons{
            gap: 3vw;
            margin-top: 9vw;
            .app-feature-component-button-1{
              gap: 1.5vw;
              .button{
                padding: 1.65vw;
                p{
                  font-size: 3.3vw;
                }
                .arrow-icon-container{
                  width: 5.1vw;
                  height: 5.1vw;
                  border-radius: 2vw;
                  svg{
                      width: 3vw;
                      height: 3vw;
                  }
          
                }
              }
              .landing-text {
                font-size: 3.3vw;
                svg{
                  width: 3.9vw;
                  height: 3.9vw;
                }
              }
            }
          }
  
          .app-feature-component-content-container {
            gap: 3vw;
          }
        }
        .app-feature-component-img-container {
          width: 97vw;
        }
        &.app-feature-component-rtl {
          padding-left: 5vw;
          flex-direction: column;
    
          .app-feature-component-img-container {
            img {
              margin-left: -5vw;
            }
          }
        }
      }
    }
  }