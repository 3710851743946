@use '/src/styles/variables' as styles;

.checkbox-container{
  display: flex;
  align-items: center;
  
  .checkbox{
    border: .15vw solid #444444;
    cursor: pointer;
    border-radius: styles.$roundedSm;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1vw;
    height: 1vw;
  }

  .checked{
    display: block;
    background-color: #444444;
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 0.34vw;
  }

  .unchecked{
    display: none;
  }

  .checkbox-label{
    color: #444;
    cursor: pointer;
    font-size: 1.25vw;
    margin-left: 0.7vw;
  }
}

@media screen and (max-width: 768px) {


  .checkbox-container{
    .checkbox{
      border: .225vw solid #444444;
      border-radius: 0.375vw;
      width: 1.5vw;
      height: 1.5vw;
    }
  
    .checked{
      width: 0.75vw;
      height: 0.75vw;
      border-radius: 0.525vw;
    }
  
    .checkbox-label{
      font-size: 1.85vw;
      margin-left: 1.05vw;
    }
  }

}

@media screen and (max-width: 425px) {


  .checkbox-container{
    .checkbox{
      border: .45vw solid #444444;
      border-radius: 0.75vw;
      width: 3vw;
      height: 3vw;
    }
  
    .checked{
      width: 1.5vw;
      height: 1.5vw;
      border-radius: 1.05vw;
    }
  
    .checkbox-label{
      font-size: 2.9vw;
      margin-left: 2.1vw;
    }
  }

}