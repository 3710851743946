
.status-label{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.35vw 0.7vw;
    text-transform: uppercase;
    font-size: 0.83vw;
    border-radius: 0.35vw;
}


@media screen and (max-width: 768px) {

    .status-label{
        padding: 0.53vw 1.05vw;
        font-size: 1.245vw;
        border-radius: 0.53vw;
    }

}

@media screen and (max-width: 425px) {

    .status-label{
        padding: 1.05vw 2.1vw;
        font-size: 2.49vw;
        border-radius: 1.05vw;
    }

}