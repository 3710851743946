.landing-text-section-component-wrapper {
    display: flex;
    position: relative;
    overflow-x: clip;

    .landing-text-section-container-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 4.1vw;
        height: 4.1vw;
        border-radius: 1vw;
        box-shadow: 0.5vw 0.5vw 1vw 0px #544b9f08;
        padding: 1.2vw;
        z-index: 4;

        svg {
            path {
                stroke: var(--text1);
            }
            fill: var(--text1);
            width: 1.6vw;
        }
    }

    .landing-text-section-component {
        padding-top: 8vw;
        display: flex;
        position: relative;
        z-index: 1;
        background: #F2F4F6;
        gap: 5vw;
        width: 100%;
        padding-bottom: 8vw;

        .animation-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .landing-text-section-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                text-align: center;
                z-index: 2;
                width: 65vw;

                .title {
                    font-family: "Inter", sans-serif;
                    font-weight: 200;
                    font-size: 3.3vw;
                    line-height: 1.2;
                    padding-bottom: 1vw;
                }

                .title-black {
                    font-weight: 800;
                }

                .landing-text {
                    max-width: 70%;
                    font-size: 1.1vw;
                    font-weight: 500;
                    color: #6f6c8a;

                    display: flex;
                    gap: 0.3vw;
                    align-items: center;

                    svg {
                        width: 1.3vw;
                        height: 1.3vw;
                    }
                }
                .landing-text-section-content-actions {
                    display: flex;
                    gap: 1vw;
                    justify-content: center;
                    align-items: center;
                    margin: 3vw auto 1vw;
                    width: fit-content;
                }

                @keyframes content-animation {
                    from {
                        top: -10vw;
                        opacity: 0;
                    }
                    to {
                        top: 0;
                        opacity: 1;
                    }
                }
            }
        }

        &.landing-text-section-component::before {
            content: "";
            position: absolute;
            width: 15vw;
            height: 70vw;
            top: -14vw;
            left: -16vw;
            transform: rotate(90deg);
            z-index: -1;
            background-color: var(--colorLightBlue);
            filter: blur(6vw);
        }
        &.landing-text-section-component::after {
            content: "";
            position: absolute;
            width: 15vw;
            height: 70vw;
            top: -14vw;
            right: -16vw;
            transform: rotate(90deg);
            z-index: -1;
            background-color: #c0bbf1;
            filter: blur(7vw);
        }
    }
}

@media (max-width: 768px) {
    .button {
        p {
            font-size: 1.6vw;
        }
    }
    .landing-text-section-component-wrapper {
        .landing-text-section-container-icon {
            width: 6.15vw;
            height: 6.15vw;
            border-radius: 1.5vw;
            svg{
                width: 2.4vw;
            }
        }

        .landing-text-section-component {
            &.landing-text-section-component::before {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                left: -14vw;
                top: 34vw;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
            &.landing-text-section-component::after {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: -6vw;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
                z-index: 2;
            }

            padding: 12vw 0;
            gap: 7.5vw;
            .animation-container {

                .landing-text-section-content {
                    width: 80vw;
                    gap:1.5vw;
                    .title {
                        font-size: 4.95vw;
                    }

                    .landing-text {
                        max-width: 100%;
                        font-size: 1.6vw;

						svg {
							width: 1.95vw;
							height: 1.95vw;
						}
                    }

                    .landing-text-section-content-actions{
                        gap: 1.5vw;
                        .button{
                            padding: 0.73vw;
                            p{
                                font-size: 1.6vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .button {
        p {
            font-size: 3.3vw;
        }
    }
    .landing-text-section-component-wrapper {

        .landing-text-section-container-icon {
            width: 12.3vw;
            height: 12.3vw;
            border-radius: 3vw;
            svg{
                width: 4.8vw;
            }
        }

        .landing-text-section-component {
            &.landing-text-section-component::before {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                left: -14vw;
                top: 94vw;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
            &.landing-text-section-component::after {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: -6vw;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
                z-index: 2;
            }

            height: fit-content;
            padding: 24vw;
            gap: 15vw;
            .animation-container {

                .landing-text-section-content {
                    gap: 3vw;
                    width: 90vw;
                    .title {
                        font-size: 9vw;
                    }

                    .landing-text {
                        max-width: 100%;
                        font-size: 3.3vw;

						svg {
							width: 3.9vw;
							height: 3.9vw;
						}
                    }

                    .landing-text-section-content-actions{
                        gap: 3vw;
                        margin: 9vw auto 3vw;
                        .button{
                            padding: 1.65vw;
                            p{
                                font-size: 3.3vw;
                            }
                            .arrow-icon-container{
                                width: 5.1vw;
                                height: 5.1vw;
                                border-radius: 2vw;
                                svg{
                                    width: 3vw;
                                    height: 3vw;
                                }
                    
                            }
                            .button-icon{
                                svg{
                                    width: 3.9vw;
                                    height: 3.9vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}