.timeline{
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: scroll;

    .timeline-calendar-table-date-container{
        display: flex;
        justify-content: left;
        position: absolute;
        height: 100%;
    }

    .timeline-calendar-table-time-interval-container{
        position: absolute;
        overflow-y: scroll;
        height: calc(100% - 8.5vw);
		top: 8.5vw;
        padding: 1vw 0;
    }

    .timeline-calendar-table-time-interval-container::-webkit-scrollbar {
        background: transparent;
        width: 0.69vw;
    }
}

@media screen and (max-width: 768px) {

    .timeline{
    
        .timeline-calendar-table-time-interval-container{
            height: calc(100% - 12.75vw);
            top: 12.75vw;
            padding: 1.5vw 0;
        }
    
        .timeline-calendar-table-time-interval-container::-webkit-scrollbar {
            width: 1.05vw;
        }
    }

}

@media screen and (max-width: 425px) {

    .timeline{
    
        .timeline-calendar-table-time-interval-container{
            height: calc(100% - 25.5vw);
            top: 25.5vw;
            padding: 3vw 0;
        }
    
        .timeline-calendar-table-time-interval-container::-webkit-scrollbar {
            width: 2.1vw;
        }
    }

}