.landing-special-offer-component-wrapper {
    display: flex;
    position: relative;
    overflow-x: clip;

    .landing-special-offer-container-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 4.1vw;
        height: 4.1vw;
        border-radius: 1vw;
        box-shadow: 0.5vw 0.5vw 1vw 0px #544b9f08;
        padding: 1.2vw;
        z-index: 4;

        svg {
            path {
                stroke: var(--text1);
            }
            fill: var(--text1);
            width: 1.6vw;
        }
    }

    .landing-special-offer-component {
        padding-top: 8vw;
        display: flex;
        position: relative;
        z-index: 1;
        background: #F2F4F6;
        gap: 5vw;
        width: 100%;
        padding-bottom: 8vw;

        .animation-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .landing-special-offer-content {
                display: flex;
                position: relative;
                justify-content: space-between;
                z-index: 2;
                width: 75vw;
                background-color: #1E1B39;
                padding: 6vw;
                border-radius: 4vw;

                .logo-container{
                    width: 25%;
                    height: auto;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
                .landing-special-offer-content-container{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 70%;

                    .title {
                        font-family: "Inter", sans-serif;
                        font-weight: 200;
                        font-size: 2.5vw;
                        line-height: 1.2;
                        padding-bottom: 1vw;
                        color: #fff;
                        text-align: left;
                    }
    
                    .title-black {
                        font-weight: 800;
                        color: #FFF;
                    }
    
                    .landing-text {
                        max-width: 70%;
                        font-size: 1.25vw;
                        font-weight: 500;
                        color: #fff;
    
                        display: flex;
                        gap: 0.3vw;
                        align-items: center;
    
                        text-align: left;
    
                        svg {
                            width: 1.3vw;
                            height: 1.3vw;
                            path{
                                stroke: #fff;
                            }
                        }
                    }
                    .landing-special-offer-content-actions {
                        display: flex;
                        gap: 1vw;
                        justify-content: left;
                        align-items: center;
                        margin: 3vw 0vw 1vw;
                        width: fit-content;
                        .button{
                            height: 100%;
                        }
                    }
                }


                @keyframes content-animation {
                    from {
                        top: -10vw;
                        opacity: 0;
                    }
                    to {
                        top: 0;
                        opacity: 1;
                    }
                }
            }
        }

        &.landing-special-offer-component::before {
            content: "";
            position: absolute;
            width: 15vw;
            height: 70vw;
            top: -14vw;
            left: -16vw;
            transform: rotate(90deg);
            z-index: -1;
            background-color: var(--colorLightBlue);
            filter: blur(6vw);
        }
        &.landing-special-offer-component::after {
            content: "";
            position: absolute;
            width: 15vw;
            height: 70vw;
            top: -14vw;
            right: -16vw;
            transform: rotate(90deg);
            z-index: -1;
            background-color: #c0bbf1;
            filter: blur(7vw);
        }
    }
}

@media (max-width: 768px) {
    
    .landing-special-offer-component-wrapper {
        .button {
            p {
                font-size: 1.6vw;
            }
        }
        .landing-special-offer-container-icon {
            width: 6.15vw;
            height: 6.15vw;
            border-radius: 1.5vw;
            svg{
                width: 2.4vw;
            }
        }

        .landing-special-offer-component {
            &.landing-special-offer-component::before {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                left: -14vw;
                top: 34vw;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
            &.landing-special-offer-component::after {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: -6vw;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
                z-index: 2;
            }

            padding: 12vw 0;
            gap: 7.5vw;
            .animation-container {

                .landing-special-offer-content {
                    width: 80vw;
                    .landing-special-offer-content-container{
                        gap: 1.5vw;
                        .title {
                            font-size: 3.75vw;
                        }
    
                        .landing-text {
                            max-width: 100%;
                            font-size: 1.9vw;
    
                            svg {
                                width: 1.95vw;
                                height: 1.95vw;
                            }
                        }
    
                        .landing-special-offer-content-actions{
                            gap: 1.5vw;
                            .button{
                                padding: 0.73vw;
                                p{
                                    font-size: 1.6vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .landing-special-offer-component-wrapper {

        .button {
            p {
                font-size: 3.3vw;
            }
        }
        .landing-special-offer-container-icon {
            width: 12.3vw;
            height: 12.3vw;
            border-radius: 3vw;
            svg{
                width: 4.8vw;
            }
        }

        .landing-special-offer-component {
            &.landing-special-offer-component::before {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                left: -14vw;
                top: 94vw;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
            &.landing-special-offer-component::after {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: -6vw;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
                z-index: 2;
            }

            height: fit-content;
            padding: 24vw;
            gap: 15vw;
            .animation-container {

                .landing-special-offer-content {
                    width: 90vw;
                    .logo-container{
                        display: none;
                    }
                    .landing-special-offer-content-container{
                        width: 100%;
                        align-items: center;
                        gap: 3vw;
                        .title {
                            font-size: 7.5vw;
                            text-align: center;
                        }

                        .landing-text {
                            max-width: 100%;
                            font-size: 3.75vw;
                            text-align: center;

                            svg {
                                width: 3.9vw;
                                height: 3.9vw;
                            }
                        }

                        .landing-special-offer-content-actions{
                            gap: 3vw;
                            margin: 9vw auto 3vw;
                            justify-content: center;
                            .button{
                                padding: 1.65vw;
                                p{
                                    font-size: 3.3vw;
                                }
                                .arrow-icon-container{
                                    width: 5.1vw;
                                    height: 5.1vw;
                                    border-radius: 2vw;
                                    svg{
                                        width: 3vw;
                                        height: 3vw;
                                    }
                        
                                }
                                .button-icon{
                                    svg{
                                        width: 3.9vw;
                                        height: 3.9vw;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}