@use '/src/styles/variables' as styles;
@use '/src/styles/mixin' as utils;

$duration: styles.$transitionDuration;
$transition: styles.$transition;
$navbarWidth: 19.8vw;

.navbar {
	display: flex;
	gap: 1vw;
	height: 100vh;

	.navbar-wrapper {
		height: 100%;
		width: $navbarWidth;
		min-width: $navbarWidth;
		max-width: $navbarWidth;
		background: #fff;
		box-shadow: styles.$shadowMd;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-right: 0.12vw solid var(--accentDisabled);
		color: var(--accentPrimary);
		fill: var(--accentPrimary);
		transition: all ease 0.4s;

		&::-webkit-scrollbar {
			display: none;
		}

		.navbar-header {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0.5vw 0;
			justify-content: center;
			cursor: pointer;
			.navbar-head-text {
				font-size: styles.$textXl;
				color: var(--accentPrimary);
				font-weight: styles.$fontMedium;
			}

			.navbar-company-avatar-container {
				display: flex;

				img {
					width: 7.14vw;
					height: 7.14vw;

					border-radius: 50%;
				}

				svg {
					display: block;
					width: 7.14vw;
					height: 7.14vw;
					path {
						fill: var(--accentPrimary);
					}
				}
			}
		}

		.navbar-content {
			height: auto;
			width: 100%;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			padding: 1.8vw;
			padding-top: 0.5vw;
			gap: 0.2vw;
			&::-webkit-scrollbar {
				display: none;
			}
		}

		.navbar-bottom {
			width: 100%;
			@include utils.flex-col;
			padding: 0 1.8vw;

			.misc-nav {
				border-bottom: 0.2vw solid var(--accentDisabled);
				width: 100%;
				font-size: styles.$textSm;
				padding-bottom: 0.2vw;

				.language-selector-container {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 1vw 0;
				}

				.nav-button {
					margin-bottom: 0.2vw;
					display: flex;
					align-items: center;
					padding: 0.5vw;
					gap: 1vw;

					color: var(--accentPrimary);
					border-radius: styles.$roundedMd;
					transition: $transition;
					.icon {
						display: flex;
						svg {
							width: 1.3vw;
							height: 1.3vw;
							fill: var(--accentPrimary);
							transition: $transition;
							path {
								transition: $transition;
								fill: var(--accentPrimary);
							}
						}
					}

					&:hover {
						background-color: var(--accentPrimary);
						color: var(--textHover);

						.unread-messages-container {
							background: var(--textHover);
							color: var(--accentPrimary);
						}

						& .icon svg {
							//fill: var(--textHover);
							path {
								fill: var(--textHover);
							}
						}
					}

					.unread-messages-container {
						padding: 0.35vw;
						background: var(--accentPrimary);
						color: var(--textHover);
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						font-size: 0.75vw;
						font-weight: 600;
						line-height: 100%;
						transition: all ease 0.4s;
						min-width: 1.52vw;
						min-height: 1.52vw;
						max-width: 1.52vw;
						max-height: 1.52vw;
					}
				}
			}
			.logout {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;
				padding: 3vw 0;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	$navbarWidth: 30vw;

	.navbar {
		display: flex;
		height: 100vh;
		&.mobile {
			position: absolute;
			z-index: 5;

			.navbar-wrapper {
				width: 0;
			}

			&.open {
				.navbar-wrapper {
					width: $navbarWidth;
					min-width: $navbarWidth;
					max-width: $navbarWidth;
				}
			}
		}

		.navbar-wrapper {
			height: 100vh;
			width: 0;
			min-width: 0;
			max-width: 0;

			border-right: 0.18vw solid var(--accentDisabled);

			.navbar-header {
				padding: 0.75vw 0;

				.navbar-head-text {
					font-size: 2.07vw;
				}

				.navbar-company-avatar-container {
					svg {
						width: 6.24vw;
						height: 8.7vw;
					}

					img {
						width: 10.71vw;
						height: 10.71vw;
					}
				}
			}

			.navbar-content {
				padding: 2.7vw;
				padding-top: 0.75vw;
				gap: 0.3vw;
			}

			.navbar-bottom {
				padding: 0 2.7vw;

				.misc-nav {
					border-bottom: 0.3vw solid var(--accentDisabled);
					font-size: 1.45vw;
					padding-bottom: 0.3vw;
					.nav-button {
						margin-bottom: 0.3vw;
						padding: 0.75vw;
						gap: 1.5vw;

						border-radius: 0.75vw;
						.icon {
							svg {
								width: 1.95vw;
								height: 1.95vw;
							}
						}
					}
				}
				.logout {
					padding: 4.5vw 0;
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {
	$navbarWidth: 60vw;

	.navbar {
		display: flex;
		height: 100vh;
		&.mobile {
			position: absolute;
			z-index: 5;

			.navbar-wrapper {
				width: 0;
			}

			&.open {
				.navbar-wrapper {
					width: $navbarWidth;
					min-width: $navbarWidth;
					max-width: $navbarWidth;
				}
			}
		}

		.navbar-wrapper {
			height: 100vh;
			width: 0;
			min-width: 0;
			max-width: 0;

			border-right: 0.36vw solid var(--accentDisabled);

			.navbar-header {
				padding: 1.5vw 0;

				.navbar-head-text {
					font-size: 4.014vw;
				}

				.navbar-company-avatar-container {
					img {
						width: 21.42vw;
						height: 21.42vw;
					}

					svg {
						width: 12.48vw;
						height: 17.4vw;
					}
				}
			}

			.navbar-content {
				padding: 5.4vw;
				padding-top: 1.5vw;
				gap: 0.6vw;
			}

			.navbar-bottom {
				padding: 0 5.4vw;

				.misc-nav {
					border-bottom: 0.6vw solid var(--accentDisabled);
					font-size: 2.9vw;
					padding-bottom: 0.6vw;
					.nav-button {
						margin-bottom: 0.6vw;
						padding: 1.5vw;
						gap: 3vw;

						border-radius: 1.5vw;
						.icon {
							svg {
								width: 3.9vw;
								height: 3.9vw;
							}
						}
					}
				}
				.logout {
					padding: 9vw 0;
				}
			}
		}
	}
}
