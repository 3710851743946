@use '/src/styles/variables' as styles;

.account-transaction-list{
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	&-table{
		width: 100%;
		height: 46vh;
		overflow-y: scroll;
		
		.account-transaction-icon{
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				width: 2vw;
				height: 2vw;
			}
		}
	}
}

@media screen and (max-width: 768px) {

    .account-transaction-list{
		&-table{
	
			.account-transaction-icon{
				svg {
					width: 3vw;
					height: 3vw;
				}
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.account-transaction-list{
		&-table{
	
			.account-transaction-icon{
				svg {
					width: 6vw;
					height: 6vw;
				}
			}
		}
	}
}