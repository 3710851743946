@use '/src/styles/variables' as styles;

.file-export-container{
	padding: 1vw;
    width: 40vw;
	.file-export-field{
		margin-top: .5vw;
		label{
			font-size: styles.$textSm;
			color: var(--text2);
		}
	}
}

@media screen and (max-width: 768px) {

	.file-export-container{
		padding: 1.5vw;
		width: 60vw;
		.file-export-field{
			margin-top: .75vw;
			label{
				font-size: 1.45vw;
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.file-export-container{
		padding: 3vw;
		width: 80vw;
		.file-export-field{
			margin-top: 1.5vw;
			label{
				font-size: 2.9vw;
			}
		}
	}

}