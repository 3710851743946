.subtitle{
    font-size: 1.6vw;
    font-weight: 400;
    text-align: center;
    color: #1E1B39;
}

@media (max-width: 768px) {
    .subtitle{
        font-size: 2.4vw;
    }
}

@media (max-width: 425px) {
    .subtitle{
        font-size: 3.75vw;
    }
}