
.create-product-container{
	height: 100%;
	.product-info{
		padding: 2vw;
		.form-wrapper {
	
			height: 100%;
			width: 56vw;
			display: grid;
			grid-template-columns: 1fr 1fr;
			justify-content: center;
			gap: 1.3vw 4.6vw;
		
			.text-area {
				justify-content: flex-end;
				.text-area-container {
					background-color: var(--accentTertiary);
					height: initial;
					border-radius: 0.7vw;
					textarea {
						background-color: transparent;
					}
				}
			}
		
			.form-dropdown{
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				&-label {
					font-size: 1.13vw;
				}
				&-currency {
					width: inherit;
					box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
	
					.dropdown {
						width: 100%;
						.dropdown-options-container,
						.dropdown-header {
							background: var(--accentTertiary);
						}
					}
				}
			}
			
		}
		
		
		
	}

	.table-wrapper {
		padding: 2vw;
		.inventory-items-label {
			font-size: 1.11vw
		}
	}
}