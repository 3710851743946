@use '/src/styles/variables' as styles;

.list-summary-card {
	width: 100%;
	background: #FAFAFA;
	border-radius: styles.$roundedLg;

	&-title {
		display: flex;
		align-items: center;
		gap: 0.7vw;
		padding: 1.04vw 1.73vw;
		border-top-left-radius: styles.$roundedLg;
		border-top-right-radius: styles.$roundedLg;
		background: var(--accentPrimary);

		svg{
			width: 1.25vw;
			height: 1.25vw;
		}

		p {
			color: #E8EBEE;
			font-size: styles.$textLg;
			font-weight: styles.$fontMedium;
		}
	}

	&-content {
		padding: 1.39vw 1.73vw;

		&-title {
			color:  #898989;
			font-size: styles.$textMd;
			font-weight: styles.$font2xBold;
			line-height: normal;
		}

		&-data {
			display: flex;
			flex-direction: column;
			gap: 1.4vw;

			&-item{
				display: flex;
				flex-direction: column;
				gap: 0.35vw;

				.title{
					color:  #898989;
					font-size: styles.$textMd;
					font-weight: 400;
					text-align: left;
				}

				.text{
					word-break: break-all;
					white-space: normal;
					font-size: styles.$text2xl;
					font-weight: styles.$font2xBold;
					color: var(--text1);
				}
			}
		}
	}

}

@media screen and (max-width: 768px) {
	.list-summary-card {
		border-radius: 1.5vw;

		&-title {
			gap: 1.05vw;
			padding: 1.56vw 2.59vw;
			border-top-left-radius: 1.5vw;
			border-top-right-radius: 1.5vw;

			svg{
				width: 1.875vw;
				height: 1.875vw;
			}

			p {
				font-size: 1.875vw;
			}
		}

		&-content {
			padding: 2.08vw 2.6vw;

			&-title {
				font-size: 1.66vw;
			}

			&-data {
				gap: 2.1vw;

				&-item{
					gap: 0.525vw;

					.title{
						font-size: 1.66vw;
					}

					.text{
						font-size: 2.5vw;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {
	.list-summary-card {
		border-radius: 3vw;

		&-title {
			gap: 2.1vw;
			padding: 3.12vw 5.2vw;
			border-top-left-radius: 3vw;
			border-top-right-radius: 3vw;

			svg{
				width: 3.75vw;
				height: 3.75vw;
			}

			p {
				font-size: 3.75vw;
			}
		}

		&-content {
			padding: 3.12vw 5.2vw;

			&-title {
				font-size: 3.32vw;
			}

			&-data {
				gap: 4.2vw;

				&-item{
					gap: 1.5vw;

					.title{
						font-size: 3.32vw;
					}

					.text{
						font-size: 5vw;
					}
				}
			}
		}
	}
}