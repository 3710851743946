@use '/src/styles/variables' as styles;

.table {
    width: 100%;
    border-spacing: 0;

	// th, td {
	// 	min-width: fit-content; /* Ensure the cell is at least wide enough to fit the content */
	// 	white-space: nowrap;   /* Prevent text from wrapping to multiple lines */
	// }
	

	.nested-table-arrow{
		cursor: pointer;
		width: 1vw;
		height: 1vw;
		transform: rotate(90deg);

		svg{
			width: 1vw;
			height: 1vw;
		}

		&.nested-table-arrow-hide{
			transform: rotate(0deg);
		}
	}

    &-head {
		text-transform: capitalize;

      &-row {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 5px 5px 0px rgba(0, 0, 0, 0.04), 0px 11px 7px 0px rgba(0, 0, 0, 0.03), 0px 20px 8px 0px rgba(0, 0, 0, 0.01), 0px 30px 9px 0px rgba(0, 0, 0, 0.00);
		overflow: hidden;
		transition: all ease 0.3s;
		max-height: 10vw;

		
		svg, path{
			width: 1vw;
			height: 1vw;
			fill: #fff;
			stroke: #fff;
			
		}

		.add-column-button{
			cursor: pointer;
		}

		th {
			border-radius: 0.35vw;
			padding: 0.7vw 1.4vw;
			background: var(--accentPrimary);
			cursor: default;
			
			&:hover{
				.table-head-row-content .plus-icon{
					opacity: 1;
				}
			}

			.table-head-row-content{

				color: var(--textHover);
				text-align: left;
				font-size: styles.$textSm;
				font-weight: normal;
	
				display: flex;
				align-items: center;
				gap: 0.5vw;

				input, td{
					color: #fff;
					font-size: styles.$textSm;
					font-weight: normal;
				}
			  
				.plus-icon{
					cursor: pointer;
					transform: rotate(45deg);
					width: 0.7vw;
					height: 0.7vw;
					opacity: 0;
					transition: all ease 0.2s;
				}
				
				svg, path{
					width: 1vw;
					height: 1vw;
					fill: #fff;
					stroke: #fff;
				
			  	}
			}
        }
		th:first-child {
			border-top-left-radius: styles.$roundedLg;
		}
		th:last-child {
			border-top-right-radius: styles.$roundedLg;
		}
      }
    }
    
    &-body {
		
      &-row {

		*{
			transition: all ease 0.2s;
		}

        td {
			overflow-wrap: break-word;
			max-width: 40vw;
			text-align: left;
			padding: 0.7vw 1.4vw;
			color: var(--text1);
			font-size: styles.$textSm;
			
		}

		&.table-row-hidden{
			*{
				overflow: hidden;
				height: 0;
				padding: 0;
			}
			
		}

		a{
			color: var(--text1);
			text-decoration: underline;
		}

		&-delete{
			cursor: pointer;
			svg{
				width: 0.8vw;
				height: 0.8vw;
				transform: rotate(45deg);

				path{
					stroke: var(--accentPrimary);
				}
			}
		}

		td:first-child {
			border-left: 0.07vw solid var(--accentTertiary);
		}
		td:last-child {
			border-right: 0.07vw solid var(--accentTertiary);
		}
      }
	  &-row:last-child {
		td {
			border-bottom: 0.07vw solid var(--accentTertiary);
		}
		td:first-child {
			border-bottom-left-radius: styles.$roundedLg;
		}
		td:last-child {
			border-bottom-right-radius: styles.$roundedLg;
		}
	  }

	  &-row:nth-child(even) {
		background: var(--backgroundSubContainer);
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 5px 5px 0px rgba(0, 0, 0, 0.04), 0px 11px 7px 0px rgba(0, 0, 0, 0.03), 0px 20px 8px 0px rgba(0, 0, 0, 0.01), 0px 30px 9px 0px rgba(0, 0, 0, 0.00);
	  }
    }
  }
  

@media screen and (max-width: 768px) {

	.table {
		.nested-table-arrow{
			width: 1.5vw;
			height: 1.5vw;
	
			svg{
				width: 1.5vw;
				height: 1.5vw;
			}
		}
	
		&-head {
	
		  &-row {
			max-height: 15vw;
	
			svg, path{
				width: 1.5vw;
				height: 1.5vw;
			}
	
			th {
				border-radius: 0.525vw;
				padding: 1.05vw 2.1vw;
				
				.table-head-row-content{
					font-size: 1.455vw;
	
					gap: 0.75vw;
	
					input, td{
						font-size: 1.455vw;
					}
				  
					.plus-icon{
						width: 1.05vw;
						height: 1.05vw;
					}
					
					svg, path{
						width: 1.5vw;
						height: 1.5vw;
					  }
				}
			}
		  }
		}
		
		&-body {
		  &-row {
	
			td {
				max-width: 60vw;
				padding: 1.05vw 2.1vw;
				font-size: 1.455vw;
			}
	
			&-delete{
				svg{
					width: 1.2vw;
					height: 1.2vw;
				}
			}
		  }
		}
	}
	
}

@media screen and (max-width: 425px) {
	.table {
		.nested-table-arrow{
			width: 3vw;
			height: 3vw;
	
			svg{
				width: 3vw;
				height: 3vw;
			}
		}
	
		&-head {
	
		  &-row {
			max-height: 30vw;
	
			svg, path{
				width: 3vw;
				height: 3vw;
			}
	
			th {
				border-radius: 1.05vw;
				padding: 2.1vw 4.2vw;
				
				.table-head-row-content{
					font-size: 2.91vw;
	
					gap: 1.5vw;
	
					input, td{
						font-size: 2.91vw;
					}
				  
					.plus-icon{
						width: 2.1vw;
						height: 2.1vw;
					}
					
					svg, path{
						width: 3vw;
						height: 3vw;
					  }
				}
			}
		  }
		}
		
		&-body {
		  &-row {
	
			td {
				max-width: 120vw;
				padding: 2.1vw 4.2vw;
				font-size: 2.91vw;
			}
	
			&-delete{
				svg{
					width: 2.4vw;
					height: 2.4vw;
				}
			}
		  }
		}
	}
	
}