.pagination-container{
	width: 100%;
	height: 100%;
	padding: 1vw;
	overflow-y: scroll;
}

@media screen and (max-width: 768px) {
	.pagination-container{
		padding: 1.5vw;
	}
}

@media screen and (max-width: 425px) {
	.pagination-container{
		padding: 3vw;
	}
}