// Global Styling
// ---------------------
// Define general classes that doesn't belong to any specific component

@use 'variables' as styles;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;1,700&family=Poppins:wght@200;400;600;700&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	font-family: styles.$fontMain;
}
  
body {
	font-family: 'Poppins', sans-serif;
	height: 100%;
	background: var(--backgroundMain);
}

.statistics-container{
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1vw;
	padding: 1vw;
	
	.chart-group{
		display: flex;
		gap: 1vw;
	}
}
  
/* width */
::-webkit-scrollbar {
	background: transparent;
	width: .8vw;
	height: .8vw;
}
  
/* Track */
::-webkit-scrollbar-track {
	background: transparent;
  
}
  
/* Handle */
::-webkit-scrollbar-thumb {
	background: #949494;
	border-radius: styles.$rounded2xl;
	border: .15vw solid #fff;
  
}
  
p{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
