@use '/src/styles/variables' as styles;

.modal-container {
  background-color: #fafafa;
  border-radius: styles.$roundedXl;
  padding: 2vw 2.7vw;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 70vh;

  .modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.25vw;
    
    &-wrapper {
      display: flex;
      margin: 0 auto;
      column-gap: 0.5vw;
      align-items: center;

      .modal-title{
        font-size: styles.$text2xl;
        width: 100%;
        text-align: center;
        font-style: normal;
        font-weight: var(--fontMedium);
        line-height: normal;
        color: #444;
      }
    }

    span {
      cursor: pointer;
      svg{
        width: 1.5vw;
      }
    }
  }

  .modal-content-container{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    
    .no-items {
      width: 30vw;
      text-align: center;
    }
  }

  .modal-button{
    width: 9.72vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25vw;
  }
}

.hidden-modal{
  visibility: hidden;
  position: fixed;
  top: 0;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  animation: fade-out ease .3s forwards;

  .modal-container {
    animation: rise-up ease 1s forwards;
  }
}

.dark-bg {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  animation: fade-in ease .3s;

  .modal-container {
    animation: drop ease 1s forwards;
  }
}


@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes drop {
  0% {}

  70% {
    transform: translateY(16vh);
  }

  100% {
    transform: translateY(15vh);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes rise-up {
  0% {
    transform: translateY(15vh);

  }

  100% {
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 768px) {

  .modal-container {
    border-radius: 2.25vw;
    padding: 3vw 4.05vw;

    .modal-content-container {
      .no-items {
        width: 45vw;
      }
    }

    .modal-header {
      margin-bottom: 1.875vw;

      &-wrapper {
        column-gap: 0.75vw;
        
        .modal-title{
          font-size: 3vw;
        }
      }
      
      span {
        svg{
          width: 2.25vw;
        }
      }
    }
    .modal-button{
      width: 14.6vw;
      margin-top: 1.875vw;
    }
  }
}


@media screen and (max-width: 425px) {

  .modal-container {
    border-radius: 3.5vw;
    padding: 6vw 8vw;

    .modal-content-container {
      .no-items {
        width: 100%;
      }
    }

    .modal-header {
      margin-bottom: 3.75vw;

      &-wrapper {
        column-gap: 0.75vw;
        
        .modal-title{
          font-size: 6vw;
        }
      }

      span {
        svg{
          width: 4.5vw;
        }
      }
    }
    .modal-button{
      width: 29.2vw;
      margin-top: 3.75vw;
    }
  }

}