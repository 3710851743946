.create-customer-container {
	width: 56vw;
	padding: 2vw;
	.form-elements-wrapper {
		display: grid;
		grid-template-columns: 22vw 22vw;
		gap: 1.3vw 4.7vw;
		justify-content: center;
	}
	
	.radio-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6vw;
		margin-top: 2vw;
	}

	.modal-title {
		margin-top: 1vw
	}
}