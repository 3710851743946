.location-search-input{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    
    &-options{
        position: relative;
        top: 1vw;
        transition: all 400ms ease;
        width: 100%;
        height: 0;
        overflow-y: hidden;

        &-container{
            display: grid;
            position: absolute;
            top: auto;
            background-color: var(--accentTertiary);
            width: 100%;
            height: auto;
            box-shadow: 0.02vw 0.14vw 0.69vw 0px #0000001a;

            &-item{
                transition: all 400ms ease;
                display: flex;
                align-items: center;
                gap: 0.35vw;
                padding: 0.5vw;
                font-size: 0.97vw;
                cursor: pointer;
                width: 91%;

                p{
                    width: 100%;
                }
        
                &:hover {
                    background-color: var(--backgroundSubContainer)
                }
            }

    
        }
        
        &.location-search-input-options-visible{
            height: 10vw;
            overflow-y: scroll;
        }
    }
}

@media screen and (max-width: 768px) {

    .location-search-input{
        
        &-options{
            top: 1.5vw;    
            &-container{
                box-shadow: 0.03vw 0.21vw 1.05vw 0px #0000001a;
    
                &-item{
                    gap: 0.53vw;
                    padding: 0.75vw;
                    font-size: 1.45vw;
                }
    
        
            }
            
            &.location-search-input-options-visible{
                height: 15vw;
            }
        }
    }
}

@media screen and (max-width: 425px) {

    .location-search-input{
        
        &-options{
            top: 3vw;    
            &-container{
                box-shadow: 0.06vw 0.42vw 2.1vw 0px #0000001a;
    
                &-item{
                    gap: 1.05vw;
                    padding: 1.5vw;
                    font-size: 2.9vw;
                }
    
        
            }
            
            &.location-search-input-options-visible{
                height: 30vw;
            }
        }
    }
    

}