@use "themes/defaultTheme" as defaultTheme;

// Themes - Use regular CSS variable to access variable in this file

// For Standard CSS Variables, It's advisable to use this extension to get the autocomplete in VSCode
// https://marketplace.visualstudio.com/items?itemName=vunguyentuan.vscode-css-variables

// Light Themes
// ------------------------------------

// Dark Themes
// ------------------------------------
@use "themes/dark/purple" as darkBlueTheme;

@mixin applyTheme(
  $primary,
  $secondary,
  $tertiary,
  $disabled,
  $success,
  $successText,
  $warning,
  $alert,
  $alertText,
  $colorInfo,
  $colorLightBlue,
  $t1,
  $t2,
  $t3,
  $t4,
  $tHover,
  $bgMain,
  $bgPrim,
  $bgSec,
  $bgTer,
  $bgAlt
) {
  // Theme Variables
  // -----------------------------------
  // Access this using var(--varName)

  --accentPrimary: #{$primary};
  --accentSecondary: #{$secondary};
  --accentTertiary: #{$tertiary};
  --accentDisabled: #{$disabled};

  --colorSuccess: #{$success};
  --colorSuccessText: #{$successText};
  --colorWarning: #{$warning};
  --colorAlert: #{$alert};
  --colorAlertText: #{$alertText};
  --colorInfo: #{$colorInfo};
  --colorLightBlue: #{$colorLightBlue};

  --text1: #{$t1};
  --text2: #{$t2};
  --text3: #{$t3};
  --text4: #{$t4};
  --textHover: #{$tHover};

  --backgroundMain: #{$bgMain};

  --backgroundContainer: #{$bgPrim};
  --backgroundSubContainer: #{$bgSec};
  --backgroundNestedSubContainer: #{$bgTer};
  --backgroundAlt: #{$bgAlt};

  transition: all 550ms ease;
}
body {
  // Default Theme (Default)
  @include applyTheme(
    defaultTheme.$accentPrimary,
    defaultTheme.$accentSecondary,
    defaultTheme.$accentTertiary,
    defaultTheme.$accentDisabled,
    defaultTheme.$colorSuccess,
    defaultTheme.$colorSuccessText,
    defaultTheme.$colorWarning,
    defaultTheme.$colorAlert,
    defaultTheme.$colorAlertText,
    defaultTheme.$colorInfo,
    defaultTheme.$colorLightBlue,
    defaultTheme.$text1,
    defaultTheme.$text2,
    defaultTheme.$text3,
    defaultTheme.$text4,
    defaultTheme.$textHover,
    defaultTheme.$backgroundMain,
    defaultTheme.$backgroundContainer,
    defaultTheme.$backgroundSubContainer,
    defaultTheme.$backgroundNestedSubContainer,
    defaultTheme.$backgroundAlt
  );

  // List of all themes
  &.darkBlue {
    @include applyTheme(
      darkBlueTheme.$accentPrimary,
      darkBlueTheme.$accentSecondary,
      darkBlueTheme.$accentTertiary,
      darkBlueTheme.$accentDisabled,
      darkBlueTheme.$colorSuccess,
      darkBlueTheme.$colorSuccessText,
      darkBlueTheme.$colorWarning,
      darkBlueTheme.$colorAlert,
      darkBlueTheme.$colorAlertText,
      darkBlueTheme.$colorInfo,
	  darkBlueTheme.$colorLightBlue,
      darkBlueTheme.$text1,
      darkBlueTheme.$text2,
      darkBlueTheme.$text3,
      darkBlueTheme.$text4,
      darkBlueTheme.$textHover,
      darkBlueTheme.$backgroundMain,
      darkBlueTheme.$backgroundContainer,
      darkBlueTheme.$backgroundSubContainer,
      darkBlueTheme.$backgroundNestedSubContainer,
      darkBlueTheme.$backgroundAlt
    );
  }
  &.theme-tertiary {
  }
}
