.file-item-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    gap: 0.4vw;
    padding: 0.4vw;
    border-radius: 0.7vw;
    background: var(--accentPrimary);

    .file-item-close-button{
        width: 0.7vw;
        height: 0.7vw;
        
        svg, path{
            cursor: pointer;
            height: 100%;
            fill: var(--textHover);
        }
    }

}

@media screen and (max-width: 768px) {

    .file-item-wrapper{
        gap: 0.6vw;
        padding: 0.6vw;
        border-radius: 1.05vw;
    
        .file-item-close-button{
            width: 1.05vw;
            height: 1.05vw;
        }
    
    }
    

}

@media screen and (max-width: 425px) {

    .file-item-wrapper{
        gap: 1.2vw;
        padding: 1.2vw;
        border-radius: 2.1vw;
    
        .file-item-close-button{
            width: 2.1vw;
            height: 2.1vw;
        }
    
    }
    

}