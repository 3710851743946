.privacy-policy {
    width: 100%;
    height: 100vh;
    padding: 2vw;

    .privacy-policy-container{
        border-radius: 1.4vw;
        background-color: var(--accentTertiary);
        padding: 2.08vw 2.5vw;
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        .privacy-policy-header {
            display: flex;
            justify-content: space-between;

            .privacy-policy-title-and-subtitle {
                display: flex;
                flex-direction: column;

                .privacy-policy-title {
                    font-size: 1.66vw;
                    text-align: center;
                    font-weight: 600; 
                    color: var(--text1);
                }

                .privacy-policy-subtitle {
                    font-size: 1.11vw;
                }
            }

            .language-selector {
                background-color: var(--accentTertiary);
                border-radius: 1vw;
            }
        }

        .privacy-policy-section {

            .privacy-policy-paragraph {
                white-space: normal;
                margin-top: 0.7vw;
                font-size: 1.11vw;
            }

            .privacy-policy-list-container {
                margin-top: 0.7vw;

                .privacy-policy-list {

                    & > ol > li {
                        font-weight: 600;
                        font-size: 1vw;
                    }

                    & > p {
                        margin-bottom: 1vw;
                        font-size: 1vw;
                    }

                    & > ul {
                        margin-left: 2vw;
                        font-size: 1vw;
                    }

                    .privacy-policy-link {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .privacy-policy {
        width: 100%;
        height: 100vh;
        padding: 3vw;
    
        .privacy-policy-container {
            border-radius: 2.1vw;
            padding: 3.12vw 3.75vw;
    
            .privacy-policy-header {
                .privacy-policy-title-and-subtitle {
                    .privacy-policy-title {
                        font-size: 2.49vw;
                    }
    
                    .privacy-policy-subtitle {
                        font-size: 1.665vw;
                    }
                }
    
                .language-selector {
                    border-radius: 1.5vw;
                }
            }
    
            .privacy-policy-section {
                .privacy-policy-paragraph {
                    margin-top: 1.05vw;
                    font-size: 1.665vw;
                }
    
                .privacy-policy-list-container {
                    margin-top: 1.05vw;
    
                    .privacy-policy-list {
                        & > ol > li {
                            font-size: 1.5vw;
                        }
    
                        & > p {
                            margin-bottom: 1.5vw;
                            font-size: 1.5vw;
                        }
    
                        & > ul {
                            margin-left: 3vw;
                            font-size: 1.5vw;
                        }
    
                        .privacy-policy-link {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    

}

@media screen and (max-width: 425px) {

    .privacy-policy {
        width: 100%;
        height: 100vh;
        padding: 6vw;
    
        .privacy-policy-container {
            border-radius: 4.2vw;
            padding: 6.24vw 7.5vw;
    
            .privacy-policy-header {
                .privacy-policy-title-and-subtitle {
                    .privacy-policy-title {
                        font-size: 4.98vw;
                    }
    
                    .privacy-policy-subtitle {
                        font-size: 3.33vw;
                    }
                }
    
                .language-selector {
                    border-radius: 3vw;
                }
            }
    
            .privacy-policy-section {
                .privacy-policy-paragraph {
                    margin-top: 2.1vw;
                    font-size: 3.33vw;
                }
    
                .privacy-policy-list-container {
                    margin-top: 2.1vw;
    
                    .privacy-policy-list {
                        & > ol > li {
                            font-size: 3vw;
                        }
    
                        & > p {
                            margin-bottom: 3vw;
                            font-size: 3vw;
                        }
    
                        & > ul {
                            margin-left: 6vw;
                            font-size: 3vw;
                        }
    
                        .privacy-policy-link {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    

}