.audio-recorder-container{
    width: 100%;
    padding: 0 0.7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .audio-recorder-data-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1vw;
        height: 100%;
        width: 100%;
        
        .audio-recorder-timer {
            --audioTimerColor:#1E1B39;
        }
        
        .audio-recorder-data-icon{
            width: 1.12vw;
            height: 1.12vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.35vw;
            svg, path{
                width: 1.12vw;
                height: 1.12vw;
            }
        }
    }

    .audio-recorder-stop-container{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 2.91vw;
            height: 2.91vw;
        }
    }
}

@media screen and (max-width: 768px) {
    .audio-recorder-container{
        padding: 0 1.05vw;
        .audio-recorder-data-container{
            gap: 1.5vw;
            
            .audio-recorder-data-icon{
                width: 1.68vw;
                height: 1.68vw;
                margin-bottom: 0.525vw;

                svg, path{
                    width: 1.68vw;
                    height: 1.68vw;
                }
            }
        }
    
        .audio-recorder-stop-container{
            svg{
                width: 4.36vw;
                height: 4.36vw;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .audio-recorder-container{
        padding: 0 2.1vw;
        .audio-recorder-data-container{
            gap: 3vw;
            
            .audio-recorder-data-icon{
                width: 3.36vw;
                height: 3.36vw;
                margin-bottom: 1.05vw;

                svg, path{
                    width: 3.36vw;
                    height: 3.36vw;
                }
            }
        }
    
        .audio-recorder-stop-container{
            svg{
                width: 6.54vw;
                height: 6.54vw;
            }
        }
    }
}