.cookies-popup {
  position: fixed;
  bottom: 3vw;
  left: 3vw;
  max-width: 45vw;
  background-color: rgba(30, 27, 57, 0.75);
  color: #fff;
  border-radius: 0.5vw;
  box-shadow: 0 0 2vw rgba(0, 0, 0, 0.2);
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2vw;
  transition: all ease 0.4s;
  padding: 2vw;
  overflow: hidden;


  .cookies-popup-text {
    font-size: 1.1vw;
  }

  .buttons-container{
    display: flex;
    align-items: center;
    gap: 1vw;
  }
}

@media screen and (max-width: 768px) {
  .cookies-popup {
    bottom: 4.5vw;
    left: 4.5vw;
    max-width: 80vw;
    border-radius: 0.7vw;
    padding: 3vw;
    box-shadow: 0px 0px 3vw rgba(0, 0, 0, 0.2);
    gap: 3vw;
  
    .cookies-popup-text {
      font-size: 2.1vw;
    }

    .buttons-container{
      .button{
        font-size: 1.7vw;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .cookies-popup {
    bottom: 6vw;
    left: 6vw;
    max-width: 80vw;
    border-radius: 1.1vw;
    padding: 4vw;
    box-shadow: 0px 0px 4vw rgba(0, 0, 0, 0.2);
    gap: 4vw;
  
    .cookies-popup-text {
      font-size: 3.2vw;
    }
    .buttons-container{
      .button{
        font-size: 2.1vw;
      }
    }
  }
}
