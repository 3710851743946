.organization-chart-container{
	height: 100%;
	width: 100%;
	margin: 0 auto;
	
	.react-flow{
		.react-flow__node{
			font-size: 2vw;
		}
		.react-flow__handle{
			opacity: 0;
		}
		.react-flow__controls-button{
			width: 1.4vw;
			height: 1.4vw;
			background-color: var(--accentPrimary);
			border-radius: .5vw;
			transition: all 150ms ease;
			&:hover{
				transform: scale(1.05);
			}
			&:active{
				transform: scale(.98);
			}
			svg{
				fill: var(--textHover);
				height: 1vw;
				width: 1vw;
				max-width: initial;
				max-height: initial;
			}
		}
	}
}
.react-flow__panel.react-flow__attribution {
	display: none;
}

@media screen and (max-width: 768px) {

	.organization-chart-container{
		.react-flow{
			.react-flow__node{
				font-size: 3vw;
			}
			.react-flow__controls-button{
				width: 2.1vw;
				height: 2.1vw;
				border-radius: .75vw;
				svg{
					height: 1.5vw;
					width: 1.5vw;
				}
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.organization-chart-container{
		.react-flow{
			.react-flow__node{
				font-size: 6vw;
			}
			.react-flow__controls-button{
				width: 4.2vw;
				height: 4.2vw;
				border-radius: 1.5vw;
				svg{
					height: 3vw;
					width: 3vw;
				}
			}
		}
	}
	
}