@use "/src/styles/variables" as styles;

.dashboard-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 1vw;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
  z-index: -100;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 80vw;
  padding: 2vw;
  pointer-events: none;

  .dashboard-action {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    font-size: styles.$textSm;
    border-radius: 0.25vw;
    font-weight: 500;
    padding: 0.4vw 0;
    border: 0.1464vw solid var(--accent);
    background-color: transparent;
    color: var(--text1);
    transition: all 150ms ease;
    &:hover,
    &.drag-over {
      background-color: var(--accent);
      color: var(--textHover);
    }
  }
  &.show {
    z-index: 100;
    opacity: 1;
    user-select: all;
    pointer-events: initial;

    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .dashboard-actions-container {
    gap: 1.5vw;
    width: 100vw;
    .dashboard-action {
      font-size: 1.45vw;
      border-radius: 0.375vw;
      padding: 0.6vw 0;
      border: 0.23vw solid var(--accent);
    }
  }
}

@media screen and (max-width: 425px) {
  .dashboard-actions-container {
    gap: 3vw;
    width: 100vw;

    .dashboard-action {
      font-size: 2.9vw;
      border-radius: 0.75vw;
      padding: 1.2vw 0;
      border: 0.46vw solid var(--accent);
    }
  }
}
