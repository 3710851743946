.create-budget-container {
  display: grid;
  padding: 1vw 3vw 3vw 1vw;
  grid-template-columns: 22vw 22vw;
  justify-content: center;
  gap: 3vw;

  .create-budget-values {
    display: flex;
    flex-direction: column;
    gap: 3vw;

    .create-budget-value {
      display: flex;
      align-items: end;
      gap: 3vw;
    }
  }

}

@media (max-width: 768px) {
  .create-budget-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 425px) {
 .create-budget-value {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
  }
}
