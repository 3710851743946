@use '/src/styles/variables' as styles;

.create-label-form {
	width: 30vw;
	padding: 2vw;
	display: flex;
	flex-direction: column;
	gap: 1vw;

	.text-area {
		.text-area-container {
			background: var(--accentTertiary);
			textarea {
				background: transparent
			}
		}
	}

    .color-label {
        font-size: styles.$textLg;
    }
}

@media screen and (max-width: 768px) {

	.create-label-form {
		width: 45vw;
		padding: 3vw;
		gap: 1.5vw;

		.color-label {
			font-size: 1.875vw;
		}
	}
	
}

@media screen and (max-width: 425px) {

	.create-label-form {
		width: 90vw;
		padding: 6vw;
		gap: 3vw;

		.color-label {
			font-size: 3.75vw;
		}
  }
  
}