@use '/src/styles/variables' as styles;

.chat-card-container {
  display: flex;
  align-items: flex-start;
  gap: 2vw;

  .chat-card-avatar-container {
    width: 4.16vw;
    height: 4.16vw;
    display: flex;
    justify-content: center;
    align-items: center;
    svg, img{
      width: 4.16vw;
      height: 4.16vw;
    }
  }
  
  .chat-card-information-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 11.8vw;
    min-height: 4vw;
  
    
    .chat-card-name{
      width: 100%;
      font-size: styles.$textMd;
      line-height: 1.64vw;
      color: var(--text1);
      font-weight: styles.$fontBold;
    }

    .chat-card-message-container{
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: baseline;
      gap: 0.69vw;

      .message{
        font-size: styles.$textSm;
        line-height: 1.45vw;
        width: 100%;

      }
      .anymore-files-container{
        display: flex;
        justify-content: center;
        width: 12%;
        svg{
          width: 100%;
        }
        :last-child{
          border-radius: 0.1vw;
          margin-left: -0.4vw;
          background-color: var(--backgroundMain);
        }
      }

      svg{
        min-width: 0.83vw;
        height: 0.83vw;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .chat-card-container {
    gap: 3vw;
  
    .chat-card-avatar-container {
      width: 6.24vw;
      height: 6.24vw;
      
      svg, img{
        width: 6.24vw;
        height: 6.24vw;
      }
    }
    
    .chat-card-information-container {
      width: 17.7vw;
      min-height: 6vw;
    
      
      .chat-card-name{
        width: 100%;
        font-size: 1.66vw;
        line-height: 2.46vw;
      }
  
      .chat-card-message-container{
        gap: 1vw;
  
        .message{
          font-size: 1.45vw;
          line-height: 2.1vw;
  
        }
        .anymore-files-container{
          :last-child{
            border-radius: 0.15vw;
            margin-left: -0.6vw;
          }
        }
  
        svg{
          min-width: 1.25vw;
          height: 1.25vw;
        }
      }
    }
  }

}


@media screen and (max-width: 425px) {

  .chat-card-container {
    gap: 6vw;
  
    .chat-card-avatar-container {
      width: 12.5vw;
      height: 12.5vw;
      
      svg, img{
        width: 12.5vw;
        height: 12.5vw;
      }
    }
    
    .chat-card-information-container {
      width: 35.4vw;
      min-height: 12vw;
    
      
      .chat-card-name{
        font-size: 3.32vw;
        line-height: 5vw;
      }
  
      .chat-card-message-container{
        gap: 2vw;
  
        .message{
          font-size: 2.9vw;
          line-height: 4.2vw;
  
        }
        .anymore-files-container{
          :last-child{
            border-radius: 0.3vw;
            margin-left: -1.2vw;
          }
        }
  
        svg{
          min-width: 2.5vw;
          height: 2.5vw;
        }
      }
    }
  }

}