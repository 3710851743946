.chat-message-files-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.69vw;
  
  .video{
    width: 100%;
    height: auto;
  }

  .audio{
      width: 100%;
      height: 5vw;
  }

  .img{
    width: 100%;
    height: auto;

    img{
        width: 100%;
    }
  }

  .document{
    height: fit-content;
  }
    
}


@media screen and (max-width: 768px) {

  .chat-message-files-container {
    gap: 1.05vw;
    
  
    .audio{
        height: 7.5vw;
    }
      
  }

}

@media screen and (max-width: 425px) {

  .chat-message-files-container {
    gap: 2.1vw;
    
  
    .audio{
        height: 15vw;
    }
      
  }

}
