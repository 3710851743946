.title{
    font-size: 3.2vw;
    font-weight: 900;
    text-align: center;
    color: #1E1B39;
}

@media (max-width: 768px) {
    .title{
        font-size: 4.8vw;
    }
}

@media (max-width: 425px) {
    .title{
        font-size: 5vw;
    }
}