@use '/src/styles/variables' as styles;
.create-pipeline {
	width: 35vw;
	padding: 0.7vw;
	display: flex;
	flex-direction: column;
	gap: 1.4vw;

	.text-area-container {
		background: var(--accentTertiary);

		textarea {
			background: transparent
		}
	}
	.pipeline-stages-label {
		font-size: 1.25vw;
	}

	.pipeline-stages {
		padding: 1vw 0
	}
	.pipeline-stage {
		position: relative;
		display: flex;
		align-items: center;
		gap: 1vw;

	
		&-remove {
			position: absolute;
			right: 0;
			display: flex;
			align-items: center;
			width: 1.9vw;
			height: 2.9vw;
			cursor: pointer;
			svg {
				width: 0.7vw;
				fill: var(--textHover)
			}
		}
	}
	.add-stage-button {
		font-size: 0.87vw;
		display: inline-block;
		color: var(--accentPrimary);
		cursor: pointer;
		background: var(--accentTertiary);
		padding: 0.5vw 1vw;
		border-radius: 0.7vw;
		box-shadow: 3px 4px 0.7vw rgba(0, 0, 0, 0.1);
	}
	.error-wrapper {
		color: var(--colorAlertText);
		font-size: styles.$text2sm;
		font-weight: 400;
  
		svg{
		  width: 1.38vw;
		  height: 1.38vw;
		}
  
		.error-text {
		  display: flex;
		  gap: 0.35vw;
		  align-items: center;
		}
  
	  } 
}