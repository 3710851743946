.create-time-tracker {
    width: 35vw;
    padding: 0.7vw;
    gap: 1.4vw;
	display: flex;
	flex-direction: column;
}

@media (max-width: 768px) {
    .create-time-tracker {
        width: 52.5vw; /* 35vw * 1.5 */
        padding: 1.05vw; /* 0.7vw * 1.5 */
        gap: 2.1vw; /* 1.4vw * 1.5 */
    }
}

@media (max-width: 425px) {
    .create-time-tracker {
        width: 90vw; /* 35vw * 3 */
        padding: 2.1vw; /* 0.7vw * 3 */
        gap: 4.2vw; /* 1.4vw * 3 */
    }
}