@use '/src/styles/variables' as styles;

.calendar-wrapper {
    .calendar-label {
        font-size: styles.$textLg;
    }
    
    .calendar-container{
        padding: 1.4vw 1vw;
        max-height: fit-content;
        border-radius: styles.$roundedMd;
        box-shadow: 3px 2px 0.7vw 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        gap: 2vw;
        background: var(--backgroundContainer);
        transition: all ease 0.4s;
        overflow: hidden;
        
        .calendar-input-fields-container{
            width: 100%;
        }

        &.hidden{
            padding: 0 !important;
            gap: 0 !important;

            .radio{
                display: none;
            }

            .calendar{
                max-height: 0 !important;
                padding: 0 !important;
            }
        }

        &.disabled{
            background: transparent;
            box-shadow: none;

            .calendar-input-fields-container{
                gap: 0 !important;

                .calendar-input-field-wrapper{

                    .calendar-input-field{
                        gap: 0 !important;

                        .input-field-wrapper{
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }

        .calendar-input-container{
            display: flex;
            align-items: center;
            gap: 0.66vw;
            .calendar-input-time-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                gap: 0.66vw;
                width: 19vw;
            }
        }

        .calendar-input-fields-container{
            display: flex;
            flex-direction: column;
            gap: 1vw;
            transition: all ease 0.4s;
            .calendar-input-field-wrapper{
                position: relative;

                .calendar-input-field-box{
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .calendar-input-field{
                    display: flex;
                    align-items: center;
                    gap: 1vw;
                    transition: all ease 0.4s;

                    .input-field-wrapper{
                        transition: all ease 0.4s;
                        .input-field-container {
                            input {
                                width: 5vw;
                            }
                        }
                    }
                }
            }
        }

        &.medium{
            padding: 0.93vw 0.66vw;
            gap: 1.33vw;

            .calendar-input-fields-container{
                gap: 0.66vw;

                .calendar-input-field-wrapper{

                    .calendar-input-field{
                        gap: 0.66vw;

                        .input-field-wrapper{
                            width: 100%;
                            gap: 1.6vw;
                            padding: 0.44vw 1vw;
                            border-radius: 0.66vw;
                            box-shadow: 1px 2px 0.47vw rgba(0, 0, 0, 0.1);

                            input{
                                font-size: 0.74vw;
                            }
                        }
                    }

                    .radio {
                        .radio-box {
                            width: 0.74vw;
                            height: 0.74vw;
                            border-radius: 0.2vw;
                            cursor: pointer;

                            &.rounded{
                                border-radius: 0.66vw;
                                .radio-inner{
                                    border-radius: 0.66vw;
                                }
                            }
                        }

                        .radio-inner {
                            width: 100%;
                            height: 100%;
                            border-radius: 0.66vw;
                        }
                    }
                }
            }

            .calendar {
                padding: 1vw;
                border-radius: 0.66vw;

                .year-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1vw;

                    .month-left-arrow,
                    .month-right-arrow {
                        cursor: pointer;
                        svg,
                        img {
                            width: 0.66vw;
                            height: 0.66vw;
                        }
                    }

                    .years {
                        font-size: 1vw;
                    }
                }

                .day-name-and-month-container {
                    .day-container {
                        gap: 1vw;

                        .day{
                            font-size: 0.83vw;
                            border-radius: 0.2vw;
                            width: 1.33vw;
                            height: 1.33vw;

                        }
                    }
                }

                .day-names {
                    margin: 0.53vw auto;

                    .day-name {
                        font-size: 1vw;
                        margin-bottom: 0.22vw;
                    }
                }
            }

        }

        &.small{
            padding: 0.7vw 0.5vw;
            gap: 1vw;

            .calendar-input-fields-container{
                gap: 0.5vw;

                .calendar-input-field-wrapper{

                    .calendar-input-field{
                        gap: 0.5vw;

                        .input-field-wrapper{
                            width: 100%;
                            gap: 0.87vw;
                            padding: 0.33vw 0.75vw;
                            border-radius: 0.25vw;
                            box-shadow: 1px 2px 0.35vw rgba(0, 0, 0, 0.1);

                            input{
                                font-size: 0.55vw;
                            }
                        }
                    }

                    .radio {
                        .radio-box {
                            width: 0.55vw;
                            height: 0.55vw;
                            border-radius: 0.2vw;
                            cursor: pointer;

                            &.rounded{
                                border-radius: 0.5vw;
                                .radio-inner{
                                    border-radius: 0.5vw;
                                }
                            }
                        }

                        .radio-inner {
                            width: 100%;
                            height: 100%;
                            border-radius: 0.2vw;
                        }
                    }
                }

            }

            .calendar {

                padding: 0.7vw;
                border-radius: 0.25vw;

                .year-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0.75vw;

                    .month-left-arrow,
                    .month-right-arrow {
                        cursor: pointer;
                        svg,
                        img {
                            width: 0.5vw;
                            height: 0.5vw;

                        }
                    }

                    .years {
                        font-size: 0.7vw;
                    }
                }

                .day-name-and-month-container {
                    .day-container {
                        gap: 0.78vw;

                        .day{
                            font-size: 0.62vw;
                            border-radius: 0.14vw;
                            width: 1vw;
                            height: 1vw;

                        }
                    }
                }

                .day-names {
                    margin: 0.39vw auto;

                    .day-name {
                        font-size: 0.73vw;
                        margin-bottom: 0.17vw;
                    }
                }
            }

        }
    }
    .error-wrapper {
        color: var(--colorAlertText);
        font-size: styles.$text2sm;
        font-weight: 400;
        margin-top: 0.5vw;

        svg{
            width: 1.38vw;
            height: 1.38vw;
        }

        .error-text {
            display: flex;
            gap: 0.35vw;
            align-items: center;
        }

        .error-text p {
            white-space: normal;
        }
    }

    @media screen and (max-width: 768px) {

        .calendar-label {
            font-size: 1.875vw;
        }
        
        .calendar-container{
            padding: 2.1vw 1.5vw;
            border-radius: 0.75vw;
            box-shadow: 3px 2px 1.05vw 0px rgba(0, 0, 0, 0.10);
            gap: 3vw;

            .calendar-input-fields-container{
                gap: 1.5vw;

                .calendar-input-field{
                    gap: 1.5vw;
                }
            }

            &.medium{
                padding: 1.35vw 0.99vw;
                gap: 2vw;

                .calendar-input-fields-container{
                    gap: 0.792vw;

                    .calendar-input-field-wrapper{

                        .calendar-input-field{
                            gap: 1vw;

                            .input-field-wrapper{
                                gap: 2.4vw;
                                padding: 0.66vw 1.5vw;
                                border-radius: 1vw;
                                box-shadow: 1px 2px 0.7vw rgba(0, 0, 0, 0.1);

                                input{
                                    font-size: 1.125vw;
                                }
                            }
                        }

                        .radio {
                            .radio-box {
                                width: 1.125vw;
                                height: 1.125vw;
                                border-radius: 0.3vw;

                                &.rounded{
                                    border-radius: 1vw;
                                    .radio-inner{
                                        border-radius: 1vw;
                                    }
                                }
                            }

                            .radio-inner {
                                border-radius: 1vw;
                            }
                        }
                    }

                }

                .calendar {

                    padding: 1.5vw;
                    border-radius: 1vw;

                    .year-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1.5vw;

                        .month-left-arrow,
                        .month-right-arrow {
                            cursor: pointer;
                            svg,
                            img {
                                width: 1vw;
                                height: 1vw;
                            }
                        }

                        .years {
                            font-size: 1.5vw;
                        }
                    }

                    .day-name-and-month-container {
                        .day-container {
                            gap: 1.5vw;

                            .day{
                                font-size: 1.25vw;
                                border-radius: 0.3vw;
                                width: 2vw;
                                height: 2vw;

                            }
                        }
                    }

                    .day-names {
                        margin: 0.75vw auto;

                        .day-name {
                            font-size: 1.5vw;
                            margin-bottom: 0.33vw;
                        }
                    }
                }

            }

            &.small{
                padding: 1.05vw 0.75vw;
                gap: 1.5vw;

                .calendar-input-fields-container{
                    gap: 0.75vw;


                    .calendar-input-field-wrapper{

                        .calendar-input-field{
                            gap: 0.75vw;

                            .input-field-wrapper{
                                gap: 1.3vw;
                                padding: 0.5vw 1.125vw;
                                border-radius: 0.37vw;
                                box-shadow: 1px 2px 0.525vw rgba(0, 0, 0, 0.1);

                                input{
                                    font-size: 0.825vw;
                                }
                            }
                        }

                        .radio {
                            .radio-box {
                                width: 0.825vw;
                                height: 0.825vw;
                                border-radius: 0.3vw;

                                &.rounded{
                                    border-radius: 0.75vw;
                                    .radio-inner{
                                        border-radius: 0.75vw;
                                    }
                                }
                            }

                            .radio-inner {
                                width: 100%;
                                height: 100%;
                                border-radius: 0.3vw;
                            }
                        }
                    }

                }

                .calendar {

                    padding: 1.05vw;
                    border-radius: 0.375vw;

                    .year-container {
                        margin-bottom: 1.125vw;

                        .month-left-arrow,
                        .month-right-arrow {
                            svg,
                            img {
                                width: 0.75vw;
                                height: 0.75vw;

                            }
                        }

                        .years {
                            font-size: 1.05vw;
                        }
                    }

                    .day-name-and-month-container {
                        .day-container {
                            gap: 1.17vw;

                            .day{
                                font-size: 0.93vw;
                                border-radius: 0.21vw;
                                width: 1.5vw;
                                height: 1.5vw;

                            }
                        }
                    }

                    .day-names {
                        margin: 0.6vw auto;

                        .day-name {
                            font-size: 1.125vw;
                            margin-bottom: 0.25vw;
                        }
                    }
                }

            }
        }

        .error-wrapper {
            font-size: 1.2vw;
            margin-top: 0.75vw;
    
            svg{
                width: 2.07vw;
                height: 2.07vw;
            }
    
            .error-text {
                gap: 0.525vw;
            }
    
            .error-info-list{
                margin-left: 4.2vw;
            }
        }
    }


    @media screen and (max-width: 425px) {
        .calendar-label {
            font-size: 3.75vw;
        }

        .calendar-container{
            padding: 4.2vw 3vw;
            border-radius: 1.5vw;
            box-shadow: 3px 2px 2.1vw 0px rgba(0, 0, 0, 0.10);
            gap: 6vw;

            .calendar-input-fields-container{
                gap: 3vw;
                .calendar-input-field-wrapper{

                    .calendar-input-field{
                        gap: 3vw;
                    }
                }
            }

            &.medium{
                padding: 2.7vw 2vw;
                gap: 4vw;

                .calendar-input-fields-container{
                    gap: 1.6vw;

                    .calendar-input-field-wrapper{


                        .calendar-input-field{
                            gap: 2vw;

                            .input-field-wrapper{
                                gap: 4.8vw;
                                padding: 1.32vw 3vw;
                                border-radius: 2vw;
                                box-shadow: 1px 2px 1.4vw rgba(0, 0, 0, 0.1);

                                input{
                                    font-size: 2.25vw;
                                }
                            }
                        }

                        .radio {
                            .radio-box {
                                width: 2.25vw;
                                height: 2.25vw;
                                border-radius: 0.6vw;

                                &.rounded{
                                    border-radius: 2vw;
                                    .radio-inner{
                                        border-radius: 2vw;
                                    }
                                }
                            }

                            .radio-inner {
                                border-radius: 2vw;
                            }
                        }
                    }

                }

                .calendar {

                    padding: 3vw;
                    border-radius: 2vw;

                    .year-container {
                        margin-bottom: 3vw;

                        .month-left-arrow,
                        .month-right-arrow {
                            svg,
                            img {
                                width: 2vw;
                                height: 2vw;
                            }
                        }

                        .years {
                            font-size: 3vw;
                        }
                    }

                    .day-name-and-month-container {
                        .day-container {
                            gap: 3vw;

                            .day{
                                font-size: 1.25vw;
                                border-radius: 0.6vw;
                                width: 4vw;
                                height: 4vw;

                            }
                        }
                    }

                    .day-names {
                        margin: 1.5vw auto;

                        .day-name {
                            font-size: 3vw;
                            margin-bottom: 0.66vw;
                        }
                    }
                }

            }

            &.small{
                padding: 2.1vw 1.5vw;
                gap: 3vw;

                .calendar-input-fields-container{
                    gap: 1.5vw;

                    .calendar-input-field-wrapper{

                        .calendar-input-field{
                            gap: 1.5vw;

                            .input-field-wrapper{
                                gap: 2.6vw;
                                padding: 1vw 2.25vw;
                                border-radius: 0.74vw;
                                box-shadow: 1px 2px 1.5vw rgba(0, 0, 0, 0.1);

                                input{
                                    font-size: 1.65vw;
                                }
                            }
                        }

                        .radio {
                            .radio-box {
                                width: 1.65vw;
                                height: 1.65vw;
                                border-radius: 0.6vw;

                                &.rounded{
                                    border-radius: 1.5vw;
                                    .radio-inner{
                                        border-radius: 1.5vw;
                                    }
                                }
                            }

                            .radio-inner {
                                width: 100%;
                                height: 100%;
                                border-radius: 0.6vw;
                            }

                        }
                    }

                }

                .calendar {

                    padding: 2.1vw;
                    border-radius: 0.75vw;

                    .year-container {
                        margin-bottom: 2.25vw;

                        .month-left-arrow,
                        .month-right-arrow {
                            svg,
                            img {
                                width: 1.5vw;
                                height: 1.5vw;

                            }
                        }

                        .years {
                            font-size: 2.1vw;
                        }
                    }

                    .day-name-and-month-container {
                        .day-container {
                            gap: 2.34vw;

                            .day{
                                font-size: 1.86vw;
                                border-radius: 0.42vw;
                                width: 3vw;
                                height: 3vw;

                            }
                        }
                    }

                    .day-names {
                        margin: 1.2vw auto;

                        .day-name {
                            font-size: 2.25vw;
                            margin-bottom: 0.5vw;
                        }
                    }
                }

            }
        }

        .error-wrapper {
            font-size: 2.4vw;
            margin-top: 1.5vw;
    
            svg{
            width: 4.14vw;
            height: 4.14vw;
            }
    
            .error-text {
                gap: 1.5vw;
            }
    
            .error-info-list{
                margin-left: 8.4vw;
            }
        }
    }
}

