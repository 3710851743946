.column-layout {
	display: grid;
	gap: 1.3vw;
	align-content: start;
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 768px) {
	.column-layout {
		gap: 1.95vw;
	}
}


@media screen and (max-width: 425px) {
	.column-layout {
		gap: 3.9vw;
	}
}