.loading-container{
  position: fixed;
  top: 1.5vw;
  left: calc(50% - 1.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  min-width: 3vw;
  max-width: 3vw;
  min-height: 3vw;
  max-height: 3vw;
  width: 3vw;
  height: 3vw;
  .loading-icon{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 50%;

    svg, path, rect{
        width: 100%;
        height: 100%;
    }

    svg{
      animation: loader 1s linear infinite;
      position: absolute;
    }
  }
    
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}


@media screen and (max-width: 768px) {
  .loading-container{
    top: 2.25vw;
    min-width: 1.2vw;
    min-height: 1.2vw;
    
    .loading-icon{
      min-width: 4.5vw;
      max-width: 4.5vw;
      min-height: 4.5vw;
      max-height: 4.5vw;
      width: 4.5vw;
      height: 4.5vw;
    }
  }

}

@media screen and (max-width: 425px) {
  .loading-container{
    top: 4.5vw;
    min-width: 2.4vw;
    min-height: 2.4vw;

    .loading-icon{
      min-width: 9vw;
      max-width: 9vw;
      min-height: 9vw;
      max-height: 9vw;
      width: 9vw;
      height: 9vw;
    }
  }

}