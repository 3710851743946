@use '/src/styles/variables' as styles;

.my-tasks-calendar{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5vw;

    .my-tasks-calendar-preveiw-container{
        display: flex;
        justify-content: space-between;
        gap: 1.73vw;
        padding: 1vw;

        .my-tasks-calendar-preveiw-calendar-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1vw;
            width: 25.13vw;
            border-radius: styles.$roundedXl;
            box-shadow: styles.$shadowMd;
        }

        .my-tasks-calendar-preveiw-tasks-container{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.7vw;
            overflow-y: auto;
            padding: 1.38vw;
            height: 22.5vw;
            border-radius: styles.$roundedXl;
            box-shadow: styles.$shadowMd;
        }
    }
}

@media screen and (max-width: 768px) {
    .my-tasks-calendar{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2.25vw;
    
        .my-tasks-calendar-preveiw-container{
            display: flex;
            justify-content: space-between;
            gap: 2.59vw;
            padding: 1.5vw;
            flex-direction: column;
            align-items: center;
    
            .my-tasks-calendar-preveiw-calendar-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1.5vw;
                width: 37.7vw;
                border-radius: styles.$roundedXl;
                box-shadow: styles.$shadowMd;
            }
    
            .my-tasks-calendar-preveiw-tasks-container{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1.05vw;
                overflow-y: auto;
                padding: 2.07vw;
                height: 33.75vw;
                border-radius: styles.$roundedXl;
                box-shadow: styles.$shadowMd;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .my-tasks-calendar{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4.5vw;
    
        .my-tasks-calendar-preveiw-container{
            display: flex;
            justify-content: space-between;
            gap: 5.18vw;
            padding: 3vw;
            flex-direction: column;
            align-items: center;
    
            .my-tasks-calendar-preveiw-calendar-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 3vw;
                width: 75vw;
                border-radius: styles.$roundedXl;
                box-shadow: styles.$shadowMd;
            }
    
            .my-tasks-calendar-preveiw-tasks-container{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 2.10vw;
                overflow-y: auto;
                padding: 4.14vw;
                height: 67.5vw;
                border-radius: styles.$roundedXl;
                box-shadow: styles.$shadowMd;
            }
        }
    }
}