.manual-item-wrapper {
  width: 100%;

  .manual-item-heading {
    color: #1E1B39;
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  .manual-item-content {
    width: 100%;
    display: flex;
    margin-bottom: 5vw;

    .manual-item-text {
      
      .manual-link-text {
        text-decoration: underline;
      }

      p {
        font-size: 1.11vw;
        margin-top: 0.5vw;
        line-height: 150%;
        white-space: normal;
        overflow: visible;
      }
    }

    .manual-item-images-wrapper {
      flex: 1;
      min-width: 60%;
      display: grid;
      margin-right: 1vw;
      grid-template-columns: repeat(2, 1fr);

      .manual-item-images-img {
        max-height: 40vw;
        z-index: 2;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .manual-item-images-img:nth-child(1) {
        grid-row: span 2;
      }
    }
    
    
    //styles if 3 images
    //.manual-item-images-wrapper:nth-last-child(2) {
    //  grid-template-areas:
    //  "A A A A   B B B B"
    //  "A A A A   B B B B"
    //  "A A A A   C C C C"
    //  "A A A A   C C C C";
    //
    //  .manual-item-images-img:first-child {
    //    grid-area: A;
    //  }
    //
    //  .manual-item-images-img:nth-child(2){
    //    grid-area: B;
    //  }
    //
    //  .manual-item-images-img:nth-child(3){
    //    grid-area: C;
    //  }
    //}
  }
}

@media (max-width: 768px) {
  .manual-item-wrapper {
    .manual-item-heading {
      font-size: 4.95vw;
      margin-bottom: 1.5vw;
    }

    .manual-item-content {
      margin-bottom: 7.5vw;

      .manual-item-text {
        p {
          font-size: 1.6vw;
          margin-top: 0.75vw;
        }
      }
    }

  }
}

@media (max-width: 425px) {
  .manual-item-wrapper {
    .manual-item-heading {
      font-size: 9vw;
      margin-bottom: 3vw;
    }

    .manual-item-content {
      margin-bottom: 15vw;
      flex-direction: column;

      .manual-item-text {
        p {
          font-size: 3.3vw;
          margin-top: 3vw;
        }
      }
    }
  }
}
