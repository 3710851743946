.file-table-parser-container{
	max-width: 65vw;
	padding: .5vw;

	display: flex;
	flex-direction: column;
	justify-content: stretch;

	gap: 1vw;

	.selected-file-input{
		max-width: 50vw;
		.input-file-wrapper{
			width: 80%;

		}
	}

	.parsed-table-container{
		max-width: 70vw;
		width: 100%;
		overflow: scroll;
		max-height: 33vh;
		.table{
			margin-top: .5vw;
			max-width: 100%;
		}
	}
}

@media screen and (max-width: 768px) {

	.file-table-parser-container{
		max-width: 80vw;
		padding: .75vw;
	
		gap: 1.5vw;
	
		.selected-file-input{
			max-width: 75vw;
		}
	
		.parsed-table-container{
			max-width: 80vw;
			.table{
				margin-top: .75vw;
				max-width: 100%;
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.file-table-parser-container{
		max-width: 80vw;
		padding: 1.5vw;
	
		gap: 3vw;
	
		.selected-file-input{
			max-width: 75vw;
		}
	
		.parsed-table-container{
			max-width: 80vw;
			.table{
				margin-top: 1.5vw;
				max-width: 100%;
			}
		}
	}

}