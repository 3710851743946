.processing-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  gap: 1.5vw;

  .processing-step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 5vw;
    gap: 0.5vw;
    margin-top: 2vw;
    grid-row: 1;
    width: 100%;

    .step-icon {
      display: flex;
      border: #1E1B39 solid 0.2vw;
      border-radius: 50%;
      padding: 1vw;

      svg {
        width: 1.5vw;
        height: 1.5vw;
      }
    }

    .step-text-wrapper {
      padding: 1vw;
      border-radius: 2vw;
      width: 100%;
      text-align: center;
      background: #1E1B39;
      color: white;

      .step-text {
        font-size: 1.2vw;
      }
    }

    .processing-arrow {
      width: 100%;
      position: absolute;
      bottom: -0.15vw;
      display: flex;

      .processing-arrow-dashed {
        width: 50%;
        border-bottom: #1E1B39 dashed 0.2vw;
        border-spacing: 0.3vw;
      }

      .processing-arrow-solid {
        width: 50%;
        border-bottom: #1E1B39 solid 0.2vw;
      }
    }

    .side-arrow-right {
      display: none;
    }

    .side-arrow-left {
      display: none;
    }
  }

  .processing-step:nth-child(3) {
    .side-arrow-right {
      display: block;
      position: absolute;
      width: 9vw;
      height: 8.7vw;
      border-top: #1E1B39 solid 0.2vw;
      border-right: #1E1B39 solid 0.2vw;
      border-radius: 0 50% 50% 0;

      top: 100%;
      left: 100%;
    }
  }

  .processing-step:nth-child(6) {
    .side-arrow-left {
      display: block;
      position: absolute;
      width: 9vw;
      height: 8.7vw;
      border-top: #1E1B39 solid 0.2vw;
      border-left: #1E1B39 solid 0.2vw;
      border-radius: 50% 0 0 50%;

      top: 100%;
      right: 100%;
    }
  }

  .processing-step:nth-child(n + 4):nth-child(-n+6) {
    justify-self: end;
    flex-direction: row-reverse;
  }

  .processing-step:nth-child(4) {
    grid-row: 2;
    grid-column: 3;
  }

  .processing-step:nth-child(5) {
    grid-row: 2;
    grid-column: 2;
  }

  .processing-step:nth-child(6) {
    grid-row: 2;
    grid-column: 1;
  }

  .processing-step:nth-child(7) {
    grid-row: 3;
    grid-column: 1;
  }

  .processing-step:nth-child(8) {
    grid-row: 3;
    grid-column: 2;
  }
}

@media (max-width: 768px) {
  .processing-wrapper {
    gap: 2.25vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .processing-step {
      min-height: 7.5vw;
      gap: 0.75vw;
      width: 80%;

      .step-icon {
        border: #1E1B39 solid 0.3vw;
        padding: 1.5vw;

        svg {
          width: 2.25vw;
          height: 2.25vw;
        }
      }

      .step-text-wrapper {
        padding: 1.5vw;
        border-radius: 3vw;

        .step-text {
          font-size: 1.8vw;
        }
      }

      .processing-arrow {
        bottom: -0.225vw;

        .processing-arrow-dashed {
          border-bottom: #1E1B39 dashed 0.3vw;
          border-spacing: 0.45vw;
        }

        .processing-arrow-solid {
          border-bottom: #1E1B39 solid 0.3vw;
        }
      }
    }

    .processing-step:nth-child(2n) {
      justify-self: end;
      flex-direction: row-reverse;
    }

    .processing-step:nth-child(5) {
      justify-self: start;
      flex-direction: row !important;
    }

    .processing-step:nth-child(2n):not(:last-child) {
      .side-arrow-left {
        display: block;
        position: absolute;
        width: 10vw;
        height: 12vw;
        border-top: #1E1B39 solid 0.3vw;
        border-left: #1E1B39 solid 0.3vw;
        border-radius: 50% 0 0 50%;

        top: 100%;
        right: 100%;
      }
    }

    .processing-step:nth-child(2n+1):not(:last-child) {
      .side-arrow-right {
        display: block;
        position: absolute;
        width: 10vw;
        height: 12vw;
        border-top: #1E1B39 solid 0.3vw;
        border-right: #1E1B39 solid 0.3vw;
        border-radius: 0 50% 50% 0;

        top: 100%;
        left: 100%;
      }
    }
  }
}

@media (max-width: 425px) {
  .processing-wrapper {
    gap: 4.5vw;

    .processing-step {
      min-height: 15vw;
      gap: 1.5vw;
      margin-top: 0;
      width: 80%;

      .step-icon {
        border: #1E1B39 solid 0.6vw;
        padding: 3vw;

        svg {
          width: 4.5vw;
          height: 4.5vw;
        }
      }

      .step-text-wrapper {
        padding: 2.25vw;
        border-radius: 4.5vw;

        .step-text {
          font-size: 3.6vw;
        }
      }

      .processing-arrow {
        bottom: -0.6vw;

        .processing-arrow-dashed {
          border-bottom: #1E1B39 dashed 0.6vw;
          border-spacing: 0.9vw;
        }

        .processing-arrow-solid {
          border-bottom: #1E1B39 solid 0.6vw;
        }
      }
    }

    .processing-step:nth-child(2n):not(:last-child) {
      .side-arrow-left {
        width: 15vw;
        height: 20vw;
        border-top: #1E1B39 solid 0.6vw;
        border-left: #1E1B39 solid 0.6vw;
      }
    }

    .processing-step:nth-child(2n+1):not(:last-child) {
      .side-arrow-right {
        width: 15vw;
        height: 20vw;
        border-top: #1E1B39 solid 0.6vw;
        border-right: #1E1B39 solid 0.6vw;
      }
    }
  }
}
