@use '/src/styles/variables' as styles;
@use '/src/styles/mixin' as utils;

$accentPrimary: var(--accentPrimary);

.landing-navbar {
    .navbar-link-wrapper{
        display: flex;
        flex-direction: column;
        position: relative;

        .navbar-link-container{
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 0.5vw;
            p, a{
                font-size: 1.11vw;
                color: #fff;
            }

        
            .navbar-link-arrow{
                transition: all ease 0.4s;
                
                svg{
                    path{
                        stroke: #fff;
                    }
        
                    width: .45vw;
                    display: flex;
                    align-items: center;
                    transition: 0.4s ease-out all;
        
                }

                &.open{
                    transform: rotate(90deg);
                }
            }
        }

        .navbar-link-dropdown-container{
            transition-property: max-height, padding-top, padding-bottom; 
            transition: 0.4s ease;
            position: absolute;
            top: 4vw;
            right: 0;
            width: 54vw;
            max-height: 0;
            overflow: hidden;

            background: #F8F9FB;
            padding-top: 0;
            padding-bottom: 0;

            border-radius: 0.5vw;
            display: flex;
            gap: 2vw;
            box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);

            .navbar-link-dropdown-links-container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1vw;
                padding: 2vw;
                width: 40%;
                overflow: scroll;

                .navbar-link-dropdown-title{
                    white-space: normal;
                    font-size: styles.$textMd;
                    color: #B7B7B7;
                    font-weight: 700;
                    text-transform: uppercase;
                    white-space: normal;

                }

                a{
                    font-size: styles.$textMd;
                    color: var(--accentPrimary);
                    transition: all 0.3s ease;
                    padding-bottom: 0.2vw;

                    svg{
                        width: 0.5vw;
                        height: auto;
                        margin-left: 0.5vw;
                    }
                }
            }

            .navbar-link-dropdown-content-container{
                display: flex;
                flex-direction: column;
                width: 55%;
                border-radius: 0.5vw;
                background-color: #fff;
                gap: 1vw;
                padding: 2vw;
                overflow: scroll;

                .navbar-link-dropdown-text-container{
                    display: flex;
                    justify-content: left;
                    width: 100%;
                    max-width: 100%;
                    transition: 0.4s ease;
                    border-radius: 0.5vw;
                    padding: 0.3vw;
                    gap: 1vw;
                    cursor: pointer;

                    .navbar-link-dropdown-title-container{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: calc(100% - 4.5vw);

                        .navbar-link-dropdown-title{
                            font-size: styles.$textMd;
                            color: var(--accentPrimary);
                            font-weight: 700;

                            svg{
                                width: 0.5vw;
                                height: auto;
                                margin-left: 0.5vw;
                            }
                        }
                      
                        .navbar-link-dropdown-subtitle{
                            font-size: styles.$textSm;
                            color: #7F7F7F;
                            font-weight: 500;
                            white-space: normal;
                        }
                    }

                    .navbar-link-dropdown-icon-container{
                        min-width: 3.5vw;
                        height: 3.5vw;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        border: 1px solid #E8EBEE;
                        border-radius: 0.5vw;
                        background-color: #fff;
                        transition: 0.4s ease;

                        svg{
                            width: 1.5vw;
                            fill: #7F7F7F;
                            path{
                                stroke: #7F7F7F;
                            }
                        }
                    }

                    &:hover{
                        background: #F8F9FB;
        
                        .navbar-link-dropdown-icon-container{
                            border: none;
                        }
                    }
                }
                
                .open{
                    background: #F8F9FB;
                    .navbar-link-dropdown-title-container{
                        width: calc(100% - 4.5vw);

                        .navbar-link-dropdown-subtitle{
                            color: var(--accentPrimary);
                        }
                    }
                    .navbar-link-dropdown-icon-container{
                        border: none;
                    }
                }
            }

            &.open{
                padding: 0.3vw;
                max-height: 80vh;
            }
        }
    }
}