@use '/src/styles/variables' as styles;
.my-tasks-calendar-weekly {
	padding: 2vw;
	width: 100%;
	border-radius: 1.5vw;
	box-shadow:  .15vw .15vw .7vw .7vw rgba(0, 0, 0, 0.048);
	position: relative;

	> hr:first-child {
		position: absolute;
		top: 32.1vw;
		left: -22vw;
		width: 59.28vw;
		transform: rotateZ(90deg);
		opacity: 0.3;
	}
	> hr:last-child {
		position: absolute;
		top: 32.1vw;
		right: -27.6vw;
		width: 59.28vw;
		transform: rotateZ(90deg);
		opacity: 0.3;
	}

	.my-tasks-calendar-weekly-table{
		width: 100%;
		height: 100%;
		position: relative;
		.my-tasks-calendar-weekly-table-container{
			display: flex;
			height: 100%;
			flex-direction:column;
			gap: 1.5vw;
			position: relative;
			padding-top: 2vw;

			.my-tasks-calendar-weekly-table-cell {
				display: flex;
				align-items: center;
				gap: 1vw;
				p {
					font-size: styles.$textSm;
					line-height: 0.97vw;
					width: 2.8vw;
					text-align: right;
					color: var(--text2)
				}
				hr {
					flex-grow: 1;
					opacity: 0.3;
					height: .1vw;
				}
			}

			.my-tasks-calendar-weekly-timer{
				position: absolute;
				right: 0.05vw;
				width: calc(100% - 5.6vw); 
				&::before {
					content: '';
					position: absolute;
					left: -0.08vw;
					top: 0.86vw;
					width: 0.35vw;
					height: 0.35vw;
					border-radius: 50%;
					background-color: black
				}

				&-time {
					font-size: styles.$textSm;
					line-height: 0.97vw;
					text-align: center
				}

				> hr {
					height: 0.21vw; 
					background: black
				}
			}

			.my-tasks-calendar-weekly-tasks {
				height: 100%;
				left: 5.59vw;

				position: absolute;
				overflow-y: hidden;
				top: 0vw;
				z-index: 10;

				display: flex;
				justify-content: stretch;

				.days-row{
					position: relative;
					font-size: .8vw;

					width: 8.97vw;
					font-weight: normal;

					border-left: .2vw solid var(--accentSecondary);
					transition: all 150ms ease;
					&:hover{
						background-color: rgba(24, 24, 24, 0.062)
					}

					.day-tasks {
						display: flex;
						height: 100%;
						position: relative;
						overflow: hidden;
						gap: 0.1vw;
						transition: all 250ms ease;
						.day-task {
							position: relative;
							cursor: pointer;
							flex-grow: 0;
							transition: all 250ms ease;
							width: 20%;
							overflow: hidden;
							display: flex;
							.day-task-header{
								background: var(--taskColor);
								padding: .4vw .3vw;
								color: var(--textHover);
								writing-mode: vertical-rl;
								display: flex;
								align-items: end;
								justify-content: end;
								p{
									height: 100%;
									text-align: left;
								}

							}

							.day-task-body{
								position: absolute;
								overflow: hidden;
								width: calc(100% - 1.2vw);
								height: 100%;
								left: 1.5vw;

								transition: all 250ms ease;
								max-width: 0%;
								// padding: .5vw;
								h2{
									font-size: 0.8vw;
									color: var(--taskColor);
									position: relative;
									z-index: 1;
									margin: .1vw;
								}
								p{
									max-width: 0%;
									margin: .2vw;
									z-index: 1;
									position: relative;
									color: var(--taskColor);
									white-space: initial;
 								}

								&::before{
									content: '';
									width: 100%;
									height: 100%;
									position: absolute;
									opacity: .2;
									z-index: -1;
									background: var(--taskColor);

								}
							}


							&.visible{
								flex-grow: 1;
								.day-task-body{
									max-width: 100%;
									padding: 0vw 0.2vw;

									p{
										max-width: 100%;
									}
									transition: all 250ms ease;
								}
							}
						}
					}

					.extra-task-indicator{
						position: absolute;
						color: var(--text2);
						width: 100%;
						height: 100%;
						text-align: right;
						user-select: none;
						pointer-events: none;
						p{
							position: relative;
						}
					}
				}

				.days-indicator{
					border-bottom: .2vw solid var(--accentPrimary);
					color: var(--text2);
					span{
						font-weight: normal;
						font-size: .9vw;
					}
				}


			}

		}
	}
}