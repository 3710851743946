@use '/src/styles/variables' as styles;
.hire-worker-form{
  width: 70vw;
  padding: 1vw;

  .hire-worker-content-container{
    height: 100%;
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hire-worker-content-input-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2.5vw;
      width: 100%;

      .hire-worker-content-field-container{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: calc(50% - 1.25vw);

        &.full-size{
          width: 100%;
        }
      }

      .hire-worker-content-field-container .text-area-container{
        height: 100%;
      }

      .hire-worker-content-field-container .text-input{
        min-height: 100%;
      }
      
    }
    
    .hire-worker-functions{
      width: 100%;
      margin-top: 2.7vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1vw;

      .hire-worker-functions-title{
        font-size: styles.$text2xl;
      }

    }
    
  }

  .hire-worker-submit-container{
    margin-top: 2.125vw;
    width: 10.42vw;
    height: 3.5vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {

  .hire-worker-form{
    width: 80vw;
    padding: 0 4.68vw 2.2vw;
    min-height: calc(100% - 6.75vw);
    max-height: calc(100% - 6.75vw);
  
    .hire-worker-content-container{
      padding: 3vw 0;
  
      .hire-worker-content-input-container{
        grid-template-columns: 1fr;
        grid-template-rows: 6.24vw 6.24vw;
        grid-row-gap: 2.25vw;
        grid-column-gap: 3.75vw;
  
        label{
          font-size: 1.66vw;
        }

        .input-field-container{
          height: 3.75vw;
          input{
            font-size: 1.45vw;
          }
        }
        
      }
      
      .hire-worker-functions{
        margin-top: 4vw;
  
        .hire-worker-functions-title{
          font-size: 2.5vw;
        }
  
      }
      
    }
  
    .hire-worker-submit-container{
      margin-top: 3vw;
      width: 15vw;
      height: 5.25vw;
    }
  }
}