.status{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #000;
    
    &.online{
        border: 1px solid var(--colorSuccessText);
        background: var(--colorSuccessText);
    }

    &.offline{
        border: 1px solid var(--text1);
        background: transparent;
    }
}