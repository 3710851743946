@use '/src/styles/variables' as styles;

.context-menu {
  font-weight: 1.04vw;
  width: 13.89vw;
  background: linear-gradient(189.58deg, rgba(255, 255, 255, 0.56) 2.18%, rgba(255, 255, 255, 0.71) 132.3%);
  padding: 0.8463541666666666vw 1.3020833333333333vw 0.8463541666666666vw 0.9765625vw;
  backdrop-filter: blur(5px);
  border-radius: 0.7vw;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(0%, -50%);
  z-index: 3;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  &.context-menu-visible {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .context-menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-bottom: 1.3020833333333333vw;

    svg{
      width: 1.04vw;
      height: 0.83vw;
      
      path{
        fill: var(--text1);
      }
    }

    .context-menu-action {
      font-weight: styles.$fontLight;
      font-size: styles.$textMd;
      line-height: 1.5625vw;
      color: var(--text1);
      padding-left: 0.8138020833333334vw;
    }

  }

  .context-menu-item:last-child {
    padding-bottom: 0;
  }
}



@media screen and (max-width: 768px) {

  .context-menu {
    font-weight: 1.56vw;
    width: 20.84vw;
    padding: 1.28vw 1.95vw 1.28vw  1.5vw;
    backdrop-filter: blur(5px);
    border-radius: 1.05vw;

    .context-menu-item {
      padding-bottom: 1.95vw;
  
      svg{
        width: 1.5vw;
        height: 1.25vw;
      }
  
      .context-menu-action {
        font-size: 1.66vw;
        line-height: 2.34vw;
        padding-left: 1.215vw;
      }
  
    }
  
  }

}

@media screen and (max-width: 425px) {

  .context-menu {
    font-weight: 3.12vw;
    width: 41.6vw;
    padding: 2.56vw 3.9vw 2.56vw  3vw;
    backdrop-filter: blur(5px);
    border-radius: 2.1vw;

    .context-menu-item {
      padding-bottom: 3.9vw;
  
      svg{
        width: 3vw;
        height: 2.5vw;
      }
  
      .context-menu-action {
        font-size: 3.32vw;
        line-height: 4.68vw;
        padding-left: 2.43vw;
      }
  
    }
  }

}