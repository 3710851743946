.search-employee-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1vw 0;

	.column-layout{
		height: 100%;
	}

}

@media screen and (max-width: 768px) {

    .search-employee-container{
        padding: 1.5vw 0;
    }
    
}

@media screen and (max-width: 425px) {

    .search-employee-container{
        padding: 3vw 0;
    }
    
}