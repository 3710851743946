@use '/src/styles/variables' as styles;
@use '/src/styles/themes/defaultTheme' as thems;

.input-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;

  &.disabled{
    .input-field{
      .input-field-wrapper{
        background: transparent;
        box-shadow: none;
      }
    }
  }

  .input-field{
    &-label {
      display: flex;
      justify-content: space-between;
        
      &-box {
        display: flex;
        align-items: center;
        column-gap: 0.5vw;

        label{
          font-size: styles.$textLg;
        }
      }

      &-letter-counter {
        color: thems.$text2;
        font-size: styles.$textMd;
      }
    }
  
    .input-field-wrapper{
      width: 100%;
      display: flex;
      align-items: center; 
      gap: 1.5vw;
      background-color: var(--accentTertiary);
      padding: 0.66vw 1.5vw;
      border-radius: styles.$roundedMd;
      box-shadow: 3px 4px 0.7vw rgba(0, 0, 0, 0.1);

      .input-field-search {
        display: flex;
        justify-content: center;
        align-items: center;

        svg, path{
          width: 1.11vw;
          height: 1.11vw;
          fill: var(--text1);
        }
      }
      
      &.input-error{
        border: 1px solid var(--colorAlertText);
      }

      &.input-success{
        border: 1px solid var(--colorSuccessText);
      }

      .input-icon{
        svg{
          display: block;
          width: 1.3vw;
          color: var(--text3);
        }
      }

      .input-field-container{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.69vw;
        width: 100%;
        overflow: hidden;

        svg{
          width: 1.5vw;
          height: 1.5vw;
        }

        input{
            border: none;
            outline: none;
            background-color: transparent;
            min-width: 33%;
            font-family: styles.$fontMain;
            flex: 1;
            font-size: styles.$textMd;

        }

        .input-field-label-container{
          display: flex;
          align-items: center;
          padding: 0.34vw;
          gap: 0.69vw;
          background-color: #1E1B39;
          max-width: 48%;
          height: 1.4vw;
          cursor: pointer;
          border-radius: 0.35vw;
          
          .input-field-label-info-container {
            display: flex;
            align-items: center;
            gap: 0.35vw;
            width: calc(100% - 1.38vw);
            
            .input-field-label-info-avatar {
              min-width: 1vw;
              max-width: 1vw;
              min-height: 1vw;
              max-height: 1vw;
            }

            p{
              font-size: styles.$text2sm;
              color: var(--textHover);
              font-family: styles.$fontMain;
              font-weight: styles.$fontLight;
              width: 100%;
            }
          }
          
          div.close-icon {
              width: 0.69vw;
              svg{
                  width: 0.69vw;
              }
          }
        }
      }
    }

  
      


      .input-field-error-wrapper{
        border: 0.07vw solid var(--colorAlertText)
      }
    
    .info-wrapper {
      font-size: styles.$text2sm;
      font-weight: 400;
      margin-top: 0.5vw;

      svg{
        width: 1.38vw;
        height: 1.38vw;
      }

      .info-text {
        display: flex;
        gap: 0.35vw;
        align-items: center;
        font-size: 1.11vw;
      }

      .info-error{
        color: var(--colorAlertText);
      }

      .info-success{
        color: var(--colorSuccessText);
        svg{
          max-width: 1.1vw;
          fill: var(--colorSuccessText);
        }
      }

      .info-info-list{
        margin-left: 2.8vw;
      }
    } 
  }

  .selection-dropdown-list-wrapper{
    position: relative;
    top: 1vw;
    transition: all 400ms ease;
    width: 100%;
    height: 0;
    overflow-y: hidden;
    background-color: var(--accentTertiary);

    .pagination-container{
      display: flex;
      flex-direction: column;
      gap: 0.5vw;
    }

    .selection-dropdown-item {
      display: flex;
      align-items: center;
      gap: 0.35vw;
      border-radius: 0.35vw;
      padding: 0.5vw;
      font-size: 0.97vw;
      cursor: pointer;

      .selection-dropdown-item-avatar {
        min-width: 1.4vw;
        max-width: 1.4vw;
        min-height: 1.4vw;
        max-height: 1.4vw;
      }
        &:hover {
        background-color: var(--backgroundSubContainer)
        }
    }
    .selection-dropdown-item-selected {
      opacity: 0.5;
      &:hover {
        background-color: var(--accentTertiary)
        }
    }

    &.selection-dropdown-list-visible {
      height: 10vw;
    }	
  }
}

@media screen and (max-width: 768px) {
  .input-wrapper{
  
    .input-field{
      &-label {
        label{
          font-size: 1.875vw;
        }

        &-letter-counter {
          font-size: 1.665vw;
        }
      }
    
      .input-field-wrapper{
        gap: 2.25vw;
        padding: 0.99vw 2.25vw;
        border-radius: 0.75vw;
        box-shadow: 3px 4px 1.05vw rgba(0, 0, 0, 0.1);
  
        .input-field-search {
          svg, path{
            width: 1.66vw;
            height: 1.66vw;
          }
        }
        
        &.input-error{
          border: 1px solid var(--colorAlertText);
        }

        &.input-success{
          border: 1px solid var(--colorSuccessText);
        }
  
        .input-icon{
          svg{
            width: 1.95vw;
          }
        }
  
        .input-field-container{
          gap: 1vw;

          svg{
            width: 2vw;
            height: 2vw;
          }
  
          input{
            font-size: 1.66vw;
          }
  
          .input-field-label-container{
            padding: 0.5vw;
            gap: 1vw;
            height: 2.1vw;
            border-radius: 0.525vw;
            
            .input-field-label-info-container {
              gap: 0.525vw;
              width: calc(100% - 2.07vw);
              
              .input-field-label-info-avatar {
                min-width: 1.5vw;
                max-width: 1.5vw;
                min-height: 1.5vw;
                max-height: 1.5vw;
              }
  
              p{
                font-size: 1.2vw;
              }
            }
            
            div.close-icon {
                width: 1vw;
                svg{
                    width: 1vw;
                }
            }
          }
        }
      }
  
    
        
  
  
      .input-field-error-wrapper{
        border: 0.1vw solid var(--colorAlertText)
      }
    
      .info-wrapper {
        font-size: styles.$text2sm;
        font-weight: 400;
        margin-top: 0.5vw;
  
        svg{
          width: 2vw;
          height: 2vw;
        }
  
        .info-text {
          font-size: 1.66vw;
          display: flex;
          gap: 0.7vw;
          align-items: center;
        }
  
        .info-error{
          color: var(--colorAlertText);
        }
  
        .info-success{
          color: var(--colorSuccessText);
          svg{
            max-width: 1.1vw;
            fill: var(--colorSuccessText);
          }
        }
  
        .info-info-list{
          margin-left: 2.8vw;
        }
      }  
    }
  
    .selection-dropdown-list-wrapper{
      top: 1.5vw;

      .pagination-container{
        gap: 0.75vw;
      }
  

      .selection-dropdown-item {
        gap: 0.525vw;
        padding: 0.75vw;
        font-size: 1.45vw;
        border-radius: 0.525vw;
        .selection-dropdown-item-avatar {
          min-width: 2.1vw;
          max-width: 2.1vw;
          min-height: 2.1vw;
          max-height: 2.1vw;
        }
      }
  
      &.selection-dropdown-list-visible {
        height: 15vw;
      }	
    }
  }
}



@media screen and (max-width: 425px) {
  .input-wrapper{
  
    .input-field{
      &-label {
        label{
          font-size: 3.75vw;
        }

        &-letter-counter {
          font-size: 3.33vw;
        }
      }
    
      .input-field-wrapper{
        gap: 4.5vw;
        padding: 2vw 4.5vw;
        border-radius: 1.5vw;
        box-shadow: 3px 4px 2.1vw rgba(0, 0, 0, 0.1);
  
        .input-field-search {
          svg, path{
            width: 3.32vw;
            height: 3.32vw;
          }
        }
        
  
        .input-icon{
          svg{
            width: 3.9vw;
          }
        }
  
        .input-field-container{
          gap: 2vw;

          svg{
            width: 4.5vw;
            height: 4.5vw;
          }
  
          input{
            font-size: 3.32vw;
          }
  
          .input-field-label-container{
            padding: 1vw;
            gap: 2vw;
            height: 4.2vw;
            border-radius: 1.5vw;
            
            .input-field-label-info-container {
              gap: 1.5vw;
              width: calc(100% - 4.14vw);
              
              .input-field-label-info-avatar {
                min-width: 3vw;
                max-width: 3vw;
                min-height: 3vw;
                max-height: 3vw;
              }
  
              p{
                font-size: 2.4vw;
              }
            }
            
            div.close-icon {
                width: 2vw;
                svg{
                    width: 2vw;
                }
            }
          }
        }
      }
  
    
        
  
  
        .input-field-error-wrapper{
          border: 0.2vw solid var(--colorAlertText)
        }
      
      .info-wrapper {
        font-size: styles.$text2sm;
        font-weight: 400;
        margin-top: 0.5vw;
  
        svg{
          width: 4vw;
          height: 4vw;
        }
  
        .info-text {
          font-size: 3.2vw;
          display: flex;
          gap: 1.4vw;
          align-items: center;
        }
  
        .info-error{
          color: var(--colorAlertText);
        }
  
        .info-success{
          color: var(--colorSuccessText);
          svg{
            max-width: 1.1vw;
            fill: var(--colorSuccessText);
          }
        }
  
        .info-info-list{
          margin-left: 2.8vw;
        }
      } 
    }
  
    .selection-dropdown-list-wrapper{
      top: 3vw;

      .pagination-container{
        gap: 1.5vw;
      }

      .selection-dropdown-item {
        gap: 1.5vw;
        padding: 1.5vw;
        font-size: 2.9vw;
        border-radius: 1.5vw;
  
        .selection-dropdown-item-avatar {
          min-width: 4.2vw;
          max-width: 4.2vw;
          min-height: 4.2vw;
          max-height: 4.2vw;
        }
      }
  
      &.selection-dropdown-list-visible {
        height: 30vw;
      }	
    }
  }
}