@use '/src/styles/variables' as styles;

.my-tasks-calendar-month-container{
    padding: 2vw;
    width: 100%;
    border-radius: styles.$roundedXl;
    box-shadow: styles.$shadowMd;
    display: grid;

    .my-tasks-calendar-month-header{
        display: grid;
        grid-template-columns: repeat(7, 9.98vw);
        justify-self: center;

        .my-tasks-calendar-month-header-item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-right: 1px solid var(--accentDisabled);
            border-left: 1px solid var(--accentDisabled);
            p{
                color: var(--text2);
                font-weight: styles.$fontMedium;
                font-size: styles.$textMd;
                margin-left: 0.34vw;
            }
        }
    }

    .my-tasks-calendar-month-cell-container{
        display: grid;
        grid-template-columns: repeat(7, 9.98vw);
        grid-template-rows: min-content 1fr min-content;
        justify-self: center;

        .my-tasks-calendar-month-cell{
            border: 1px solid var(--accentDisabled);
            font-weight: styles.$fontMedium;
            display: flex;
            flex-direction: column;
            padding: 0;
            min-height: 9.9vw;
            gap: 0.7vw;
            .my-tasks-calendar-month-cell-task-container{
                gap: 0.5vw;
            }
            .my-tasks-calendar-month-cell-day{
                color: var(--text2);
                font-weight: styles.$fontMedium;
                font-size: styles.$textMd;
                grid-row-start: 1;
				grid-row-end: 2;
                padding: 0.34vw 0 0 0.34vw;
            }

            &.today{
                border-top: 0.2vw solid var(--accentPrimary);
                
                p{
                    color: var(--accentPrimary);
                }
            }
            .my-tasks-calendar-month-cell-task{
                width: 100%;
                height: 2vw;
                display: flex;
                align-items: center;
                gap: 0.55vw;
                padding-left: 0.34vw;
                flex-shrink: 0;
                

                .task-circle{
                    width: 0.55vw;
                    height: 0.55vw;
                    border-radius: 50%;
                }
                
                .task-title{
                    width: 90%;
                    font-size: styles.$textSm;
                }

                &.start{
                    border-radius: styles.$roundedXl 0 0 styles.$roundedXl;

                }

                &.end{
                    border-radius: 0 styles.$roundedXl styles.$roundedXl 0;

                }

                &.single-day-task {
                    border-radius: styles.$roundedXl;
                }
            }

            .my-tasks-calendar-month-cell-extra-tasks{
                grid-row-start: 4;
                grid-row-end: 5;
                color: var(--text2);
                font-size: styles.$textMd;
                font-weight: styles.$fontBold;
                padding: 0.34vw 0 0 0.34vw;

            }
        }
    }
}

@media screen and (max-width: 768px) {
    .my-tasks-calendar-month-container{
        padding: 3vw;
    
        .my-tasks-calendar-month-header{
            grid-template-columns: repeat(7, 9.98vw);
    
            .my-tasks-calendar-month-header-item{
                p{
                    color: var(--text2);
                    font-weight: styles.$fontMedium;
                    font-size: 1.66vw;
                    margin-left: 0.51vw;
                }
            }
        }
    
        .my-tasks-calendar-month-cell-container{
            grid-template-columns: repeat(7, 9.98vw);
    
            .my-tasks-calendar-month-cell{
                min-height: 9.98vw;
                .my-tasks-calendar-month-cell-task-container{
                    gap: 0.75vw;
                }
                .my-tasks-calendar-month-cell-day{
                    font-size: 1.66vw;
                    padding: 0.51vw 0 0 0.51vw;
                }
    
                &.today{
                    border-top: 0.3vw solid var(--accentPrimary);
                }
    
                .my-tasks-calendar-month-cell-task{
                    height: 3vw;
                    gap: 0.82vw;
                    padding-left: 0.51vw;
    
                    .task-circle{
                        width: 0.82vw;
                        height: 0.82vw;
                    }

                    .task-title{
                        width: 90%;
                        font-size: 1.455vw;
                    }
                }
    
                .my-tasks-calendar-month-cell-extra-tasks{
                    padding: 0.51vw 0 0 0.51vw;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .my-tasks-calendar-month-container{
        padding: 6vw;
    
        .my-tasks-calendar-month-header{
            grid-template-columns: repeat(7, 9.98vw);
    
            .my-tasks-calendar-month-header-item{
                p{
                    color: var(--text2);
                    font-weight: styles.$fontMedium;
                    font-size: 3.33vw;
                    margin-left: 0.75vw;
                }
            }
        }
    
        .my-tasks-calendar-month-cell-container{
            grid-template-columns: repeat(7,9.98vw);
    
            .my-tasks-calendar-month-cell{
                min-height: 9.98vw;
                .my-tasks-calendar-month-cell-task-container{
                    gap: 1.5vw;
                }
                .my-tasks-calendar-month-cell-day{
                    font-size: 3.32vw;
                    padding: 1vw 0 0 1vw;
                }
    
                &.today{
                    border-top: 0.45vw solid var(--accentPrimary);
                }
    
                .my-tasks-calendar-month-cell-task{
                    height: 6vw;
                    gap: 1.64vw;
                    padding-left: 1vw;
    
                    .task-circle{
                        width: 1.6vw;
                        height: 1.6vw;
                    }
                    .task-title{
                        width: 90%;
                        font-size: 2.990vw;
                    }
                }
    
                .my-tasks-calendar-month-cell-extra-tasks{
                    padding: 1vw 0 0 1vw;
                }
            }
        }
    }
}