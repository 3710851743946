.moving-list{
	width: 100%;
	height: 85vh;
	padding: 1vw;
}

.moving-tasks-toolbar{
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.2vw;
	padding-top: 0.7vw;
	padding-right: 1.7vw;
	max-width: 80%;
}

.moving-tasks-toolbar-container{
	display: flex;
	flex-direction: column;
}