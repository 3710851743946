.warehouse-section-container {
  padding: 1vw 0;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1.3vw 4.6vw;

  .text-area-container {
    background-color: var(--accentTertiary);

    textarea {
      background-color: transparent;
    }
  }

  .warehouse-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
  }

  .counterparty-field,.warehouse-fields {
    grid-column: span 2;
  }

  .warehouse-inventory-selection {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .warehouse-section-container {
    padding: 1.5vw 0;
  }
}

@media screen and (max-width: 425px) {
  .warehouse-section-container {
    padding: 3vw 0;

    .warehouse-fields {
      display: flex;
      flex-direction: column;
    }
  }
}
