.landing-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	background-color: #1E1B39;
	padding: 1vw 2vw;
	width: 100%;
	position: fixed;
	z-index: 1000;

	.navnar-logo-link{
		width: 10%;
	}

	.navbar-logo {
		width: 100%;
		display: flex;
		gap: 1vw;
		align-items: flex-end;
		svg {
			width: 2.33vw;
			height: 2.66vw;
		}
	}

	.navbar-actions {
		display: flex;
		align-items: center;
		gap: 2vw;
		.navbar-links {
			list-style-type: none;
			display: flex;
			align-items: center;
			gap: 1.5vw;

			.language-selector{
				.language-selector-button{
					cursor: pointer;

					p{
						color: #fff;
						font-size: 1.11vw;
					}

					&.active, &:hover{
						background-color: var(--accentPrimary);
			
						p{
							color: #fff;
							font-weight: 900;
						}
					}
				}
			}

			.navbar-link-item {
				display: flex;
				align-items: center;
				a {
					font-size: 1.11vw;
					text-decoration: none;
					color: #fff;
				}
				
			}
		}
		.navbar-buttons {
			display: flex;
			gap: 1vw;
			width: fit-content;
		}
	}
}

@media (max-width: 768px) {
	.landing-navbar {
		padding: 1.5vw 3vw;

		.navbar-actions {
			.navbar-links {	

				.language-selector{
					.language-selector-button{
						p{
							font-size: 1.11vw;
						}
					}
				}

				.navbar-link-item {
					a {
						font-size: 1.11vw;
					}
				}
			}
		}

		.navbar-logo {
			svg {
				width: 3.5vw;
				height: 4vw;
			}
			p {
				font-size: 1.11vw;
			}
		}
	}
}

@media (max-width: 425px) {
	.landing-navbar {
		padding: 3vw 6vw;

		.navbar-actions {
			.navbar-links {	
				.language-selector{
					.language-selector-button{
						p{
							font-size: 2.5vw;
						}
					}
				}

				gap: 5vw;
				.navbar-link-item {
					a {
						font-size: 2.5vw;
					}
				}
			}

			.navbar-buttons {
				.button:nth-child(2){
					max-width: 20vw;
				}
			}
		}

		.navbar-logo {
			svg {
				width: 7vw;
				height: 8vw
			}
			p {
				display: none;
			}
		}
	}
}