.report-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vw;

    .report-total{
        display: flex;
        justify-content: space-between;

        .report-total-item{
            display: flex;
            flex-direction: column;
            text-transform: capitalize;
            color: var(--text1);
            font-size: 1.11vw;

            .report-total-item-value{
                display: flex;
                gap: 1vw;
            }
        }
    }
}