.setting-field{
	color: #444444;
	font-size: .95vw;
	border-bottom: .1vw solid #bababa;
	padding: 1vw;
	display: grid;
	width: 100%;
	align-items: center;
	grid-template-columns: 20vw 1fr;

	.setting-field-content{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 1.5vw;
	}
}

@media screen and (max-width: 768px) {

	.setting-field{
		font-size: 1.43vw;
		border-bottom: .15vw solid #bababa;
		padding: 1.5vw;
		grid-template-columns: 30vw 1fr;
	
		.setting-field-content{
			gap: 2.25vw;
		}
	}
	

}

@media screen and (max-width: 425px) {

	.setting-field{
		font-size: 2.86vw;
		border-bottom: .3vw solid #bababa;
		padding: 3vw;
		grid-template-columns: 60vw 1fr;
	
		.setting-field-content{
			gap: 4.5vw;
		}
	}
	

}