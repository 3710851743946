@use '../../../styles/variables' as styles;

.info-details-table-container{
	width: 100%;
	padding: 1vw;
	gap: 1vw;
    display: grid;
	grid-template-columns: calc(50% - 1vw) calc(50% - 1vw);

	.full{
		grid-column: span 2;
	}
}

@media screen and (max-width: 768px) {

	.info-details-table-container{
		padding: 1.5vw;
		gap: 1.5vw;
		grid-template-columns: calc(100% - 0.5vw);
	}
	

}

@media screen and (max-width: 425px) {

	.info-details-table-container{
		padding: 3vw;
		gap: 3vw;
		grid-template-columns: calc(100% - 0.5vw);
	}
	

}