.pie-container{
	max-width: 30vw;
	max-height: 30vw;
	height: 100%;
	width: 100%;
	position: relative;
	canvas{
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	.pie-center-text{
		position: absolute;
		font-size: 2vw;
		width: 100%;
		height: 100%;
		top: 0%;
		left: 0%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 1vw;
		user-select: none;
		pointer-events: none;
	}
}

@media screen and (max-width: 768px) {

	.pie-container{
		max-width: 45vw;
		max-height: 45vw;
		.pie-center-text{
			font-size: 3vw;
			font-weight: 1.5vw;
		}
	}

}

@media screen and (max-width: 425px) {

	.pie-container{
		max-width: 90vw;
		max-height: 90vw;
		.pie-center-text{
			font-size: 6vw;
			font-weight: 3vw;
		}
	}

}