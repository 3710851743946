@use '/src/styles/variables' as styles;

.text-area{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;

	.info-wrapper {
		font-size: styles.$text2sm;
		font-weight: 400;
		margin-top: 0.5vw;
  
		svg{
		  width: 1.38vw;
		  height: 1.38vw;
		}
  
		.info-text {
		  display: flex;
		  gap: 0.35vw;
		  align-items: center;
		  font-size: 1.11vw;
		}
  
		.info-error{
			color: var(--colorAlertText);
		}
  
		.info-success{
		  color: var(--colorSuccessText);
		  svg{
			max-width: 1.1vw;
			fill: var(--colorSuccessText);
			}
		}
  
		.info-info-list{
		  margin-left: 2.8vw;
		}
	}

	.textarea-label{
		font-size: styles.$textLg;
	}

	.text-area-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0.3vw 0.4vw 0.7vw rgba(0, 0, 0, 0.1);
		border-radius: styles.$roundedMd;
		transition: all ease 0.3s;
		background-color: var(--backgroundMain);
		padding: 0.3vw 0.3vw 0.3vw 0.7vw;
	
		&-files-preview{
			box-shadow: 0 0.5vw 0.7vw -1px rgba(0, 0, 0, 0.25);
			border-radius: 0 0 styles.$roundedLg styles.$roundedLg;
		}

		svg{
			width: 1.5vw;
			height: 1.5vw;
			margin-top: 0.5vw;
		}
	
		.text-input {
			max-height: none; /* Remove the max-height constraint */
			width: 100%;
			height: auto; /* Change height to auto */
			font-size: styles.$textMd;
			// min-height: 2.20vw;
			padding: 0.5vw 0.3vw;
			outline: none;
			overflow: hidden;
			border: none;
			appearance: none;
			resize: none; /* Allow vertical resizing */
			text-wrap: wrap;
		}
		
		
		.text-area-left-addon,
		.text-area-right-addon  {
			align-self: stretch;
		}
	}
}



@media screen and (max-width: 768px) {
	.text-area{
		.info-wrapper {
			margin-top: 0.75vw;
	  
			svg{
			  width: 2vw;
			  height: 2vw;
			}
	  
			.info-text {
			  gap: 0.5vw;
			  font-size: 1.66vw;
			}
	  	}

		.textarea-label{
			font-size: 1.875vw;
		}
	
		.text-area-container {
			box-shadow: 0px 0px 1.05vw -1px rgba(0, 0, 0, 0.25);
			border-radius: 0.75vw;
			padding: 1.05vw;

			svg{
				width: 2vw;
				height: 2vw;
				margin-top: 1vw;
			}
		
			&-files-preview{
				box-shadow: 0 0.75vw 1.05vw -1px rgba(0, 0, 0, 0.25);
				border-radius: 0 0 1.5vw 1.5vw;
			}
		
			.text-input {
				max-height: 7vh;
				font-size: 1.66vw;
				// line-height: 1.875vw;
				min-height: 1.875vw;
				padding: 0.75vw 0.45vw;
			}
		}
	}
	
}

@media screen and (max-width: 425px) {
	.text-area{
		.info-wrapper {
			margin-top: 1.5vw;
	  
			svg{
			  width: 4vw;
			  height: 4vw;
			}
	  
			.info-text {
			  gap: 1vw;
			  font-size: 3.2vw;
			}
	  	}

		.textarea-
		.textarea-label{
			font-size: 3.75vw;
		}
	
		.text-area-container {
			box-shadow: 0px 0px 2.1vw -1px rgba(0, 0, 0, 0.25);
			border-radius: 1.5vw;
			padding: 2.1vw;

			svg{
				width: 4.5vw;
				height: 4.5vw;
			}
		
			&-files-preview{
				box-shadow: 0 1.5vw 2.1vw -1px rgba(0, 0, 0, 0.25);
				border-radius: 0 0 3vw 3vw;
			}
		
			.text-input {
				max-height: 35vh;
				font-size: 3.32vw;
				// line-height: 3.75vw;
				min-height: 3.75vw;
				padding: 1.5vw 0.9vw;
			}
		}
	}
	
}