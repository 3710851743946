.create-offering-container {

    .form-elements-wrapper {
        width: 56vw;
        height: 100%;
        display: grid;
        grid-template-columns: 22vw 22vw;
        justify-content: center;
        gap: 1.3vw 4.6vw;

        .text-area-container {
            background-color: var(--accentTertiary);

            textarea {
                background-color: transparent;
            }
        }

    }
}