.navbar-burger-wrapper{
    display: flex;
    flex-direction: column;
    width: 2vw;
    position: relative;

    .navbar-link-arrow{
        transition: all ease 0.4s;
        
        svg{
            path{
                stroke: #fff;
            }

            width: .45vw;
            display: flex;
            align-items: center;
            transition: 0.4s ease-out all;
        }

        &.open{
            transform: rotate(90deg);
        }
    }
    .navbar-burger-content-container{
        position: fixed;
        top: 7vw;
        height: calc(100vh - 7vw);
        overflow-y: scroll;
        right: 0;
        background-color: #F8F9FB;
        transition-property: width, padding-left, padding-right; 
        transition: 0.4s ease;
        padding: 1vw 0;
        width: 0;
        display: flex;
        flex-direction: column;
        gap: 5vw;
        
        &.open{
            width: 70vw;
            padding: 1vw 2vw;
        }

        .navbar-burger-content-links{
            display: flex;
            flex-direction: column;
            gap: 1vw;
            background: #fff;
            padding: 2vw;
            border-radius: 0.75vw;
        }

        .navbar-burger-content-container-footer{
            display: flex;
            flex-direction: column;
            gap: 1vw;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        
    }
    
    .navbar-burger-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.525vw;
        width: 3.05vw;
        height: 3.05vw;
        cursor: pointer;

        &.open {
            .navbar-burger-item:nth-child(1) {
                transform: rotate(45deg) translateY(1.15vw);
                transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
            }

            .navbar-burger-item:nth-child(2) {
                transform: scale(0);
                opacity: 0;
                transition: transform 0.4s ease, opacity 0.3s ease;
            }

            .navbar-burger-item:nth-child(3) {
                transform: rotate(-45deg) translateY(-1.2vw);
                transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
            }
        }

        .navbar-burger-item {
            width: 100%;
            background: #fff;
            height: 0.3vw;
            border-radius: 0.375vw;
            transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
            position: relative;
        }
    }
}

@media (max-width: 425px) {

    .navbar-burger-wrapper{
        width: 4vw;

        .navbar-link-arrow{
            svg{
                width: .9vw;
            }
        }
        .navbar-burger-content-container{
            top: 14vw;
            height: calc(100vh - 14vw);
            padding: 2vw 0;
            gap: 10vw;
         
            &.open{
                width: 100vw;
                padding: 2vw 4vw;
            }
    
            .navbar-burger-content-links{
                gap: 2vw;
                padding: 3vw;
                border-radius: 1.5vw;
            }
    
            .navbar-burger-content-container-footer{
                gap: 2vw;
            }
            
        }
        
        .navbar-burger-container{
            gap: 1.05vw;
            width: 6.1vw;
            height: 6.1vw;
    
            &.open {
                .navbar-burger-item:nth-child(1) {
                    transform: rotate(45deg) translateY(2.3vw);
                }
    
    
                .navbar-burger-item:nth-child(3) {
                    transform: rotate(-45deg) translateY(-2.4vw);
                }
            }
    
            .navbar-burger-item {
                height: 0.6vw;
                border-radius: 0.75vw;
            }
        }
    }
    

}