.timeline-calendar-table-date-item{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .timeline-calendar-table-month-container{
        padding: 1.38vw 0.69vw;
		width: 100%;
        border-bottom: 0.14vw solid var(--accentTertiary);
		position: relative;

		p{
			font-size: 1.11vw;
			font-weight: 500;
			width: fit-content;
			position: sticky;
			left: 0.7vw;
		}
    }

    .timeline-calendar-table-days-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        .timeline-calendar-table-day-item{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 4.86vw;
            border: 0.07vw solid var(--accentTertiary);
            border-bottom: none;
            border-top: none;
			background: var(--backgroundNestedSubContainer);

			.timeline-calendar-table-day-head{
				width: 100%;
				padding: 0.5vw 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 0.35vw;
				background: var(--backgroundMain);
				box-shadow: 0.21vw 0.28vw 0.7vw rgba(0, 0, 0, 0.1); 

				.timeline-calendar-table-day{
					color: var(--text3);
					font-size: 0.97vw;
					font-weight: 500;
				}

				.timeline-calendar-table-day-date{
					color: var(--accentPrimary);
					font-size: 0.97vw;
					font-weight: 500;
				}
			}
        }
    }
}

@media screen and (max-width: 768px) {

	.timeline-calendar-table-date-item{
		.timeline-calendar-table-month-container{
			padding: 2.1vw 1.05vw;
			border-bottom: 0.21vw solid var(--accentTertiary);
	
			p{
				font-size: 1.66vw;
				left: 1.05vw;
			}
		}
	
		.timeline-calendar-table-days-container{
	
			.timeline-calendar-table-day-item{
				width: 7.3vw;
				border: 0.1vw solid var(--accentTertiary);
	
				.timeline-calendar-table-day-head{
					padding: 0.75vw 0;
					gap: 0.53vw;
					box-shadow: 0.3vw 0.45vw 1.05vw rgba(0, 0, 0, 0.1); 
	
					.timeline-calendar-table-day{
						font-size: 1.45vw;
					}
	
					.timeline-calendar-table-day-date{
						font-size: 1.45vw;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {

	.timeline-calendar-table-date-item{
		.timeline-calendar-table-month-container{
			padding: 4.2vw 2.1vw;
			border-bottom: 0.4vw solid var(--accentTertiary);
	
			p{
				font-size: 3.32vw;
				left: 2.1vw;
			}
		}
	
		.timeline-calendar-table-days-container{
	
			.timeline-calendar-table-day-item{
				width: 14.6vw;
				border: 0.2vw solid var(--accentTertiary);
	
				.timeline-calendar-table-day-head{
					padding: 1.5vw 0;
					gap: 1.05vw;
					box-shadow: 0.6vw 0.9vw 2.1vw rgba(0, 0, 0, 0.1); 
	
					.timeline-calendar-table-day{
						font-size: 2.9vw;
					}
	
					.timeline-calendar-table-day-date{
						font-size: 2.9vw;
					}
				}
			}
		}
	}

}