.audio-recorder-timer {
  font-style: normal;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 0.78vw;
  width: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--audioTimerColor);
}

@media screen and (max-width: 768px) {

  .audio-recorder-timer {
    font-size: 1.05vw;
    line-height: 1.17vw;
    width: 3vw;
  }
  
}

@media screen and (max-width: 425px) {

  .audio-recorder-timer {
    font-size: 2.1vw;
    line-height: 2.34vw;
    width: 6vw;
  }
  
}