.chat-input-wrapper{  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
  margin: 1vw 0;
  padding: 0 1.75vw;
  transition: all 0.4s ease;
}

@media screen and (max-width: 768px) {

  .chat-input-wrapper{ 
    margin: 1.5vw 0;
    padding: 0 2.625vw;
  }
}


@media screen and (max-width: 425px) {

  .chat-input-wrapper{ 
    margin: 3vw 0;
    padding: 0 5.25vw;
  }
}