@use '/src/styles/variables' as styles;

$accentPrimary: var(--accentPrimary);
$textHover: var(--textHover);
$duration: styles.$transitionDuration;

.app-navitem{
    width: 100%;
    background-color: var(--backgroundMain);
    transition: $duration ease-out all;
    stroke: $accentPrimary;
	cursor: pointer;
    
	.navitem-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
		padding: .4vw .8vw;
		border-radius: styles.$roundedMd;
		transition: $duration ease-out all;
		margin-bottom: 0.5vw;

		.navitem-header-text{
            display: flex;
            justify-content: space-between;
            align-items: center;

            p{
                margin-left: 1vw;
                font-size: styles.$textSm;
            }
        }
    
		.navitem-icons{
			display:flex;
			justify-content: space-between;
			max-width: 3vw;
			width: fit-content;
			gap: 1vw;

			&-arrow{
				svg{
					path{
						stroke: $accentPrimary;
					}
				}

				width: .45vw;
				display: flex;
				align-items: center;
				transition: $duration ease-out all;
			}
		}
		&:hover{
			background-color: $accentPrimary;
			color: $textHover;
			fill: $textHover;
			stroke: $textHover;

			path{
				stroke: $textHover;
			}
			
			.navitem-icons-arrow{
				path{
					stroke: $textHover;
				}
			}

			svg{
				display: block;
				&.should-fill{
					path{
						fill: $textHover;
						display: block;
					}
				}
			}

		}
		
    }

    .navitem-category-container{
        display: flex;
        flex-direction: column;
		gap: .5vw;
		border-left: .12vw solid $accentPrimary;
		color: $accentPrimary;
		max-height: 0;
		margin-left: 10%;
		overflow: hidden;

		// Transition Out
		transition: 500ms ease all;

        .navbar-link{
            display: flex;
            align-items: center;
			border-radius: 0;
			padding: .3vw 0.1vw;
			margin: 0 .5vw;
			transition: $duration;
			cursor: pointer;
			height: auto;
			block-size: auto;
			overflow: hidden;

            svg{
                width: .9vw;
                display: block;
            }

            p{
				color: $accentPrimary;
                margin-left: .95vw;
                font-size: styles.$textSm;
				font-weight: styles.$fontMedium;
            }


            &:hover{
				scale: .98;
				background-color: var(--accentTertiary);

				p{
					color: $accentPrimary;
					font-weight: styles.$fontBold;
				}
            }
        }
    }

	svg{
		width: 1.3vw;
		height: 1.3vw;
		display: block;
		&.should-fill{
			path{
				fill: $accentPrimary;
			}
		}
	}

	&.selected{
		.navitem-header{
			background-color: $accentPrimary;
			color: $textHover;
			fill: $textHover;
			stroke: $textHover;
			.navitem-icons-arrow{
				path{
					stroke: $textHover;
				}
			}   

			svg{
				display: block;
				&.should-fill{
					path{
						fill: $textHover;
						display: block;
					}
				}
			}

			.navitem-icons-arrow{
				width: .45vw;
				rotate: 90deg;
			}

			.info-popup-icon{
				svg{
					path{
						stroke: $accentPrimary;
					}
					.notification-circle {
						stroke: $textHover;
						fill: $textHover;
					}
					.notification-trinagle {
						stroke: $accentPrimary;
						fill: $accentPrimary;
					}
					.notification-exclamation {
						stroke: $textHover;
						fill: $accentPrimary;
					}
				}
			}
		}



		.navitem-category-container{
			max-height: 15vw;
		}
	}

}

@media screen and (max-width: 768px) {

	.navitem{
		
		.navitem-header{
			padding: .6vw 1.2vw;
			border-radius: 0.75vw;
			margin-bottom: 0.75vw;
			.navitem-icons{
				max-width: 4.5vw;
				width: fit-content;
			}
		
			.navitem-header-text{
				p{
					margin-left: 1.5vw;
					font-size: 1.45vw;
				}
			}
		
			.navitem-icons-arrow{	
				width: .67vw;
			}
	
			&:hover{
	
				svg{
					width: 1.95vw;
					height: 1.95vw;
				}
			}
			
		}
	
		.navitem-category-container{
			gap: .75vw;
			border-left: .18vw solid $accentPrimary;
			color: $accentPrimary;
			max-height: 0;
			margin-left: 10%;
	
			// Transition Out
			transition: 500ms ease all;
	
			.navbar-link{
				padding: .45vw 0.15vw;
				margin: 0 .75vw;
	
				svg{
					width:1.35vw;
				}
	
				p{
					margin-left: 1.45vw;
					font-size: 1.45vw;
				}
			}
		}
	
		svg{
			width: 1.85vw;
			height: 1.85vw;
		}
	
		&.selected{
			.navitem-header{
				svg{
					width: 1.85vw;
					height: 1.85vw;
				}
	
	
				.navitem-icons-arrow{
					width: .67vw;
				}
			}
	
			.navitem-category-container{
				max-height: 22.5vw;
			}
		}
	
	}

}

@media screen and (max-width: 425px){
	.navitem{
	
		.navitem-header{
			padding: 1.2vw 2.4vw;
			border-radius: 1.5vw;
			margin-bottom: 4.5vw;

			.navitem-icons{
				max-width: 9vw;
				width: fit-content;
			}
		
			.navitem-header-text{

				p{
					margin-left: 3vw;
					font-size: 2.91vw;
				}
			}
		
			.navitem-icons-arrow{
				width: 1.35vw;
			}

			&:hover{

				svg{
					width: 3.9vw;
					height: 3.9vw;
				}
			}
			
		}

		.navitem-category-container{
			gap: 1.5vw;
			border-left: .36vw solid $accentPrimary;

			.navbar-link{
				padding: .9vw 0.3vw;
				margin: 0 1.5vw;

				svg{
					width: 2.7vw;
				}

				p{
					margin-left: 2.85vw;
					font-size: 2.91vw;
				}
			}
		}

		svg{
			width: 3.9vw;
			height: 3.9vw;
		}

		&.selected{
			.navitem-header{

				svg{
					width: 3.9vw;
					height: 3.9vw;
					
				}


				.navitem-icons-arrow{
					width: 1.35vw;
				}
			}

			.navitem-category-container{
				max-height: 45vw;
			}
		}

	}
}