@use '/src/styles/variables' as styles;

.float-input{
    width: 100%;
    label{
        font-size: 1.25vw;
    }

    .float-input-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5vw;
        background-color: var(--accentTertiary);
        padding: 0.66vw 1.5vw;
        border-radius: 0.5vw;
        box-shadow: 3px 4px 0.7vw rgba(0, 0, 0, 0.1);

        .float-input-container{

            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.69vw;
            width: 100%;
            overflow: hidden;

            input{
                border: none;
                outline: none;
                background-color: transparent;
                min-width: 33%;
                font-family: styles.$fontMain;
                flex: 1;
                font-size: 1.11vw;
            }
        }
    }

    .error-wrapper {
        color: var(--colorAlertText);
        font-size: styles.$text2sm;
        font-weight: 400;
        margin-top: 0.5vw;
  
        svg{
          width: 1.38vw;
          height: 1.38vw;
        }
  
        .error-text {
          display: flex;
          gap: 0.35vw;
          align-items: center;
        }
  
        .error-info-list{
          margin-left: 2.8vw;
        }
      } 
}

@media screen and (max-width: 768px) {

    .float-input {
        width: 100%;
    
        label {
            font-size: 1.875vw;
        }
    
        .float-input-wrapper {
            width: 100%;
            gap: 2.25vw;
            background-color: var(--accentTertiary);
            padding: 0.99vw 2.25vw;
            border-radius: 0.75vw;
            box-shadow: 4.5px 6px 1.05vw rgba(0, 0, 0, 0.1);
    
            .float-input-container {
                gap: 1.035vw;
                width: 100%;
    
                input {
                    font-size: 1.665vw;
                }
            }
        }
    
        .error-wrapper {
            color: var(--colorAlertText);
            font-size: styles.$text2sm;
            font-weight: 400;
            margin-top: 0.75vw;
    
            svg {
                width: 2.07vw;
                height: 2.07vw;
            }
    
            .error-text {
                gap: 0.525vw;
            }
    
            .error-info-list {
                margin-left: 4.2vw;
            }
        }
    }
}

@media screen and (max-width: 425px) {

    .float-input {
        width: 100%;
    
        label {
            font-size: 3.75vw;
        }
    
        .float-input-wrapper {
            width: 100%;
            gap: 4.5vw;
            padding: 1.98vw 4.5vw;
            border-radius: 1.5vw;
            box-shadow: 6px 8px 2.1vw rgba(0, 0, 0, 0.1);
    
            .float-input-container {
                gap: 2.07vw;
                
                input {
                    font-size: 3.33vw;
                }
            }
        }
    
        .error-wrapper {
            color: var(--colorAlertText);
            font-size: styles.$text2sm;
            font-weight: 400;
            margin-top: 1.5vw;
    
            svg {
                width: 4.14vw;
                height: 4.14vw;
            }
    
            .error-text {
                gap: 1.05vw;
            }
    
            .error-info-list {
                margin-left: 8.4vw;
            }
        }
    }

}