.navbar-burger{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #312E49;
    gap: 0.525vw;
    width: 4.05vw;
    height: 4.05vw;
    padding: 0.525vw;
    border-radius: 0.75vw;
    margin-top: 1.5vw;

    .navbar-burger-item{
        width: 100%;
        height: 0.3vw;
        background: #fff;
        border-radius: 0.375vw;
        position: relative;
        transition: transform ease 0.2s;
    }

    &.open{
        .navbar-burger-item:nth-child(1){
            transform: rotate(45deg);
            top: 0.765vw;
        }

        .navbar-burger-item:nth-child(2){
            opacity: 0;
        }

        .navbar-burger-item:nth-child(3){
            transform: rotate(-45deg);
            bottom: 0.765vw;
        }
    }
}

@media (max-width: 425px) {

    .navbar-burger{
        gap: 1vw;
        width: 8vw;
        height: 8vw;
        padding: 1vw;
        border-radius: 1.5vw;
        margin-top: 3vw;
    
        .navbar-burger-item{
            height: 0.6vw;
            border-radius: 0.75vw;
        }
    
        &.open{
            .navbar-burger-item:nth-child(1){
                top: 1.53vw;
            }
    
            .navbar-burger-item:nth-child(3){
                bottom: 1.53vw;
            }
        }
    }

}