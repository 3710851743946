.avatar-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    // width: 100%;
    height: 100%;

    img, svg, path{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .avatar-status{
        width: 30%;
        height: 30%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}