.updating{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--accentPrimary);
    min-height: 100vh;
    color: #fff;

    p{
        font-size: 1.11vw;
    }
}

@media screen and (max-width: 768px) {

    .updating{
        p{
            font-size: 1.66vw;
        }
    }

}

@media screen and (max-width: 425px) {

    .updating{
        p{
            font-size: 3.2vw;
        }
    }
}