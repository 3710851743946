.authentication-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.4vw;
  
  .authentication-header-title{
    font-size: 1.66vw;
    text-align: center;
    font-weight: 600; 
    color: var(--text1);
  }

  .authentication-subtitle {
    display: flex;
    gap: 0.11vw;
    font-size: 1.11vw;

    .authentication-link-text {
      text-decoration: underline;
    }

    p{
        color: var(--text1);
    }

    a{
      p{
        color:var(--text1);
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .authentication-header {
    margin-bottom: 2.1vw;
    
    .authentication-header-title{
      font-size: 2.5vw;
    }
  
    .authentication-subtitle {
      gap: 0.15vw;
      font-size: 1.66vw;
    }
  }

}

@media screen and (max-width: 425px) {

  .authentication-header {
    margin-bottom: 4.2vw;
    
    .authentication-header-title{
      font-size: 5vw;
    }
  
    .authentication-subtitle {
      gap: 0.3vw;
      font-size: 3.32vw;
    }
  }

}