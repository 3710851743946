.move-project{
    width: 40vw;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    height: 40vh;
}

@media screen and (max-width: 768px) {

    .move-project{
        width: 60vw;
        padding: 1.5vw;
        gap: 3.75vw;
        height: 40vh;
    }    
}

@media screen and (max-width: 425px) {

    .move-project{
        width: 80vw;
        padding: 3vw;
        gap: 7.5vw;
        height: 40vh;
    }
    

}