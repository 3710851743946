@use 'src/styles/variables' as styles;

.skeleton-filter-bar-container{
    box-shadow: styles.$shadowSm;
    border-radius: styles.$roundedMd;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw 1.38vw;
    gap: 0.5vw;
    margin: 2vw 0;
    position: relative;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .skeleton-filter-bar-container{
        border-radius: 0.75vw;
        padding: 1.5vw 2.07vw;
        gap: 0.75vw;
        margin: 3vw 0;
    }
}

@media screen and (max-width: 425px) {
    .skeleton-filter-bar-container{
        border-radius: 1.5vw;
        padding: 3vw 4.14vw;
        gap: 1.5vw;
        margin: 6vw 0;
    }
}