@use '/src/styles/variables' as styles;
.chat-input-container {
    position: relative;
    width: 100%;
    max-height: 36vh;

    .text-area-container {
        align-items: center !important;
    }

    &.chat-input-container-action{
        border-radius: 0 0 1vw 1vw;
        overflow: hidden;
        box-shadow: 0 0.7vw 0.7vw -1px rgba(0, 0, 0, 0.25);

        .text-area-container{
            border-radius: 0;
        }
    }

    .chat-input-tools {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        min-width: 2.92vw;
        min-height: 2.92vw;
        transition: all ease 0.3s;

        &:hover{
            background: var(--accentPrimary);
            path{
                fill: var(--textHover) !important;
            }
        }

        svg {
        height: 1.73vw;
        }
    }

    .chat-input {
        width: 100%;
        max-width: 87%;
        max-height: 35vh;
        font-size: styles.$textMd;
        line-height: 1.1vw;
        padding: 0 0.3vw;
        overflow-y: scroll;
        outline: none;
    }

}

@media screen and (max-width: 768px) {

    .chat-input-container {
        max-height: 36vh;
    
        &.chat-input-container-action{
            border-radius: 0 0 1.5vw 1.5vw;
            box-shadow: 0 1.05vw 1.05vw -1px rgba(0, 0, 0, 0.25);
        }
    
        .chat-input-tools {
            min-width: 4.38vw;
            min-height: 4.38vw;
    
    
            svg {
                height: 2.6vw;
            }
        }
    
        .chat-input {
            max-height: 35vh;
            font-size: 1.66vw;
            line-height: 1.66vw;
            padding: 0 0.45vw;
        }
    
    }

}

@media screen and (max-width: 425px) {

    .chat-input-container {
        max-height: 36vh;
    
        &.chat-input-container-action{
            border-radius: 0 0 3vw 3vw;
            box-shadow: 0 2.1vw 2.1vw -1px rgba(0, 0, 0, 0.25);
        }
    
        .chat-input-tools {
            min-width: 8.76vw;
            min-height: 8.76vw;
    
    
            svg {
                height: 5.2vw;
            }
        }
    
        .chat-input {
            max-height: 35vh;
            font-size: 3.32vw;
            line-height: 3.32vw;
            padding: 0 0.9vw;
        }
    
    }

}