@use '/src/styles/variables' as styles;

.landing-slider-steps-wrapper {
    position: relative;
    background: #f2f4f6;
    position: relative;
    padding-top: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;

    .landing-text {
        max-width: 70vw;
        font-weight: 500;
        color: #6f6c8a;
        z-index: 5;
    }

    .landing-slider-hero {
      width: 75vw;
      margin: 0 auto;
      z-index: 5;
  
      &::before {
        content: "";
        position: absolute;
        width: 26vw;
        height: 14vw;
        bottom: 0;
        left: 0;
        background-color: var(--colorLightBlue);
        filter: blur(6vw);
        z-index: 2;
      }
      &::after {
        content: "";
        position: absolute;
        width: 14vw;
        height: 34vw;
        top: 0;
        right: 0;
        background-color: #c0bbf1;
        filter: blur(8vw);
        z-index: 2;
      }
  
      .landing-slider-content {
        position: relative;
        z-index: 2;
        display: flex;
        border-radius: styles.$textMd;
        background: linear-gradient(90deg, #EDF2FD 0%, #FFFFFF 33%);
        gap: 4vw;
        overflow: hidden;

        .landing-slider-img-container {
            display: flex;
            align-items: center;
            width: 25vw;
            overflow: hidden;
            .landing-slider-img-container-list{
                display: flex;
                transition: all 0.3s;
                gap: 20vw;
                img {
                    width: 17vw;
                }
            }
        }

        .landing-slider-info-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            align-items: flex-start;
            gap: 5vw;
            padding: 5vw 3vw 5vw 0;
            width: 100%;

            .landing-slider-info-list {
                display: flex;
                justify-content: space-between;
                width: 87%;
                gap: 0.5vw;

                .step-line {
                    position: relative;
                    width: 100%;
                    border-bottom: 2px #E8E8E8 solid;

                    &.selected {
                        border-bottom: 2px solid #C0BBF1;
                        box-shadow: 0px 0px 0.5vw 0px #C0BBF1;
                    }

                    .step-line-icon-container {
                        position: absolute;
                        display: flex;
                        gap: 0.5vw;
                        align-items: center;
                        background: #F5F4FF;
                        top: -2.7vw;
                        padding: 0.2vw 0.5vw;
                        border-radius: styles.$textMd;
                        width: fit-content;

                        p {
                            font-size: 1vw;
                            color: #444444;
                        }

                        svg {
                            width: 1.2vw;
                            height: 1.2vw;
                        }
                    }
                }
            }
            
            .landing-slider-info-buttons {
                display: flex;
                gap: 1vw;
                width: 50%;
                height: 2.8vw;

                .button {
                    p {
                        font-size: 1.1vw;
                    }
                }

                .button-outlined {
                    border: 2px #CAD1D9 solid;
                }
            }

            .landing-slider-info-text-list{
                display: flex;
                gap: 40vw;
                transition: all 0.3s;
                .landing-slider-info-text {
                    display: flex;
                    flex-direction: column;
                    gap: 1.5vw;
                    width: 51vw;

                    .landing-slider-info-small-text {
                        display: flex;
                        gap: 0.5vw;
                        align-items: center;
                        background: #F5F4FF;
                        padding: 0.2vw 0.5vw;
                        border-radius: styles.$textMd;
                        width: fit-content;

                        p {
                            font-size: 0.8vw;
                            color: #444444;
                        }

                        svg {
                            width: 1.2vw;
                            height: 1.2vw;
                            fill: #C0BBF1;
                            stroke: #C0BBF1;
                            g {
                                fill: #C0BBF1;
                                stroke: #C0BBF1; 
                            }
                            path {
                                stroke: #C0BBF1;
                            }
                        }
                    }

                    .title {
                        font-family: "Inter", sans-serif;
                        font-weight: 200;
                        font-size: 2.2vw;
                        line-height: 1.2;
                        text-align: left;
                    }
                
                    .title-black {
                        font-weight: 800;
                    }
                
                    .landing-text {
                        text-align: left;
                        max-width: 80%;
                        font-size: 1.1vw;
                        font-weight: 500;
                        color: #6f6c8a;
                    }
                }
            }
        }

        @keyframes content-animation {
          from {
            top: -10vw;
            opacity: 0;
          }
          to {
            top: 0;
            opacity: 1;
          }
        }
      }
    }
}

@media (max-width: 768px) {
    .landing-slider-steps-wrapper {
        gap: 3vw;
        padding-top: 15vw;
        .landing-text {
            font-size: 1.6vw;
        }
        .title{
            font-size: 3.6vw;
        }
        .landing-slider-hero {
            width: 80vw;
            &.header-app-feature-hero::before {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                bottom: 0;
                left: 0;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
            &.header-app-feature-hero::after {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: 0;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
                z-index: 2;
            }
            .landing-slider-content {
                gap: 6vw;
                border-radius: 1.7vw;
                .landing-slider-info-container {
                    gap: 7.5vw;
                    padding: 7.5vw 4.5vw 7.5vw 0;
                    .landing-slider-info-list {
                        width: 100%;
                        gap: 0.75vw;
                        .step-line {
                            .step-line-icon-container {
                                gap: 0.75vw;
                                top: -4vw;
                                padding: 0.3vw 0.75vw;
                                border-radius: 1.7vw;
                                p {
                                    font-size: 1.5vw;
                                }

                                svg {
                                    width: 1.8vw;
                                    height: 1.8vw;
                                }
                            }
                        }
                    }
                    .landing-slider-info-text-list{
                        .landing-slider-info-text {
                            .landing-slider-info-small-text {
                                gap: 0.75vw;
                                border-radius: 1.7vw;
                                padding: 0.3vw 0.8vw;
                                border-radius: 1.7vw;
                                p {
                                    font-size: 1.8vw;
                                }
                                svg {
                                    width: 1.8vw;
                                    height: 1.8vw;
                                }
                            }
                            .title {
                                font-size: 3.6vw;
                            }
                            .landing-text {
                                font-size: 1.6vw;
                            }
                        }
                    }
                    .landing-slider-info-buttons {
                        gap: 1.5vw;
                        height: 3.456vw;
                        .button {
                            padding: 0.73vw;
                            p {
                                font-size: 1.6vw;
                            }
                        }
                    }
                }
            }
        }
    }
}
  
@media (max-width: 425px) {
    .landing-slider-steps-wrapper {
        gap: 6vw;
        .landing-text {
            font-size: 3.3vw;
            max-width: 90%;
        }
        .title{
            font-size: 6.6vw;
        }
        .landing-slider-hero {
            width: 90vw;

            &.landing-slider-hero::before {
                content: "";
                position: absolute;
                width: 78vw;
                height: 42vw;
                bottom: 0;
                left: 0;
                background-color: var(--colorLightBlue);
                filter: blur(18vw);
                z-index: 2;
            }
            &.landing-slider-hero::after {
                content: "";
                position: absolute;
                width: 42vw;
                height: 102vw;
                top: 0;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(24vw);
                z-index: 2;
            }

            .landing-slider-content { 
                flex-direction: column-reverse; 
                border-radius: 3.3vw;   
                gap: 12vw;           
                .landing-slider-img-container {
                    height: 70vw;
                    padding-bottom: 4vw;
                    width: 100%;
                    .landing-slider-img-container-list{
                        gap: 30vw;
                        img {
                            width: 90vw;
                        }
                    }
                }
                .landing-slider-info-container {
                    padding: 12vw 4vw 0vw 4vw;
                    width: 100%;
                    gap: 15vw;
                    .landing-slider-info-list {
                        gap: 1.5vw;
                        .step-line {
                            .step-line-icon-container {
                                gap: 1.5vw;
                                padding: 0.6vw 1.5vw;
                                border-radius: 3.3vw;
                                top: -7.5vw;
                                p {
                                    font-size: 3vw;
                                }

                                svg {
                                    width: 3.6vw;
                                    height: 3.6vw;
                                }
                            }
                        }
                    }
                    .landing-slider-info-text-list{
                        
                        .landing-slider-info-text {
                            width: 80vw;
                            .landing-slider-info-small-text {
                                border-radius: 3.7vw;
                                padding: 0.8vw 1.2vw;
                                gap: 1vw;
                                p {
                                    font-size: 3.3vw;
                                }
                                svg {
                                    width: 3.6vw;
                                    height: 3.6vw;
                                }
                            }
                            .title {
                                font-size: 6.6vw;
                            }
                            .landing-text {
                                max-width: 100%;
                                font-size: 3.3vw;
                            }
                        }
                    }
                    .landing-slider-info-buttons {
                        gap: 3vw;
                        height: 8.4vw;
                        .button {
                            padding: 1.65vw;
                            p {
                                font-size: 3.3vw;
                            }
                        }
                    }
                }
            }
        }
    }
}