@use '/src/styles/variables' as styles;

.hierarchy {
    width: 100%;
    padding-inline: 0;
    font-family: styles.$fontMain;

    .hierarchy-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 70vh;
        margin-left: auto;
        margin-right: auto;
        overflow-x: scroll;
    }

    ::-webkit-scrollbar {
        height: 0.69vw;
        width: 0.69vw;
    }

    ::-webkit-scrollbar-track {
        border-radius: styles.$roundedLg;
        background: #D9D9D9;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: styles.$roundedLg;
        background: #949494;
        border: none;
    }
}