@use '/src/styles/variables' as styles;
.extension-banner {
  width: 100%;
  box-shadow: 0px 0px 0.69vw -1px rgba(0, 0, 0, 0.25);
  border-radius: 1vw 1vw 0vw 0vw;
  padding: 0.78vw 1.17vw;
  background: var(--backgroundMain);
  transition: all ease 0.3s;
  overflow: hidden;

  .extension-banner-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--accentTertiary);
    border-radius: styles.$roundedLg;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .extension-banner-close-icon {
      min-width: 1vw;
      height: 100%;
      display: flex;
      padding-right: 1vw;
      align-items: center;

      svg {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    

    .extension-banner-items-container{
      display: flex;
      flex-direction: column;
      gap: 1vh;
      width: calc(100% - 4.9vw);
      height: 100%;
      padding: 1vw 1.24vw;
  
      .extension-banner-sender-info {
        width: 100%;

        .extension-banner-sender {
          font-size: styles.$textMd;
          font-weight: styles.$fontBold;
          line-height: 1.56vw;
          color: var(--text1);
          width: 100%;
        }
  
        .extension-banner-sender-message {
          font-size: styles.$textMd;
          font-weight: styles.$fontLight;
          line-height: 1.56vw;
          color: var(--text1);
          width: 100%;
        }
      }
  
      .extension-banner-files-container{
        display: flex;
        align-items: flex-start;
        gap: 2.2vw;
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        padding-bottom: 0.5vw;
  
        &::-webkit-scrollbar {
          background: transparent;
          width: 0.69vw;
          height: 0.7vh;
        }
        &::-webkit-scrollbar-thumb {
            background: #949494;
            border-radius: styles.$roundedXl;
            border: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .extension-banner {
    box-shadow: 0px 0px 1.05vw -1px rgba(0, 0, 0, 0.25);
    border-radius: 1.5vw 1.5vw 0vw 0vw;
    padding: 1.05vw 1.8vw;
  
    .extension-banner-container {
      border-radius: 1.5vw;

      .extension-banner-close-icon {
        min-width: 1.5vw;
        padding-right: 1.5vw;
  
        svg {
          width: 1.5vw;
          height: 1.5vw;
        }
      }
      
      .extension-banner-items-container{
        gap: 1vh;
        width: calc(100% - 7.35vw);
        height: 100%;
        padding: 1.5vw 1.86vw;
    
        .extension-banner-sender-info {  
          .extension-banner-sender {
            font-size: 1.66vw;
            line-height: 2.34vw;
          }
    
          .extension-banner-sender-message {
            font-size: 1.66vw;
            line-height: 2.34vw;
          }
        }
    
        .extension-banner-files-container{
          gap: 3.3vw;
          padding-bottom: 0.75vw;
    
          &::-webkit-scrollbar {
            width: 1.05vw;
            height: 1.05vh;
          }
          &::-webkit-scrollbar-thumb {
              border-radius: 1.5vw;
          }
        }
      }
    }
  }
}