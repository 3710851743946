.file-preview-modal-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 80vh;
    width: fit-content;
    min-width: 60vw;
    max-width: 70vw;


    .file-preview-modal-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
