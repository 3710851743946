@use '/src/styles/variables' as styles;
.audio-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93.2%;
  height: 4.44vw;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
  border-radius: styles.$roundedLg;
  transition: all ease 0.3s;
  background-color: var(--backgroundMain);
  overflow: hidden;
}

@media screen and (max-width: 768px) {

  .audio-container{
    height: 6.66vw;
    box-shadow: 0px 0px 1.05vw -1px rgba(0, 0, 0, 0.25);
    border-radius: 1.5vw;
  }
  

}

@media screen and (max-width: 425px) {

  .audio-container{
    height: 13.32vw;
    box-shadow: 0px 0px 2.1vw -1px rgba(0, 0, 0, 0.25);
    border-radius: 3vw;
  }
  

}