@use '/src/styles/variables' as styles;

.dashboard-card-container {

	border-radius: .25vw;
	box-shadow: 0.1vw 0.1vw .5vw 0vw rgba(0, 0, 0, 0.172);
	cursor: pointer;
	padding: 1vw .7vw;

	font-size: styles.$textMd;
	display: flex;
	flex-direction: column;
	gap: 1vw;
	position: relative;

	img, svg{
		height: 1.5vw;
		min-width: 1.5vw;
		max-width: 1.5vw;
	}

	.icon{
		display: flex;
		align-items: center;
	}

	.dashboard-card-header{
		display: flex;
		align-items: center;
		gap: .5vw;	

		.avatar-container{
			width: min-content;
		}

		h2{
			font-weight: 550;
			font-size: styles.$textMd;
			color: var(--text1);
		}
		
	}
	.dashboard-card-body{
		display: flex;
		align-items: center;
		gap: .5vw;
		
		p{
			overflow: visible;
			white-space: pre-wrap;
			word-break: break-word;
			font-size: styles.$text2sm;
			text-wrap: wrap;
			max-width: 20vw;
		}
	}

	.dashboard-labels-container{
        display: flex;
        flex-direction: row;
		flex-wrap: wrap;
        gap:0.2vw;
        text-transform: capitalize;
		width: 100%;
		.label{
			padding: .25vw 0.5vw;
			margin:  0;
			width: 100%;
			p{
				font-size: styles.$textXs;
			}

		} 
    }

	.dashboard-budget{
		font-size: styles.$textLg;
		color: var(--text1);
	}
	
	.dashboard-card-footer{
		display: flex;
		justify-content: space-between;
		.date-container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: .2vw;
			font-size: styles.$textXs;
			
			.dates-data{
				display: flex;
				align-items: center;
				gap: 0.5vw;

				.date{
					display: flex;
					flex-direction: column;
				}
			}			

			&.isDue{

				.due-date, .days-left{
					color: var(--colorAlertText);
				}

				.days-left{
					margin-left: 0.2vw;
				}
				
				svg{
					path{
						fill: var(--colorAlertText);
					}
				}
			}
		}
		
	}

	.table {
		.table-body-row td{
			padding: 0.3vw 0.7vw;

			.dashboard-card-warehouse-action-item-container{
				display: flex;
				align-items: center;
				gap: 0.5vw;
				 
				p {
					word-break: break-all;
					white-space: break-spaces;
				}

				.avatar-container{
					width: 1.5vw;
					height: 1.5vw;
				}
			}
		}
		
		.table-head-row th{
			padding: 0.3vw 0.7vw;
		}
	}
}
  

@media screen and (max-width: 768px) {

	.dashboard-card-container {

		border-radius: .375vw;
		box-shadow: 0.15vw 0.15vw .75vw 0vw rgba(0, 0, 0, 0.172);
		padding: 1.5vw 1.05vw;
	
		font-size: 1.665vw;
		gap: 1.5vw;
	
		img, svg{
			height: 2.25vw;
			min-width: 2.25vw;
			max-width: 2.25vw;
		}
	
		.dashboard-card-header{
			gap: .75vw;		

			h2{
				font-size: 1.665vw;
			}
		}
		.dashboard-card-body{
			gap: .75vw;
			
			p{
				font-size: 1.2vw;
			}
		}
	
		.dashboard-labels-container{
			gap:0.3vw;
			display: flex;
			flex-direction: column;
			.label{
				padding: .375vw 0.75vw;
				p{
					font-size: 1.05vw;
				}
	
			} 
		}
	
		.dashboard-budget{
			font-size: 1.875vw;
		}
		
		.dashboard-card-footer{
			.date-container{
				gap: .3vw;
				font-size: 1.05vw;

	
				&.isDue{
					.days-left{
						margin-left: 0.3vw;
					}
					
				}
			}
			
		}
	}
	  

}


@media screen and (max-width: 425px) {

	.dashboard-card-container {

		border-radius: .75vw;
		box-shadow: 0.3vw 0.3vw 1.5vw 0vw rgba(0, 0, 0, 0.172);
		padding: 3vw 2.1vw;
	
		font-size: 3.32vw;
		gap: 3vw;
	
		img, svg{
			height: 4.5vw;
			min-width: 4.5vw;
			max-width: 4.5vw;
		}
	
		.dashboard-card-header{
			gap: 1.5vw;		

			h2{
				font-size: 3.32vw;
			}
		}
		.dashboard-card-body{
			gap: 1.5vw;
			
			p{
				font-size: 2.4vw;
			}
		}
	
		.dashboard-labels-container{
			gap:0.6vw;
			.label{
				padding: .75vw 1.5vw;
				p{
					font-size: 2.1cvw;
				}
	
			} 
		}
	
		.dashboard-budget{
			font-size: 3.75vw;
		}
		
		.dashboard-card-footer{
			.date-container{
				gap: .6vw;
				font-size: 2.1vw;

	
				&.isDue{
					.days-left{
						margin-left: 0.6vw;
					}
					
				}
			}
			
		}
	}
	  

}