.navbar-burger-content-item-wrapper{
    display: flex;
    flex-direction: column;

    .navbar-burger-content-item-header{
        display: flex;
        align-items: center;
        gap: 0.5vw;
        cursor: pointer;
        transition: all ease 0.4s;

        &.open{
            background: #F8F9FB;
            border-radius: 0.5vw;
            padding: 0.3vw;
        }

        .navbar-burger-content-item-title{
            color: var(--accentPrimary);
            text-transform: capitalize;
            font-size: 1.66vw;
            font-weight: 700;
        }


        .navbar-link-arrow{
            transition: all ease 0.4s;
            
            svg{
                path{
                    stroke: var(--accentPrimary);
                }

                width: .45vw;
                display: flex;
                align-items: center;
                transition: 0.4s ease-out all;

            }

            &.open{
                transform: rotate(90deg);
            }
        }
    }

    .navbar-burger-content-item-container{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 0;
        transition-property: max-height, padding-top, padding-bottom; 
        transition: 0.4s ease-out;
        gap: 2vw;

        &.open{
            max-height: 200vh;
            padding: 1vw 2vw;
        }

        .navbar-burger-content-item-section{
            display: flex;
            flex-direction: column;
            transition: all ease 0.4s;

            &.open{
                background: #F8F9FB;
                border-radius: 0.5vw;
                padding: 0.3vw;

                .navbar-link-dropdown-icon-container{
                    border: none;
                }
            }

            .navbar-burger-content-item-section-header{
                display: flex;
                gap: 1vw;
                align-items: center;
                cursor: pointer;

                .navbar-burger-content-item-section-icon{
                    min-width: 3.5vw;
                    height: 3.5vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #E8EBEE;
                    border-radius: 0.5vw;
                    background-color: #fff;
                    transition: 0.4s ease;

                    svg {
                        width: 1.5vw;
                        fill: #7F7F7F;
                    }                    
                }

                .navbar-burger-content-item-section-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    
                    .navbar-burger-content-item-section-title {
                        font-size: 1.11vw;
                        color: var(--accentPrimary);
                        font-weight: 700;
                    }

                    .navbar-burger-content-item-section-subtitle {
                        font-size: 0.97vw;
                        color: var(--accentPrimary);
                        font-weight: 500;
                    }
                }
            }

            .navbar-burger-content-item-section-content{
                display: flex;
                flex-direction: column;
                gap: 0.5vw;
                max-height: 0;
                transition: all ease 0.4s;
                overflow: hidden;
                border-radius: 0.5vw;
                background: #F8F9FB;

                &.open{
                    max-height: 200vw;
                }

                a, p {
                    padding: 1vw;
                    font-size: 1.11vw;
                    color: var(--accentPrimary);
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .navbar-burger-content-item-wrapper {
        .navbar-burger-content-item-header {
            gap: 0.75vw;
    
            &.open {
                border-radius: 0.75vw;
                padding: 0.45vw;
            }
    
            .navbar-burger-content-item-title {
                font-size: 2.49vw;
            }
    
            .navbar-link-arrow {
                svg {
                    width: 0.675vw;
                }
            }
        }
    
        .navbar-burger-content-item-container {
            gap: 3vw;
    
            &.open {
                padding: 1.5vw 3vw;
            }
    
            .navbar-burger-content-item-section {
                &.open {
                    border-radius: 0.75vw;
                    padding: 0.45vw;
                }
    
                .navbar-burger-content-item-section-header {
                    gap: 1.5vw;
    
                    .navbar-burger-content-item-section-icon {
                        min-width: 5.25vw;
                        height: 5.25vw;
                        border-radius: 0.75vw;
    
                        svg {
                            width: 2.25vw;
                        }
                    }
    
                    .navbar-burger-content-item-section-text {
                        .navbar-burger-content-item-section-title {
                            font-size: 1.665vw;
                        }
    
                        .navbar-burger-content-item-section-subtitle {
                            font-size: 1.455vw;
                        }
                    }
                }
    
                .navbar-burger-content-item-section-content {
                    gap: 0.75vw;
    
                    &.open {
                        max-height: 300vw;
                    }
    
                    a, p {
                        padding: 1.5vw;
                        font-size: 1.665vw;
                    }
                }
            }
        }
    }
    

}

@media (max-width: 425px) {
    .navbar-burger-content-item-wrapper {
        .navbar-burger-content-item-header {
            gap: 1.5vw;
    
            &.open {
                border-radius: 1.5vw;
                padding: 0.9vw;
            }
    
            .navbar-burger-content-item-title {
                font-size: 4.98vw;
            }
    
            .navbar-link-arrow {
                svg {
                    width: 1.35vw;
                }
            }
        }
    
        .navbar-burger-content-item-container {
            gap: 6vw;
    
            &.open {
                padding: 3vw 6vw;
            }
    
            .navbar-burger-content-item-section {
                &.open {
                    border-radius: 1.5vw;
                    padding: 0.9vw;
                }
    
                .navbar-burger-content-item-section-header {
                    gap: 3vw;
    
                    .navbar-burger-content-item-section-icon {
                        min-width: 10.5vw;
                        height: 10.5vw;
                        border-radius: 1.5vw;
    
                        svg {
                            width: 4.5vw;
                        }
                    }
    
                    .navbar-burger-content-item-section-text {
                        .navbar-burger-content-item-section-title {
                            font-size: 3.33vw;
                        }
    
                        .navbar-burger-content-item-section-subtitle {
                            font-size: 2.91vw;
                        }
                    }
                }
    
                .navbar-burger-content-item-section-content {
                    gap: 1.5vw;
    
                    &.open {
                        max-height: 600vw;
                    }
    
                    a, p {
                        padding: 3vw;
                        font-size: 3.33vw;
                    }
                }
            }
        }
    }
    
}