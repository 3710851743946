.task-details{
    width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1vw;

	.task-details-actions{
		width: 90%;
		display: flex;
		gap: 1vw;
	}
}