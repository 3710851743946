
.avatar-picker {
	display: flex;
	position: relative;
	align-items: center;

	&-avatar {
		width: 9.08vw;
		height: 9.08vw;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		.avatar {
			width: 100%;
			height: 100%;
			border-radius: 100%;
			background: transparent;
			border: 1px solid var(--accentPrimary);
			overflow: hidden;

			svg{
				width: 100%;
				height: 100%;
			}
		}

		button {
			position: absolute;
			bottom: 0.6vw;
			right: 0.6vw;
			background-color: var(--accentPrimary);
			border: none;
			min-width: 1.3vw;
			min-height: 1.3vw;
			max-width: 1.3vw;
			max-height: 1.3vw;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			svg {
				width: 60%;
				height: 60%;
			}
		}
	}


}

