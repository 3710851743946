@use '/src/styles/variables' as styles;

.label {
    padding: 0.35vw 0.7vw;
    max-width: 15vw;
    border-radius: 0.35vw;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
        width: 100%;
        font-size: var(--textSm);
        
    }
}

@media screen and (max-width: 768px) {

    .label {
        padding: 0.53vw 1.05vw;
        max-width: 22vw;
        border-radius: 0.53vw;
        
        p {
            font-size: 1.45vw;
        }
    }    

}

@media screen and (max-width: 425px) {

    .label {
        padding: 1.05vw 2.1vw;
        max-width: 14vw;
        border-radius: 1.05vw;
        
        p {
            font-size: 2.9vw;
        }
    }    

}