.hierarchy-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 95%;
    overflow-y: scroll;
    min-width: 24.7vw;
	padding: 1vw 0.5vw;

	.counterparty-skeleton { 
		height: 6vw;
	}

    .hierarchy-hire-worker-button {
        margin-top: 2vw;
        cursor: pointer;

        svg {
            width: 1.7vw;
        }
    }
	gap: 1vw;

	.counterparty{
		width: 100%;
		height: 100%;
		min-height: 6vw;
	}

	.counterparty-container{
		display: flex;
		width: 100%;
		transition: all ease 0.4s;

		&.counterparty-active{
			.counterparty{
				border: 1px solid var(--accentPrimary);
			}
		}

		&.fired {
			.counterparty{
				border: 1px solid var(--colorAlert);
			}

		}
	}
}

@media screen and (max-width: 768px) {

	.hierarchy-column {
		padding: 0 1.2vw;
		min-width: 37.05vw;
		padding: 1.5vw 0.75vw;
		.hierarchy-hire-worker-button {
			margin-top: 3vw;
	
			svg {
				width: 2.55vw;
			}
		}
		gap: 1.5vw;
	
		.counterparty{
			min-height: 9vw;
		}
	}

}

@media screen and (max-width: 425px) {

	.hierarchy-column {
		padding: 0 2.4vw;
		min-width: 74.1vw;
		padding: 3vw 1.5vw;
		.hierarchy-hire-worker-button {
			margin-top: 6vw;
	
			svg {
				width: 5.1vw;
			}
		}
		gap: 3vw;
	
		.counterparty{
			min-height: 18vw;
		}
	}

}