@use '/src/styles/variables' as styles;

.copanies-list-container{
    padding: 1vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2vw;
    grid-column-gap: 4.5vw;
    height: 100%;
    margin-top: 2.17vw;
    overflow-y: auto;

    .add-company-button-container{
        width: 17.36vw;
        height: 5.97vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;


        svg{
            display: block;
            cursor: pointer;
            width: 3.47vw;
        }
    }

    .company-card{
        width: 17.36vw;
        height: 5.97vw;
        padding: 1.25vw 1.7vw;
        display: flex;
        align-items: center;
        transition: ease 0.3s;
        border-radius: 1vw;
        box-shadow: 0.34vw 0.28vw 0.7vw rgba(0, 0, 0, 0.15);

        .company-avatar{
            border-radius: 50%;
            width: 2.78vw;
            height: 2.78vw;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all ease 0.3s;

            img, svg{
                height: 100%;
                border-radius: 50%;
            }
        }

        .company-data{
            margin-left: 3.47vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;

            .company-name{
                font-size: styles.$textMd;
                font-weight: styles.$fontMedium;
                width: 100%;
            }

            .company-description{
                font-size: styles.$textMd;
                font-style: italic;
            }
        }

        &:hover{
            background: var(--accentPrimary);
            box-shadow: none;
            color: #fff;
            cursor: pointer;
            .company-avatar{
                background: #fff;
                border: 1px solid #fff;
            }
        }
    }

}

@media screen and (max-width: 768px) {

    .copanies-list-container{
        padding: 1.5vw;
        grid-row-gap: 3vw;
        grid-column-gap: 7vw;
        margin-top: 3.25vw;
        grid-template-columns: 1fr;

        .add-company-button-container{
            width: 26vw;
            height: 9vw;
    
            svg{
                width: 5.25vw;
            }
        }
    
        .company-card{
            width: 26vw;
            height: 9vw;
            padding: 1.875vw 2.55vw;
            border-radius: 1.5vw;
            box-shadow: 0.5vw 0.45vw 1.05vw rgba(0, 0, 0, 0.15);
    
            .company-avatar{
                width: 4.17vw;
                height: 4.17vw;
            }
    
            .company-data{
                margin-left: 5.2vw;

                .company-name{
                    font-size: 1.66vw;
                }
    
                .company-description{
                    font-size: 1.66vw;
                }
            }
        }
    
    }    

}

@media screen and (max-width: 425px) {

    .copanies-list-container{
        padding: 3vw;
        grid-row-gap: 6vw;
        grid-column-gap: 14vw;
        margin-top: 6.5vw;
    
        .add-company-button-container{
            width: 52vw;
            height: 18vw;
    
            svg{
                width: 10.5vw;
            }
        }
    
        .company-card{
            width: 52vw;
            height: 18vw;
            padding: 3.75vw 5vw;
            border-radius: 3vw;
            box-shadow: 1vw 0.9vw 2.1vw rgba(0, 0, 0, 0.15);
    
            .company-avatar{
                width: 8.3vw;
                height: 8.3vw;
            }
    
            .company-data{
                margin-left: 10.4vw;

                .company-name{
                    font-size: 3.32vw;
                }
    
                .company-description{
                    font-size: 3.32vw;
                }
            }
        }
    
    }    

}