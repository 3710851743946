.text-underline-container{
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
    width: fit-content;

    .text-underline{
        width: 0;
        height: 0.07vw;
        transition: all ease 0.3s;
    }

    &:hover{
        .text-underline{
            width: 100%;
        }
    }
}