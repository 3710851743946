.layout-time-tracker{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    bottom: 2vw;
    right: 2vw;

    .layout-time-tracker-list{
        position: absolute;
        bottom: 3vw;
        padding: 0;
        background-color: var(--backgroundMain);
        height: 0;
        transition: all ease 0.4s;
        overflow: scroll;
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        gap: 2vw;

        p{
            font-size: 1.11vw;
        }

        .time-tracker-item{
            width: 40vw;

            .time-tracker-item-content{
                width: 100%;
                flex-direction: column;
    
                .time-tracker-details{
                    flex-direction: column;
                    width: 100%;
                }
            }
        }

        &.tracker-list-open{
            padding: 1.8vw;
            height: 70vh;
            box-shadow: 0.15vw 0.15vw 0.7vw 0.7vw rgba(0, 0, 0, 0.048);
        }
    }

    .layout-time-tracker-button{
        border-radius: 50%;
        background: var(--accentPrimary);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0.6vw;

        svg {
            width: 1.5vw;
            height: 1.5vw;
            fill: #fff;
        }
    }
}