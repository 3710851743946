.create-warehouse{
    width: 35vw;
	padding: 0.7vw;
    display: flex;
    flex-direction: column;
    gap: 1.875vw;

    .warehouse-location-selector{
        width: 100%;
    }

    textarea {
        background-color: transparent;
    }
}