@use '../../../../../../styles/variables' as styles;

.create-transaction-container {
    padding: 1vw 1vw 3vw 1vw;

    .form-elements-wrapper {
        width: 56vw;
        height: 100%;
        display: grid;
        grid-template-columns: 22vw 22vw;
        justify-content: center;
        align-items: center;
        gap: 1.3vw 4.6vw;
        
        .textarea-label {
            padding-bottom: 0.7vw;
        }

        .textarea-container {
            width: 22vw;
        }

    }
}