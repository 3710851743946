@use '/src/styles/variables' as styles;

.input-range{
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1vw;

	&.small{
		gap: 0.5vw;

		.input-range-title{
			font-size: styles.$textSm;	
		}

		.input-range-slider{
			width: 100%;
			background: var(--accentDisabled);
			border-radius: 0.15vw;
			height: .15vw;
			position: relative;
			cursor: pointer;
	
			&-progress{
				height: 100%;
				width: 100%;
				background: var(--accentPrimary);
				position: absolute;
			}
	
			&-ball{
				width: 0.5vw;
				height: 0.5vw;
				border-radius: 50%;
				background: var(--accentPrimary);
				position: absolute;
				top: -100%;
			}
		}

		.input-range-values{
			label{
				font-size: styles.$textSm;	
			}
			.input-field-wrapper{
				width: 100%;
				gap: 0.87vw;
				padding: 0.33vw 0.75vw;
				border-radius: 0.25vw;
				box-shadow: 1px 2px 0.35vw rgba(0, 0, 0, 0.1);
			}
	
		}
	}

	&-title{
		font-size: styles.$textLg;	
	}

	&-values{
		display: flex;
		justify-content: space-between;
		
		&-container{
			width: 40%;

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button{
				appearance: none;
				-webkit-appearance: none;
			}
		}

	}

	&-slider{
		width: 100%;
		background: var(--accentDisabled);
		border-radius: 0.35vw;
		height: .3vw;
		position: relative;
		cursor: pointer;

		&-progress{
			height: 100%;
			width: 100%;
			background: var(--accentPrimary);
			position: absolute;
		}

		&-ball{
			width: 1vw;
			height: 1vw;
			border-radius: 50%;
			background: var(--accentPrimary);
			position: absolute;
			top: -100%;
		}
	}
}

@media screen and (max-width: 768px) {

	.input-range{
		gap: 1.5vw;
	
		&.small{
			gap: 0.75vw;
	
			.input-range-title{
				font-size: 1.45vw;	
			}
	
			.input-range-slider{
				border-radius: 0.22vw;
				height: .22vw;
		
				&-ball{
					width: 0.75vw;
					height: 0.75vw;
				}
			}
	
			.input-range-values{
				label{
					font-size: 1.45vw;	
				}
				.input-field-wrapper{
					width: 100%;
					gap: 1.3vw;
					padding: 0.5vw 1.12vw;
					border-radius: 0.375vw;
					box-shadow: 1px 2px 0.525vw rgba(0, 0, 0, 0.1);

				}
		
			}
		}
	
		&-title{
			font-size: 1.875vw;	
		}
	
		&-slider{
			border-radius: 0.525vw;
			height: .45vw;
	
	
			&-ball{
				width: 1.5vw;
				height: 1.5vw;
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.input-range{
		gap: 3vw;
	
		&.small{
			gap: 1.5vw;
	
			.input-range-title{
				font-size: 2.9vw;	
			}
	
			.input-range-slider{
				border-radius: 0.44vw;
				height: .44vw;
		
				&-ball{
					width: 1.5vw;
					height: 1.5vw;
				}
			}
	
			.input-range-values{
				label{
					font-size: 2.9vw;	
				}
				.input-field-wrapper{
					width: 100%;
					gap: 2.6vw;
					padding: 1vw 2.24vw;
					border-radius: 0.75vw;
					box-shadow: 1px 2px 1.5vw rgba(0, 0, 0, 0.1);
	
				}
		
			}
		}
	
		&-title{
			font-size: 375vw;	
		}
	
		&-slider{
			border-radius: 1.5vw;
			height: .9vw;
	
	
			&-ball{
				width: 3vw;
				height: 3vw;
			}
		}
	}

}