.radio{
	.radio-input {
		display: none;
	}

	.radio-label {
		display: flex;
	
		justify-content: center;
		align-items: center;
		cursor: pointer;
	
		.radio-box {
			width: 1.05vw;
			height: 1.05vw;
			border-radius: 0.35vw;
			border: 0.14vw solid #444; 
			cursor: pointer;
		
			&.rounded{
				border-radius: 1vw;
				.radio-inner{
					border-radius: 1vw;
				}
			}
		}
	
		.radio-inner {
			width: 100%;
			height: 100%;
			border-radius: 0.35vw;
			background-color: #444; 
			opacity: 0;
			scale: .6;
			&.checked{
				opacity: 1;
			}
		}
		span {
			margin-left: 0.55vw;
			font-size: .8vw;
		}
	}
	
}

@media screen and (max-width: 768px) {

	.radio{
		
		.radio-label {
			.radio-box {
				width: 1.5vw;
				height: 1.5vw;
				border-radius: 0.53vw;
				border: 0.21vw solid #444; 
			
				&.rounded{
					border-radius: 1.5vw;
					.radio-inner{
						border-radius: 1.5vw;
					}
				}
			}
		
			.radio-inner {
				border-radius: 0.53vw;
			}
			span {
				margin-left: 0.75vw;
				font-size: 1.2vw;
			}
		}
		
	}

}

@media screen and (max-width: 425px) {

	.radio{
		
		.radio-label {
			.radio-box {
				width: 3vw;
				height: 3vw;
				border-radius: 1.05vw;
				border: 0.4vw solid #444; 
			
				&.rounded{
					border-radius: 3vw;
					.radio-inner{
						border-radius: 3vw;
					}
				}
			}
		
			.radio-inner {
				border-radius: 1.05vw;
			}
			span {
				margin-left: 1.5vw;
				font-size: 2.4vw;
			}
		}
		
	}

}