@use '/src/styles/variables' as styles;

.pinned-message-card-container{
  height: fit-content;
  width: 100%;
  padding: 1.39vw 2vw;
  display: flex;
  justify-content: left;
  align-items: center;
  transition: all ease 0.4s;
  gap: 2vw;

  .chat-card-container {
    display: flex;
    align-items: flex-start;
    gap: 2vw;

    .chat-card-avatar-container {
      width: 4.16vw;
      height: 4.16vw;
      display: flex;
      justify-content: center;
      align-items: center;
      svg, img{
        width: 4.16vw;
        height: 4.16vw;
      }
    }
    
    .chat-card-information-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      min-height: 4vw;      
      
      .chat-card-name{
        width: 100%;
        font-size: styles.$textMd;
        line-height: 1.64vw;
        color: var(--text1);
        font-weight: styles.$fontMedium;
      }

      .chat-card-files-container{
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        width: 100%;
        gap: 0.69vw;
        
        .video{
          width: 100%;
          height: auto;
        }
      
        .audio{
            width: 100%;
            height: 5vw;
        }
      
        .img{
          width: 100%;
          height: auto;
      
          img{
              width: 100%;
          }
        }
      
        .document{
          padding: 1vw;
          gap: 0.6vw;
          max-width: 7vw;
          
          .file-list-modal-files-name{
              color: #d6dcff;
              font-size: styles.$textSm;
              text-align: center;
              width: 100%;
          }
      }
          
      }

      .chat-card-message-container{
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: baseline;
        gap: 0.69vw;

        .message{
          font-size: styles.$textSm;
          line-height: 1.45vw;
          width: 100%;
          max-width: 16vw;
        }
        .anymore-files-container{
          display: flex;
          justify-content: center;
          width: 12%;
          svg{
            width: 100%;
          }
          :last-child{
            border-radius: 0.1vw;
            margin-left: -0.4vw;
            background-color: var(--backgroundMain);
          }
        }

        svg{
          min-width: 0.83vw;
          height: 0.83vw;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  
  .pinned-message-card-container{
    padding: 2.1vw 3vw;
    gap: 3vw;
  
    .chat-card-container {
      gap: 3vw;
  
      .chat-card-avatar-container {
        width: 6.24vw;
        height: 6.24vw;
        
        svg, img{
          width: 6.24vw;
          height: 6.24vw;
        }
      }
      
      .chat-card-information-container {
        min-height: 6vw;      
        
        .chat-card-name{
          font-size: 1.66vw;
          line-height: 2.46vw;
        }
  
        .chat-card-files-container{
          gap: 1.05vw;
        
          .audio{
              height: 7.5vw;
          }
        
          .document{
            padding: 1.5vw;
            gap: 0.9vw;
            max-width: 1.05vw;
            
            .file-list-modal-files-name{
                font-size: 1.45vw;
            }
        }
            
        }
  
        .chat-card-message-container{
          gap: 1.05vw;
  
          .message{
            font-size: 1.45vw;
            line-height: 2.18vw;
            max-width: 24vw;
          }
          .anymore-files-container{
            :last-child{
              border-radius: 0.15vw;
              margin-left: -0.6vw;
            }
          }
  
          svg{
            min-width: 1.25vw;
            height: 1.25vw;
          }
        }
      }
    }
  }

}


@media screen and (max-width: 425px) {
  
  .pinned-message-card-container{
    padding: 4.2vw 6vw;
    gap: 6vw;
  
    .chat-card-container {
      gap: 6vw;
  
      .chat-card-avatar-container {
        width: 12.5vw;
        height: 12.5vw;
        
        svg, img{
          width: 12.5vw;
          height: 12.5vw;
        }
      }
      
      .chat-card-information-container {
        min-height: 12vw;      
        
        .chat-card-name{
          font-size: 3.32vw;
          line-height: 5vw;
        }
  
        .chat-card-files-container{
          gap: 2.1vw;
        
          .audio{
            height: 15vw;
          }
        
          .document{
            padding: 3vw;
            gap: 1.8vw;
            max-width: 2.1vw;
            
            .file-list-modal-files-name{
                font-size: 2.9vw;
            }
        }
            
        }
  
        .chat-card-message-container{
          gap: 2.1vw;
  
          .message{
            font-size: 2.9vw;
            line-height: 4.36vw;
            max-width: 48vw;
          }
          .anymore-files-container{
            :last-child{
              border-radius: 0.3vw;
              margin-left: -1.2vw;
            }
          }
  
          svg{
            min-width: 2.5vw;
            height: 2.5vw;
          }
        }
      }
    }
  }

}