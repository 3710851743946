.list-container{
    width: 100%;
    height: 100%;
    padding: 1vw;
}

@media screen and (max-width: 768px) {

    .list-container{
        padding: 1.5vw;
    }
}

@media screen and (max-width: 425px) {

    .list-container{
        padding: 3vw;
    }
}