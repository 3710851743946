.move-employee-container{
    padding: 0 3.82vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .move-employee-search-container{   
        width: 32.63vw;
        margin-left: auto;
        margin-right: auto;
    }

    .move-employee-new-leaders-list{
        gap: 1vw;
        width: 40vw;
        height: 20vw;
        margin-top: 2vw;
        padding: 2vw 1vw 1vw 1vw;
    }
}