.functions-checkbox-container{
    display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 1vw;
}

@media screen and (max-width: 768px) {
    .functions-checkbox-container{
        margin-top: 5.7vw;
        grid-column-gap: 20.85vw;
        grid-row-gap: 4.2vw;
    }
}