@use '/src/styles/variables' as styles;
@use '/src/styles/mixin' as utils;

.footer {
	padding: 5.5vw 3vw 3vw 3vw;
	background: #1E1B39;
	overflow: hidden;
	flex: 1;
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
	gap: 5.5vw;

	.footer-text-container{
		display: flex;
		flex-direction: column;
		justify-content: left;
		gap: 1vw;
		width: 21.2vw;

		.footer-text-title{
			font-size: 1.9vw;
			color: #fff;
			font-weight: 700;
		}

		.footer-text-details{
			font-size: styles.$textSm;
			color: #FFFFFF;
			margin-bottom: 2vw;
		}

		.footer-text-copyRight{
			font-size: styles.$text2sm;
			color: #9792C9;
		}

		.footer-logo-link{
			width: 70%;
		}
	
		.footer-logo {
			width: 100%;
			svg {
				width: 3vw;
				height: 3vw
			}
		}
	}

	.footer-links-container{
		display: flex;
		justify-content: center;
		width: fit-content;
		gap: 2.2vw;	
	
		.footer-links-content-container{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: fit-content;
			gap: 1.3vw;
	
			.footer-links-content-title{
				font-size: 1vw;
				font-weight: 500;
				color: #9792C9;
				text-transform: uppercase;
			}
	
			a{
				font-size: 1.1vw;
				color: #fff;
				display: flex;
				align-items: center;
				padding-bottom: 0.2vw;
				svg{
					margin-right: 0.5vw;
					min-width: styles.$textLg;
					max-width: styles.$textLg;
					fill: #9792C9;
					path{
						// fill: #9792C9;
						stroke: #9792C9;
					}
				}
			}
		}
	}

	.footer-social-media-container{
		display: flex;
		flex-direction: column;
		gap: 2vw;
		width: 23.6vw;

		.footer-sign-up-container{
			position: relative;
			overflow: hidden;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 7.6vw;
			gap:1vw;
			border-radius: 0.5vw;
			border: 1px solid #9792C9;
			background-color: #fff;
			max-width: 100%;
			padding: 1vw;

			.button{
				width: 50%;
				p{
					text-wrap: wrap;
				}
			}
	
			.footer-text-details{
				font-size: styles.$textSm;
				font-weight: 500;
				color: #1E1B39;
			}

			
		}

		.footer-text-social-media{
			font-size: styles.$textSm;
			color: #9792C9;
		}

		a{
			font-size: 1.11vw;
			display: flex;
			gap: 0.5vw;
			align-items: center;
			font-weight: 500;
			color: #fff;
			width: -moz-fit-content;
			width: fit-content;
			cursor: pointer;
		}

		.footer-links-icons-container{
			display: flex;
			justify-content: left;
			gap: 1vw;

			.footer-icon-container{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 3vw;
				height: 3vw;

				border-radius: 0.5vw;
				background-color: #2E295A;

				svg{
					width: styles.$textXl;
					path{
						fill: #fff;
					}
				}

			}
		}
	}

	h1, h2, p {
		color: #fff;
		text-align: left;
	}
}

@media (max-width: 768px) {
	.footer {
		flex-direction: column;
		align-items: center;
		.footer-text-container{
			width: 80vw;
			.footer-text-title{
				font-size: 4.95vw;
			}
			.footer-text-details{
				font-size: 1.6vw;
			}
			.footer-text-copyRight{
				font-size: 1.4vw;
				color: #9792C9;
			}
			.footer-logo {
				svg {
					width: 5vw;
					height: 5vw
				}
			}
		}
		.footer-links-container{
			flex-wrap: wrap;
			justify-content: left;
			gap: 3vw;
			width: 80vw;
			.footer-links-content-container{
				.footer-links-content-title{
					font-size: 1.5vw;
				}
				a{
					font-size: 1.6vw;
					svg{
						margin-right: 0.7vw;
						min-width: 1.9vw;
						max-width: 1.9vw;
					}
				}
			}
		}
		.footer-social-media-container{
			width: 80vw;
			.footer-sign-up-container{
				border-radius: 1.3vw;
				height: fit-content;
				width: fit-content;

				.footer-text-details{
					width: 25vw;
					font-size: 1.6vw;
				}
			}

			.footer-text-social-media{
				font-size: 1.6vw;
				color: #9792C9;
			}

			a{
				font-size: 1.6vw;
			}
			.footer-links-icons-container{
				.footer-icon-container{
					border-radius: 1.3vw;
					width: 4.5vw;
					height: 4.5vw;
					svg{
						width: 2vw;
					}
				}

			}
		}
	}
}

@media (max-width: 425px) {
	.footer {
		flex-direction: column;
		align-items: center;
		.footer-text-container{
			width: 90vw;
			.footer-text-title{
				font-size: 9vw;
			}
			.footer-text-details{
				font-size: 3.3vw;
			}
			.footer-text-copyRight{
				font-size: 3.3vw;
				color: #9792C9;
			}
			.footer-logo {
				svg {
					width: 9vw;
					height: 9vw
				}
			}
		}
		.footer-links-container{
			flex-wrap: wrap;
			justify-content: left;
			width: 90vw;
			gap: 6.6vw;
			.footer-links-content-container{
				.footer-links-content-title{
					font-size: 3.2vw;
				}
				a{
					font-size: 3.2vw;
					svg{
						margin-right: 1vw;
						min-width: 3.8vw;
						max-width: 3.8vw;
					}
				}
			}
		}
		.footer-social-media-container{
			width: 90vw;
			.footer-sign-up-container{
				width: 100%;
				padding: 2vw;
				height: fit-content;
				border-radius: 1.8vw;

				.footer-text-details{
					width: fit-content;
					font-size: 3.3vw;
				}

				.button{
					width: fit-content;
					p{
						text-wrap: nowrap;
					}
				}
			}

			.footer-text-social-media{
				font-size: 3.3vw;
				color: #9792C9;
			}

			a{
				font-size: 3.3vw;
			}
			.footer-links-icons-container{
				.footer-icon-container{
					border-radius: 1.8vw;
					width: 9vw;
					height: 9vw;
					svg{
						width: 4vw;
					}
				}

			}
		}
	}
}