@use '/src/styles/variables' as styles;

.pipeline {
    margin-bottom: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

	.list-section {
		height: 21.18vw;
	}
  
}