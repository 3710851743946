@use '/src/styles/variables' as styles;

.tripl-info-wrapper {
    position: relative;
    background: #f2f4f6;
    position: relative;

    .tripl-info-hero {
        width: 75vw;
        margin: 0 auto;
        padding: 7vw 0 0 0;

        &.tripl-info-hero::before {
            content: "";
            position: absolute;
            width: 26vw;
            height: 14vw;
            bottom: 0;
            left: 0;
            background-color: var(--colorLightBlue);
            filter: blur(6vw);
            z-index: 2;
        }
        &.tripl-info-hero::after {
            content: "";
            position: absolute;
            width: 14vw;
            height: 34vw;
            top: 0;
            right: 0;
            background-color: #c0bbf1;
            filter: blur(8vw);
            z-index: 2;
        }

        .tripl-info-content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3vw 2vw 3vw 2vw;
            border: 2px #fff solid;
            border-radius: styles.$textMd;
            background-color: rgba(#fff, 0.2);

            .title {
                font-family: "Inter", sans-serif;
                font-weight: 200;
                font-size: 3.3vw;
                line-height: 1.2;
            }

            .title-black {
                font-weight: 800;
            }

            .landing-text {
                max-width: 70%;
                font-size: 1.1vw;
                font-weight: 500;
                color: #6f6c8a;
                margin-top: 2vw;
                margin-bottom: 2vw;
            }

            .tripl-info-items {
                display: flex;
                justify-content: space-between;
                gap: 3vw;

                .white {
                    background-color: rgba(#fff, 0.4);
                    border: 2px #fff solid;

                    .tripl-info-item {
                        background: none !important;

                        .landing-text {
                            font-weight: 200;
                            font-size: 1vw;
                            text-align: left;
                            max-width: 100%;
                            margin: 0;
                            color: var(--accentPrimary);
                        }

                        .item-header-container {
                            .icon-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 3vw;
                                height: 3vw;
                                background: #fff !important;
                                border-radius: styles.$textMd;

                                svg {
                                    width: 2vw;
                                    height: 2vw;
                                    path {
                                        stroke: #C0BBF1 !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .tripl-info-item-wrapper {
                    height: 25vw;
                    display: flex;
                    align-items: center;

                    .tripl-info-item-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 19.6vw;
                        box-shadow: 1.3vw 1.3vw 2vw 0px #514B871C;
                        background-color: rgba(#fff, 0.4);
                        border-radius: styles.$textMd;
                        padding: 0.5vw;

                        .tripl-info-item {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            padding: 1vw;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            border-radius: styles.$textMd;
                            gap: 1vw;

                            .landing-text {
                                font-weight: 200;
                                font-size: 1vw;
                                text-align: left;
                                max-width: 100%;
                                margin: 0;
                            }

                            .item-header-container {
                                display: flex;
                                justify-content: left;
                                align-items: center;
                                gap: 1vw;

                                .subtitle {
                                    text-align: left;
                                    font-size: 1.3vw;
                                    font-weight: 700;
                                }

                                .icon-container {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    min-width: 3vw;
                                    min-height: 3vw;
                                    background: linear-gradient(180deg, #C0BBF1 0%, #BBCAF1 100%);
                                    border-radius: styles.$textMd;

                                    svg {
                                        width: 2vw;
                                        height: 2vw;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @keyframes content-animation {
                from {
                    top: -10vw;
                    opacity: 0;
                }
                to {
                    top: 0;
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .tripl-info-wrapper {
        .tripl-info-hero {
            width: 80vw;
            padding: 11vw 0 0 0;
            &.tripl-info-hero::before {
                content: "";
                position: absolute;
                width: 36vw;
                height: 21vw;
                bottom: 0;
                left: 0;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
            &.tripl-info-hero::after {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: 0;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
                z-index: 2;
            }
            .tripl-info-content {
                padding: 4.5vw 3vw 4.5vw 3vw;
                border-radius: 1.7vw;
                .title {
                    font-size: 4.95vw;
                }
                .landing-text {
                    font-size: 1.6vw;
                }
                .tripl-info-items {
                    gap: 4.5vw;
                    .tripl-info-item-wrapper {
                        height: 100%;
                        .tripl-info-item-container {
                            padding: 1vw;
                            .tripl-info-item {
                                border-radius: 1.7vw;
                                gap: 1.5vw;
                                .landing-text {
                                    font-size: 1.6vw;
                                }
                                .item-header-container {
                                    gap: 1.5vw;
                                    .subtitle {
                                        font-size: 1.6vw;
                                    }
                                    .icon-container {
                                        min-width: 4.5vw;
                                        min-height: 4.5vw;
                                        border-radius: 1.7vw;

                                        svg {
                                            width: 3vw;
                                            height: 3vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .tripl-info-wrapper {
        .tripl-info-hero {
            width: 90vw;
            padding: 21vw 0 0 0;

            &.tripl-info-hero::before {
                content: "";
                position: absolute;
                width: 78vw;
                height: 42vw;
                bottom: 0;
                left: 0;
                background-color: var(--colorLightBlue);
                filter: blur(18vw);
                z-index: 2;
            }
            &.tripl-info-hero::after {
                content: "";
                position: absolute;
                width: 42vw;
                height: 102vw;
                top: 0;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(24vw);
                z-index: 2;
            }
            .tripl-info-content {
                padding: 9vw 6vw 9vw 6vw;
                border-radius: 3vw;
                .title {
                    font-size: 9vw;
                }
                .landing-text {
                    font-size: 3.3vw;
                    max-width: 100%;
                }
                .tripl-info-items {
                    flex-direction: column;
                    align-items: center;
                    gap: 9vw;
                    .tripl-info-item-wrapper {
                        padding: 1vw;
                        width: 90%;
                        .tripl-info-item-container {
                            padding: 1.5vw;
                            height: fit-content;
                            width: 100%;
                            .tripl-info-item {
                                padding: 3vw;
                                .landing-text {
                                    font-size: 3.3vw;
                                }
                                .item-header-container {
                                    gap: 2vw;
                                    .subtitle {
                                        font-size: 3.9vw;
                                    }
                                    .icon-container {
                                        min-width: 9vw;
                                        min-height: 9vw;
                                        border-radius: 3.7vw;

                                        svg {
                                            width: 6vw;
                                            height: 6vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
