.home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    height: 100%;
    gap: 2vw;

    p{
        white-space: normal;
    }

    .home-guidance-container{
        display: flex;
        flex-direction: column;
        gap: 1vw;

        .home-guidance-item{
            display: flex;
            justify-content: space-between;
            padding: 0.5vw;
            border-bottom: 1px solid var(--accentPrimary);

            p {
                font-size: 1.11vw;
            }

            .home-guidance-item-action{
                cursor: pointer;
                width: 2.5vw;
                height: 2.5vw;
                background: var(--accentPrimary);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg{
                    width: 1vw;
                    path{
                        fill: #fff;
                    }
                }
            }
        }
    }
    
}    

@media (max-width: 425px){
    .home{
        gap: 6vw;
    
        .home-text-wrapper{
    
            .home-title{
                margin-bottom: 9vw;
                font-size: 7.8vw;
                text-align: center;
            }
        
            .home-text-container{
                gap: 6vw;
        
                .home-text{
                    font-size: 3.33vw;
                    text-align: center;
    
                    span{
                        font-style: italic;
                        font-weight: 600;
                    }
                }
            }
        }
    
        .home-set-up-text-wrapper{
            gap: 3vw;
    
            .home-set-up-title{
                font-size: 4.8vw;
                text-align: center;
            }
    
            .home-set-up-text-container{
                gap: 3vw;
    
                .home-set-up-text{
                    font-size: 3.33vw;
                    text-align: center;
                }
    
                .home-set-up-text-item-container{
                    gap: 1.5vw;

                    .home-set-up-text-items{
                        gap: 3vw;
                        flex-direction: column;
                        a{
                            font-size: 3.33vw;
                            text-align: center;
                            gap: 1.5vw;
                            border-bottom: 0.42vw solid #1E1B39;
                            font-weight: 500;
                            color: #1E1B39;
                            cursor: pointer;
                        }
                    }   
    
                    
                }
            }
        }
    
    }
    
}