@use '/src/styles/variables' as styles;
.attached-files-preview {
  width: 100%;
  box-shadow: 0px 0px 0.69vw -1px rgba(0, 0, 0, 0.25);
  border-radius: 1vw 1vw 0vw 0vw;
  padding: 0.78vw 1.17vw;
  background: var(--backgroundMain);
  transition: all ease 0.3s;
  overflow: hidden;

  &-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--accentTertiary);
    border-radius: styles.$roundedLg;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .attached-files-preview-close-icon {
      min-width: 1vw;
      height: 100%;
      display: flex;
      padding-right: 1vw;
      align-items: center;

      svg {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-files{
      display: flex;
      align-items: flex-start;
      gap: 2.2vw;
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      padding: 1vw 1.24vw;

      &::-webkit-scrollbar {
        background: transparent;
        width: 0.69vw;
        height: 0.7vh;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: styles.$roundedXl;
          border: none;
      }

      .file-item-wrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.4vw;
        height: 100%;
        padding: 0.4vw;
        border-radius: 0.7vw;
        background: var(--accentPrimary);

        .file-item-close-button{
          position: relative;
          width: 0.7vw;
          height: 0.7vw;
          z-index: 100;
          svg, path{
            cursor: pointer;
            height: 100%;
            fill: var(--textHover);
          }
        }

      }
    }
  }
}

@media screen and (max-width: 768px) {

  .attached-files-preview {
    box-shadow: 0px 0px 1.05vw -1px rgba(0, 0, 0, 0.25);
    border-radius: 1.5vw 1.5vw 0vw 0vw;
    padding: 1.05vw 1.8vw;
  
    &-container {
      border-radius: 1.5vw;
  
      .attached-files-preview-close-icon {
        min-width: 1.5vw;
        padding-right: 1.5vw;
  
        svg {
          width: 1.5vw;
          height: 1.5vw;
        }
      }
  
      &-files{
        gap: 3.3vw;
        padding: 1.5vw 1.8vw;
  
        &::-webkit-scrollbar {
          background: transparent;
          width: 1.05vw;
          height: 0.7vh;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 2.25vw;
        }
  
        .file-item-wrapper{
          gap: 0.6vw;
          padding: 0.6vw;
          border-radius: 1.05vw;
  
          .file-item-close-button{
            width: 1.05vw;
            height: 1.05vw;
          }
  
        }
      }
    }
  }
  

}

@media screen and (max-width: 425px) {

  .attached-files-preview {
    box-shadow: 0px 0px 2.1vw -1px rgba(0, 0, 0, 0.25);
    border-radius: 3vw 3vw 0vw 0vw;
    padding: 2.1vw 3.6vw;
  
    &-container {
      border-radius: 3vw;
  
      .attached-files-preview-close-icon {
        min-width: 3vw;
        padding-right: 3vw;
  
        svg {
          width: 3vw;
          height: 3vw;
        }
      }
  
      &-files{
        gap: 6.6vw;
        padding: 3vw 3.6vw;
  
        &::-webkit-scrollbar {
          background: transparent;
          width: 2.1vw;
          height: 0.7vh;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4.5vw;
        }
  
        .file-item-wrapper{
          gap: 1.2vw;
          padding: 1.2vw;
          border-radius: 2.1vw;
  
          .file-item-close-button{
            width: 2.1vw;
            height: 2.1vw;
          }
  
        }
      }
    }
  }
  

}