
.otp-input-container {
	display: flex;
	justify-content: center;
	margin-top: 1.4vw;
}
  
.otp-input {
	width: 5.07vw;
	height: 3.47vw;
	font-size: 1.25vw;
	text-align: center;
	margin: 0 0.7vw;
	border-radius: 0.55vw;
	border: 0.07vw solid var(--accentDisabled);
	background: var(--accentTertiary);
	outline: none;

	&:focus {
		border: 0.14vw solid  var(--colorInfo);
	}
}

@media screen and (max-width: 768px) {

	.otp-input-container {
		margin-top: 2.1vw;
	}
	
	.otp-input {
		width: 7.6vw;
		height: 5.2vw;
		font-size: 1.875vw;
		margin: 0 1.05vw;
		border-radius: 0.75vw;
		border: 0.15vw solid var(--accentDisabled);

		&:focus {
			border: 0.21vw solid var(--colorInfo);
		}
	}
}

@media screen and (max-width: 425px) {

	.otp-input-container {
		margin-top: 4.2vw;
	}
	
	.otp-input {
		width: 15.2vw;
		height: 10.4vw;
		font-size: 3.75vw;
		margin: 0 2.1vw;
		border-radius: 1.5vw;
		border: 0.3vw solid var(--accentDisabled);

		&:focus {
			border: 0.42vw solid var(--colorInfo);
		}
	}
}
