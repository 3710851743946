.moving-container {
    width: 100%;
    height: 100%;

    .my-tasks-tool-bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.2vw;
        padding-top: 0.7vw;
        padding-right: 1.7vw;
        
        .my-tasks-tool-bar-left{
          display: flex;
          align-items: center;
          gap: 1vw;
        }
      }
}