.task-preview-card-container {
  display: flex;
  flex-direction: column;
  gap: 0.35vw;
  padding: 0.35vw 1.38vw 0.35vw 0.69vw;
  height: 100%;

  .task-preview-card-title {
    font-weight: 600;
    font-size: 1.4vw;
  }
  .task-preview-description {
    font-weight: 500;
    font-size: 1.11vw;
  }
  .task-preview-time {
    text-align: right;
    font-weight: 500;
    font-size: 1.25vw;
  }
}
.hiddenTask {
  padding: 0vw;
}
@media screen and (max-width: 768px) {
  .task-preview-card-container {
    .task-preview-card-title {
      font-size: 2.1vw;
    }
    .task-preview-description {
      font-size: 1.665vw;
    }
    .task-preview-time {
      font-size: 1.875vw;
    }
  }
}

@media screen and (max-width: 425px) {
  .task-preview-card-container {
    .task-preview-card-title {
      font-size: 4.2vw;
    }
    .task-preview-description {
      font-size: 3.33vw;
    }
    .task-preview-time {
      font-size: 3.75vw;
    }
  }
}
