.video-player-container{
    position: relative;

    .video-player-controls-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 1.5vw;
        cursor: pointer;

        .video-player-controls-central-play-button-container{
            width: 9.36%;
            height: 100%;

            display: flex;
            align-items: center;

            transition: all ease 0.4s;
            opacity: 0;

            svg, path{
                width: 100%;
            }
        }

        .show-central-play-button{
            opacity: 1;
        }

        .video-player-controls-interaction-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            transform: translateY(4.7vw);
            transition: all ease 0.4s;

            .video-player-controls-progressbar{
                height: 0.35vw;
            }

            .video-player-controls-interaction-buttons-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                max-height: 100%;
                margin-top: 1.39vw;

                .video-player-controls-interaction-buttons-item{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1.39vw;
                    max-height: 100%;

                    .video-player-controls-play-button, .video-player-controls-volume, .video-player-controls-fullscreen-button{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        svg, path{
                            width: 1.39vw;
                            height: 1.39vw;
                            display: flex;
                            align-items: center;
                        }

                        .video-player-controls-volume-bar-container{
                            width: 0;
                            height: 0.4vw;
                            background: #fff;
                            transition: all ease 0.3s;

                            .video-player-volume-bar{
                                width: 100%;
                                height: 100%;
                            }

                        }

                        .show-volume-bar{
                            width: 4.17vw;
                            margin-left: 1.39vw;
                        }
                    }
                    
                    .video-player-timer{
                        color: #fff;
                        font-size: 0.83vw;
                    }
                }

                .video-player-controls-interaction-buttons-item:last-child{
                    justify-content: flex-end;
                }
            }
        }

        .show-video-player-controls{
            transform: translateY(0);
        }
    }


    video{
        position: relative;
        width: 100%;
        height: 100%;
        outline: none;
    }

}

.video-player-fullscreen{
    position: fixed;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .video-player-container{
        width: 95%;
    }
    

    video::-webkit-media-controls {
        display:none !important;
    }
      
    video::-webkit-media-controls-enclosure {
        display:none !important;
    }
}

@media screen and (max-width: 768px) {

    .video-player-container{
        .video-player-controls-container{
            padding: 2.25vw;
    
            .video-player-controls-interaction-container{
                transform: translateY(7.05vw);
    
                .video-player-controls-progressbar{
                    height: 0.53vw;
                }
    
                .video-player-controls-interaction-buttons-container{
                    margin-top: 2.1vw;
    
                    .video-player-controls-interaction-buttons-item{
                        gap: 2.1vw;
    
                        .video-player-controls-play-button, .video-player-controls-volume, .video-player-controls-fullscreen-button{

                            svg, path{
                                width: 2.1vw;
                                height: 2.1vw;
                            }
    
                            .video-player-controls-volume-bar-container{
                                height: 0.6vw;
                            }
    
                            .show-volume-bar{
                                width: 8.4vw;
                                margin-left: 2.1vw;
                            }
                        }
                        
                        .video-player-timer{
                            font-size: 1.2vw;
                        }
                    }
                }
            }
        }    
    }

}

@media screen and (max-width: 425px) {

    .video-player-container{
        .video-player-controls-container{
            padding: 4.5vw;
    
            .video-player-controls-interaction-container{
                transform: translateY(14.1vw);
    
                .video-player-controls-progressbar{
                    height: 1.05vw;
                }
    
                .video-player-controls-interaction-buttons-container{
                    margin-top: 4.2vw;
    
                    .video-player-controls-interaction-buttons-item{
                        gap: 4.2vw;
    
                        .video-player-controls-play-button, .video-player-controls-volume, .video-player-controls-fullscreen-button{

                            svg, path{
                                width: 4.2vw;
                                height: 4.2vw;
                            }
    
                            .video-player-controls-volume-bar-container{
                                height: 1.2vw;
                            }
    
                            .show-volume-bar{
                                width: 16.8vw;
                                margin-left: 4.2vw;
                            }
                        }
                        
                        .video-player-timer{
                            font-size: 2.4vw;
                        }
                    }
                }
            }
        }    
    }

}