.customers {
	width: 100%;
	height: 100%;
	gap: 1vw;
	margin-top: 1vw;

	display: flex;
	flex-direction: column;
	overflow: hidden;

	&-actions{
		&-wrapper {
			display: flex;
			margin: 1.39vw 1.5vw 2vw 0;
			gap: 0.7vw
		}
		&-dropdown {
			width: 15.3vw;
		}
	}
}

