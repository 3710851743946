@use '/src/styles/variables' as styles;

$sliderBgOff:#b2b2b2;
$sliderBgOn:#4970B5;
$sliderOff:#d9d9d9;
$sliderOn:#adccff;

.switch{
	background-color: $sliderBgOff;
	width: 2.5vw;
	height: 1vw;
	border-radius: styles.$rounded2xl;
	transition: all 250ms ease;
	cursor: pointer;
	.switch-slider{
		height: 100%;
		aspect-ratio: 1/1;
		min-width: 25%;
		scale: 1.4;
		background-color: $sliderOff;
		border-radius: 100%;
		transition: transform 250ms ease;
		transform: translateX(0%);
	}
	
	&.on{
		background-color: $sliderBgOn;
		.switch-slider{
			transform: translateX(1.2vw);
			background-color: $sliderOn;
		}
	}
}

@media screen and (max-width: 768px) {

	.switch{
		width: 3.75vw;
		height: 1.5vw;
		border-radius: 3vw;
	}
	
}

@media screen and (max-width: 425px) {

	.switch{
		width: 7.5vw;
		height: 3vw;
		border-radius: 6vw;
	}
	
}