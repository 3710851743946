.pipeline-stages-editor-container{
    .pipeline-stage-editor-title {
        font-size: 1.25vw;
        margin-top: 0.7vw;
        text-align: center;
    }
    
    .pipeline-stages-container {
        padding: 1vw 0;
    
        .pipeline-stage {
            position: relative;
            margin: 0 0 1.4vw;
            display: flex;
            align-items: center;
            gap: 1vw;
        
        
            &-remove {
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                width: 1.9vw;
                height: 2.9vw;
                cursor: pointer;
                svg {
                    width: 0.7vw;
                    fill: var(--textHover);
                }
            }
        }
    }
}