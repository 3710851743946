@use '../../../../styles/variables' as styles;

.create-company-container {
  width: 31.25vw;
  height: 100%;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  gap: 0.5vw;
}


@media screen and (max-width: 768px) {

  .create-company-container {
    width: 46.875vw;
    padding: 1.5vw;
    gap: 0.75vw;
  }

}

@media screen and (max-width: 465px) {

  .create-company-container {
    width: 80.75vw;
    padding: 3vw;
    gap: 1.5vw;
  }

}