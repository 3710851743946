@use '/src/styles/variables' as styles;

.avatar-stack{
	display: flex;
	align-items: center;
	.avatar{
		height: 100%;
		margin-left: -.7vw;
	}

	p{
		margin-left: 0.1vw;
		color: var(--text2);
		font-size: styles.$textXs;
	}
}

@media screen and (max-width: 768px) {

	.avatar-stack{
		.avatar{
			margin-left: -1.05vw;
		}
	
		p{
			margin-left: 0.15vw;
			font-size: 1.05vw;
		}
	}

}

@media screen and (max-width: 425px) {

	.avatar-stack{
		.avatar{
			margin-left: -2.1vw;
		}
	
		p{
			margin-left: 0.3vw;
			font-size: 2.1vw;
		}
	}

}