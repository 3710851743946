.order-item-container{
    width: 100%;
    padding: 1.38vw 1.7vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    background: var(--backgroundMain);
    box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.10);
    border-radius: 1.4vw;
    cursor: pointer;

    .order-item-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.35vw;
    }

    .order-item-header-info{
        display: flex;
        align-items: center;
        width: 100%;
        gap: 0.7vw;

        svg, img {
            width: 2.5vw;
            height: 2.5vw;
        }

        .order-item-header-name-text{
            color: var(--text1);
            font-size: 1.11vw;
            font-weight: 600;
        }
    }

    .order-item-table-container{
        height: 17.36vw;
        overflow-y: scroll;

        .table{
            max-height: 100%;
            
            .order-item-product-container{
                display: flex;
                align-items: center;
                gap: 0.7vw;
        
                .avatar-container{
                    width: 1.7vw;
                    height: 1.7vw;
        
                    svg, img{
                        width: 1.7vw;
                        height: 1.7vw;
                    }
                }
        
                .order-item-product-name{
                    color: var(--text1);
                    font-size: 0.83vw;
                }
            }
        }
    }

    .order-item-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .order-item-footer-date-container{
            display: flex;
            flex-direction: column;

            .order-item-footer-date{
                font-size: 0.7vw;
                color: var(--text1);

                &.late{
                    color: var(--colorAlertText);
                }
            }
        }

        .order-item-footer-worth{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 0.7vw;

            .order-item-footer-title{
                font-size: 1.11vw;
                color: var(--accentPrimary);
            }

            .order-item-footer-text-container{
                display: flex;
                align-items: center;
                gap: 0.7vw;

                .order-item-footer-text{
                    font-size: 1.11vw;
                    color: var(--accentPrimary);
                    font-weight: 500;
                }
            }
        }
    }

    .order-item-header-status {
        display: flex;
        align-items: center;
        gap: 0.5vw;
        
        svg {
            width: 1.5vw;
            height: 1.5vw;
        }
    }
} 

@media screen and (max-width: 768px) {

    .order-item-container{
        padding: 2.1vw 2.55vw;
        gap: 1.5vw;
        box-shadow: 0 0 1.05vw 0 rgba(0, 0, 0, 0.10);
        border-radius: 2.1vw;
    
        .order-item-header{
            padding: 0.53vw;
        }
    
        .order-item-header-info{
            gap: 1.05vw;
    
            svg, img {
                width: 3.75vw;
                height: 3.75vw;
            }
    
            .order-item-header-name-text{
                font-size: 1.66vw;
            }
        }
    
        .order-item-table-container{
            height: 26.04vw;
    
            .table{
                
                .order-item-product-container{
                    gap: 1.05vw;
            
                    .avatar-container{
                        width: 2.55vw;
                        height: 2.55vw;
            
                        svg, img{
                            width: 2.55vw;
                            height: 2.55vw;
                        }
                    }
            
                    .order-item-product-name{
                        font-size: 1.25vw;
                    }
                }
            }
        }
    
        .order-item-footer{
            .order-item-footer-date-container{
                .order-item-footer-date{
                    font-size: 1.05vw;
                }
            }
    
            .order-item-footer-worth{
                gap: 1.05vw;
    
                .order-item-footer-title{
                    font-size: 1.66vw;
                }
    
                .order-item-footer-text-container{
                    gap: 1.05vw;

                    .order-item-footer-text{
                        font-size: 1.66vw;
                    }
                }
            }
        }
    } 

}

@media screen and (max-width: 425px) {

    .order-item-container{
        padding: 4.2vw 5.1vw;
        gap: 3vw;
        box-shadow: 0 0 2.1vw 0 rgba(0, 0, 0, 0.10);
        border-radius: 4.2vw;
    
        .order-item-header{
            padding: 1.05vw;
        }
    
        .order-item-header-info{
            gap: 2.1vw;
    
            svg, img {
                width: 7.5vw;
                height: 7.5vw;
            }
    
            .order-item-header-name-text{
                font-size: 3.32vw;
            }
        }
    
        .order-item-table-container{
            height: 52vw;
    
            .table{
                
                .order-item-product-container{
                    gap: 2.1vw;
            
                    .avatar-container{
                        width: 5.1vw;
                        height: 5.1vw;
            
                        svg, img{
                            width: 5.1vw;
                            height: 5.1vw;
                        }
                    }
            
                    .order-item-product-name{
                        font-size: 2.5vw;
                    }
                }
            }
        }
    
        .order-item-footer{
            .order-item-footer-date-container{
                .order-item-footer-date{
                    font-size: 2.1vw;
                }
            }
    
            .order-item-footer-worth{
                gap: 2.1vw;
    
                .order-item-footer-title{
                    font-size: 3.32vw;
                }
                .order-item-footer-text-container{
                    gap: 2.1vw;
             
                    .order-item-footer-text{
                        font-size: 3.32vw;
                    }
                }
            }
        }
    } 

}