@use '/src/styles/variables' as styles;

.reply-banner-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--backgroundNestedSubContainer);
    border-radius: 0.7vw;
    padding: 0.55vw;
    width: 100%;

    p{
        color: var(--text1) !important;
        width: 100%;
        font-size: styles.$textLg;
    }

    .reply-banner-name{
        font-size: styles.$textMd;
        font-weight: styles.$fontBold;
    }

}

@media screen and (max-width: 768px) {

    .reply-banner-container{
        border-radius: 1.05vw;
        padding: 0.825vw;
    
        p{
            font-size: 1.875vw;
        }
    
        .reply-banner-name{
            font-size: 1.66vw;
        }
    
    }

}

@media screen and (max-width: 425px) {

    .reply-banner-container{
        border-radius: 2.1vw;
        padding: 1.65vw;
    
        p{
            font-size: 3.75vw;
        }
    
        .reply-banner-name{
            font-size: 3.32vw;
        }
    
    }

}