@use '/src/styles/variables' as styles;

.manual-layout-wrapper{
    position: relative;
    background: #f2f4f6;
    position: relative;
    overflow: hidden;
    padding: 8vw 0 3vw 0;

    .manual-layout-container{
        width: 80vw;
        margin: 0 auto;
        padding: 2.7vw;
        display: flex;
        justify-content: left;
        gap: 3vw;
        background-color: rgba(#fff, 0.2);
        border-radius: styles.$roundedLg;
        border: 2px solid #fff;

        

        &.manual-layout-container::before {
            content: "";
            position: absolute;
            width: 14vw;
            height: 34vw;
            top: 15vw;
            right: -5vw;
            background-color: var(--colorLightBlue);
            filter: blur(8vw);
        }
        &.manual-layout-container::after {
            content: "";
            position: absolute;
            width: 26vw;
            height: 40vw;
            bottom: 8vw;
            left: -15vw;
            background-color: #c0bbf1;
            filter: blur(6vw);
            z-index: 2;
        }

        .manual-layout-information-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2vw;

            .manual-layout-text-wrapper{
                padding: 1vw;
                width: 55.5vw;
                height: fit-content;
                background-color: rgba(#fff, 0.6);
                border-radius: styles.$roundedLg;
                z-index: 5;
                .manual-layout-text-container{
                    width: 100%;
                    background-color: #fff;
                    border-radius: styles.$roundedLg;

                    display: flex;
                    flex-direction: column;
                    padding: 2vw;
                    gap: 1vw;
                }
            }
        }

        .manual-layout-selector-wrapper{
            padding: 1vw;
            width: 16vw;
            height: fit-content;
            background-color: rgba(#fff, 0.6);
            border-radius: styles.$roundedLg;
            z-index: 5;

            .manual-layout-selector-container{
                width: 100%;
                background-color: #fff;
                border-radius: styles.$roundedLg;

                display: flex;
                flex-direction: column;
                padding: 0.5vw;
                gap: 1vw;

                .manual-layout-selector-title-container{
                    display: flex;
                    border-radius: styles.$roundedMd;
                    transition: all 0.3s;
                    padding: 0.5vw 1vw;
                    cursor: pointer;

                    .subtitle{
                        font-size: 1vw;
                    }

                    &.active{
                        background-color: #F8F7FC;
                        .subtitle{
                            color: #544B9F;
                        }
                    }
                }

                .manual-layout-selector-title-container:hover{
                    background-color: #F8F7FC;
                    .subtitle{
                        color: #544B9F;
                    }
                }
            }
        }  
    }
}

@media (max-width: 768px) {
  .manual-layout-wrapper{
    padding: 12vw 0 4.5vw 0;
    .manual-layout-container{
        padding: 4.2vw;
        gap: 4.5vw;
        border-radius: 1.7vw;
        &.manual-layout-container::before {
            content: "";
            position: absolute;
            width: 21vw;
            height: 51vw;
            top: 15vw;
            right: -5vw;
            background-color: var(--colorLightBlue);
            filter: blur(12vw);
        }
        &.manual-layout-container::after {
            content: "";
            position: absolute;
            width: 39vw;
            height: 60vw;
            bottom: 8vw;
            left: -26vw;
            background-color: #c0bbf1;
            filter: blur(9vw);
            z-index: 2;
        }
        .manual-layout-information-container{
            gap: 3vw;
            .manual-layout-text-wrapper{
                padding: 1.5vw;
                border-radius: 1.7vw;
                width: 52.5vw;
                .manual-layout-text-container{
                    border-radius: 1.7vw;
                }
            }
            .title{
                text-align: left;
                font-size: 4.95vw;
            }
        }
        .manual-layout-selector-wrapper{

            padding: 1.5vw;
            border-radius: 1.7vw;
            .manual-layout-selector-container{
                border-radius: 1.7vw;
                padding: 0.75vw;
                gap: 1.5vw;
                .manual-layout-selector-title-container{
                    padding: 0.75vw 1.5vw;
                    border-radius: 1.5vw;
                    .subtitle{
                        font-size: 1.6vw;
                    }
                }
            }
        }  
    }
  }
}

@media (max-width: 425px) {
  .manual-layout-wrapper{
    padding: 18vw 0 4vw 0;
    .manual-layout-container{
        width: 90vw;
        flex-direction: column;
        border-radius: 3.3vw;
        &.manual-layout-container::before {
            content: "";
            position: absolute;
            width: 42vw;
            height: 105vw;
            top: 15vw;
            right: -5vw;
            background-color: var(--colorLightBlue);
            filter: blur(24vw);
        }
        &.manual-layout-container::after {
            content: "";
            position: absolute;
            width: 78vw;
            height: 120vw;
            bottom: 8vw;
            left: -74vw;
            background-color: #c0bbf1;
            filter: blur(18vw);
            z-index: 2;
        }
        .manual-layout-information-container{
            gap: 6vw;
            .title{
                font-size: 9vw;
            }
            .manual-layout-text-wrapper{
                padding: 3vw;
                width: 100%;
                border-radius: 3.3vw;
                .manual-layout-text-container{
                    border-radius: 3.3vw;
                }
            }
        }

        .manual-layout-selector-wrapper{
            width: 100%;
            padding: 3vw;
            border-radius: 3.3vw;
            .manual-layout-selector-container{
                border-radius: 3.3vw;
                padding: 1.5vw;
                gap: 3vw;
                .manual-layout-selector-title-container{
                    padding: 1.5vw 3vw;
                    border-radius: 3.3vw;
                    .subtitle{
                        font-size: 3.3vw;
                    }
                }
            }
        }  
    }
  }
}
