@use '/src/styles/variables' as styles;

.chat-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6.25vw;
  padding: 1vw 1.73vw;
  background-color: #fff;
  border-bottom: 0.12vw solid var(--accentDisabled);

  .chat-info-container{
    display: flex;
    align-items: center;
    height: 100%;
    width: 55%;

    .chat-avatar{
      img, svg {
        width: 4.16vw;
        height: 4.16vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }

    .chat-name-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-left: 0.97vw;
      width: 75%;

      .name {
        width: 100%;
        font-size: styles.$textXl;
        font-weight: styles.$fontMedium;
        line-height: 1.66vw;
        color: var(--text1);
      }

      .status-container {
        width: 100%;
        gap: 0.4vw;
        display: flex;
        align-items: center;

        .status-circle-container{
          width: 0.7vw;
          height: 0.7vw;
        }

        p{
          font-size: styles.$textSm;
          font-weight: styles.$fontLight;
          color: var(--text1);
        }

        .typing-animation-wrapper{

          margin-right: 0.5vw;
          display: flex;
          align-items: center;

          p{
            margin: 0 0.25vw;
          }

          .typing-animation-container{
            width: 3.1vw;
            margin-right: 0.25vw;

            .typing-animation{
              position: relative;
              left: 1.05vw;
              width: 0.7vw;
              height: 0.7vw;
              border-radius: 0.35vw;
              background-color: var(--accentPrimary);
              color: var(--accentPrimary);
              animation: typing 0.5s infinite linear alternate;
              animation-delay: 0.25s;
              
              &::before, &::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
              }
    
              &::before {
                left: -1.05vw;
                width: 0.7vw;
                height: 0.7vw;
                border-radius: 50%;
                background-color: var(--accentPrimary);
                color: var(--accentPrimary);
                animation: typing 0.5s infinite alternate;
                animation-delay: 0s;
              }
              &::after {
                left: 1.05vw;
                width: 0.7vw;
                height: 0.7vw;
                border-radius: 50%;
                background-color: var(--accentPrimary);
                color: var(--accentPrimary);
                animation: typing 0.5s infinite alternate;
                animation-delay: 0.5s;
              }
            }

          }
          
        }
      }
    }
  }

  .additional-tools {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    gap: 1vw;

    svg {
      width: 1.94vw;
      height: 1.94vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.chat-bar-header-input {
  all: unset;
  width: 100%;
  font-weight: styles.$fontLight;
  font-size: styles.$textMd;
  line-height: 1.2369791667vw;
  background: var(--backgroundMain);

  &:focus {
    outline: none;
  }
}

.search-massage-input-container{
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1); // No variable yet
  background: var(--backgroundMain);
  border-radius: 0.35vw;
  height: 3vw;
  width: 17vw;
  padding: 0.9vw 0.6vw;
  overflow: hidden;
  gap: 0.69vw;
  transition: all 0.4s ease;

  .search-massage-input-cross {
    width: 1vw;
    height: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.search-massage-button{
  width: 2vw;
  transition: all ease 0.4s;
  overflow: hidden;
}

@keyframes typing {
  0% {
    background-color: var(--accentPrimary);
  }
  50%, 100% {
    background-color: rgba(73, 112, 181, 0.2);
  }
}

@media screen and (max-width: 768px) {

  .chat-header-container {
    height: 9.375vw;
    padding: 1.5vw 2.6vw;
    border-bottom: 0.18vw solid var(--accentDisabled);
  
    .chat-info-container{
  
      .chat-avatar{
        img, svg {
          width: 6.24vw;
          height: 6.24vw;
        }
      }
  
      .chat-name-container {
        margin-left: 1.45vw;
  
        .name {
          font-size: 2.07vw;
          line-height: 2.5vw;
        }
  
        .status-container {
          gap: 0.6vw;
  
          .status-circle-container{
            width: 1.05vw;
            height: 1.05vw;
          }
  
          p{
            font-size: 1.45vw;
          }
  
          .typing-animation-wrapper{
  
            margin-right: 0.75vw;
  
            p{
              margin: 0 0.375vw;
            }
  
            .typing-animation-container{
              width: 4.6vw;
              margin-right: 0.375vw;
  
              .typing-animation{
                left: 1.575vw;
                width: 1.05vw;
                height: 1.05vw;
                border-radius: 0.525vw;
      
                &::before {
                  left: -1.575vw;
                  width: 1.05vw;
                  height: 1.05vw;
                }

                &::after {
                  left: 1.575vw;
                  width: 1.05vw;
                  height: 1.05vw;
                }
              }
  
            }
            
          }
        }
      }
    }
  
    .additional-tools {
      height: 100%;
      gap: 1.5vw;
  
      svg {
        width: 2.91vw;
        height: 2.91vw;
      }

    }
  }
  
  .search-massage-button{
    width: 3vw;
  }
  .chat-bar-header-input {
    font-size: 1.66vw;
    line-height: 1.8vw;
  }
  
  .search-massage-input-container{
    box-shadow: 3px 4px 1.05vw rgba(0, 0, 0, 0.1); // No variable yet
    border-radius: 0.525vw;
    height: 3.5vw;
    gap: 1.05vw;
    width: 25.5vw;
    padding: 1.35vw 0.9vw;
  
    .search-massage-input-cross {
      width: 1.5vw;
      height: 1.5vw;

      svg{
        width: 100%;
        height: 100%;
      }
    }
  }

}

@media screen and (max-width: 425px) {

  .chat-header-container {
    height: 18.75vw;
    padding: 3vw 5.2vw;
    border-bottom: 0.36vw solid var(--accentDisabled);
  
    .chat-info-container{
  
      .chat-avatar{
        img, svg {
          width: 12.5vw;
          height: 12.5vw;
        }
      }
  
      .chat-name-container {
        margin-left: 2.9vw;
  
        .name {
          font-size: 4.14vw;
          line-height: 5vw;
        }
  
        .status-container {
          gap: 1.2vw;
  
          .status-circle-container{
            width: 2.1vw;
            height: 2.1vw;
          }
  
          p{
            font-size: 2.9vw;
          }
  
          .typing-animation-wrapper{
  
            margin-right: 1.5vw;
  
            p{
              margin: 0 0.56vw;
            }
  
            .typing-animation-container{
              width: 9.2vw;
              margin-right: 0.75vw;
  
              .typing-animation{
                left: 3.15vw;
                width: 2.1vw;
                height: 2.1vw;
                border-radius: 1.5vw;
      
                &::before {
                  left: -3.15vw;
                  width: 2.1vw;
                  height: 2.1vw;
                }

                &::after {
                  left: 3.15vw;
                  width: 2.1vw;
                  height: 2.1vw;
                }
              }
  
            }
            
          }
        }
      }
    }
  
    .additional-tools {
      gap: 3vw;
  
      svg {
        width: 5.82vw;
        height: 5.82vw;
      }

      
    }
  }
  
  .search-massage-button{
    width: 6vw;
  }

  .chat-bar-header-input {
    font-size: 3.32vw;
    line-height: 3.6vw;
  }
  
  .search-massage-input-container{
    box-shadow: 3px 4px 2.1vw rgba(0, 0, 0, 0.1); // No variable yet
    border-radius: 1.5vw;
    height: 7vw;
    gap: 2.1vw;
    width: 51vw;
    padding: 2.7vw 1.8vw;

    .search-massage-input-cross {
      width: 3vw;
      height: 3vw;
    }
  }

}