.expandable-checkbox {
	&-main-option {
		padding: 0.35vw 0.7vw;
		transition: 400ms ease-out all;
		border-radius: 0.5vw;

		&.checkbox-main-option-selected,
		&:hover {
			background: var(--accentPrimary);
			.checkbox-label {
				color: var(--textHover)
			}
			.checkbox {
				border-color: var(--backgroundMain);

				.checked {
					background-color: var(--backgroundMain)
				}
			}
		}
	}

	&-nested-options {
		margin: 0.5vw 0vw 0.5vw 1.1vw;
		border-left: 0.14vw solid #000;
		display: grid;
		grid-template-rows: 0fr;

		&.nested-options-expanded {
			grid-template-rows: 1fr;
		}
		.checkbox-nested-option {
			margin: 0.7vw;
			padding: 0.35vw 0.7vw;
			border-radius: 0.5vw;
			transition: 400ms ease-out all;

			&:hover {
				background: var(--accentTertiary)
			}
		}

		&-container {
			overflow: hidden;
		}
	}
}