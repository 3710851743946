@use '/src/styles/variables' as styles;

.info-popup-container{
	display: inline-flex;
	flex-direction: column-reverse;
	position: relative;
	justify-content: flex-end;

	.info-popup-icon-container{
		display:flex;
		justify-content: space-between;
		align-items: center;

		.info-popup-icon{
			height: auto;

			.notification-title {
				fill: #1E1B39;
			}
		}

		svg{
			width: 1.5vw;
			stroke: none;
			display: block;
			overflow: visible;
			path{
				transition: all 400ms ease-out;
				stroke: #1E1B39;
			}
			circle{
				transition: all 400ms ease-out;
			}
			
		}

		&:hover{

			.info-popup-icon{
				path{
					stroke: #1E1B39;
				}
				.notification-circle {
					stroke: #fff;
					fill: #fff;
				}
				.notification-trinagle {
					stroke: #1E1B39;
					fill: #1E1B39;
				}
				.notification-exclamation {
					stroke: #fff;
					fill: #1E1B39;
				}
				.notification-title {
					fill: #fff;
				}
				.notification-title-bg {
					fill: #1E1B39;
				}
			}

			svg{
				display: block;
				&.should-fill{
					path{
						fill: #fff;
						display: block;
					}
				}
			}
		}
	}

	.info-popup-text-container {
		color: #1E1B39;
		font-size: 1vw;
		background: linear-gradient(189.58deg, rgba(255, 255, 255, 0.56) 2.18%, rgba(255, 255, 255, 0.71) 132.3%);
		white-space: normal;
		backdrop-filter: blur(5px);
		border-radius: 0.5vw;
		position: fixed;
		z-index: 9999;
		opacity: 0;
		transition: opacity 0.4s ease-in-out;
		max-width: 30vw;
		padding: 0.7vw;
		border: .015vw solid #1E1B39;
		display: flex;
		flex-direction: column;
		gap: 1.5vw;
		
		.info-popup-text{
			p{
				text-wrap: wrap;
			}

			.info-popup-title{
				font-weight: bold;
				font-size: 1.2vw;
				margin-bottom: 0.5vw;
			}

			.info-popup-description{
				font-size: 1vw;
				margin-bottom: 0.3vw;
			}
			
			a{
				color: var(--text1);
				font-size: 1vw;
				text-decoration: underline;
			}

		}
		.flow{
			p{
				text-wrap: nowrap;
			}
		}
		&.info-popup-hidden{
			animation: widthZero 0s ease-in-out forwards;
			animation-delay: 0.4s; 
		}
		
		&.info-popup-visible {
			opacity: 1;
			transition: opacity 0.4s ease-in-out;
		}
	}
}

@keyframes widthZero{
	from {
		max-width: 30vw;
		padding: 0.7vw;
		border: .015vw solid #1E1B39;
	}

	to{
		border: none;
		max-width: 0;
		max-height: 0;
		padding: 0;
		overflow: hidden;
	}
}

@media screen and (max-width: 768px) {
	.info-popup-container{
		.info-popup-icon-container{
			padding: 0.3vw 0.45vw;
			svg{
				width: 2.25vw;
			}
		}
		
		.info-popup-text-container {
			border: .225vw solid #1E1B39;
			border-radius: 1.05vw;
			font-size: 1.5vw;
			padding: 1.05vw;
			
			.info-popup-text{
				.info-popup-title{
					font-weight: bold;
					font-size: 1.8vw;
					margin-bottom: 0.75vw;
				}

				.info-popup-description{
					font-size: 1.5vw;
					margin-bottom: 0.45vw;
				}

				a{
					font-size: 1.5vw;
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {

	.info-popup-container{
		.info-popup-icon-container{
			padding: 0.6vw 0.9vw;
			svg{
				width: 4.2vw;
			}
		}
		
		.info-popup-text-container{
			border: .45vw solid #1E1B39;
			border-radius: 2.1vw;
			max-width: 60vw;
			font-size: 3vw;
			padding: 2.1vw;

			.info-popup-text{
				.info-popup-title{
					font-weight: bold;
					font-size: 3.2vw;
					margin-bottom: 1.5vw;
				}

				.info-popup-description{
					font-size: 3vw;
					margin-bottom: 0.9vw;
				}

				a{
					font-size: 3vw;
				}
			}
		}
	}
}

  