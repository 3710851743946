@use 'src/styles/variables' as styles;

.filter-bar-container{
    box-shadow: styles.$shadowSm;
    border-radius: styles.$roundedMd;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw 1.38vw;
    gap: 0.5vw;
    margin: 0.5vw 0;

    .filter-bar-buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 1vw;

        .filter-bar-buttons-container{
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 1vw;

            .filter-bar-show-button{
                svg{
                    transform: rotate(90deg);
                    width: 1.3vw;
                    height: 1.3vw;
                    transition: all ease 0.4s;
        
                    path{
                        stroke: var(--text1);
                    }
                }

                &.open{
                    svg{
                        transform: rotate(-90deg);
                    }
                
                }
            }

            .filter-bar-data-import{
                svg{
                    transform: rotate(90deg);
                    width: 1.3vw;
                    height: 1.3vw;
        
                    path{
                        stroke: #fff;
                    }
                }

                p{
                    color: var(--text1);
                    font-weight: styles.$fontMedium;
                    font-size: styles.$textMd;
                }

                display: flex;
                align-items: center;
                gap: .2vw;
            }

            .filter-bar-data-export{
                svg{
                    transform: rotate(-90deg);
                    width: 1.3vw;
                    height: 1.3vw;
        
                    path{
                        stroke: #fff;
                    }
                }

                p{
                    color: var(--text1);
                    font-weight: styles.$fontMedium;
                    font-size: styles.$textMd;
                }
                display: flex;
                align-items: center;
                gap: .2vw;
            }

        }
    }
   
}

@media screen and (max-width: 768px) {
    .filter-bar-container{
        border-radius: 0.75vw;
        padding: 1.5vw 2.07vw;
        gap: 0.75vw;
        margin: 0.75vw 0;
    
        .filter-bar-buttons{
    
            .filter-bar-buttons-container{
                gap: 1.5vw;
    
                .filter-bar-show-button{
                    svg{
                        width: 1.95vw;
                        height: 1.95vw;
                    }
                }
    
                .filter-bar-data-import{
                    svg{
                        width: 1.95vw;
                        height: 1.95vw;
            
                    }
    
                    p{
                        font-size: 1.66vw;
                    }
                    gap: .3vw;
                }
    
                .filter-bar-data-export{
                    svg{
                        width: 1.95vw;
                        height: 1.95vw;
                    }
    
                    p{
                        font-size: 1.66vw;
                    }
                    
                    gap: .3vw;
                }
    
            }
        }
       
    }
}

@media screen and (max-width: 425px) {
    .filter-bar-container{
        border-radius: 1.5vw;
        padding: 3vw 4.14vw;
        gap: 1.5vw;
        margin: 1.5vw 0;
    
        .filter-bar-buttons{
    
            .filter-bar-buttons-container{
                gap: 3vw;
    
                .filter-bar-show-button{
                    svg{
                        width: 3.9vw;
                        height: 3.9vw;
                    }
                }
    
                .filter-bar-data-import{
                    svg{
                        width: 3.9vw;
                        height: 3.9vw;
            
                    }
    
                    p{
                        font-size: 3.32vw;
                    }
                    gap: .6vw;
                }
    
                .filter-bar-data-export{
                    svg{
                        width: 3.9vw;
                        height: 3.9vw;
                    }
    
                    p{
                        font-size: 3.32vw;
                    }
                    
                    gap: .6vw;
                }
    
            }
        }
       
    }
}