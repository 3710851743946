.location-selector{
    display: flex;
    flex-direction: column;
    gap: 1.875vw;

    .location-selector-map{
        width: 100%;
        height: 15vw;
    }
}


@media screen and (max-width: 768px) {

    .location-selector{
        gap: 2.81vw;
    
        .location-selector-map{
            height: 22.5vw;
        }
    }

}

@media screen and (max-width: 425px) {

    .location-selector{
        gap: 5.6vw;
    
        .location-selector-map{
            height: 45vw;
        }
    }

}