.purchase-order-list{
  	width: 100%;
    height: 100%;
    gap: 1vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@media screen and (max-width: 768px) {

    .purchase-order-list{
        gap: 1.5vw;
    }

}

@media screen and (max-width: 425px) {

    .purchase-order-list{
        gap: 3vw;
    }
}