.bill-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;

  .bill-details-actions{
    width: 90%;
    display: flex;
    gap: 1vw;
  }
}
