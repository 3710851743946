@use '/src/styles/variables' as styles;

.help-wrapper{
    position: relative;
    background: #f2f4f6;
    position: relative;
    overflow: hidden;

    .loading-container{
        position: static;
    }

    .help-container{
        width: 80vw;
        margin: 0 auto;
        padding: 7vw 0 5vw 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 4vw;

        .title {
            font-family: "Inter", sans-serif;
            font-weight: 200;
            font-size: 3.3vw;
            line-height: 1.2;
            text-align: left;
        }
    
        .title-black {
            font-weight: 800;
        }

        .landing-text {
            font-size: 1.1vw;
            font-weight: 500;
            color: #6f6c8a;
            text-align: left;
        }

        .help-text-container{
            display: flex;
            flex-direction: column;
            gap: 3vw;
            z-index: 3;
            width: 35vw;

            .help-icons-container{
                display: flex;
                flex-direction: column;
                gap: 1vw;

                .help-icon-list{
                    display: flex;
                    justify-content: space-between;
                    gap: 3vw;
                    align-items: center;
                    width: fit-content;

                    .help-icon-white-block{
                        width: 1.1vw;
                        height: 1.1vw;
                        background-color: #fff;
                        border-radius: 0.3vw;
                    }
                    .block1{
                        margin-right: 1.4vw;
                    }
                    .block2{
                        margin-left: 1.4vw;
                    }

                    .help-icon-container{
                        width: 4vw;
                        height: 4vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        background-color: rgba(#fff, 0.2);
                        border-radius: styles.$roundedXl;
                        border: 2px solid #fff;

                        svg{
                            width: 2vw;
                            height: 2vw;
                        }
                    }
                }
            }

            .help-messangers-list{
                display: flex;
                gap: 1vw;
                flex-wrap: wrap;

                .help-messanger-container{
                    cursor: pointer;
                    display: flex;
                    gap: 0.5vw;
                    align-items: center;
                    padding: 0.8vw;
                    background-color: rgba(#fff, 0.6);
                    border-radius: styles.$roundedMd;
                    border: 2px solid #fff;

                    .subtitle{
                        font-size: 1vw;
                        color: #6f6c8a;
                    }

                    svg{
                        width: 2vw;
                        height: 2vw;
                        fill: #C0BBF1 !important;
                        path{
                            fill: #C0BBF1 !important; 
                        }
                    }
                }
            }
        }

        .help-form-container{
            border-radius: styles.$roundedXl;
            width: 35vw;
            padding: 3vw;
            background-color: #fff;
            z-index: 3;
            box-shadow: 1.5vw 1.5vw 1vw 0px #544b9f08;

            .help-form{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2vw;
                width: 100%;

                .help-form-message{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 1vw;
                    background: rgba(94, 169, 122, 0.2);
                    border-radius: 0.5vw;
                    transition: all ease 0.4s;
                    overflow: hidden;
                    padding: 0.5vw;
                    border: 1px solid var(--colorSuccessText);


                    svg{
                        fill: var(--colorSuccessText);
                        min-width: 1vw;
                        max-width: 1vw;
                        min-height: 1vw;
                        max-height: 1vw;
                    }
                    
                     
                    p{
                        color: var(--colorSuccessText);
                        font-size: 1.11vw;
                        white-space: normal;
                    }
                }

                .help-inputs-container{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1vw;

                    .input-wrapper{
                        .input-field-wrapper{
                            padding: 0.66vw;
                            background-color: transparent;
                            border: 1px solid #E8E8E8;
                            box-shadow: none;
                        }
                        
                    }

                    .text-area{
                        .text-area-container{
                            background-color: transparent;
                            border: 1px solid #E8E8E8;
                            display: flex;
                            align-items: flex-start;
                            gap: 0.6vw;
                            box-shadow: none;
                            padding-left: 0.66vw;
                            textarea{
                                height: 6vw !important;
                                overflow: scroll;
                            }
                        }
                    }
                }
            }
        }
        

        &.help-container::before {
            content: "";
            position: absolute;
            width: 14vw;
            height: 34vw;
            top: 15vw;
            right: 0;
            background-color: #c0bbf1;
            filter: blur(8vw);
        }
        &.help-container::after {
            content: "";
            position: absolute;
            width: 26vw;
            height: 14vw;
            bottom: 26vw;
            left: 0;
            background-color: var(--colorLightBlue);
            filter: blur(6vw);
            z-index: 2;
        }

        
    }

}

@media (max-width: 768px) {
    .help-wrapper{
    
        .help-container{
            padding: 10vw 0 7vw 0;
            width: 85vw;
            gap: 6vw;

            &.help-container::before {
                content: "";
                position: absolute;
                width: 21vw;
                height: 51vw;
                top: 15vw;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(12vw);
            }
            &.help-container::after {
                content: "";
                position: absolute;
                width: 39vw;
                height: 21vw;
                bottom: 10vw;
                left: 0;
                background-color: var(--colorLightBlue);
                filter: blur(9vw);
                z-index: 2;
            }
    
            .title {
                font-size: 4.95vw;
            }
            .landing-text {
                font-size: 1.6vw;
            }
    
            .help-text-container{
                width: 39vw;
                gap: 4.5vw;
    
                .help-icons-container{
                    gap: 1.5vw;
    
                    .help-icon-list{
                        gap: 4vw;
    
                        .help-icon-white-block{
                            width: 1.7vw;
                            height: 1.7vw;
                            border-radius: 0.5vw;
                        }
                        .block1{
                            margin-right: 2.1vw;
                        }
                        .block2{
                            margin-left: 2.1vw;
                        }
    
                        .help-icon-container{
                            width: 6vw;
                            height: 6vw;
    
                            svg{
                                width: 3vw;
                                height: 3vw;
                            }
                        }
                    }
                }
    
                .help-messangers-list{
                    gap: 3vw;
    
                    .help-messanger-container{
                        gap: 0.75vw;
                        border-radius: 1.4vw;
                        padding: 1.2vw;
                        border-radius: 1.5vw;
    
                        .subtitle{
                            font-size: 1.4vw;
                        }
    
                        svg{
                            width: 3vw;
                            height: 3vw;
                        }
                    }
                }
            }
    
            .help-form-container{
                width: 40vw;
                border-radius: 1.7vw;
                padding: 4.5vw;
                box-shadow: 2.3vw 2.3vw 1.5vw 0px #544b9f08;
    
                .help-form{
                    gap: 3vw;

                    .help-form-message{
                        gap: 1.5vw;
                        border-radius: 0.75vw;
                        padding: 0.75vw;

                        svg{
                            min-width: 1.5vw;
                            max-width: 1.5vw;
                            min-height: 1.5vw;
                            max-height: 1.5vw;
                        }
                        
                         
                        p{
                            font-size: 1.66vw;
                        }

                    }
    
                    .button{
                        padding: 0.73vw;
                        p{
                          font-size: 1.6vw;
                        }
                        
                    }
    
                    .help-inputs-container{
                        gap: 1.5vw;
    
                        .text-area{
                            .text-area-container{
                                padding: 0.66vw;
                                svg{
                                    margin-top: 0;
                                }
                                textarea{
                                    padding-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
  
@media (max-width: 425px) {
    .help-wrapper{
    
        .help-container{
            width: 90vw;
            padding: 21vw 0 15vw 0;
            flex-direction: column;
            gap: 12vw;

            &.help-container::before {
                content: "";
                position: absolute;
                width: 42vw;
                height: 102vw;
                top: 15vw;
                right: 0;
                background-color: #c0bbf1;
                filter: blur(24vw);
            }
            &.help-container::after {
                content: "";
                position: absolute;
                width: 78vw;
                height: 21vw;
                bottom: 4vw;
                left: 0;
                background-color: var(--colorLightBlue);
                filter: blur(18vw);
                z-index: 2;
            }
    
            .title {
                font-size: 9vw;
            }
            .landing-text {
                font-size: 3.3vw;
            }
    
            .help-text-container{
                width: 100%;
                gap: 9vw;
    
                .help-icons-container{
                    gap: 3vw;
    
                    .help-icon-list{
                        gap: 6vw;
    
                        .help-icon-white-block{
                            width: 3.3vw;
                            height: 3.3vw;
                            border-radius: 0.9vw;
                        }
                        .block1{
                            margin-right: 4.2vw;
                        }
                        .block2{
                            margin-left: 4.2vw;
                        }
    
                        .help-icon-container{
                            width: 12vw;
                            height: 12vw;
    
                            svg{
                                width: 6vw;
                                height: 6vw;
                            }
                        }
                    }
                }
    
                .help-messangers-list{
                    gap: 3vw;
    
                    .help-messanger-container{
                        border-radius: 3.3vw;
                        gap: 1.5vw;
                        padding: 2.4vw;
    
                        .subtitle{
                            font-size: 3.3vw;
                        }
    
                        svg{
                            width: 6vw;
                            height: 6vw;
                        }
                    }
                }
            }
    
            .help-form-container{
                width: 100%;
                padding: 9vw;
                box-shadow: 4.5vw 4.5vw 3vw 0px #544b9f08;

                .help-form{
                    gap: 4vw;

                    .help-form-message{
                        gap: 3vw;
                        border-radius: 1.5vw;
                        padding: 1.5vw;

                        svg{
                            min-width: 3vw;
                            max-width: 3vw;
                            min-height: 3vw;
                            max-height: 3vw;
                        }
                        
                         
                        p{
                            font-size: 3.2vw;
                        }

                    }

                    .button{
                        padding: 1.65vw;
                        p{
                          font-size: 3.3vw;
                        }
                        .arrow-icon-container{
                          width: 5.1vw;
                          height: 5.1vw;
                          border-radius: 2vw;
                          svg{
                              width: 3vw;
                              height: 3vw;
                          }
                  
                        }
                    }
    
                    .help-inputs-container{
                        gap: 2vw;
    
                        .input-wrapper{
                            .input-field-wrapper{
                            }
                            
                        }
    
                        .text-area{
                            .text-area-container{
                                textarea{
                                    height: 9vw !important;
                                    padding-left: 1.5vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}