.employee-node{
	min-width: 20vw;
	display: flex;
	width: 100%;
	height: 100%;
	
	&.counterparty-active{
		.counterparty{
			border: 1px solid var(--accentPrimary);
		}
	}

	&.fired {
		.counterparty{
			border: 1px solid var(--colorAlert);
		}

	}
}

@media screen and (max-width: 768px) {

	.employee-node{
		min-width: 30vw;
	}
}

@media screen and (max-width: 425px) {

	.employee-node{
		min-width: 60vw;
	}
}