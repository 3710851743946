.edit-position-form {
  width: 30vw;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;

  .text-area {
    .text-area-container {
      background: var(--accentTertiary);
      textarea {
        background: transparent
      }
    }
  }
}
