.pipeline {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  
    .pipeline-tool-bar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.2vw;
      padding-top: 0.7vw;
      
      .pipeline-tool-bar-left{
        display: flex;
        align-items: center;
        gap: 1vw;
      }
    }
}

@media screen and (max-width: 768px) {
  .pipeline{
    .pipeline-tool-bar {
      margin-bottom: 3.3vw;
      padding-top: 1.05vw;
    }
  }

}

@media screen and (max-width: 425px) {
  .pipeline{
    .pipeline-tool-bar {
      margin-bottom: 6.6vw;
      padding-top: 1.15vw;
    }
  }
}
  