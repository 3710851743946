.audio-message-container{
    display: flex;
    align-items: center;
    width: 21.65vw;
    padding: 0.9vw;

    .audio-message-play-button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        svg{
            width: 2.125vw;
            height: 2.125vw;
        }
    }

    .audio-message-data-container{
        display: flex;
        flex-direction: column;
        margin-left: 0.7vw;
        width: 100%;
        height: 100%;

        .audio-message-waves-container{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 768px) {

    .audio-message-container{
        width: 32.5vw;
        padding: 1.35vw;
    
        .audio-message-play-button{
            svg{
                width: 3.18vw;
                height: 3.18vw;
            }
        }
    
        .audio-message-data-container{
            margin-left: 1.05vw;
        }
    }

}

@media screen and (max-width: 425px) {

    .audio-message-container{
        width: 65vw;
        padding: 2.7vw;
    
        .audio-message-play-button{
            svg{
                width: 6.4vw;
                height: 6.4vw;
            }
        }
    
        .audio-message-data-container{
            margin-left: 2.1vw;
        }
    }

}