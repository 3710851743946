.chart-container{
	box-shadow: .1vw .1vw .5vw 0vw rgba(0, 0, 0, 0.10);
	border-radius: .4vw;
	padding: 1.8vw;
	color: #1E1B39;
	width: 100%;
	
	&-head{
		padding-bottom: 1vw;
		.chart-container-title{
			color: #1E1B39;
			font-size: 1.4vw;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 90%;
		}
		.chart-container-sub-title{
			color: #808080;
			font-size: 1.2vw;
			font-weight: 300;
		}
	}
	
	.chart-container-charts{
		min-height: 12vw;
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 1vw 0.1vw;
	}
	.chart-container-action{
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.chart-container-action-duration{
			display: flex;
			flex-direction: column;
			position: relative;


			.chart-container-action-duration-calendar{
				position: absolute;
				z-index: 10;
				height: 0;
				overflow: hidden;
				transition: all ease 0.4s;
				overflow: hidden;
				top: 2vw;
				width: 100%;

				&.visible{
					height: 19.5vw;
					padding: 0.6vw;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {

	.chart-container{
		box-shadow: .15vw .15vw .75vw 0vw rgba(0, 0, 0, 0.10);
		border-radius: .6vw;
		padding: 2.7vw;

		&-head{
			padding-bottom: 1.5vw;
			.chart-container-title{
				font-size: 2.1vw;
			}
			.chart-container-sub-title{
				font-size: 1.8vw;
			}
		}
		
		.chart-container-charts{
			min-height: 18vw;
			margin: 1.5vw 0.15vw;
		}
		.chart-container-action{
	
			.chart-container-action-duration{
	
				.chart-container-action-duration-calendar{
					top: 3vw;
	
					&.visible{
						height: 29.25vw;
						padding: 0.9vw;
					}
				}
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.chart-container{
		box-shadow: .3vw .3vw .3vw 0vw rgba(0, 0, 0, 0.10);
		border-radius: 1.2vw;
		padding: 5.4vw;

		&-head{
			padding-bottom: 3vw;
			.chart-container-title{
				font-size: 4.2vw;
			}
			.chart-container-sub-title{
				font-size: 3.6vw;
			}
		}
		
		.chart-container-charts{
			min-height: 36vw;
			margin: 3vw 0.3vw;
		}
		.chart-container-action{
	
			.chart-container-action-duration{
	
				.chart-container-action-duration-calendar{
					top: 6vw;
	
					&.visible{
						height: 58.5vw;
						padding: 1.8vw;
					}
				}
			}
		}
	}

}