.company-positions {
	display: flex;
	flex-direction: column;
	padding: 1vw;
	width: 100%;
	height: 100%;

	.position-heading {
		font-size: 1.25vw;
		font-weight: 600;
		color: var(--text1)
	}
}