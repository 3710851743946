.chart-legend-container{
	display: flex;
	align-items: stretch;
	cursor: pointer;
	gap: .1vw;
	padding: .2vw .5vw;
	border-radius: .5vw;
	transition: all 250ms ease;

	svg{
		width: .7vw;
		height: .7vw;
	}

	span{
		font-size: .7vw;
	}

	svg{
		circle{
			transition: all 250ms ease;
		}
	}

	&.selected{
		svg{
			transform: scale(110%);
			scale: 1%;
		}
		background: #e2f0ff;
	}
}

@media screen and (max-width: 768px) {

	.chart-legend-container{
		gap: .15vw;
		padding: .3vw .75vw;
		border-radius: .75vw;
	
		svg{
			width: 1.05vw;
			height: 1.05vw;
		}
	
		span{
			font-size: 1.05vw;
		}
	}

}

@media screen and (max-width: 425px) {

	.chart-legend-container{
		gap: .3vw;
		padding: .6vw 1.5vw;
		border-radius: 1.5vw;
	
		svg{
			width: 2.1vw;
			height: 2.1vw;
		}
	
		span{
			font-size: 2.1vw;
		}
	}

}