.input-range-slider{
    width: 100%;
    background: var(--accentDisabled);
    border-radius: 0.35vw;
    height: .3vw;
    position: relative;
    cursor: pointer;

    &-progress{
        height: 100%;
        width: 100%;
        background: var(--accentPrimary);
        position: absolute;
    }

    &-ball{
        width: 1vw;
        height: 1vw;
        border-radius: 50%;
        background: var(--accentPrimary);
        position: absolute;
        top: -100%;
    }
}

@media screen and (max-width: 768px) {

    .input-range-slider{
        border-radius: 0.53vw;
        height: .45vw;
    
        &-ball{
            width: 1.5vw;
            height: 1.5vw;
        }
    }
    

}

@media screen and (max-width: 425px) {

    .input-range-slider{
        border-radius: 1.05vw;
        height: .9vw;
    
        &-ball{
            width: 3vw;
            height: 3vw;
        }
    }
    

}