$primary: var(--accentPrimary);
$secondary: var(--text4);
$background: var(--backgroundSubContainer);
$activeColor: var(--textHover);

.switch-buttons-container{
	background-color: $background;
	padding: 0.1vw;
	border-radius: 1.5vw;
	font-size: .9vw;
	width: min-content;
	// Layout
	display: flex;
	justify-content: space-around;
	height: min-content;
	.switch-button{
		cursor: pointer;
		border-radius: 2vw;
		white-space: nowrap;
		padding: .35vw 1vw;
		transition: all 250ms ease;
		color: $secondary;
		display: flex;
		align-items: center;
		justify-content: center;
		scale: 1;
		svg{
			// Size
			height: 1.4vw;
			width: 1.4vw;

			transition: all 250ms ease;

			&:not(.excludeFill){
				fill: $secondary;
			}
			path{
				transition: all 250ms ease;
				// Apply this class to icons that path doesn't need fill
				&.changeStroke{
					stroke: $secondary;
				}
				// Apply this class to icons that path doesn't need stroke
				&.changeFill{
					fill:$secondary;
				}
			}
		} 

		&:hover, &.active{
			color: $activeColor;
			background-color: $primary;

			svg{
				// Apply this class to icons that path doesn't need svg fill 
				&:not(.excludeFill){
					fill: $activeColor;
				}

				path{
					// Apply this class to icons that path doesn't need fill
					&.changeStroke{
						stroke: $activeColor;
					}
					// Apply this class to icons that path doesn't need stroke
					&.changeFill{
						fill:$activeColor;
					}
				}
			}
		}
		
		&:active,&.active{
			scale: .95;
		}
	}


	&.small{
		font-size: .6vw;
		.switch-button{
			padding: 0.2vw 1vw;
		}
		svg{
			height: 1vw;
			width: 1vw;
		}		
	}
	&.large{
		font-size: 1.3vw;
		.switch-button{
			padding: .5vw 1.5vw;
		}
		svg{
			height: 1.8vw;
			width: 1.8vw;
			scale: 1.1;
		}
	}

	&.vertical {
		flex-direction: column;
		gap: 0.35vw;
		padding: 0.41vw 0.278vw;
		border-radius: 0.7vw;

		.switch-button{
			cursor: pointer;
			border-radius: 2vw;
			white-space: nowrap;
			padding: 0.07vw 0.14vw;
			transition: all 250ms ease;
			color: $secondary;
			display: flex;
			align-items: center;
			justify-content: center;
			scale: 1;
			svg{
				// Size
				height: 1.4vw;
				width: 1.4vw;
	
				transition: all 250ms ease;
	
				&:not(.excludeFill){
					fill: $secondary;
				}
				path{
					transition: all 250ms ease;
					// Apply this class to icons that path doesn't need fill
					&.changeStroke{
						stroke: $secondary;
					}
					// Apply this class to icons that path doesn't need stroke
					&.changeFill{
						fill:$secondary;
					}
				}
			} 
	
			&:hover, &.active{
				color: $activeColor;
				background-color: $primary;
	
	
				svg{
					// Apply this class to icons that path doesn't need svg fill 
					&:not(.excludeFill){
						fill: $activeColor;
					}
					path{
						// Apply this class to icons that path doesn't need fill
						&.changeStroke{
							stroke: $activeColor;
						}
						// Apply this class to icons that path doesn't need stroke
						&.changeFill{
							fill:$activeColor;
						}
					}
				}
			}
			&:active,&.active{
				scale: .95;
			}
		}
	
	}
}

@media screen and (max-width: 768px) {

	.switch-buttons-container{
		padding: 0.15vw;
		border-radius: 2.25vw;
		font-size: 1.35vw;
		.switch-button{
			border-radius: 3vw;
			padding: .525vw 1.5vw;
			svg{
				// Size
				height: 2.1vw;
				width: 2.1vw;
			} 
		}


		&.small{
			font-size: .9vw;
			.switch-button{
				padding: 0.3vw 1.5vw;
			}
			svg{
				height: 1.5vw;
				width: 1.5vw;
			}		
		}
		&.large{
			font-size: 1.95vw;
			.switch-button{
				padding: .75vw 2.25vw;
			}
			svg{
				height: 2.7vw;
				width: 2.7vw;
				scale: 1.1;
			}
		}

		&.vertical {
			gap: 0.525vw;
			padding: 0.61vw 0.42vw;
			border-radius: 1.05vw;

			.switch-button{
				border-radius: 3vw;
				padding: 0.1vw 0.21vw;
				svg{
					// Size
					height: 2.1vw;
					width: 2.1vw;
				} 
		
			}
		
		}
	}
}

@media screen and (max-width: 425px) {

	.switch-buttons-container{
		padding: 0.3vw;
		border-radius: 4.5vw;
		font-size: 2.7vw;
		.switch-button{
			border-radius: 6vw;
			padding: 1.5vw 3vw;
			svg{
				// Size
				height: 4.2vw;
				width: 4.2vw;
			} 
		}


		&.small{
			font-size: 1.8vw;
			.switch-button{
				padding: 0.6vw 3vw;
			}
			svg{
				height: 3vw;
				width: 3vw;
			}		
		}
		&.large{
			font-size: 4vw;
			.switch-button{
				padding: 1.5vw 4.5vw;
			}
			svg{
				height: 5.4vw;
				width: 5.4vw;
				scale: 1.1;
			}
		}

		&.vertical {
			gap: 1.5vw;
			padding: 1.22vw 0.84vw;
			border-radius: 2.1vw;

			.switch-button{
				border-radius: 6vw;
				padding: 0.2vw 0.4vw;
				svg{
					// Size
					height: 4.2vw;
					width: 4.2vw;
				} 
		
			}
		
		}
	}
}