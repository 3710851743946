.timeline-calendar-table-time-interval-row-container{
    display: grid;

    .timeline-calendar-table-time-interval-container-item{
		background-color: var(--backgroundMain);
        margin: 1vw 0 0 0;
        border-radius: 0.7vw;
        padding: 0.7vw;
        box-shadow: 0 0 0.7vw 0 rgba(71, 71, 71, 0.25);
        display: flex;
        gap: 0.7vw;
        justify-content: space-between;
        z-index: 1;

        .info {
            .title {
                width: fit-content;
				
                .top-line {
                   height: 0.138vw;
                   border-radius: 0.5vw;   
                }
            }

            p{
                color: var(--text1);
                font-size: 1.11vw;
                font-weight: 500;
				white-space: wrap;
            }

            .date-container {
                color: var(--text3);
                margin-top: 0.7vw;
                font-size: 1vw;
            }
        }
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: end;
            .executors-container {
                display: flex;
                align-items: center;
                .executors{
                    display: flex;
                    align-items: center;
                    .executor-avatar-container {
                        height: 1.7vw;
                        width: 1.7vw;
                        &:not(:first-child) {
                            margin-left: -0.8vw;
                        }
                    }
                }
                
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .timeline-calendar-table-time-interval-row-container{
    
        .timeline-calendar-table-time-interval-container-item{
            margin: 1.5vw 0 0 0;
            border-radius: 1.05vw;
            padding: 1.05vw;
            box-shadow: 0 0 1.05vw 0 rgba(71, 71, 71, 0.25);
            gap: 1.05vw;

            .info {
                .title {
                    
                    .top-line {
                       height: 0.21vw;
                       border-radius: 0.75vw;   
                    }
                }
    
                p{
                    font-size: 1.66vw;
                }
    
                .date-container {
                    margin-top: 1.05vw;
                    font-size: 1.5vw;
                }
            }
            .actions {
                .executors-container {
                    .executors{
                        .executor-avatar-container {
                            height: 2.25vw;
                            width: 2.25vw;
                            &:not(:first-child) {
                                margin-left: -1.2vw;
                            }
                        }
                    }
                    
                }
            }
        }
    }    

}

@media screen and (max-width: 425px) {

    .timeline-calendar-table-time-interval-row-container{
    
        .timeline-calendar-table-time-interval-container-item{
            margin: 3vw 0 0 0;
            border-radius: 2.1vw;
            padding: 2.1vw;
            box-shadow: 0 0 2.1vw 0 rgba(71, 71, 71, 0.25);
            gap: 2.1vw;

            .info {
                .title {
                    
                    .top-line {
                       height: 0.4vw;
                       border-radius: 1.5vw;   
                    }
                }
    
                p{
                    font-size: 3.32vw;
                }
    
                .date-container {
                    margin-top: 2.1vw;
                    font-size: 3vw;
                }
            }
            .actions {
                .executors-container {
                    .executors{
                        .executor-avatar-container {
                            height: 4.5vw;
                            width: 4.5vw;
                            &:not(:first-child) {
                                margin-left: -2.4vw;
                            }
                        }
                    }
                    
                }
            }
        }
    }    

}