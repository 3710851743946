@use '/src/styles/variables' as styles;

.chat-card-wrapper {
  width: 100%;
  padding: 1.7vw 2vw;
  display: flex;
  align-items: center;
  transition: all ease 0.3s;

  .unread-messages-container{
    width: 1.52vw;
    height: 1.52vw;
    border-radius: 50%;
    background: var(--accentPrimary);
    display: flex;
    justify-content: center;
    align-items: center;

    .unread-messages {
      font-size: styles.$textSm;
      font-weight: styles.$fontBold;
      color: var(--textHover);
    }
  }

  &:hover{
    background: var(--backgroundSubContainer); 
  }
}

@media screen and (max-width: 768px) {

  .chat-card-wrapper {
    padding: 2.55vw 3vw;
  
    .unread-messages-container{
      width: 2.28vw;
      height: 2.28vw;
  
      .unread-messages {
        font-size: 1.45vw;
      }
    }
  }

}

@media screen and (max-width: 425px) {

  .chat-card-wrapper {
    padding: 5.1vw 6vw;
  
    .unread-messages-container{
      width: 4.56vw;
      height: 4.56vw;
  
      .unread-messages {
        font-size: 2.9vw;
      }
    }
  }

}