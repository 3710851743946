.order-item-skeleton-container{
  padding: 1.38vw 1.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  background: white;
  box-shadow: 0 0 0.7vw 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.4vw;
  min-height: 30vw;
}

@media screen and (max-width: 768px) {
    .order-item-skeleton-container{
        padding: 2.1vw 2.5vw;
        gap: 1.5vw;
        box-shadow: 0 0 1.05vw 0 rgba(0, 0, 0, 0.1);
        border-radius: 2.1vw;
      }
}

@media screen and (max-width: 425px) {
    .order-item-skeleton-container{
        padding: 4.2vw 5vw;
        gap: 3vw;
        box-shadow: 0 0 2.1vw 0 rgba(0, 0, 0, 0.1);
        border-radius: 4.2vw;
      }
}