@use '/src/styles/variables' as styles;
.message-area {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 1.736vw;

  .date-banner{
    width: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: var(--textHover);
    border-radius: styles.$rounded2xl;
    position: absolute;
    left: calc(50% - 6vw);
    top: 7vw;
    transition: all ease 0.3s;
    opacity: 1;
    font-size: 1.1vw;
    z-index: 3;
  }

  .hidden-date-banner{
    opacity: 0;
  }
  
  .loading-messages .box::before {
    background: #caddff;
  }

  .loading-messages {
    top: 7vw;
    position: absolute;
    left: calc(50% - 2.28vw);
  }

  .text-container{
    width: 100%;
    height: 1.66vw;
    padding-top: 3.47vw;
    padding-bottom: 3.47vw;
    align-items: center;
    display: flex;
    justify-content: center;

    p{
        font-size: styles.$textMd;
        line-height: 1.66vw;
        color: var(--text1);
    }
  }

  ::-webkit-scrollbar-thumb {
    border-color: transparent;
    border: 3px solid transparent;
  }

  /* width */
  ::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;

  }
}

.message-area-block-scroll {
  overflow: hidden;
}

@media screen and (max-width: 768px) {

  .message-area {
    padding: 0 2.55vw;
  
    .date-banner{
      width: 18vw;
      border-radius: 3vw;
      left: calc(50% - 12vw);
      top: 10.5vw;
      font-size: 1.66vw;
    }
  
    .loading-messages {
      top: 10.5vw;
      left: calc(50% - 3.42vw);
    }
  
    .text-container{
      height: 2.5vw;
      padding-top: 5.2vw;
      padding-bottom: 5.2vw;
  
      p{
          font-size: 1.66vw;
          line-height: 2.5vw;
      }
    }
  }

}



@media screen and (max-width: 425px) {

  .message-area {
    padding: 0 5vw;
  
    .date-banner{
      width: 36vw;
      border-radius: 6vw;
      left: calc(50% - 24vw);
      top: 21vw;
      font-size: 3.32vw;
    }
  
    .loading-messages {
      top: 21vw;
      left: calc(50% - 6.84vw);
    }
  
    .text-container{
      height: 5vw;
      padding-top: 10.4vw;
      padding-bottom: 10.4vw;
  
      p{
          font-size: 3.32vw;
          line-height: 5vw;
      }
    }
  }

}