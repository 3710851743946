@use '/src/styles/variables' as styles;

.chat-message-container {
  width: 100%;
  opacity: 1;
  transition: all ease 0.4s;
  display: flex;
  flex-direction: column;
  gap: 0.55vw;

  &.user-message-container {
    align-items: flex-end;

    .chat-message-pinned-container{
      svg{
        height: 100%;
        fill: var(--textHover);
      }
    }

    .audio-wave{
      --waveFillingAccent: #444;
      --waveAccent: #fff;
    }

    .audio-recorder-timer {
      --audioTimerColor:#fff;
    }
  }
  
  &.stranger-message-container {
    align-items: flex-start;
  
    .chat-message-avatar-container{
      min-width: 1.73vw;
      max-width: 1.73vw;
      min-height: 1.73vw;
      max-height: 1.73vw;
      border-radius: 50%;
    }
    
    .edited{
      color: var(--text1);
    }

    .chat-message-pinned-container{
      svg{
        height: 100%;
        fill: var(--text1);
      }
    }

    .audio-wave{
      --waveFillingAccent: #444;
      --waveAccent: #fff;
    }

    .audio-recorder-timer {
      --audioTimerColor:#444;
    }
  }
  

  .chat-message-info-container{
    display: flex;
    justify-content: space-between;
    margin-left: 2.81vw;
    width: calc(100% - 2.81vw);

    .chat-message-info-item{
      color: var(--text1);
      font-size: styles.$textMd;
      max-width: 50%;
    }
  }

  .chat-message {
    display: flex;
    gap: 0.7vw;
    max-width: 60%;
    margin-bottom: 2vw;

    .chat-message-content-wrapper{
      display: flex;
      flex-direction: column;
      
      .chat-message-content{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0.55vw;
        border-radius: 0.7vw;

        .chat-message-text{
          font-size: styles.$textLg;
          word-break: break-word;
          white-space: pre-wrap;
          width: 100%;
          padding: 0.35vw 0.7vw 0.7vw 0.7vw;
        }

        &.user-message {
          background: var(--accentPrimary);
      
          p {
            color: var(--textHover);
          }
        }
      
        &.stranger-message {
          background: var(--backgroundNestedSubContainer);
      
          p {
            color: var(--text1);
          }
        }
      }

      .chat-message-status{
        color: var(--text1);
        font-size: styles.$text2sm;
        display: flex;
        justify-content: flex-end;
      }
    }
    
  }

  

  .chat-message-pinned-container{
    height: 1vw;
    width: 100%;

    display: flex;
    justify-content: end;

    svg{
      height: 100%;
      fill: var(--text1);
    }
  }
}

.hidden-message{
  opacity: 0;
}


@media screen and (max-width: 768px) {

  .chat-message-container {
    gap: 0.75vw;
    
    &.stranger-message-container {
    
      .chat-message-avatar-container{
        min-width: 2.6vw;
        max-width: 2.6vw;
        min-height: 2.6vw;
        max-height: 2.6vw;
      }
      
    }
    
  
    .chat-message-info-container{
      margin-left: 4.22vw;
      width: calc(100% - 4.22vw);
  
      .chat-message-info-item{
        font-size: 1.66vw;
      }
    }
  
    .chat-message {
      gap: 1.05vw;
      margin-bottom: 3vw;
  
      .chat-message-content-wrapper{
        .chat-message-content{
          padding: 0.75vw;
          border-radius: 1.05vw;
  
          .chat-message-text{
            font-size: 1.875vw;
            padding: 0.525vw 1.05vw 1.05vw 1.05vw;
          }
  
        }
  
        .chat-message-status{
          font-size: 1.2vw;
        }
      }
      
    }
  
    .chat-message-pinned-container{
      height: 1.5vw;
    }
  }
  
}

@media screen and (max-width: 425px) {

  .chat-message-container {
    gap: 1.5vw;
    
    &.stranger-message-container {
    
      .chat-message-avatar-container{
        min-width: 5.1vw;
        max-width: 5.1vw;
        min-height: 5.1vw;
        max-height: 5.1vw;
      }
      
    }
    
  
    .chat-message-info-container{
      margin-left: 8.44vw;
      width: calc(100% - 8.44vw);
  
      .chat-message-info-item{
        font-size: 3.32vw;
      }
    }
  
    .chat-message {
      gap: 2.1vw;
      margin-bottom: 6vw;
  
      .chat-message-content-wrapper{
        .chat-message-content{
          padding: 1.5vw;
          border-radius: 2.1vw;
  
          .chat-message-text{
            font-size: 3.75vw;
            padding: 1.05vw 2.1vw 2.1vw 2.1vw;
          }
  
        }
  
        .chat-message-status{
          font-size: 2.4vw;
        }
      }
      
    }
  
    .chat-message-pinned-container{
      height: 3vw;
    }
  }
  
}