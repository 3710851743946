@use '/src/styles/variables' as styles;
.chat-video-container{
	background-color: var(--backgroundMain);
	width: 100%;
	$borderRadius: 1vw;
	border-radius: styles.$roundedLg;
	display: flex;
	flex-direction: column;
	justify-content: end;
    font-size: 1vw;
	overflow: hidden;

	.chat-video-preview{
		flex-basis: 1;
		height: 100%;
		background-color: rgba(72, 72, 72, 0.313);
		margin: 0.4vw;
		height: 25vw;
		display: flex;
		border-radius: styles.$roundedLg;
		transition: all 500ms ease;
		&.open{
			max-height: 25vw;
			opacity: 1;
		}
		&.closed{
			opacity: 0;
			max-height: 0vh;
			margin: 0;
			video{
				max-height: 0%;
			}
		}
		video{
			border-radius: styles.$roundedLg;
			height: 100%;
			width: 100%;
			background-color: var(--text1);
			object-fit: cover;
		}
        .video-player-container,.video-player-wrapper{
			width: 100%;
			height: 100%;
		}
	}
	.chat-video-action{
		height: 8vh;	
		max-height: 37vh;

		.chat-video-action_recording{
			display: flex;
			justify-content: space-between;
			height: min-content;
			align-items: stretch;
			.btn-delete{
				background-color: var(--colorAlert);
				width: 3vw;
				padding: .7vw;
				border-radius: 0 0 0 styles.$roundedMd;
				display: flex;
				border: none;
				align-items: center;
				cursor: pointer;
				svg{
					transition: all 250ms ease;
				}
				&:hover{
					background-color:#E3494C;
					svg{
						scale: 1.1;	
					}
				}
			}

			.btn-tool{
				$size:3vw;
				width: $size;
				height: $size;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 250ms ease;
				cursor: pointer;
				margin-bottom: .5vw;
				margin-right: 1vw;
				border: none;
				background:none;
				border-radius: 50%;
				aspect-ratio: 1/1;
				svg{
					width: calc($size / 2);
					height: calc($size / 2);
					fill: #444444;
					stroke: #444444;
					path,rect{
						fill: #444 !important;
					}
				}

                &:hover{
					background-color: var(--accentPrimary);
					svg{
						fill: var(--textHover);
						stroke: var(--textHover);
						path,rect{
							fill: var(--textHover) !important;
						}
					}
				}
			}

			&.video-action-end{
				align-items: center;
				height: 100%;
				.btn-delete{
					align-self: stretch;
				}
			}
			.chat-video-recording-status{
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: center;
				gap: .2vw;
				margin: 0 1vw;
				padding: .3vw 0;
				svg{
					width: 50%;
					height: 1.5vw;
					margin:  0 auto;
				}
				.timer{
					font-weight: 300;
					font-size: 1vw;
					color: var(--accentPrimary);
					width: 3vw;
					text-align: center;
				}
			}
		}

        .chat-video-action_start{
			display: flex;
			align-items: center;
			height: 100%;
			justify-content: center;
			gap: 1vw;
            
			.button:first-of-type{
				$borderColor: var(--colorAlert); 				
			}

			.button{
				$borderColor: var(--accentPrimary);
				border: .15vw solid $borderColor;

				opacity: 0;
			}

			.button:nth-child(2){
				$borderColor: var(--colorAlert); 
				border: .15vw solid var(--colorAlert);
				&:hover{
					background-color: var(--colorAlert);
				}
			}
            
            &.show{
				.button{
					opacity: 1;
				}
			}
			padding: .5vw;

		}
	}
}

@media screen and (max-width: 768px) {

	.chat-video-container{
		border-radius: 1.5vw;
		font-size: 1.5vw;
	
		.chat-video-preview{
			margin: 0.6vw;
			height: 37.5vw;
			border-radius: 1.5vw;

			&.open{
				max-height: 37.5vw;
			}

			video{
				border-radius: 1.5vw;
			}
		}
		.chat-video-action{
	
			.chat-video-action_recording{
				.btn-delete{
					width: 4.5vw;
					padding: 1.05vw;
					border-radius: 0 0 0 0.75vw;
				}
	
				.btn-tool{
					width: 4.5vw;
					height: 4.5vw;
					margin-bottom: .75vw;
					margin-right: 1.5vw;
					
					svg{
						width: calc(4.5vw / 2);
						height: calc(4.5vw / 2);
					}
				}

				.chat-video-recording-status{
					gap: .3vw;
					margin: 0 1.5vw;
					padding: .45vw 0;
					svg{
						height: 2.25vw;
					}
					.timer{
						font-size: 1.5vw;
						width: 4.5vw;
					}
				}
			}
	
			.chat-video-action_start{
				gap: 1.5vw;
				
				
				.button{
					$borderColor: var(--accentPrimary);
					border: .22vw solid $borderColor;
				}
	
				.button:nth-child(2){
					border: .2vw solid var(--colorAlert);
				}
				
				padding: .75vw;
	
			}
		}
	}
}

@media screen and (max-width: 425px) {

	.chat-video-container{
		border-radius: 3vw;
		font-size: 3vw;
	
		.chat-video-preview{
			margin: 1.2vw;
			height: 75vw;
			border-radius: 3vw;

			&.open{
				max-height: 75vw;
			}

			video{
				border-radius: 3vw;
			}
		}
		.chat-video-action{
	
			.chat-video-action_recording{
				.btn-delete{
					width: 9vw;
					padding: 2.1vw;
					border-radius: 0 0 0 1.5vw;
				}
	
				.btn-tool{
					width: 9vw;
					height: 9vw;
					margin-bottom: 1.5vw;
					margin-right: 3vw;
					
					svg{
						width: calc(9vw / 2);
						height: calc(9vw / 2);
					}
				}

				.chat-video-recording-status{
					gap: .6vw;
					margin: 0 3vw;
					padding: .9vw 0;
					svg{
						height: 3.5vw;
					}
					.timer{
						font-size: 3vw;
						width: 9vw;
					}
				}
			}
	
			.chat-video-action_start{
				gap: 3vw;
				
				
				.button{
					$borderColor: var(--accentPrimary);
					border: .44vw solid $borderColor;
				}
	
				.button:nth-child(2){
					border: .4vw solid var(--colorAlert);
				}
				
				padding: 1.5vw;
	
			}
		}
	}
}