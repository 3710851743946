.filter-bar-slider{
    transition: 400ms all ease;
    width: 0vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    background: #fff;
    box-shadow: 0.15vw 0.15vw 0.7vw 0.7vw rgba(0, 0, 0, 0.048);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1vw;

    &-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1vw;
        svg{
            cursor: pointer;
            width: 1.5vw;
        }
    }

    &.visible{
        width: 22.8vw;
        padding: 1.8vw;
    }

    .filter-bar-calendar-label {
        font-weight: 400;
        font-size: 1.11vw;
        line-height: 1.39vw;
    }

}

@media screen and (max-width: 768px) {

    .filter-bar-slider{
        box-shadow: 0.22vw 0.22vw 1.05vw 1.05vw rgba(0, 0, 0, 0.048);
        gap: 1.5vw;
    
        &-header{
            svg{
                width: 2.25vw;
            }
        }
    
        &.visible{
            width: 34.2vw;
            padding: 2.7vw;
        }
    }

}

@media screen and (max-width: 425px) {

    .filter-bar-slider{
        box-shadow: 0.44vw 0.44vw 2.1vw 2.1vw rgba(0, 0, 0, 0.048);
        gap: 3vw;
    
        &-header{
            svg{
                width: 4.5vw;
            }
        }
    
        &.visible{
            width: 68.4vw;
            padding: 5.4vw;
        }
    }

}