.clock-container{
    width: 22vw;
    height: 22vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .clock {
        width: 80%;
        height: 80%;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        border: 0.5vw solid #fff;

        .clear-button{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .button{
                width: fit-content;
            }
        }

        .number {
            --roation: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            transform: rotate(var(--roation));
            font-size: 1vw;
            padding: 2%;
    
            &::before{
                content: '';
                position: absolute;
                top: -0.28vw;
                left: 50%;
                transform: translateX(-50%);
                width: 0.13vw;
                height: 0.347vw;
                background-color: black;
            }
    
            &.number1 {
                --roation: 15deg;
    
                div{
                    transform: rotate(-15deg);
                  }
            }
    
            &.number2 {
                --roation: 30deg;
    
                div{
                    transform: rotate(-30deg);
                  }
            }
    
            &.number3 {
                --roation: 45deg;
    
                div{
                    transform: rotate(-45deg);
                  }
            }
    
            &.number4 {
                --roation: 60deg;
    
                div{
                    transform: rotate(-60deg);
                  }
            }
    
            &.number5 {
                --roation: 75deg;
    
                div{
                    transform: rotate(-75deg);
                  }
            }
    
            &.number6 {
                --roation: 90deg;
    
                div{
                    transform: rotate(-90deg);
                  }
            }
    
            &.number7 {
                --roation: 105deg;
    
                div{
                    transform: rotate(-105deg);
                  }
            }
    
            &.number8 {
                --roation: 120deg;
    
                div{
                    transform: rotate(-120deg);
                  }
            }
    
            &.number9 {
                --roation: 135deg;
    
                div{
                    transform: rotate(-135deg);
                  }
            }
    
            &.number10 {
                --roation: 150deg;
    
                div{
                    transform: rotate(-150deg);
                  }
            }
    
            &.number11 {
                --roation: 165deg;
    
                div{
                    transform: rotate(-165deg);
                  }
            }
    
            &.number12 {
                --roation: 180deg;
    
                div{
                    transform: rotate(-180deg);
                  }
            }
    
    
            &.number13 {
                --roation: 195deg;
    
                div{
                    transform: rotate(-195deg);
                  }
            }
    
            &.number14 {
                --roation: 210deg;
    
                div{
                    transform: rotate(-210deg);
                  }
            }
    
            &.number15 {
                --roation: 225deg;
    
                div{
                    transform: rotate(-225deg);
                  }
            }
    
            &.number16 {
                --roation: 240deg;
    
                div{
                    transform: rotate(-240deg);
                  }
            }
    
            &.number17 {
                --roation: 255deg;
    
                div{
                    transform: rotate(-255deg);
                  }
            }
    
            &.number18 {
                --roation: 270deg;
    
                div{
                    transform: rotate(-270deg);
                  }
            }
    
            &.number19 {
                --roation: 285deg;
    
                div{
                    transform: rotate(-285deg);
                  }
            }
    
            &.number20 {
                --roation: 300deg;
    
                div{
                    transform: rotate(-300deg);
                  }
            }
    
            &.number21 {
                --roation: 315deg;
    
                div{
                    transform: rotate(-315deg);
                  }
            }
    
            &.number22 {
                --roation: 330deg;
    
                div{
                    transform: rotate(-330deg);
                  }
            }
    
            &.number23 {
                --roation: 345deg;
    
                div{
                    transform: rotate(-345deg);
                  }
            }
    
            &.number24 {
                --roation: 360deg;
    
                div{
                    transform: rotate(-360deg);
                  }
            }
        }
      }
      
}

@media screen and (max-width: 768px) {
    .clock-container{
        width: 33vw;
        height: 33vw;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .clock {
            width: 80%;
            height: 80%;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            border: 0.75vw solid #fff;
    
            .number {
                font-size: 1.5vw;
                padding: 2%;
        
                &::before{
                    content: '';
                    position: absolute;
                    top: -0.42vw;
                    width: 0.3vw;
                    height: 0.6vw;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .clock-container{
        width: 66vw;
        height: 66vw;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .clock {
            width: 80%;
            height: 80%;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            border: 1.5vw solid #fff;
    
            .number {
                font-size: 3vw;
                padding: 2%;
        
                &::before{
                    content: '';
                    position: absolute;
                    top: -0.82vw;
                    width: 0.6vw;
                    height: 1.2vw;
                }
            }
        }
    }
}