.counterparty-skeleton{
    width:100%;
    padding: 0.7vw 1.05vw;
    display: flex;
    align-items: center;
    gap: 3vw;
    box-shadow: 0.2vw 0.13vw 0.69vw 0vw rgba(0, 0, 0, 0.3);
    position: relative;
    border-radius: 1vw;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .counterparty-skeleton{
        padding: 1.05vw 1.75vw;
        gap: 4.5vw;
        border-radius: 1.5vw;
    }
}

@media screen and (max-width: 425px) {
    .counterparty-skeleton{
        padding: 2.1vw 3.5vw;
        gap: 4.5vw;
        border-radius: 3vw;
    }
}