@use '/src/styles/variables' as styles;
.editable-table {
	width: 100%;
	.editable-table-header{
		display: flex;
		align-items: center;
		gap: 1vw;

		&.error{
			border: 1px solid var(--colorAlertText);
		}
	}

	.error-text{
		color: var(--colorAlertText);
		font-size: styles.$text2sm;
		font-weight: 400;
		margin-top: 0.5vw;
		display: flex;
		gap: 0.35vw;
		align-items: center;

		svg{
			width: 1.38vw;
			height: 1.38vw;
		}

	}

	.pagination-container {
		overflow: auto;
		max-height: 50vh;
	
		.editable-table-checkbox {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.table-body-row.selected {
			background-color: var(--accentTertiary);
			transition: all 100ms ease;
		}
		.editable-table-name {
			width: 33.5vw;
			font-size: styles.$textSm;
			display: flex;
			align-items: center;
			gap: 0.7vw;
	
			.product-avatar {
				width: 1.67vw
			}
		}
	
		.editable-table-quantity {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 0.2vw;
			height: 100%;
			.product-amount{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				gap: .2vw;
				min-width: 3.5vw;
				input {
					text-align: left;
					height: 1.5vw;
					border: none;
					background: transparent;
					font-size: styles.$textLg;
					padding-block: .3vw;
				}
				p{
					font-size: styles.$textSm;
					line-height: 80%;
					margin-top: .2vw;
				}
			}
			.quantity-control {
				cursor: pointer;
				height: 100%;
				display: flex;
				align-items: center;
				svg{
					width: 1.5vw;
					height: 1.4vw;
	
				}
				&:hover{
					scale: 1.04;
				}
			}
	
			font-size: styles.$textSm
		}
	
		.editable-table-price {
			font-size: styles.$textSm;
			width: 7vw;
			display: flex;
			align-items: center;
			gap: 0.3vw;
			.editable-table-price-currency{
				overflow: visible;
			}
		}
	}
}

@media screen and (max-width: 768px) {

	.editable-table {
		.editable-table-header{
			gap: 1.5vw;
		}

		.pagination-container {
			overflow: auto;
			max-height: 30vw;
			.editable-table-name {
				width: 50.25vw;
				font-size: 1.45vw;
				gap: 1.05vw;
		
				.product-avatar {
					width: 2.55vw
				}
			}
		
			.editable-table-quantity {
				gap: 0.3vw;
				.product-amount{
					gap: .3vw;
					min-width: 5.25vw;
					input {
						height: 2.25vw;
						font-size: 1.875vw;
						padding-block: .3vw;
					}
					p{
						font-size: 1.45vw;
						margin-top: .3vw;
					}
				}
				.quantity-control {
					svg{
						width: 2.25vw;
						height: 2.25vw;
		
					}
				}
		
				font-size: 1.45vw;
			}
		
			.editable-table-price {
				font-size: 1.45vw;
				width: 10.5vw;
				gap: 0.45vw;
			}
		}
	}
	
}


@media screen and (max-width: 425px) {

	.editable-table {
		width: 75vw;
		.editable-table-header{
			gap: 3vw;
		}
		.pagination-container {
			max-height: 60vw;
			.editable-table-name {
				width: 100vw;
				font-size: 2.9vw;
				gap: 2.1vw;
		
				.product-avatar {
					width: 5.1vw
				}
			}
		
			.editable-table-quantity {
				gap: 0.6vw;
				.product-amount{
					gap: .6vw;
					min-width: 10.5vw;
					input {
						height: 5.5vw;
						font-size: 3.75vw;
						padding-block: .6vw;
					}
					p{
						font-size: 2.9vw;
						margin-top: .6vw;
					}
				}
				.quantity-control {
					svg{
						width: 4.5vw;
						height: 4.5vw;
		
					}
				}
		
				font-size: 2.9vw;
			}
		
			.editable-table-price {
				font-size: 2.9vw;
				width: 21vw;
				gap: 0.9vw;
			}
		}
	}
	
}