@use '/src/styles/variables' as styles;

.language-selector{
    display: flex;
    align-items: center;
    gap: 1vw;

    .language-selector-button{
        cursor: pointer;
        
        transition: 400ms;
        width: 2vw;
        height: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;

        p{
            color: var(--accentPrimary);
            font-size: styles.$textSm;
            font-weight: styles.$fontMedium;
        }

        &.active, &:hover{
            scale: .98;
            background-color: var(--accentTertiary);

            p{
                color: var(--accentPrimary);
                font-weight: styles.$fontBold;
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .language-selector{
        gap: 1.5vw;
    
        .language-selector-button{
            width: 3vw;
            height: 3vw;

            p{
                font-size: 1.45vw;
            }

        }
    }

}

@media screen and (max-width: 425px) {

    .language-selector{
        gap: 3vw;
    
        .language-selector-button{
            width: 6vw;
            height: 6vw;

            p{
                font-size: 2.9vw;
            }

        }
    }

}