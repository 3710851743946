.chat-files-slider{
    position: absolute;
    right: 0;
    background: var(--backgroundSubContainer);
    height: calc(100% - 6.25vw);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0;
    transition: all 0.4s ease;
    box-shadow: 3px 2px 0.7vw 0px rgba(0, 0, 0, 0.10);
    padding: 1vw 0;
    z-index: 1;
    
    .chat-files-slider-content-container{
        display: grid;
        grid-template-columns: 1fr;
        width: 90%;

        img{
            width: 100%;
        }
        
    }
}