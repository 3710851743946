// Mixin
// ------------------
// Behave like utility, but used inside scss file on class declaration



// Layouts
// ------------------------------
@mixin grid-two{
	display: grid;
	grid-template-columns: repeat(2,1fr);
}

@mixin grid-three{
	display: grid;
	grid-template-columns: repeat(3,1fr);
}


@mixin flex-col{
	display: flex;
	flex-direction: column;
}

@mixin flex-between{
	display: flex;
	justify-content: space-between;
}

@mixin flex-center{
	display: flex;
	align-items: center;
	justify-content: center;
}