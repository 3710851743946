.editable-cell{
    font-size: 1.25vw;
    color: ver(--text1);
    border-radius: 0.4vw;
    border: 1px solid rgba(0, 0, 0, 0);

    .input-wrapper{
        .input-field{
            .input-field-wrapper{
                box-shadow: none;
                padding: 0;
                background: none;
            }
        
        }
    }

    .text-area {
        .text-area-container {
            background: transparent;
            box-shadow: none;
            padding: 0;
            textarea{
                background: transparent;
            }
        }
    }

    .float-input .float-input-wrapper{
        box-shadow: none;
        padding: 0;
        background: none;
    }

    &.active{
        border: 1px solid var(--accentPrimary);
    }

    .dropdown{
        min-width: 15vw;
    }
}