.create-group-chat-container{
  height: 42vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .create-group-chat-input-container{
    display: flex;
    flex-direction: column;
    width: 31.25vw;
    margin-left: auto;
    margin-right: auto;
    gap: 1.4vw 0;
  }
}

@media screen and (max-width: 768px) {
  .create-group-chat-container{
    height: 63vh;
  
    .create-group-chat-input-container{
      width: 66.875vw;
      gap: 2.1vw 0;
    }
  }

}

@media screen and (max-width: 425px) {
  .create-group-chat-container{
    height: 90vh;
  
    .create-group-chat-input-container{
      width: 80vw;
      gap: 4.2vw 0;
    }
  }

}