.landing-text{
    font-size: 1.11vw;
    color: #1E1B39;
    line-height: 140%;
    white-space: normal;
    text-align: center;
}

@media (max-width: 768px) {
    .landing-text{
        font-size: 1.66vw;
    }
    
}

@media (max-width: 425px) {
    .landing-text{
        font-size: 2.5vw;
    }
}