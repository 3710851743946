@use '/src/styles/variables' as styles;
.my-tasks-calendar-day {
	padding: 2vw;
    width: 100%;
    border-radius: 1.5vw;
    box-shadow:  .15vw .15vw .7vw .7vw rgba(0, 0, 0, 0.048);
	position: relative;
	overflow: auto;

	> hr:first-child {
		position: absolute;
		top: 32.1vw;
		left: -22vw;
		width: 59.28vw;
		transform: rotateZ(90deg);
		opacity: 0.3;
	}
	> hr:last-child {
		position: absolute;
		top: 32.1vw;
		right: -27.6vw;
		width: 59.28vw;
		transform: rotateZ(90deg);
		opacity: 0.3;
	}

	.my-tasks-calendar-day-table{
		width: 100%;
		height: 62.25vw;
		overflow: auto;

		.my-tasks-calendar-day-table-container{
			display: flex;
			height: 62.25vw;
			flex-direction:column;
			gap: 1.5vw;
			position: relative;

			.my-tasks-calendar-day-table-cell {
				display: flex;
				align-items: center;
				gap: 1vw;
				p {
					font-size: 0.97vw;
					line-height: 0.97vw;
					width: 2.8vw;
					text-align: right;
					color: var(--text2)
				}
				hr {
					flex-grow: 1;
					opacity: 0.3;
				}
			}
	
			.my-tasks-calendar-day-timer{
				position: absolute;
				right: 0.05vw;
				width: calc(100% - 5.6vw); 
				&::before {
					content: '';
					position: absolute;
					left: -0.08vw;
					top: 0.86vw;
					width: 0.35vw;
					height: 0.35vw;
					border-radius: 50%;
					background-color: black
				}
	
				&-time {
					font-size: 0.97vw;
					line-height: 0.97vw;
					text-align: center
				}
	
				> hr {
					height: 0.21vw; 
					background: black
				}
			}
			
			.my-tasks-calendar-day-tasks {
				width: calc(100% - 5.69vw);
				height: 100%;
				left: 5.69vw;
				position: absolute;
				overflow: auto;
				overflow-y: hidden;

				.my-tasks-calendar-day-tasks-item {
					position: absolute;
					width: 20vw
				}
			}
			
		}
	}
	
}

@media screen and (max-width: 768px) {
	.my-tasks-calendar-day {
		padding: 3vw;
		border-radius: 2.25vw;
		box-shadow:  .22vw .22vw .11vw .11vw rgba(0, 0, 0, 0.048);

		> hr:first-child {
			top: 48.1vw;
			left: -33vw;
			width: 90vw;
		}
		> hr:last-child {
			top: 48.1vw;
			right: -41.4vw;
			width: 90vw;
		}

		.my-tasks-calendar-day-table{
			height: 93.25vw;

			.my-tasks-calendar-day-table-container{
				height: 93.25vw;
				gap: 2.25vw;

				.my-tasks-calendar-day-table-cell {
					gap: 1.5vw;
					p {
						font-size: 1.48vw;
						line-height: 1.48vw;
						width: 4.2vw;
					}
				}

				.my-tasks-calendar-day-timer{
					right: 0.075vw;
					width: calc(100% - 5.6vw);
					&::before {
						left: -0.12vw;
						top: 1.29vw;
						width: 0.52vw;
						height: 0.52vw;
					}

					&-time {
						font-size: 1.48vw;
						line-height: 1.48vw;
					}

					> hr {
						height: 0.46vw;
					}
				}

				.my-tasks-calendar-day-tasks {
					width: calc(100% - 8.5vw);
					height: 100%;
					left: 8.53vw;

					.my-tasks-calendar-day-tasks-item {
						width: 30vw
					}
				}

			}
		}

	}
}


@media screen and (max-width: 425px) {
	.my-tasks-calendar-day {
		padding: 6vw;
		border-radius: 4.5vw;
		box-shadow:  .45vw .45vw .21vw .21vw rgba(0, 0, 0, 0.048);

		> hr:first-child {
			top: 96.1vw;
			left: -66vw;
			width: 180.28vw;
		}
		> hr:last-child {
			top: 96.1vw;
			right: -81.6vw;
			width: 180.28vw;
		}

		.my-tasks-calendar-day-table{
			height: 186.25vw;

			.my-tasks-calendar-day-table-container{
				height: 186.25vw;
				gap: 4.5vw;

				.my-tasks-calendar-day-table-cell {
					gap: 3vw;
					p {
						font-size: 3vw;
						line-height: 3vw;
						width: 8.4vw;
					}
				}

				.my-tasks-calendar-day-timer{
					position: absolute;
					right: 0.15vw;
					width: calc(100% - 16.8vw);
					&::before {
						left: -0.24vw;
						top: 2.7vw;
						width: 1.05vw;
						height: 1.05vw;
					}

					&-time {
						font-size: 3vw;
						line-height: 3vw;
					}

					> hr {
						height: 0.63vw;
					}
				}

				.my-tasks-calendar-day-tasks {
					width: calc(100% - 17.1vw);
					height: 100%;
					left: 17.1vw;

					.my-tasks-calendar-day-tasks-item {
						width: 60vw
					}
				}
			}
		}
	}
}