@use '/src/styles/variables' as styles;

.chat-bar-header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.11vw;
  padding: 2.78vw 1.4vw;

  .chat-bar-header-button {
    margin-left: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 2vw;
      height: 2.36vw;
    }
  }

  .chat-bar-header-input {
    all: unset;
    padding: 0.9vw 0.6vw;
    width: 17.447916666666668vw;
    height: 1.325vw;
    box-shadow: 3px 4px 0.7vw rgba(0, 0, 0, 0.1); // No variable for this shadow. Necessary as it is used in mnay places
    border-radius: styles.$roundedMd;
    font-weight: styles.$fontLight;
    font-size: styles.$textMd;
    line-height: 1.2369791667vw;
    background: var(--backgroundMain);

    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 768px) {

  .chat-bar-header-wrapper {
    gap: 1.66vw;
    padding: 4.17vw 2.1vw;
  
    .chat-bar-header-button {
      margin-left: 1.8vw;
  
      svg {
        width: 3vw;
        height: 3.54vw;
      }
    }
  
    .chat-bar-header-input {
      padding: 1.35vw 0.9vw;
      width: 26.16vw;
      height: 1.98vw;
      box-shadow: 3px 4px 1.05vw rgba(0, 0, 0, 0.1); // No variable for this shadow. Necessary as it is used in mnay places
      border-radius: 0.75vw;
      font-size: 1.66vw;
      line-height: 1.8vw;
    }
  }

}

@media screen and (max-width: 425px) {

  .chat-bar-header-wrapper {
    gap: 2.5vw;
    padding: 8.4vw 4.2vw;
  
    .chat-bar-header-button {
      margin-left: 3.6vw;
  
      svg {
        width: 6vw;
        height: 7vw;
      }
    }
  
    .chat-bar-header-input {
      padding: 2.7vw 1.8vw;
      width: 52.2vw;
      height: 4vw;
      box-shadow: 3px 4px 2.1vw rgba(0, 0, 0, 0.1); // No variable for this shadow. Necessary as it is used in mnay places
      border-radius: 1.5vw;
      font-size: 2.5vw;
      line-height: 2.7vw;
    }
  }

}