.shift-selector{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw;
    border-radius: 1vw;
    background: #fff;
    gap: 1vw;
    height: fit-content;

    .shift-selector-days{
        display: flex;
        flex-direction: column;
        gap: 1vw;
    }

    .shift-selector-time{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1vw;

        .shift-selector-time-input{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1vw;

            .shift-selector-time-input-container{
                display: flex;
                flex-direction: column;
                gap: 1vw;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .shift-selector{
        padding: 1.5vw;
        border-radius: 1.5vw;
        gap: 1.5vw;
    
        .shift-selector-days{
            gap: 1.5vw;
        }
    
        .shift-selector-time{
            gap: 1.5vw;
            flex-direction: column;
    
            .shift-selector-time-input{
                gap: 1.5vw;
    
                .shift-selector-time-input-container{
                    gap: 1.5vw;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .shift-selector{
        padding: 3vw;
        border-radius: 3vw;
        gap: 3vw;
    
        .shift-selector-days{
            gap: 3vw;
        }
    
        .shift-selector-time{
            gap: 3vw;
            flex-direction: column;
    
            .shift-selector-time-input{
                gap: 3vw;
    
                .shift-selector-time-input-container{
                    gap: 3vw;
                }
            }
        }
    }
}