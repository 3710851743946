.offering-card-skeleton-container{
    position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  background-color: #fff;
  padding: 1.73vw;
  border-radius: 1vw;
  box-shadow: 0.2vw 0.13vw 0.69vw 0vw rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 15vw;
}

@media screen and (max-width: 768px) {
    .offering-card-skeleton-container{
      gap: 1.5vw;
      padding: 2.6vw;
      border-radius: 1.5vw;
      box-shadow: 0.3vw 0.2vw 1.05vw 0vw rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width: 425px) {
    .offering-card-skeleton-container{
        gap: 3vw;
        padding: 5.2vw;
        border-radius: 3vw;
        box-shadow: 0.6vw 0.4vw 2.1vw 0vw rgba(0, 0, 0, 0.1);
      }
}