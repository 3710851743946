@use '/src/styles/variables' as styles;
@use '/src/styles/mixin' as utils;

.forecast-element-container{
	@include utils.flex-col;
    border-radius: styles.$roundedSm;
    padding: 0.2vw 0.5vw;

    &.positive{
        background: var(--colorSuccess);
        .forecast-element-title, .forecast-element-value{
            color: var(--colorSuccessText);
        }
    }

    &.negative{
        background: var(--colorAlert);
        .forecast-element-title, .forecast-element-value{
            color: var(--colorAlertText);
        }
    }

    .forecast-element-title{
        font-size: styles.$text2sm;
    }

    .forecast-element-value{
        font-size: styles.$textSm;
    }
}

@media screen and (max-width: 768px) {


    .forecast-element-container{
        border-radius: 3.75vw;
        padding: 0.3vw 0.75vw;

        .forecast-element-title{
            font-size: 1.2vw;
        }

        .forecast-element-value{
            font-size: 1.45vw;
        }
    }
}

@media screen and (max-width: 425px) {


    .forecast-element-container{
        border-radius: 7.5vw;
        padding: 0.6vw 1.5vw;

        .forecast-element-title{
            font-size: 2.4vw;
        }

        .forecast-element-value{
            font-size: 2.9vw;
        }
    }


}