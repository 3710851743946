.server-error-container{
    height: 100vh;
    .section-headline {
        margin-top: 5vw;
        color: #1E1B39;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height: 100vh;
        .error-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3vw;
            .error-text{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1vw;
                h1{
                    text-align: center;
                    color: #1E1B39;
                }
                p{
                    text-align: center;
                    font-weight: 500;
                    font-size: large;
                }
            }
        }
        .headline-text {
            text-align: center;
    
            .headline-heading {
                font-size: 3.2vw;
            }
    
            .headline-subheading {
                font-size: 2.1vw;
                font-weight: 600;
            }
        }
        .section-headline-right-lines, 
        .section-headline-left-lines {
            display: flex;
            flex-direction: column;
            gap: 1vw;
    
            .section-headline-long-line{
                width: 18vw;
                height: 0.21vw;
                background: #1E1B39;
            }
            .section-headline-short-line{
                width: 12vw;
                height: 0.21vw;
                background: #1E1B39;
            }
        }
    
        .section-headline-right-lines {
            .section-headline-short-line{
                margin-left: auto;
            }
        }
    }
}


@media (max-width: 768px) {
    .server-error-container{
        .section-headline {
            .error-content{
                gap: 4.5vw;
                .error-text{
                    gap: 1.5vw;
                }
            }
            .headline-text {
                .headline-heading {
                    font-size: 4.8vw;
                }
    
                .headline-subheading {
                    font-size: 3.15vw;
                }
            }
    
            .section-headline-right-lines, 
            .section-headline-left-lines {
                display: none;
            }
        }
    }
	
}

@media (max-width: 425px) {
    .server-error-container{
        .section-headline {
            .error-content{
                gap: 9vw;
                .error-text{
                    gap: 3vw;
                }
            }
            .headline-text {
                .headline-heading {
                    font-size: 5vw;
                }
    
                .headline-subheading {
                    font-size: 3.15vw;
                }
            }
        }
    }

}