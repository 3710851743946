.pipeline-list{
	width: 100%;
	height: 100%;
	padding: 1vw;
}

@media screen and (max-width: 768px) {

    .pipeline-list{
        padding: 1.5vw;
    }

}

@media screen and (max-width: 425px) {

    .pipeline-list{
        padding: 3vw;
    }
}