@use '/src/styles/variables' as styles;

.calendar {
  margin: 0vw auto;
  padding: 1.4vw;
  border-radius: styles.$roundedMd;
  max-height: 30vw;
  transition: all ease 0.4s;

  .year-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5vw;

    .month-left-arrow,
    .month-right-arrow {
      cursor: pointer;
      svg,
      img {
        width: 0.9vw;
        height: 0.9vw;

        path {
          stroke: var(--text1);
        }
      }
    }

    .left-arrow {
      display: flex;
      rotate: 180deg;
      cursor: pointer;
    }

    .right-arrow {
      display: flex;
      cursor: pointer;
      rotate: -360deg;
    }

    .years {
      color: var(--text1);
      font-size: styles.$textLg;
      font-weight: styles.$fontMedium;
    }
  }

  .day-name-and-month-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    .day-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      margin: 0 auto;
      gap: 1.4vw;
      justify-content: center;
      align-items: center;

      .today {
        background-color: var(--accentPrimary);
        color: var(--textHover)  !important;
        font-weight: styles.$fontBold;
      }

      .day {
        color: var(--text1);
        font-size: styles.$textMd;
        font-weight: styles.$fontMedium;
        border-radius: styles.$roundedSm;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.75vw;
        height: 1.75vw;
        cursor: pointer;
        transition: all ease 0.4s;

        &:hover{
          background: var(--accentPrimary);
          color: var(--textHover) !important;
        }
      }
  
      .selected {
        background-color: var(--accentPrimary);
        color: var(--textHover)  !important;
        font-weight: styles.$fontBold;
      }
  
      .other-month {
        color: var(--accentDisabled);
      }
    }

    

    .day-names {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 0.7vw auto;

      .day-name {
        font-weight: 400;
        font-size: 1.3vw;
        display: flex;
        margin-bottom: 0.3vw;
        align-items: center;
        justify-content: space-between;
        color: #898989;
        font-style: normal;
        line-height: normal;
      }
    }
  }
}


@media screen and (max-width: 768px) {

  .calendar {
    padding: 2.1vw;
    border-radius: 0.75vw;
    max-height: 42vw;

    .year-container {
      margin-bottom: 2.25vw;
  
      .month-left-arrow,
      .month-right-arrow {
        svg,
        img {
          width: 1.35vw;
          height: 1.35vw;
  
        }
      }
  
  
      .years {
        font-size: 1.875vw;
      }
    }
  
    .day-name-and-month-container {
      .day-container {
        gap: 2.1vw;
  
        .day {
          font-size: 1.66vw;
          border-radius: 0.375vw;
          width: 2.625vw;
          height: 2.625vw;
        }
      }
  
      
  
      .day-names {
        margin: 1.05vw auto;
  
        .day-name {
          font-size: 1.95vw;
          margin-bottom: 0.45vw;
        }
      }
    }
  }
  

}


@media screen and (max-width: 425px) {

  .calendar {
    padding: 4.2vw;
    border-radius: 1.5vw;
    max-height: 84vw;

    .year-container {
      margin-bottom: 4.5vw;
  
      .month-left-arrow,
      .month-right-arrow {
        svg,
        img {
          width: 2.7vw;
          height: 2.7vw;
  
        }
      }
  
  
      .years {
        font-size: 3.75vw;
      }
    }
  
    .day-name-and-month-container {
      .day-container {
        gap: 2.2vw;
  
        .day {
          font-size: 3.32vw;
          border-radius: 0.75vw;
          width: 5.25vw;
          height: 5.25vw;
  
        }
      }
  
      
  
      .day-names {
        margin: 2.1vw auto;
  
        .day-name {
          font-size: 4vw;
          margin-bottom: 0.9vw;
        }
      }
    }
  }
  

}