@use '/src/styles/variables' as styles;

.transaction-list{
	width: 100%;
	height: 100%;
	&-table{
		width: 100%;
		height: 100%;

		.transaction-icon{
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				width: 2vw;
				height: 2vw;
			}
		}
	}
}

@media screen and (max-width: 768px) {

    .transaction-list{
		&-table{
	
			.transaction-icon{
				svg {
					width: 3vw;
					height: 3vw;
				}
			}
		}
	}

}

@media screen and (max-width: 425px) {

	.transaction-list{
		&-table{
	
			.transaction-icon{
				svg {
					width: 6vw;
					height: 6vw;
				}
			}
		}
	}
}