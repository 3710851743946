@use '/src/styles/variables' as styles;

.forward-messages-container{

    display: flex;
    flex-direction: column;
    height: 42vh;
    width: 52.4vw;

    
    .forward-messages-chats-container{
        width: 100%;
        overflow-y: auto;
        font-size: styles.$textMd;
        padding:1.4vw 2.8vw;
        display: grid;
        grid-template-columns: 22.36vw 22.36vw;
        grid-gap: 1.4vw;
        margin-top: 3.69vw;
        max-height: 100%;
    }

}
