.overview-skeleton-container{
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 1vw;
    .overview-skeleton-title{
        display: flex;
		align-items: center;
		gap: 0.7vw;
		padding: 1.04vw 1.73vw;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        background-color: #1E1B39;
        p {
			color: #E8EBEE;
			font-size: 1.25vw;
			font-weight: 500;
		}
        svg{
			width: 1.25vw;
			height: 1.25vw;
		}
    }
    .overview-skeleton-content{
        padding: 1.39vw 1.73vw;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .overview-skeleton-container{
        border-radius: 1.5vw;
        .overview-skeleton-title{
            padding: 2.2vw 3.15vw;
            border-top-left-radius: 1.5vw;
            border-top-right-radius: 1.5vw;
            p {
                font-size: 1.9vw;
            }
            svg{
                width: 1.9vw;
                height: 1.9vw;
            }
        }
        .overview-skeleton-content{
            padding: 2.1vw 2.6vw;
        }
    }
}

@media screen and (max-width: 425px) {
    .overview-skeleton-container{
        border-radius: 3vw;
        .overview-skeleton-title{
            padding: 4.4vw 6.3vw;
            border-top-left-radius: 3vw;
            border-top-right-radius: 3vw;
            p {
                font-size: 3.8vw;
            }
            svg{
                width: 3.8vw;
                height: 3.8vw;
            }
        }
        .overview-skeleton-content{
            padding: 4.2vw 5.2vw;
        }
    }
}