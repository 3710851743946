@use '/src/styles/variables' as styles;

.list-item-card {
	width: 100%;
	padding: 1.04vw 1.73vw 1.73vw 1.73vw;
	box-shadow: 0.21vw 0.14vw 0.7vw 0vw rgba(0, 0, 0, 0.10);
	border-radius: 0.7vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.5vw;
		margin-bottom: 1.04vw;	

		svg{
			width: 2vw;
			height: 2vw;
		}
		
		&-title {
			color: var(--colorPrimary);
			text-transform: capitalize;
			font-size: styles.$textLg;
			
			line-height: normal;
			white-space: normal;
		}
	}

	&-description {
		font-size: styles.$textMd;
		line-height: 1.46vw;
		white-space: initial;
		margin-bottom: 2.08vw;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&-data {
		color:  #444;
		padding-bottom: 2.08vw;
		border-bottom: 0.07vw solid #898989;

		&-item{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 1vw;

			&-title {
				display: flex;
				align-items: center;
				gap: 0.7vw;
				font-size: styles.$textSm;

				svg {
					width: 1.39vw;
					height: 1.39vw;
				}
			}

			&-value {
				white-space: normal;
				font-size: styles.$textXl;
				font-weight: bold;
				text-align: right;
			}
		}
	}

	&-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1.39vw;
		font-size: styles.$textMd;
		font-weight: bold;
		text-decoration: underline;
		a{
			color: var(--text1);
			line-height: normal;
		}

		button {
			background: transparent;
			border: none;
			text-decoration: underline;
			font-size: styles.$textMd;
			font-weight: bold;
			color: var(--colorAlertText);
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 768px) {
	.list-item-card {
		padding: 1.56vw 2.5vw 2.5vw 2.5vw;
		border-radius: 1.05vw;
	
		&-title {
			margin-bottom: 1.56vw;	
			font-size: 1.875vw;
		}
	
		&-description {
			font-size: 1.66vw;
			line-height: 2.19vw;
			margin-bottom: 3.12vw;
		}
	
		&-data {
			padding-bottom: 3.12vw;
			border-bottom: 0.1vw solid #898989;
	
			&-item{
				gap: 1.5vw;
	
				&-title {
					gap: 1.05vw;
					font-size: 1.45vw;
	
					svg {
						width: 2vw;
						height: 2vw;
					}
				}
	
				&-value {
					font-size: 2.07vw;
				}
			}
		}
	
		&-actions {
		
			margin-top: 2vw;
			font-size: 1.66vw;
	
			button {
				font-size: 1.66vw;
			}
		}
	}
}
  
@media screen and (max-width: 425px) {
	.list-item-card {

		padding: 3.12vw 5.2vw 5.2vw 5.2vw;
		box-shadow: 0.63vw 0.42vw 2.1vw 0vw rgba(0, 0, 0, 0.10);
		border-radius: 2.1vw;

		&-title {
			margin-bottom: 3.12vw;	
			font-size: 3.75vw;
		}

		&-description {
			font-size: 3.33vw;
			line-height: 4.38vw;
			margin-bottom: 6.24vw;
		}

		&-data {
			padding-bottom: 6.27vw;
			border-bottom: 0.21vw solid #898989;

			&-item{
				gap: 3vw;

				&-title {
					gap: 2.1vw;
					font-size: 2.91vw;

					svg {
						width: 4.2vw;
						height: 4.2vw;
					}
				}

				&-value {
					font-size: 4.14vw;
				}
			}
		}

		&-actions {
			margin-top: 3.2vw;
			font-size: 3.33vw;

			button {
				font-size:  3.33vw;
			}
		}
	}
}
  