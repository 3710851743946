@use '/src/styles/variables' as styles;

.document-list {
	padding: 1vw;

	.document-list-empty {
		font-size: 1.11vw;
		font-weight: 600;
		text-align: center;
	}

	.document-item-container {
		position: relative;

		.file-item-container {
			box-shadow: 0.21vw 0.139vw 0.7vw 0vw rgba(0, 0, 0, 0.10);
			border-radius: 0.7vw;
			height: 15vw;

			&.document {
				padding-top: 3vw;
				background: var(--backgroundMain);
				max-width: 100%;

				svg {
					height: 8vw;

					path {
						fill: var(--accentPrimary)
					}
				}

				.file-list-modal-files-name-wrapper {
					width: 100%;
					background: var(--accentPrimary);
					padding: 0.5vw 1vw;
					border-bottom-left-radius: 0.7vw;
					border-bottom-right-radius: 0.7vw;
				}

				.file-list-modal-files-name {
					font-size: styles.$textMd;
					text-align: left;
				}
			}

		}

		.file-item-button-delete {
			cursor: pointer;
			background: none;
			border: none;
			position: absolute;
			top: 0.4vw;
			right: 0.4vw;
			z-index: 3;

			svg {
				background: #fff;
				border-radius: 0.5vw;
				height: 2vw;
			}
		}
		.audio-message-container {
			width: auto;
		}
	}

}

@media screen and (max-width: 768px) {

	.document-list {
		padding: 1.5vw;
	
		.document-list-empty {
			font-size: 1.66vw;
		}
	
		.document-item-container {
			.file-item-container {
				box-shadow: 0.3vw 0.21vw 1.05vw 0vw rgba(0, 0, 0, 0.10);
				border-radius: 1.05vw;
				height: 22.5vw;
	
				&.document {
					padding-top: 4.5vw;
	
					svg {
						height: 12vw;
	
					}
	
					.file-list-modal-files-name-wrapper {
						padding: 0.75vw 1.5vw;
						border-bottom-left-radius: 1.05vw;
						border-bottom-right-radius: 1.05vw;
					}
	
					.file-list-modal-files-name {
						font-size: 1.66vw;
					}
				}
	
			}
	
			.file-item-button-delete {
				top: 0.6vw;
				right: 0.6vw;
	
				svg {
					height: 3vw;
				}
			}
		}
	
	}

}

@media screen and (max-width: 425px) {

	.document-list {
		padding: 3vw;
	
		.document-list-empty {
			font-size: 3.32vw;
		}
	
		.document-item-container {
			.file-item-container {
				box-shadow: 0.6vw 0.42vw 2.1vw 0vw rgba(0, 0, 0, 0.10);
				border-radius: 2.1vw;
				height: 45vw;
	
				&.document {
					padding-top: 9vw;
	
					svg {
						height: 36vw;
	
					}
	
					.file-list-modal-files-name-wrapper {
						padding: 1.5vw 3vw;
						border-bottom-left-radius: 2.1vw;
						border-bottom-right-radius: 2.1vw;
					}
	
					.file-list-modal-files-name {
						font-size: 3.32vw;
					}
				}
	
			}
	
			.file-item-button-delete {
				top: 1.2vw;
				right: 1.2vw;
				svg {
					height: 6vw;
				}
			}
		}
	
	}

}