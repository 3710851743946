@use '/src/styles/variables' as styles;

.chat-info-slider{
    position: absolute;
    right: 0;
    background: var(--backgroundSubContainer);
    height: calc(100% - 6.25vw);
    width: 50%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease;
    box-shadow: 3px 2px 0.7vw 0px rgba(0, 0, 0, 0.10);
    
    .chat-info-slider-container{
        background: var(--backgroundMain);
        margin: 0.41vw 1.25vw;
        border-radius: 0.7vw;
        padding: 1vw 1.52vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.chat-info-slider-header-container{
            padding: 2.5vw;

            .chat-info-slider-header-avatar{
                width: 8.88vw;
                height: 8.88vw;
                border-radius: 50%;
    
                img, svg{
                    width: 100%;
                    height: 100%;
                }
            }
            
            .chat-info-slider-header-text-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5vw;
    
                .chat-info-slider-header-title {
                    input, p{
                        text-align: center;
                        font-size: styles.$textXl;
                        font-weight: styles.$fontBold;
                    }
                }
    
                .chat-info-slider-header-subtitle{
                    input, p{
                        text-align: center;
                        font-size: styles.$textLg;
                        font-weight: styles.$fontMedium;
                    }
                }
            }
        }

        .chat-info-slider-text{
            width: 100%;
            overflow: visible;
            white-space: inherit;
            font-size: styles.$textLg;
            color: var(--text1);
            font-weight: styles.$fontLight;
        }

        .chat-info-slider-more-data-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            cursor: pointer;

            .chat-info-slider-more-data-arrow{
                width: 0.9vw;
                height: 1.8vw;
                fill: var(--text1);
                margin-left: 0.83vw;
            }

            .chat-info-slider-more-data-arrow-container{
                width: fit-content;
                display: flex;
                align-items: center;
            }
        }

        .chat-info-slider-participants-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 1.39vw;

            .chat-info-slider-participants-search{
                width: 1.11vw;
                height: 1.11vw;
                cursor: pointer;
            }
        }

        .chat-info-slider-participants-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 9.7vw;
            overflow-y: scroll;

        }

        .chat-info-pinned-messages-container{
            width: 100%;
            height: 2vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg{
                height: 50%;
            }

            .pinned-messages-text-container{
                width: 95%;
                height: 2vw;
                display: flex;
                justify-content: left;
                align-items: center;
                gap: 1vw;

                svg{
                    path {
                        fill: var(--text1);
                    }
                    height: 50%;
                }
            }
        }

        .setting-field{
            display: flex;
            border-bottom: none;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            .setting-field-content{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 1.5vw;
            }

            p{
                font-family: styles.$fontMain;
                font-size: styles.$textLg;
                color: var(--text1);
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .chat-info-slider{
        box-shadow: 3px 2px 1.05vw 0px rgba(0, 0, 0, 0.10);
        height: calc(100% - 9.375vw);
        width: 75%;
        
        .chat-info-slider-container{
            margin: 0.615vw 1.875vw;
            border-radius: 1.05vw;
            padding: 1.5vw 2.28vw;

            &.chat-info-slider-header-container{
                padding: 3.75vw;

                .chat-info-slider-header-avatar{
                    width: 13.32vw;
                    height: 13.32vw;
                }
                
                .chat-info-slider-header-text-container{
                    gap: 0.75vw;
        
                    .chat-info-slider-header-title {
                        input, p{
                            font-size: 2.07vw;
                        }
                    }
        
                    .chat-info-slider-header-subtitle{
                        input, p{
                            font-size: 1.875vw;
                        }
                    }
                }
            }

            .chat-info-slider-text{
                font-size: 1.875vw;
            }

            .chat-info-slider-more-data-container{

                .chat-info-slider-more-data-arrow{
                    width: 1.35vw;
                    height: 2.7vw;
                    margin-left: 1.245vw;
                }
            }

            .chat-info-slider-participants-header{
                margin-bottom: 2.1vw;

                .chat-info-slider-participants-search{
                    width: 1.66vw;
                    height: 1.66vw;
                }
            }

            .chat-info-slider-participants-container{
                max-height: 14.55vw;
            }

            .chat-info-pinned-messages-container{
                height: 3vw;

                .pinned-messages-text-container{
                    height: 3vw;
                    gap: 1.5vw;

                }
            }

            .setting-field{
                .setting-field-content{
                    gap: 2.25vw;
                }

                p{
                    font-size: 1.875vw;
                }
            }
        }
    }

}

@media screen and (max-width: 425px) {

    .chat-info-slider{
        box-shadow: 3px 2px 2.1vw 0px rgba(0, 0, 0, 0.10);
        height: calc(100% - 18.75vw);
        width: 100%;

        .chat-info-slider-container{
            margin: 1.23vw 3.75vw;
            border-radius: 2.1vw;
            padding: 3vw 4.5vw;

            &.chat-info-slider-header-container{
                padding: 7.5vw;

                .chat-info-slider-header-avatar{
                    width: 26.64vw;
                    height: 26.64vw;
                }
                
                .chat-info-slider-header-text-container{
                    gap: 1.5vw;
        
                    .chat-info-slider-header-title {
                        input, p{
                            font-size: 4.14vw;
                        }
                    }
        
                    .chat-info-slider-header-subtitle{
                        input, p{
                            font-size: 3.75vw;
                        }
                    }
                }
            }

            .chat-info-slider-text{
                font-size: 3.75vw;
            }

            .chat-info-slider-more-data-container{

                .chat-info-slider-more-data-arrow{
                    width: 2.7vw;
                    height: 5.4vw;
                    margin-left: 2.49vw;
                }
            }

            .chat-info-slider-participants-header{
                margin-bottom: 4.2vw;

                .chat-info-slider-participants-search{
                    width: 3.32vw;
                    height: 3.32vw;
                }
            }

            .chat-info-slider-participants-container{
                max-height: 29.1vw;
            }

            .chat-info-pinned-messages-container{
                height: 6vw;

                .pinned-messages-text-container{
                    height: 6vw;
                    gap: 3vw;

                }
            }

            .setting-field{
                .setting-field-content{
                    gap: 4.5vw;
                }

                p{
                    font-size: 3.75vw;
                }
            }
        }
    }

}