.time-usage-data{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    p{
        font-size: 1.11vw;
    }
}

@media (max-width: 768px) {
    .time-usage-data{
        gap: 1.5vw;
        p{
            font-size: 1.66vw;
        }
    }
}

@media (max-width: 425px) {
    .time-usage-data{
        gap: 3vw;
        p{
            font-size: 3.2vw;
        }
    }
}