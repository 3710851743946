@use '/src/styles/variables' as styles;
.audio-wave {
  min-width: 0.34vw;
  max-width: 0.34vw;
  opacity: 0;
  background: var(--accentPrimary);
  border-radius: styles.$roundedMd;
  margin: 0 0.1vw;
  transition: all ease 0.2s;
  --waveFillingAccent: #444;
  --waveAccent: #fff;

  &.audio-wave-appeared{
    opacity: 1;
  }
}


@media screen and (max-width: 768px) {

  .audio-wave {
  
    min-width: 0.5vw;
    max-width: 0.5vw;
    margin: 0 0.15vw;
  }

}

@media screen and (max-width: 425px) {

  .audio-wave {
  
    min-width: 1vw;
    max-width: 1vw;
    margin: 0 0.3vw;
  }

}