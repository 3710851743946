@use '/src/styles/variables' as styles;

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    padding: 0.55vw;
    border-radius: styles.$roundedMd;
    transition: styles.$transition;
    width: 100%;
    gap: 0.7vw;
    border: none;
    background: transparent;
	
    cursor: pointer;

    .arrow-icon-container{
        width: 1.7vw;
        height: 1.7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: styles.$roundedMd;
        background-color: rgba($color: #fff, $alpha: 0.14);

        svg{
            width: 1vw;
            height: 1vw;
            path{
                stroke: #fff;
            }
        }

    }

    .button-icon {
        display: flex;
        svg{
            width: 1.3vw;
            height: 1.3vw;
            transition: styles.$transition;
            path{
                transition: styles.$transition;
            }
        }
    }

    p {
        color: var(--text1) ;
        font-weight: styles.$fontLight;
        font-size: styles.$textSm;
        line-height: 1.31vw;
    }

    &.button-active{
        background-color: var(--accentPrimary);
        
        p{
            color: #fff;
            -webkit-text-fill-color: #fff;
        }

		&.button-outlined {
			border: 0.14vw solid var(--accentPrimary);
			background: transparent;

			p{
                -webkit-text-fill-color: var(--accentPrimary);
				color: var(--accentPrimary);
			}
		}
    }
  
    &:disabled {
      background-color: var(--accentDisabled);
      pointer-events: none;
    }

    &:hover{
        background-color: var(--accentPrimary);
        scale: 1.02;
        p{
            color: #fff;
        }

        & .icon svg{
            fill: var(--textHover);
            path{
                fill:var(--textHover);
            }
        }
    }

	&.button-inverted {
		&.button-outlined {
			border: 0.14vw solid var(--backgroundMain);
			background: transparent;
		}
		p {
			color: var(--backgroundMain);
		}
		&.button-active{
			background-color: var(--backgroundMain);
			p{
                -webkit-text-fill-color: var(--accentPrimary);
				color: var(--accentPrimary);
			}
		}
	}
}


@media screen and (max-width: 768px) {
	.button{
        .arrow-icon-container{
            width: 2vw;
            height: 2vw;

            svg{
                width: 1.5vw;
                height: 1.5vw;
            }
        }
        padding: 0.825vw;
        border-radius: 0.75vw;
        gap: 1.05vw;
    
        .button-icon{
            svg{
                width: 1.95vw;
                height: 1.95vw;
            }
        }
    
        p{
            font-size: 1.45vw;
            line-height: 2vw;
        }
    
    }
}
  
@media screen and (max-width: 425px) {
    .button{
        .arrow-icon-container{
            width: 2.5vw;
            height: 2.5vw;

            svg{
                width: 2vw;
                height: 2vw;
            }
        }
        padding: 1.65vw;
        border-radius: 1.5vw;
        gap: 2.1vw;

        .button-icon{
            svg{
                width: 3vw;
                height: 3vw;
            }
        }

        p{
            font-size: 2.91vw;
            line-height: 3.93vw;
        }
    }
}
  